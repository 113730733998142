import {
 TagLabel, Tag
} from "@chakra-ui/react";

const StatusTag = ({status}) => {
    let colorScheme;

    switch (status) {
        case 'Submitted':
            colorScheme = 'gray';
            break;
        case 'Rejected':
            colorScheme = 'red';
            break;
        case 'Accepted':
            colorScheme = 'green';
            break;
        case 'Ready':
            colorScheme = 'green';
            break;
        case 'Paid':
            colorScheme = 'blue';
            break;
        case 'Failed':
            colorScheme = 'orange';
            break;
        default:
            colorScheme = 'gray';
    }

    return (
        <Tag size='lg' colorScheme={colorScheme} borderRadius='full'>
            <TagLabel>{status}</TagLabel>
        </Tag>
    );
};
export default StatusTag;
