import React, {useEffect, useState} from "react";
import {
    Box, Flex, FormControl, FormLabel, Grid, GridItem, Heading, Input, InputGroup, Select, Text,
} from "@chakra-ui/react";
import utils from "../../../utils/commonFunctions";
import {useUserProfile} from "../../../providers/UserProfileProvider";
import enums from "wuc-library/enums";
import {useLocation} from "react-router-dom";
import {SelectInput} from "../../../components/Applications/Forms/Inputs";

const CustomerDetails = () => {
    const getMinAgeDate = () => {
        const today = new Date();
        const minAge = 18;
        today.setFullYear(today.getFullYear() - minAge);
        return today.toISOString().split('T')[0];
    };
    const [date, setDate] = useState(getMinAgeDate());

    const location = useLocation();
    const form = JSON.parse(localStorage.getItem('customerDetailsConnection'));
    let {personalDetails,customerNumber}=location?.state?.application||{}
    if(personalDetails&&!form){
        localStorage.setItem('customerDetailsConnection',JSON.stringify({...personalDetails,customerNumber}))
    }

    let {userProfile}=useUserProfile()
    if(userProfile&&!personalDetails&&!form){
        let {personalDetails,customerNumber}=userProfile||{}
        localStorage.setItem('customerDetailsConnection',JSON.stringify({...personalDetails,customerNumber}))
    }


    const [showType, setShowType] = useState(form?.maritalStatus === enums.MaritalStatus.MARRIED);
    const [formData, setFormData] = useState({
        userId: utils.getUserId(),
        spouseCustomerNumber: form?.spouseCustomerNumber || null,
        customerNumber: form?.customerNumber || null,
        firstName: form?.firstName || null,
        middleName: form?.middleName || null,
        lastName: form?.lastName || null,
        identityNo: form?.identityNo || null,
        identityType: form?.identityType || null,
        maritalStatus: form?.maritalStatus || null,
        maritalType: (form?.maritalStatus === enums.MaritalStatus.MARRIED) ? form?.maritalType : null,
        sex:form?.sex||null,
        dateOfBirth:form?.dateOfBirth||null
    });

    // Effect to handle localStorage update on formData change
    useEffect(() => {
        localStorage.setItem('customerDetailsConnection', JSON.stringify(formData));
        const { middleName,spouseCustomerNumber, customerNumber,...exclMiddleName }=formData
        const { maritalType, ...exclMaritalType } = exclMiddleName;

        let dataForm=(formData?.maritalStatus==='Married')?exclMiddleName :exclMaritalType
        if (Object.values(dataForm).some(detail => detail === null||detail === ""||!detail)) {
            localStorage.setItem('isValidForm', 'false');
        } else {
            localStorage.setItem('isValidForm', 'true');
        }

    }, [formData]);

    // Effect to update showType based on maritalStatus
    useEffect(() => {
        const data = { ...formData };
        if (formData?.maritalStatus !== enums.MaritalStatus.MARRIED) {
            data.maritalType = null; // Clear maritalType when not married
            setShowType(false);
        } else {
            setShowType(true);
        }
        localStorage.setItem('customerDetails', JSON.stringify(data)); // Update local storage
    }, [formData.maritalStatus, formData.maritalType]);

    const handleInputChange = (e) => {
        const {name, value,id} = e.target;
        setFormData(prevState => ({
            ...prevState, [name]: value
        }));
    };
    const handleDate = (e) => {
        const {value} = e.target;
        let minDate=getMinAgeDate()
        console.log(formData.dateOfBirth)
        if(minDate<value){
            setDate(getMinAgeDate())
        }
        else{
            setDate(value)
        }
    };
    const genderOptions = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' }
    ];

    const identityTypeOptions = [
        { value: 'Omang', label: 'Identity card (Omang)' },
        { value: 'Passport', label: 'Passport' }
    ];
    const maritalStatusOptions = [
        { value: enums.MaritalStatus.SINGLE, label: 'Single' },
        { value: enums.MaritalStatus.MARRIED, label: 'Married' },
        { value: enums.MaritalStatus.DIVORCED, label: 'Divorced' },
        { value: enums.MaritalStatus.WIDOWED, label: 'Widowed' }
    ];
    const marriageTypeOptions = [
        { value: 'In Community Of Property', label: 'In Community Of property'},
        { value: 'Out Of Community Of Property', label: 'Out Of Community Of property' }
    ];

    return (<>
            <Box>
                <Flex mb={4}>
                    <Box>
                        <Heading color={'brand.500'}>Personal Details</Heading>
                        <Text color={'gray.500'}>Your simple personal information required for identification</Text>
                    </Box>
                </Flex>
                <Grid templateColumns="repeat(12, 1fr)" gap={2} color={"blue.800"}>
                    {/* Firstname */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl id="firstname" isRequired >
                            <FormLabel>Firstname</FormLabel>
                            <InputGroup>
                                <Input  type="text" name="firstName" id='Firstname' value={formData.firstName} onChange={handleInputChange}/>
                            </InputGroup>
                        </FormControl>
                    </GridItem>

                    {/* Middle Name */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl id="middlename" >
                            <FormLabel>Middle Name</FormLabel>
                            <InputGroup>
                                <Input  type="text" name="middleName" id='Middlename' value={formData.middleName}
                                       onChange={handleInputChange}/>
                            </InputGroup>
                        </FormControl>
                    </GridItem>

                    {/* Lastname */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl id="lastname" isRequired>
                            <FormLabel >Lastname</FormLabel>
                            <InputGroup>
                                <Input  type="text" name="lastName" id="Lastname"
                                       value={formData.lastName} onChange={handleInputChange}/>
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                    {/* Gender */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <SelectInput
                            id={"Gender"}
                            name={"sex"}
                            placeholder="-- Select gender --"
                            options={genderOptions}
                            value={utils.getOptionByValue(genderOptions,formData.sex)}
                            label={"Gender"}
                            callback={handleInputChange}
                        />
                    </GridItem>

                    {/* Identity Number */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isDisabled id="identityNumber" isRequired>
                            <FormLabel >Identity Number</FormLabel>
                            <InputGroup>
                                <Input  type="number" name="identityNo"  id="Identity Number"
                                       value={formData.identityNo} onChange={handleInputChange}/>
                            </InputGroup>
                        </FormControl>
                    </GridItem>

                    {/* Identity Type */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <SelectInput
                            id={"Identity Document Type"}
                            name={"identityType"}
                            placeholder="-- Select option --"
                            options={identityTypeOptions}
                            value={utils.getOptionByValue(identityTypeOptions,formData.identityType)}
                            label={"Identity Type"}
                            callback={handleInputChange}
                        />
                    </GridItem>
                    {/* Date of brith*/}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isDisabled id="dateOfBirth" isRequired>
                            <FormLabel >Date of Birth</FormLabel>
                            <InputGroup>
                                <Input   type="date" name="dateOfBirth" id="Date Of Birth"

                                       max={getMinAgeDate()}
                                       value={formData.dateOfBirth}  onChange={(event) => {
                                    handleInputChange(event);
                                    handleDate(event);
                                }}/>
                            </InputGroup>
                        </FormControl>
                    </GridItem>

                    {/* Marital Status */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <SelectInput
                            name={"maritalStatus"}
                            id={"maritalStatus"}
                            placeholder="-- Select Status --"
                            options={maritalStatusOptions}
                            value={utils.getOptionByValue(maritalStatusOptions,formData.maritalStatus)}
                            label={"Marital Status"}
                            callback={handleInputChange}
                        />
                    </GridItem>

                    {/* Marriage Type */}
                    {showType && (<GridItem colSpan={{base:12,lg:6}}>
                        <SelectInput
                            id={"maritalType"}
                            name={"maritalType"}
                            placeholder="-- Select option --"
                            options={marriageTypeOptions}
                            value={utils.getOptionByValue(marriageTypeOptions,formData.maritalType)}
                            label={"Marriage Type"}
                            callback={handleInputChange}
                        />
                    </GridItem>)}
                    {/* Cutomer Number */}
                    <GridItem isDisabled colSpan={{base:12,lg:6}}>
                        <FormControl id="customerNumber" >
                            <FormLabel >Customer Number</FormLabel>
                            <InputGroup>
                                <Input  type="number" name="customerNumber"  id="Customer Number"
                                       value={formData.customerNumber} onChange={handleInputChange}/>
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                    {/* Spouse number */}
                    {showType && (<GridItem colSpan={{base:12,lg:6}}>
                        <FormControl id="spouseCustomerNumber" >
                            <FormLabel >Spouse Customer Number</FormLabel>
                            <InputGroup>
                                <Input  type="number" name="spouseCustomerNumber" id="Spouse Customer Number"
                                       value={formData.spouseCustomerNumber} onChange={handleInputChange}/>
                            </InputGroup>
                        </FormControl>
                    </GridItem>)}
                    {/* Gender */}
                </Grid>
            </Box>
        </>);
};

export default CustomerDetails;
