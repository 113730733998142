// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html {
    padding: 0;
    margin: 0;
}
.page__loader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: white;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
}
.page__loader svg {
    height: 282px;
    z-index: 10001;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-44%, -55%);
}
.page__loader #rect4487 {
    fill: white;
}
.loader {
    height: 200px;
    width: 174px;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    border-radius: 50%;
}
.water {
    background: #2BBCF2;
    position: relative;
    top: 50%;
    height: 200%;
    width: 200%;
    z-index: 1000;
    border-radius: 38%;
    left: -50%;
    transform: rotate(360deg);
    transition: all 1s ease;
    animation: wave 5s linear infinite, movetop 10s linear forwards;
}
@keyframes movetop {
    0% {
        top: 60%;
    }
    100% {
        top: 10px;
    }
}
@keyframes wave {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Thothi/Thothi.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,SAAS;AACb;AACA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,uBAAuB;IACvB,sBAAsB;AAC1B;AACA;IACI,aAAa;IACb,cAAc;IACd,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;AACpC;AACA;IACI,WAAW;AACf;AACA;IACI,aAAa;IACb,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;IACd,kBAAkB;AACtB;AACA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,QAAQ;IACR,YAAY;IACZ,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,UAAU;IACV,yBAAyB;IACzB,uBAAuB;IACvB,+DAA+D;AACnE;AACA;IACI;QACI,QAAQ;IACZ;IACA;QACI,SAAS;IACb;AACJ;AACA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":["body, html {\n    padding: 0;\n    margin: 0;\n}\n.page__loader {\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    z-index: 1000;\n    background-color: white;\n    display: flex;\n    text-align: center;\n    justify-content: center;\n    flex-direction: column;\n}\n.page__loader svg {\n    height: 282px;\n    z-index: 10001;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-44%, -55%);\n}\n.page__loader #rect4487 {\n    fill: white;\n}\n.loader {\n    height: 200px;\n    width: 174px;\n    overflow: hidden;\n    position: relative;\n    margin: 0 auto;\n    border-radius: 50%;\n}\n.water {\n    background: #2BBCF2;\n    position: relative;\n    top: 50%;\n    height: 200%;\n    width: 200%;\n    z-index: 1000;\n    border-radius: 38%;\n    left: -50%;\n    transform: rotate(360deg);\n    transition: all 1s ease;\n    animation: wave 5s linear infinite, movetop 10s linear forwards;\n}\n@keyframes movetop {\n    0% {\n        top: 60%;\n    }\n    100% {\n        top: 10px;\n    }\n}\n@keyframes wave {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
