import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    InputGroup,
    Input,
    Text,
    InputRightElement,
    IconButton,
    ModalCloseButton,
    ModalHeader,
    Flex,
    Stack,
    Badge,
    Center
} from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';
import { useSearch } from "../../../providers/searchProvider";
import Paginate from "../../Paginate/Paginate";
import {useNavigate} from "react-router-dom";

const SearchInput = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const { setQuery } = useSearch();

    const handleSearch = () => {
        setQuery({
            page: 1,
            limit: 5,
            query: searchQuery
        });
    };

    return (
        <InputGroup w="auto">
            <Input
                placeholder="Search"
                borderRadius="lg"
                border="none"
                bg="gray.100"
                color="#686869"
                _placeholder={{ color: "#686869" }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <InputRightElement>
                <IconButton
                    aria-label="Search"
                    icon={<FaSearch color="white" />}
                    bg="brand.700"
                    _hover={{ bg: "brand.700" }}
                    borderRadius="lg"
                    onClick={handleSearch}
                />
            </InputRightElement>
        </InputGroup>
    );
};

const ResultComponent = ({ title, type }) => {
    const navigate= useNavigate()
    const redirectEvents=(type)=>{
        let category=type?.toLowerCase()
        if(category==='event'){
            navigate('/media/events')
        }
        if(category==='faq'){
            navigate('/media/faqs')
        }
        if(category==='news'){
            navigate('/news')
        }
        if(category==='photoAlbums'){
            navigate('/media/pictures')
        }
        if(category==='notice'){
            navigate('/notices')
        }
        if(category==='publication'){
            navigate('/publications')
        }
        if(category==='tender'){
            navigate('/tenders')
        }
        if(category==='video'){
            navigate('/media/videos')
        }

    }
    return (
        <Flex direction="column" p={4} borderWidth="2px" borderColor="gray.200" borderRadius="md" w="full">
            <Text onClick={()=>redirectEvents(type)} cursor={'pointer'} _hover={{textDecoration:'underline'}} fontWeight="semibold" color="gray.600" fontSize="lg" textTransform="capitalize" isTruncated whiteSpace="nowrap">
                {title}
            </Text>
            <Badge w="fit-content" color="gray.600" fontSize="12px" textTransform="capitalize">
                {type}
            </Badge>
        </Flex>
    );
};

const SearchModal = ({ onClose, isOpen }) => {
    const {pagination,setCurrentPage} = useSearch();
    return (
        <Modal closeOnOverlayClick={false} isCentered isOpen={isOpen} onClose={onClose} size={{ base: "full", md: '2xl' }}>
            <ModalOverlay />
            <ModalContent p={4} h="98vh">
                <ModalHeader>Search</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <SearchInput />
                    {pagination.pageData.length ? (
                        <Stack gap={2} mt={2}>
                            {pagination.pageData.map((result, index) => (
                                <ResultComponent key={index} title={result.title || result.question} type={result.type} />
                            ))}
                            <Paginate currentPage={pagination.page} numberOfPages={pagination.totalPages} setCurrentPage={setCurrentPage}/>


                        </Stack>
                    ) : (
                        <Center w="full" h="full">
                            <Text fontSize="xl">No Records Found</Text>
                        </Center>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default SearchModal;
