import {
    Box,
    Button,
    Text,
    Icon,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
} from "@chakra-ui/react";
import React, {useEffect} from "react";
import {FaCircleCheck, FaCircleXmark} from "react-icons/fa6";
import {Link} from "react-router-dom";
import utils from "../../../../utils/commonFunctions";
import {Flex, useDisclosure} from "@chakra-ui/react";
import {HashLoader, PropagateLoader} from "react-spinners";

const LoaderModal = ({ isOpen,onClose}) => {

  return (
    <>
      <Modal  bg={'transparent'} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered={true} zIndex={10000}>
        <ModalOverlay />
        <ModalContent bg={'transparent'} p={4} borderRadius={"xl"} zIndex={10000}>
          <ModalBody bg={'transparent'} d={'flex'} justifyContent={'center'} align={'center'}>
              <Flex
                  bg={'transparent'}
                  justifyContent="center"
                  alignItems="center"
                  w={'full'} h={'full'}
              >
                <PropagateLoader color="#0093D0" />
              </Flex>

          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LoaderModal;
