import React, { useState, useEffect } from "react";
import {

    Heading,
    Flex, Image, Grid, GridItem, Button,
    Center
} from "@chakra-ui/react";
import { Box } from "@chakra-ui/layout";
import { usePhoto } from "../../../providers/PhotosProvider";
import {API} from "../../../utils/api";
import { useDisclosure } from "@chakra-ui/react";
import ViewProjectModal from "./ViewProjectModal"; // Ensure this import is correct
//import navigator to route to the project page
import { useNavigate } from 'react-router-dom';

const Project = ({ projectData }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigator = useNavigate();

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
            return text;
        }
        return text.slice(0, maxLength) + '...';
    };

    const date = new Date(projectData.startDate);
    const day = date.getDate();
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    const handleClick = (projectId) => {
        if (projectId)
            navigator('/projects/view', { state: { projectId: projectId } });
    }

    // const handleClick = () => {
    //     onOpen();
    // };

    return (
        <>
            <Flex cursor={'pointer'} onClick={() => handleClick(projectData._id)} direction={'column'} justify={'center'} align={'center'} bg={'white'} pb={4} boxShadow="md" borderRadius={'2xl'}>
                <Flex
                    as={'button'}
                    width="full"
                    justifyContent="center"
                    alignItems="end"
                    borderTopRadius="2xl"
                    height={'200px'}
                >
                    <Center>
                        <Image
                            h={'200px'} w={'auto'} src={projectData.imagesDocumentsKeys[0].key}
                            mx={'auto'}
                            transition="transform 0.5s, filter 0.5s ease-in-out"
                            _hover={{
                                transform: `scale(100%)`,
                                filter: "brightness(75%)",
                            }}
                            fallbackSrc='https://via.placeholder.com/250'
                        />
                    </Center>
                </Flex>
                <Flex p={4} gap={4} height="120px">
                    <Flex direction={'column'} textAlign={'center'}>
                        <Heading size={'sm'} color={'purple.600'} fontWeight={'medium'} w={'full'}>{day}</Heading>
                        <Heading size={'sm'} color={'blue.800'} fontWeight={'bold'} w={'full'}>{month}</Heading>
                        <Heading size={'sm'} color={'blue.800'} fontWeight={'bold'} w={'full'}>{year}</Heading>
                    </Flex>
                    <Box>
                        <Heading size={'sm'} color={'blue.800'} fontWeight={'bold'} noOfLines={2} w={'full'}>{projectData.title}</Heading>
                        <Heading size={'sm'} color={'gray.400'} fontWeight={'normal'} noOfLines={2} mt={2} w={'full'}>
                            <div dangerouslySetInnerHTML={{ __html: truncateText(projectData.description, 15) }} />
                        </Heading>
                    </Box>
                </Flex>
                <Flex direction={'column'} justify={'center'}>
                    <Button w={'200px'} bg={'brand.700'} boxShadow={'md'} size={'lg'} _hover={{ color: 'white', bg: 'brand.600' }}>View</Button>
                </Flex>
            </Flex>
            <ViewProjectModal
                isOpen={isOpen}
                onClose={onClose}
                projectData={projectData}
            />
        </>
    );
};

const Projects = () => {
    const { setPhoto } = usePhoto();
    const [projectsArr, setProjectsArr] = useState([]);

    const fetchProjects = async () => {
        try {
            const response = await API.get(`/projects`);
            console.log("projects fetched: ", response.data);
            setProjectsArr(response.data);
        } catch (error) {
            console.error("Error fetching projects: ", error);
        }
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    return (
        <Flex justify={'center'} align={'center'} py={8} w={'full'}>
            <Box>
                <Heading size="xs" mb={2} textAlign={'center'} color={'blue.800'}>Activities</Heading>
                <Heading size="lg" mb={4} textAlign={'center'} color={'blue.800'}>Projects </Heading>
                <Grid templateColumns='repeat(12, 1fr)' gap={4}>
                    {projectsArr.map((project, index) => (
                        <GridItem key={index} colSpan={{ base: 12, md: 4, lg: 3 }}>
                            <Project projectData={project} />
                        </GridItem>
                    ))}
                </Grid>
            </Box>
        </Flex>
    );
};

export default Projects;
