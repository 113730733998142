import React, { useEffect, useState } from "react";
import {
    Flex, Stack, Heading, Table, TableContainer, Thead, Tr, Th, Tbody, Td, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, Text, useBreakpointValue
} from "@chakra-ui/react";
import moment from "moment";
import Paginate from "../../../components/Paginate/Paginate";
import utils from "../../../utils/commonFunctions";
import { useQuoteCart } from "../../../providers/QuoteCartProvider";
import { HashLoader } from "react-spinners";

const TransactionsTable = () => {
    const [checkedBills, setCheckedBills] = useState({ docs: [], totalPages: 1 });
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const { refreshFlag, setRefreshFlag } = useQuoteCart();
    
    const displayAsAccordion = useBreakpointValue({ base: true, md: false });

    const fetchData = async () => {
        const userId = utils.getUserId();
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
        threeMonthsAgo.setHours(0, 0, 0, 0);
        const query = {
            customerId: userId,
            hasToken: true,
            updatedAtGte: threeMonthsAgo,
            pageNumber: currentPage,
            pageSize: 6
        };

        setLoading(true);

       const fetchedBills = await utils.getPrepaid(query);
        setCheckedBills(fetchedBills);
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    useEffect(() => {
        if (refreshFlag) {
            fetchData();
        }
    }, [refreshFlag]);

    return (
        <Stack p={8} bg="white" w="full" h="full" borderRadius={'2xl'}>
            <Flex align="center" py={2.5} px={8} w="full">
                <Heading color="blue.800" fontSize="xl">Successful Transactions</Heading>
            </Flex>
            {displayAsAccordion ? (
                <Accordion allowMultiple>
                    {!loading ? (
                        checkedBills?.docs?.length > 0 ? (
                            checkedBills?.docs?.map((bill, index) => (
                                <AccordionItem key={index}>
                                    <AccordionButton>
                                        <Box flex="1" fontWeight={'semibold'} textAlign="left">
                                            {bill.token}
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel pb={4}>
                                        <Text><strong>Customer Name:</strong> {bill.fullName}</Text>
                                        <Text><strong>Meter Number:</strong> {bill.meterNo}</Text>
                                        <Text><strong>Amount:</strong> {utils.formatCurrency(bill.amount)}</Text>
                                        <Text><strong>Token:</strong> {bill?.token}</Text>
                                        <Text><strong>Units:</strong> {bill?.units}</Text>
                                        <Text><strong>Date Checked:</strong> {moment(bill?.updatedAt).format("DD/MM/YYYY")}</Text>
                                    </AccordionPanel>
                                </AccordionItem>
                            ))
                        ) : (
                            <Flex justifyContent="center" align="center" w="full" h="full" py={16}>
                                <Heading size="lg" color="gray.500">No Record Found</Heading>
                            </Flex>
                        )
                    ) : (
                        <Flex justifyContent="center" align="center" w="full" h="full">
                            <HashLoader color="#2bbcf2"/>
                        </Flex>
                    )}
                </Accordion>
            ) : (
                <TableContainer p={8} w="full" h="full">
                    <Table colorScheme="gray">
                        <Thead>
                            <Tr>
                                <Th color="blue.800" fontSize={'sm'} textTransform="capitalize">Customer Name</Th>
                                <Th color="blue.800" fontSize={'sm'} textTransform="capitalize">Meter Number</Th>
                                <Th color="blue.800" fontSize={'sm'} textTransform="capitalize">Amount</Th>
                                <Th color="blue.800" fontSize={'sm'} textTransform="capitalize">Token</Th>
                                <Th color="blue.800" fontSize={'sm'} textTransform="capitalize">Units</Th>
                                <Th color="blue.800" fontSize={'sm'} textTransform="capitalize">Transaction Date</Th>
                            </Tr>
                        </Thead>
                        {!loading ? (
                            checkedBills?.docs?.length > 0 && (
                                <Tbody>
                                    {checkedBills?.docs?.map((bill, index) => (
                                        <Tr key={index}>
                                            <Td>{`${bill.firstName} ${bill.lastName}`}</Td>
                                            <Td>{bill.meterNo}</Td>
                                            <Td>{utils.formatCurrency(bill?.amount)}</Td>
                                            <Td>{bill?.token}</Td>
                                            <Td>{bill?.units}</Td>
                                            <Td>{moment(bill.updatedAt).format("DD/MM/YYYY")}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            )
                        ) : (
                            <Flex justifyContent="center" align="center" w="full" h="full">
                                <HashLoader color="#2bbcf2"/>
                            </Flex>
                        )}
                    </Table>
                </TableContainer>
            )}
            {(!checkedBills || checkedBills?.docs?.length <= 0) && (
                <Flex justifyContent="center" align="center" w="full" h="full" py={16}>
                    <Heading size="lg" color="gray.500">No Record Found</Heading>
                </Flex>
            )}
            <Paginate
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                numberOfPages={checkedBills?.totalPages || 1}
            />
        </Stack>
    );
};

export default TransactionsTable;
