import React from 'react';
import {Page, Text, View, Document, StyleSheet, Image} from '@react-pdf/renderer';
import bgImage from '../../assets/BlueLogoNoBG.png'; // Your background image
import logoImage from '../../assets/BlueLogoNoBG.png'; // Your logo image

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFF',
        position: 'relative',
        fontFamily: 'Helvetica', // Change to registered font if necessary
        fontSize: 11,
        padding: 40,
    },
    logo: {
        width: 80,
        height: 80,
        position: 'absolute',
        top: 30,
        left: '50%',
    },
    background: {
        position: 'absolute',
        minWidth: '70%',
        minHeight: '70%',
        height: '70%',
        width: '70%',
        opacity: 0.1,
        top: '50%',
        left: '50%',
        transform: 'translate(-150%, -280%)',

    },
    header: {
        marginTop: 70, // Adjust this to position your header correctly
        marginBottom: 30,
        fontSize: 15,
        textAlign: 'center',
        fontWeight: 'extrabold',
    },
    grid: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginRight: -15,
        marginLeft: -15,
        marginBottom: 15
    },
    col: {
        paddingLeft: 15,
        paddingRight: 15,
        flexBasis: '50%',

    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 8, // Add space between rows
    },
    label: {
        fontWeight: 'bold',
    },
    checkbox: {
        borderColor: 'gray',
        borderWidth: 1,
        backgroundColor: "blue",
        borderRadius: '30px',
        width: 8,
        height: 8
    },
    title: {
        fontSize: 14,
        fontWeight: 'extrabold',
        paddingLeft: 15,
        paddingRight: 15,
        flexBasis: '100%',
        marginBottom: 8
    },
    fieldValue: {
        marginLeft: 4,
        textAlign: 'justify'
    },
    // ... rest of your styles
});

const Grid = ({children}) => <View style={styles.grid}>{children}</View>;
const Col = ({children}) => <View style={styles.col}>{children}</View>;
const Row = ({children}) => <View style={styles.row}>{children}</View>;
// Your component that uses the grid
const ApplicationRequestPdf = ({pdfData}) => {
    console.log(pdfData)
    return (
        <Document>
            <Page size='A4' style={styles.page}>
                <Image style={styles.background} src={bgImage}/>
                <Image style={styles.logo} src={logoImage}/>
                <Text style={styles.header}>Application For Water Service Disconnection/ Individual Or Domestic
                    Disconnection</Text>
                <Grid>


                    <Col>
                        <Row>
                            <Text style={styles.label}>Customer Name:</Text>
                            <Text
                                style={styles.fieldValue}>{pdfData.personalDetails.firstName + " " + pdfData.personalDetails.lastName}</Text>
                        </Row>
                        <Row>
                            <Text style={styles.label}>Customer Number:</Text>
                            <Text style={styles.fieldValue}>{pdfData.customerNumber}</Text>
                        </Row>

                    </Col>
                    <Col>
                        <Row>
                            <Text style={styles.label}>PC/Plot No:</Text>
                            <Text style={styles.fieldValue}>{pdfData.address.houseNo}</Text>
                        </Row>
                    </Col>
                </Grid>

                <Grid>
                    <Text style={styles.title}>Service Details</Text>
                    <Col>
                        <Row>
                            <Row>
                                <Text style={styles.label}>Plot No:</Text>
                                <Text style={styles.fieldValue}>{pdfData.address.houseNo}</Text>
                            </Row>
                        </Row>
                        <Row>
                            <Text style={styles.label}>Area / Ward:</Text>
                            <Text style={styles.fieldValue}>{pdfData.address.ward}</Text>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Text style={styles.label}>City / Village / Town:</Text>
                            <Text style={styles.fieldValue}>{pdfData.address.city}</Text>
                        </Row>
                    </Col>
                </Grid>
                <Grid>
                    <Text style={styles.title}>Landlord/Owner Details</Text>
                    <Col>
                        <Row>
                            <Text style={styles.label}>Owner Name:</Text>
                            <Text style={styles.fieldValue}>{pdfData.landLordDetails.name}</Text>
                        </Row>

                        <Row>
                            <Text style={styles.label}>Identity/Passport number:</Text>
                            <Text style={styles.fieldValue}>{pdfData.landLordDetails.identityNo}</Text>
                        </Row>
                        <Row>
                            <Text style={styles.label}>Identity/Passport Document:</Text>
                            <Text style={styles.fieldValue}>{pdfData.landLordDetails.identityType}</Text>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Text style={styles.label}>Cell :</Text>
                            <Text style={styles.fieldValue}>{pdfData.landLordDetails.cell}</Text>
                        </Row>
                        <Row>
                            <Text style={styles.label}>Tel :</Text>
                            <Text style={styles.fieldValue}>{pdfData.landLordDetails.tel}</Text>
                        </Row>
                        <Row>
                            <Text style={styles.label}>Email Address:</Text>
                            <Text style={styles.fieldValue}>{pdfData.landLordDetails.email}</Text>
                        </Row>
                        <Row>
                            <Text style={styles.label}>Postal Address:</Text>
                            <Text style={styles.fieldValue}>{pdfData.landLordDetails.postal}</Text>
                        </Row>
                    </Col>
                </Grid>
                <Grid>
                    <Text style={styles.title}>Banking Details</Text>
                    <Col>
                        <Row>
                            <Text style={styles.label}>Bank Name:</Text>
                            <Text style={styles.fieldValue}>{pdfData.bankingDetails.name}</Text>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Text style={styles.label}>Branch Name:</Text>
                            <Text style={styles.fieldValue}>{pdfData.bankingDetails.branch}</Text>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Text style={styles.label}>Branch Code:</Text>
                            <Text style={styles.fieldValue}>{pdfData.bankingDetails.branchCode}</Text>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Text style={styles.label}>Account Number:</Text>
                            <Text style={styles.fieldValue}>{pdfData.bankingDetails.accountNumber}</Text>
                        </Row>
                    </Col>
                </Grid>

                <Text style={styles.fieldValue}>
                    I, {`${pdfData.personalDetails.firstName} ${pdfData.personalDetails.lastName} `}declare that to the best of
                    my knowledge and belief the information in this application and the accompanying documents is true and correct
                    and have read and understood the implications of the Waterworks Act and all the relevant Legislation and Regulations
                </Text>


                {/* Continue creating the grid layout for the rest of your form */}
            </Page>
        </Document>
    );
};

export default ApplicationRequestPdf;
