import React from 'react';
import SelfServiceContainer from "../../components/Containers/SelfServiceContainer";
import LeakReportGuest from "./Components/LeakReportGuest";
import utils from "../../utils/commonFunctions";
import { Navigate } from 'react-router-dom';

const ReportLeak = () => {

  if (utils.isAuthenticated()) {
    // NAVIGATE TO THE REPORT LEAK PAGE
    return <Navigate to={'/self-service-portal/report-leak'}/>
  }

  if (!utils.isAuthenticated()) {
    return (
      <SelfServiceContainer childComponent={<LeakReportGuest />} />
    )
  }

};

export default ReportLeak;
