import React from "react";
import {
  GridItem, Grid
} from "@chakra-ui/react";



import SideNav from "./SideNav";

const MediaComponents = ({childComponent}) => {

    return(
        <Grid templateColumns='repeat(12, 1fr)' gap={6} w={'full'} bg={'white'} boxShadow={'md'} borderRadius={'2xl'} mt={16} mb={16}>
            <GridItem colSpan={{base:12, lg:3} } bg={'red.500'}><SideNav/></GridItem>
            <GridItem colSpan={{base:12, lg:9} }>
                {childComponent}
            </GridItem>
        </Grid>
    )
}
export default MediaComponents