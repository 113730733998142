import { Button } from "@chakra-ui/button";
import { Icon } from "@chakra-ui/icon";
import { Box,  Text } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/modal";
import React, {useEffect} from "react";
import {FaCircleCheck, FaCircleXmark} from "react-icons/fa6";
import {Link} from "react-router-dom";
import utils from "../../../../utils/commonFunctions";
import {useDisclosure} from "@chakra-ui/react";

const AlertModal = ({ isOpen,onClose,success}) => {

  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered={true} zIndex={10000}>
        <ModalOverlay />
        <ModalContent p={4} borderRadius={"xl"} zIndex={10000}>
          <ModalBody d={'flex'} justifyContent={'center'} align={'center'}>
            <Box w={'full'} h={'full'}>
              <Box >
                <Icon as={success?FaCircleCheck:FaCircleXmark} boxSize={{base:"16",md:"24"}} borderWidth={8} borderRadius={'full'} borderColor={success?'green.200':"red.300"} color={success?"green.500":"red.500"} />
              </Box>

              <Text my={2} fontSize={"20px"}>
                {success?
                " Your request for assistance has been sent.You will receive an email from our sales department.": " Your request for assistance was not submitted, please try again or contact our customer service center."
                }


              </Text>
            </Box>
          </ModalBody>

          <ModalFooter display={"flex"} justifyContent={"center"}>
            {success?
                <Link to={'/self-service-portal/laboratory-services'}>
                  <Button
                      variant='outline'
                      color='gray.600'
                      borderColor={'green.300' }
                      _hover={{ bg:'green.500', color: 'white' }}
                  >
                    {"OK" }
                  </Button>
                </Link>:
                <Button
                    variant='outline'
                    color='gray.600'
                    borderColor={'red.300'}
                    _hover={{ bg:'red.500', color: 'white' }}
                    onClick={()=>onClose()}
                >
                  {"Try Again"}
                </Button>
            }

          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AlertModal;
