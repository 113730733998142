import React from "react";

import PublicViewContainer from "../../components/Containers/PublicViewContainer";
import PublicationTemplate from "./Components/PublicationTemplate";

const Publications = ({}) => {
    return (<>

        <PublicViewContainer childComponent={<PublicationTemplate/>}/>

    </>);
};

export default Publications;
