import { Button } from "@chakra-ui/button";
import { Icon } from "@chakra-ui/icon";
import { Box,  Text } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/modal";
import React, {useEffect} from "react";
import {FaCircleCheck, FaCircleXmark} from "react-icons/fa6";
import {Link} from "react-router-dom";
import QuotationCart from "../QuotationCart";
import {CloseButton} from "@chakra-ui/react";
const CartModal = ({ isOpen,onClose}) => {

  return (
    <>
      <Modal  size={'6xl'} isOpen={isOpen} onClose={onClose} isCentered={true} zIndex={10000}>
        <ModalOverlay />
        <ModalContent p={4} borderRadius={"xl"} zIndex={10000}>
          <ModalCloseButton />
          <ModalBody d={'flex'} justifyContent={'center'} align={'center'}>
            <Box w={'full'} h={'full'}>
             <QuotationCart/>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CartModal;
