import React, { useState, useEffect } from "react";
import {
    Badge, SimpleGrid, Circle,
    Box, Button, ButtonGroup, Center, Divider, Flex, Heading, Stack, Text, useDisclosure
} from "@chakra-ui/react";

import PdfViewerModal from "../../../components/PdfViewer/PdfViewerModal";
import ResponseModal from "../../../components/PayGate/ResponseModal";
import TendersModal from "./TenderModal";
import utils from "../../../utils/commonFunctions";
import { API } from "../../../utils/api";

const Tender = ({ title, url, tenderNumber, tenderFee, tenderDetail, startDate, closingDate, status, advertDocumentKey, tenderId }) => {
    const { isOpen: isTendersModalOpen, onOpen: onTendersModalOpen, onClose: onTendersModalClose } = useDisclosure();
    const { isOpen: isPdfModalOpen, onOpen: onPdfModalOpen, onClose: onPdfModalClose } = useDisclosure();
    const [viewResponseModalOpen, setViewResponseModalOpen] = useState(false);
    const [reference, setReference] = useState("");
    const [statusCode, setStatusCode] = useState();
    const [reason, setReason] = useState();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        setStatusCode(urlParams.get("code"));
        setReference(urlParams.get("reference"));
        setReason(urlParams.get("reason"));

        if (urlParams.has("reference") && urlParams.has("code")) {
            setViewResponseModalOpen(true);
        }
    }, []);

    return (
        <>
            <Flex
                w="full"
                marginBottom={{ base: 0, lg: 8 }}
                align="center"
                h={{ base: 'auto', md: 'auto', lg: '40vh' }}
                _hover={{ cursor: 'pointer', transform: 'scale(1.02)' }}
                transition="all 0.2s ease-in-out"
            >
                <Box
                    boxShadow="xl"
                    p="8"
                    fontWeight="normal"
                    borderRadius="2xl"
                    w="full"
                >
                    <Heading
                        size={{ base: 'sm', md: 'md', lg: 'md' }}
                        color="blue.800"
                        fontWeight="medium"
                    >
                        {title}
                    </Heading>

                    <Stack direction={{ base: 'column', md: 'row' }} gap={2} mt={2} >
                        <Text fontSize={{ base: 'sm', md: 'sm', lg: 'md' }}>Start Date: {startDate}</Text>
                        <Divider orientation={{ base: 'horizontal', md: 'vertical' }} display={{ base: 'none', md: 'block' }} />
                        <Text fontSize={{ base: 'sm', md: 'sm', lg: 'md' }} color="red">Closing Date: {closingDate}</Text>
                        <Flex align="center">
                            <Badge
                                colorScheme={status === 'Open' ? "green" : "red"}
                                borderRadius="2xl"
                                px={2}
                                textAlign="center"
                            >
                                {status}
                            </Badge>
                        </Flex>
                    </Stack>

                    <Box
                        fontSize="md"
                        my={4}
                        color="gray.400"
                        h={{ base: 'auto', md: 'auto', lg: '25vh' }}
                    >
                        <Text fontSize={{ base: 'sm', md: 'sm', lg: 'md' }} >Tender Number: <Box as="span" ml={2}>{tenderNumber}</Box></Text>
                        <Text fontSize={{ base: 'sm', md: 'sm', lg: 'md' }} >Tender Fee: BWP <Box as="span" ml={2}>{tenderFee}</Box></Text>
                        <Text  fontSize={{ base: 'sm', md: 'sm', lg: 'md' }} mt={4} dangerouslySetInnerHTML={{ __html: tenderDetail }}></Text>
                    </Box>

                    <ButtonGroup mt={2} w="full" flexDirection={{ base: 'row', sm: 'row' }} >
                        <Button
                            w={{ base: 'full', sm: '48%', md: '200px' , lg: 'full' }}
                            bg="white"
                            color="gray.800"
                            _hover={{ bg: 'brand.600', color: 'white' }}
                            boxShadow="md"
                            onClick={onPdfModalOpen}
                            mb={{ base: 2, sm: 0 }}
                        >
                            Advert
                        </Button>
                        <Button
                            w={{ base: 'full', sm: '48%', md: '200px', lg: 'full' }}
                            bg="brand.700"
                            boxShadow="md"
                            _hover={{ bg: 'brand.600' }}
                            onClick={onTendersModalOpen}
                        >
                            Buy
                        </Button>
                    </ButtonGroup>

                </Box>
            </Flex>

            <TendersModal
                isOpen={isTendersModalOpen}
                onClose={onTendersModalClose}
                url={`/tenders/buy-tender/${tenderId}`}
                title="Condition Of Purchase"
                message="The tender fee is non-refundable and the Corporation will not be responsible for, or pay for, expenses or losses which may be incurred by any tenderer in the purchasing of the tender document or in visiting the Corporation’s website in connection therewith."
            />

            <PdfViewerModal
                isOpen={isPdfModalOpen}
                onClose={onPdfModalClose}
                filePath={advertDocumentKey}
            />

            {viewResponseModalOpen && (
                <ResponseModal
                    reference={reference}
                    statusCode={statusCode}
                    reason={reason}
                />
            )}
        </>
    );
};

const Tenders = () => {
    const [tenders, setTenders] = useState([]);
    const [paginationData, setPaginationData] = useState({
        totalDocs: 0,
        limit: 6,
        totalPages: 1,
        page: 1
    });
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let formattedDate = today.toISOString().split('T')[0];// Outputs: 2024-09-12

    const fetchTenders = async (pageNumber = 1) => {
        try {
            const response = await API.get(`/tenders?pageNumber=${pageNumber}&pageSize=${paginationData.limit}&closingDate=${formattedDate}`,
            );
            setTenders(response.data.docs);
            setPaginationData({
                totalDocs: response.data.totalDocs,
                limit: response.data.limit,
                totalPages: response.data.totalPages,
                page: response.data.page
            });
        } catch (error) {
            await utils.sendMessageToBugNinja('ERROR FETCHING TENDERS', error.message, error);
            console.error("Error fetching tenders: ", error);
        }
    };

    useEffect(() => {
        fetchTenders();
    }, []);

    const handlePageChange = (pageNumber) => {
        fetchTenders(pageNumber);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
    };

    return (
        <Box>
            <SimpleGrid columns={{ base: 1, md: 1, lg: 2 }} spacing={{ base: 4, md: 12 }}>
                {tenders.map((tender, index) => (
                    <Box key={index} w="full" my={{ base: 2, md: 4, lg: 8 }}>
                        <Tender
                            title={tender.title}
                            tenderNumber={tender.number}
                            tenderFee={tender.fees}
                            tenderDetail={tender.description}
                            closingDate={formatDate(tender.closingDate)}
                            startDate={formatDate(tender.startDate)}
                            status={tender.status}
                            advertDocumentKey={tender.advertDocumentKey.key}
                            tenderId={tender._id}
                        />
                    </Box>
                ))}
            </SimpleGrid>

            {/* Pagination Circles */}
            <Center h="100px" color="white">
                {Array.from({ length: paginationData.totalPages }, (_, index) => (
                    <Circle
                        key={index}
                        size="40px"
                        bg={paginationData.page === index + 1 ? "brand.700" : "#dee2e6ff"}
                        color="white"
                        mx={2}
                        onClick={() => handlePageChange(index + 1)}
                        cursor="pointer"
                    >
                        {index + 1}
                    </Circle>
                ))}
            </Center>
        </Box>
    );
}

export default Tenders;
