import React from "react";
import { useNavigate } from "react-router-dom";
import {
    Flex,
    Image,
    Center,
    VStack,
    Text, Heading
} from "@chakra-ui/react";
import SurveyImage from "../../assets/survey.png";

const Surveys = () => {
    // useNavigate hook for navigation
    const navigate = useNavigate();

    // handleClick function for routing
    const handleClick = () => {
        navigate("/survey");
    }

    return (
        <>
            <Flex
                justify={'center'}
                onClick={handleClick}
            >
                <VStack
                    spacing={4}
                    align='stretch'
                >
                    <Center>
                        <Heading size={'lg'} color={'brand.700'}>Surveys</Heading>
                    </Center>
                    <Center>
                        <Image
                            borderRadius='10px'
                            boxSize='150px'
                            src={SurveyImage}
                            alt='Ongoing survey'
                        />
                    </Center>

                    <Center>
                        <Text color='black' fontSize='18px' textAlign='left'>How can we improve our services?</Text>
                    </Center>
                </VStack>
            </Flex>
        </>
    );
}
export default Surveys
