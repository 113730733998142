import React, { useState, useEffect } from 'react';
import PortalContainer from "../../components/PortalContainer/PortalContainer";
import DashboardComponent from "../../pages/Dashboard/components/Dashboard";

const Dashboard = () => {
    return (
        <>
            <PortalContainer Title={"Dashboard"} childComponent={<DashboardComponent/>}/>
        </>
    );
};

export default Dashboard;
