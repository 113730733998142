import React from 'react';
import Header from '../../components/Header/Header';
import Carousel from '../../components/Carousel/Carousel';
import QuickLinks from '../../components/QuickLinks/QuickLinks';
import SelfServicesBox from '../../components/SelfServicesBox/SelfServicesBox';
import Projects from '../../components/Projects/Projects';
import Footer from '../../components/Footer/Footer';
import News from '../../components/News/News';
import BottomBanner from '../../components/BottomBanner/BottomBanner';
import {Box} from "@chakra-ui/layout";
import Surveys from "../Surveys/Surveys";

const Home = () => {
    return (
        <div>
            <Header/>
            <Carousel/>
            <Box  bg={'white'}>
                <QuickLinks/>
            </Box>
            <SelfServicesBox/>
            <Projects/>
            <News/>
            <Surveys/>
            <BottomBanner/>
            <Footer/>
        </div>
    );
};

export default Home;
