import React from "react";

import PublicViewContainer from "../../components/Containers/PublicViewContainer";
import BuyTenderComponent from "./Components/BuyTender";


const BuyTender = ({tenderFee}) => {

    return <PublicViewContainer childComponent={<BuyTenderComponent/>}/>

};


export default BuyTender;
