import React from "react";
import {Button, Flex, IconButton, Text, useToast} from "@chakra-ui/react";
import {Link, useNavigate} from "react-router-dom";
import {CustomThemedToast} from "../../utils/customChakraToasts";
import utils from "../../utils/commonFunctions";
import {FiLogIn, FiLogOut} from "react-icons/fi";

export default function SidebarFooter() {
    const navigate=useNavigate()
    const toast =useToast()
    const {addThemedToast}=CustomThemedToast()
    const handleLogout = () => {
        toast.closeAll();
        addThemedToast({
            message: "Logging you out...",
            variant: "lightBlue",
        });
        setTimeout(() => {
            utils.logout();
            navigate('/login')
            toast.closeAll()
        }, 1500);
    };

    return (
        <>
            {utils.isAuthenticated() ?
                <Flex as="button" w={'full'}  onClick={handleLogout}  gap={3} justify={'center'} align={'center'} px="4" py="5">
                    <IconButton
                        p={2}
                        as={FiLogOut}
                        isRound
                        colorScheme="red"
                        variant="solid"
                        boxShadow={'md'}
                        cursor={'pointer'}
                        bg='white'
                        color="red.400"
                        _hover={{
                            bg:'red.400',
                            color:'white'
                        }}
                    />
                    <Text color={'red.400'}>Logout</Text>
                </Flex>:
                <Link to={'/login'} w={'full'} >
                    <Flex  gap={3} justify={'center'} align={'center'} px="4" py="5">
                        <IconButton
                            p={2}
                            as={FiLogIn}
                            isRound
                            colorScheme="brand"
                            variant="solid"
                            boxShadow={'md'}
                            cursor={'pointer'}
                            bg='white'
                            color="brand.700"
                            _hover={{
                                bg:'brand.700',
                                color:'white'
                            }}
                        />
                        <Text color={'brand.700'}>Login</Text>
                    </Flex>

                </Link>

            }
        </>

    );
}


