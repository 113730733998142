import React, { useEffect, useState } from "react";
import {Box, Heading, Flex, Center, Icon, Text} from "@chakra-ui/react";
import VideoPlayer from "./VideoPlayer";
import utils from "../../../../utils/commonFunctions";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import '@splidejs/react-splide/css/skyblue';
import placeholder from "../../../../assets/albumPlaceholder.jpg";
import {FaPlayCircle} from "react-icons/fa";

const Video = ({ name, thumbnail }) => (
    <Flex
        width="full"
        justifyContent="start"
        alignItems="end"
        height="165px"
        borderRadius="2xl"
        boxShadow="md"
        cursor="pointer"
        bgImage={thumbnail || placeholder}
        bgRepeat="no-repeat"
        bgSize="cover"
        transition="transform 0.5s, filter 0.5s ease-in-out"
        _hover={{
            transform: `scale(105%)`,
            filter: "brightness(75%)",
        }}
    >
        <Heading
            size="sm"
            color="white"
            fontWeight="medium"
            noOfLines={2}
            bg="blackAlpha.500"
            w="full"
            borderBottomRadius="2xl"
            p={2}
        >
            {name}
        </Heading>
    </Flex>
);

const Videos = () => {
    const [Videos, setVideos] = useState([]);
    const [video, setVideo] = useState('');
    const [videoTitle, setVideoTitle] = useState('');

    useEffect(() => {
        const fetchVideos = async () => {
            const fetchedVideos = await utils.fetchVideos();
            setVideos(fetchedVideos);
            if (fetchedVideos && fetchedVideos.length > 0) {
                setVideo(fetchedVideos[0]?.videoCode || fetchedVideos[0]?.fileUrl);
                setVideoTitle(fetchedVideos[0]?.title || '');
            }
        };
        fetchVideos();

    }, []);

    useEffect(() => {
        if (Videos && Videos.length > 0) {
            setVideo(Videos[0]?.videoCode || Videos[0]?.fileUrl);
        }
    }, [Videos]);

    function extractYouTubeID(url) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    }

    const VideoThumbnail = ({ video, style }) => {
        const youtubeID = video?.videoCode ? extractYouTubeID(video.videoCode) : null;
        return (

            <Flex
                w="full"
                h="full"
                align="center"
                justify="center"
                overflow="hidden"
                position="relative"
                borderRadius="md"
                style={style}
                _hover={{
                    cursor: 'pointer',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
                    '& .overlay': {
                        width: '100%',
                        opacity: 1,
                    }
                }}
            >
                {youtubeID ? (
                    <img
                        src={`https://img.youtube.com/vi/${youtubeID}/0.jpg`}
                        alt={video.title}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                ) : video?.fileUrl ? (
                    <video
                        preload="auto"
                        width="100%"
                        height="100%"
                        muted
                    >
                        <source src={video.fileUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                ) : (
                    <Flex w="full" h="full" backgroundColor="#eee" align="center" justify="center">
                        <Icon as={FaPlayCircle} w={6} h={6} color="gray.500" />
                    </Flex>
                )}

                <Center position="absolute" top="0" right="0" bottom="0" left="0">
                    <Icon as={FaPlayCircle} w={8} h={8} color="white" />
                </Center>
            </Flex>

        );
    };

    return (
        <Flex justify="center" align="center" bg="white" p={{ base: 0, md: 8 }} w="full">
            <Box w="full">
                <Heading size="xs" mb={2} textAlign="center" color="blue.800">Media</Heading>
                <Heading size="lg" mb={4} textAlign="center" color="blue.800">Videos</Heading>
                <Box w="full">
                    <Flex bg="gray.50" w="full" justify="center" align="center" boxShadow="md" borderRadius="2xl">
                        <Box h={{ base: '350px', md: '450px' }} w="full">
                            <VideoPlayer videoUrl={video} />
                            <Text fontSize="xs" color="gray.500" textAlign="center" mt={2}>{videoTitle}</Text>
                        </Box>
                    </Flex>
                    <Flex justify="center" width="full" mt={8}>
                        <Splide
                            options={{
                                type: 'slide',
                                perPage: 4,
                                arrows: true,
                                pagination: true,
                                drag: 'free',
                                gap: '1rem',
                                breakpoints: {
                                    768: {
                                        perPage: 1,
                                        arrows: false,
                                        gap: '0.5rem',
                                    },
                                    1024: {
                                        perPage: 2,
                                        gap: '1rem',
                                    },
                                    1440: {
                                        perPage: 4,
                                        gap: '1rem',
                                    },
                                },
                            }}
                            style={{ width: '100%' }}
                        >
                            {Videos && Videos.map((video, index) => (

                                <SplideSlide key={index} onClick={() => {
                                    setVideo(video.videoCode || video.fileUrl);
                                    setVideoTitle(video.title || '');
                                }}>
                                    <VideoThumbnail
                                        style={{
                                            width: "100%",
                                            height: "100px",
                                            overflow: "hidden",
                                            borderRadius: "0px",
                                        }}
                                        video={video}
                                    />
                                </SplideSlide>
                            ))}

                        </Splide>
                    </Flex>
                </Box>
            </Box>
        </Flex>
    );
};

export default Videos;
