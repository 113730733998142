import {
    Input,
    Stack,
    Button,
    FormLabel,
    FormControl,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton, ModalBody,HStack, Text, Switch,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {PropagateLoader} from "react-spinners";
import {CustomGeneralToast} from "../../../utils/customChakraToasts";
import {API} from "../../../utils/api";
import BillAlert from "../../../components/PortalContainer/BillAlert";
import utils from "../../../utils/commonFunctions";
import {useUserProfile} from "../../../providers/UserProfileProvider";
import {useQuoteCart} from "../../../providers/QuoteCartProvider";

const ManageUtilityModal = ({isOpen,onClose}) => {
    const userId=utils.getUserId()
    const {userProfile} = useUserProfile() || {}
    const getInitialName = () => {
        if (personalDetails && personalDetails.firstName && personalDetails.lastName) {
            return `${personalDetails.firstName} ${personalDetails.lastName}`;
        }
        return "";  // Default return if personalDetails are not available
    };


    const {customerNumber,personalDetails} = userProfile || {}
    const [name, setName] = useState(getInitialName);
    const [type, setType] = useState("");
    const [plot, setPlot] = useState("");
    const [number, setNumber] = useState(customerNumber||"");
    const [utility, setUtility] = useState("");
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const {addGeneralToast} = CustomGeneralToast();
    const toast = useToast();
    const [checkForOthers, setCheckForOthers] = useState(false);
    const {refreshFlag,setRefreshFlag}=useQuoteCart()
    useEffect(() => {
        if(!isOpen){
            setCheckForOthers(false)
        }
    }, [isOpen]);
    useEffect(() => {

        if(!checkForOthers){
            setName(getInitialName)
            setNumber(customerNumber)
            setType("Self")
        }else{
            setType("Beneficiary")
        }
    }, [checkForOthers]);

    const addUtility= async () => {
        if (!number||!name||!utility||!plot) {
            toast.closeAll();
            addGeneralToast({
                message: "All fields must be filled in",
                type: "warning",
            });
            setLoading(false);
            return;
        }
        setShow(false);
        setLoading(true);
        try {
            const response = await API.post(
                `/utility-contracts/`,
                {
                    name:name,
                    customerNumber: number,
                    contractNumber: utility,
                    type:type,
                    userId:userId,
                    plot:plot
                }
            );
            const data = await response.data;
            if (response.status === 201) {
                toast.closeAll();
                addGeneralToast({
                    message: data.message,
                    type: "success",
                });
                setShow(true);
                setLoading(false);
                setRefreshFlag(true)
                setNumber("")
                setUtility("")
                setName("")
                setPlot("")
                setType("")
                onClose()
                //setIsModalOpen(true)
            } else {
                toast.closeAll();
                addGeneralToast({
                    message: data.message,
                    type: "error",
                });
                setShow(false);
                setLoading(false);

            }
        } catch (e) {
            setLoading(false);
            toast.closeAll();
            console.log(e)
            addGeneralToast({
                message: e.response ? e.response.data.message : e.statusText,
                type: "error",
            });
        }
    };
    return <>
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody py={10}>
                    <Stack  spacing={1} justify={'center'} w="full" h={"full"} >
                        <BillAlert/>
                        <FormControl isRequired id="customerNumber">
                            <FormLabel>Customer Name</FormLabel>
                            {!utils.isAuthenticated()||checkForOthers ? (
                                <Input
                                    isRequired
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />): <Input
                                isRequired
                                isDisabled
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />}
                        </FormControl>
                        <FormControl isRequired id="customerNumber">
                            <FormLabel>Customer Number</FormLabel>
                            {!utils.isAuthenticated()||checkForOthers ? (
                                <Input
                                    isRequired
                                    type="number"
                                    onChange={(e) => setNumber(e.target.value)}
                                />): <Input
                                isRequired
                                isDisabled
                                type="number"
                                value={number}
                                onChange={(e) => setNumber(e.target.value)}
                            />}
                        </FormControl>

                        <FormControl isRequired id="utilityNumber">
                            <FormLabel >Utility Number</FormLabel>
                            <Input
                                type="text"
                                value={utility}
                                onChange={(e) => setUtility(e.target.value)}
                            />
                        </FormControl>

                         <FormControl isRequired id="plotNo">
                            <FormLabel >Plot No</FormLabel>
                            <Input
                                type="text"
                                value={plot}
                                onChange={(e) => setPlot(e.target.value)}
                            />
                        </FormControl>

                        <Stack spacing={6} mt={2}>
                            <Button
                                onClick={!loading ?addUtility : undefined}
                            >
                                {!loading ? "Add Utility Contract" : <PropagateLoader size={8} color="white"/>}
                            </Button>
                        </Stack>
                        {utils.isAuthenticated()&&
                            <HStack mt={2} justifyContent="center" alignItems="center">
                                <Text color={"blue.800"}>Add Beneficiary Utility</Text>
                                <Switch id='bill-for-others' onChange={(e) => setCheckForOthers(e.target.checked)}/>
                            </HStack>
                        }
                    </Stack>
                </ModalBody>


            </ModalContent>
        </Modal>




    </>;
};

export default ManageUtilityModal;
