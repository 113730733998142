import React, {useEffect, useState} from "react";
import {
    Box, Flex, FormControl, FormLabel, Grid, GridItem, Heading, Input, InputGroup,Text,
} from "@chakra-ui/react";
import {useUserProfile} from "../../../providers/UserProfileProvider";
import enums from "wuc-library/enums";
import {useLocation} from "react-router-dom";

const OwnerDetails = () => {
    const [owner,setOwner]=useState(false)
    let form=JSON.parse(localStorage.getItem('ownerOrLandlord'))
    const location = useLocation();
    let {personalDetails,address,contactDetails}=location?.state?.application||{}
    if(personalDetails&&!form){
        localStorage.setItem('ownerOrLandlord',JSON.stringify({...personalDetails,...contactDetails,...address}))

        if(JSON.parse(localStorage.getItem('connectionDetails'))?.applicantType===enums.ApplicantType.OWNER){
            //setOwner(true)
            localStorage.setItem('ownerOrLandlord',JSON.stringify({...personalDetails,...contactDetails,...address}))
        }else{
            form=JSON.parse(localStorage.getItem('ownerOrLandlord'))
        }
    }
    let {userProfile}=useUserProfile()
    if(userProfile&&!personalDetails&&!form){

        let {personalDetails,address,contactDetails}=userProfile||{}

        if(JSON.parse(localStorage.getItem('connectionDetails'))?.applicantType===enums.ApplicantType.OWNER){
            //setOwner(true)
            localStorage.setItem('ownerOrLandlord',JSON.stringify({...personalDetails,...contactDetails,...address}))
        }else{
            form=JSON.parse(localStorage.getItem('ownerOrLandlord'))
        }
    }






    const [formData, setFormData] = useState({
        firstName: form?.firstName ||null,
        middleName: form?.middleName ||null,
        lastName: form?.lastName ||null,
        cell: form?.cell ||null,
        email: form?.email ||null,
    });
    useEffect(() => {
        localStorage.setItem('ownerOrLandlord', JSON.stringify(formData));
        const { middleName, ...exclMiddleName }=formData
        if(Object.values(exclMiddleName).some(detail => detail === null||detail === ""||!detail)){
            localStorage.setItem('isValidForm',false );
        }
        else{
            localStorage.setItem('isValidForm',true );
        }


    }, [formData]);
    const handleInputChange = (e) => {
        const {name, value,id} = e.target;
        setFormData(prevState => ({
            ...prevState, [name]:value
        }));
    };

    return (
        <>
            <Box>
                <Flex mb={4}>
                    <Box>
                        <Heading color={'brand.500'}>Owner / Landlord Details</Heading>
                        <Text color={'gray.500'}>Enter basic details of the plot owner.</Text>
                    </Box>
                </Flex>
                <Grid templateColumns="repeat(12, 1fr)" gap={2} color={"blue.800"}>
                    {/* Kin Firstname */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired id="firstName">
                            <FormLabel >Firstname</FormLabel>
                            <InputGroup>
                                <Input   type="text" name="firstName" id="Firstname"  value={formData.firstName} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>

                    {/* Kin Middle Name */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl id="middleName">
                            <FormLabel >Middle Name</FormLabel>
                            <InputGroup>
                                <Input   type="text" name="middleName" id="Middlename"   value={formData.middleName} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>

                    {/* Kin Lastname */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired id="lastName">
                            <FormLabel >Lastname</FormLabel>
                            <InputGroup>
                                <Input  type="text" name="lastName" id="Lastname" value={formData.lastName} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>



                    {/* Kin Cell */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired id="cell">
                            <FormLabel>Cell</FormLabel>
                            <InputGroup>
                                <Input   type="number" name="cell" id="Cell"  value={formData.cell} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                    {/* Email */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isDisabled={owner} isRequired id="email">
                            <FormLabel>Email</FormLabel>
                            <InputGroup>
                                <Input  type="email" name="email" id="Email"  value={formData.email} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                </Grid>
            </Box>
        </>
    );
};

export default OwnerDetails;
