import React, {useEffect, useState} from "react";
import {
    Box, Flex, Grid, GridItem, Heading, IconButton, Image, Text
} from "@chakra-ui/react";
import image1 from "../../../assets/gardnersCroped.png";
import {Link, useNavigate} from "react-router-dom";
import {FaArrowRightLong} from "react-icons/fa6";
import utils from "../../../utils/commonFunctions";


const Publication = ({title,categoryId,href}) => {
    const navigate=useNavigate()
    const handleClick = () => {
        navigate(href, {state: {category: categoryId,name:title}});
    };
    return (
    <>

            <Flex onClick={()=>handleClick()}  cursor={'pointer'} direction={'column'}  bg={'white'}   boxShadow="md"  borderRadius={'2xl'} >
                <Flex
                    as={'button'}
                    width="full"
                    justifyContent="start"
                    alignItems="end"
                    borderTopRadius="2xl"

                >
                    <Image h={'200px'} w={'full'} src={image1}
                           borderTopRadius="2xl"
                           transition="transform 0.5s, filter 0.5s ease-in-out"
                           filter="brightness(45%)"
                           _hover={{
                               transform: `scale(100%)`,
                               filter: "brightness(65%)",
                           }}/>
                </Flex>
                <Flex py={4} px={8} gap={4}   justify={'start'} align={'start'}>

                    <Box w={'full'}  >
                        <Heading size={'lg'} color={'blue.800'} fontWeight={'bold'} noOfLines={1}  w={'full'}>{title}</Heading>
                        <IconButton icon={<FaArrowRightLong/>} color={'white'} bg={'#0093D0'} isRound mt={2}/>
                    </Box>
                </Flex>

            </Flex>


    </>
  );
};

const Publications = () => {
    const pub = [
        {
            title: 'Acts',
            url:'/publications/acts',
            brief: 'Comprehensive legal documents that govern the standards and regulations within specific sectors or areas. These acts serve as foundational frameworks ensuring compliance and oversight by relevant authorities.'
        },
        {
            title: 'Annual Reports',
            url:'/publications/annual-reports',
            brief: 'A detailed report that summarizes the activities, accomplishments, and financial performance of an organization over the past year. Aimed at stakeholders, it provides transparency and insight into the organization\'s strategic direction and operational efficiency.'
        },

        {
            title: 'Forms',
            url:'/publications/forms',
            brief: 'Standardized documents used for the submission of information required by an organization for processing various requests or applications. They facilitate uniform data collection and processing, streamlining administrative and operational procedures.'
        },
        {
            title: 'Surveys',
            url:'/publications/surveys',
            brief: 'Tools used to gather data and insights from a specific group of people, aimed at understanding opinions, behaviors, or needs. The collected information supports research, decision-making, and strategy formulation, reflecting the views and preferences of the targeted respondents.'
        },
        {
            title: 'Guidelines',
            url:'/publications/guidelines',
            brief: 'Set of recommended practices and procedures designed to guide actions and decisions in specific situations or processes. These guidelines help ensure consistency, quality, and compliance with established standards and best practices.'
        },
        {
            title: 'Press Release',
            url:'/publications/press-release',
            brief: 'An official statement issued to the media to announce a range of news items, including events, achievements, or product launches. Press releases aim to generate media coverage and inform the public or specific target audiences about significant developments.'
        },
        {
            title: 'Products and Services',
            url:'/publications/products-and-services',
            brief: 'Overview and details of the various products and services offered by an organization, highlighting features, benefits, and usage. Aimed at potential and current customers, providing essential information to support informed decision-making and engagement.'
        },
        {
            title: 'Botswana Emergency Water Security & Efficiency Project',
            url:'/publications/botswana-emergency-water-security-and-efficiency-project',
            brief: 'A focused initiative aimed at enhancing water security and management efficiency in response to critical water shortages in Botswana. The project encompasses the development and implementation of sustainable water resources and emergency response strategies.'
        },
        {
            title: 'Sedibeng Newsletter',
            url:'/publications/sedibeng-newsletter',
            brief: 'A periodic publication that provides updates, news, and informative content related to the Sedibeng region or specific thematic areas of interest. The newsletter serves as a communication tool to engage with the community, stakeholders, and interested parties, offering insights and developments.'
        },

    ];
    const [publications,setPublications]=useState([])
    const formatUrl = (input) => {
        return input
            .toLowerCase()
            .replace(/\s+/g, '-')
    };

    const getCategories = async () => {
        try {
            let categories = await utils.fetchPublicationsCategories()
            if(categories){
                let pubs=[]
                categories.map((categ)=>(
                    pubs.push({
                        title:categ.name,
                        id:categ._id,
                        url:formatUrl(`/publications/category/${categ.name.toLowerCase()}`)
                    })
                ))

                setPublications(pubs)
            }

        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        getCategories()
    }, []);
    return (
        <>
            <Box   >
                <Grid
                    templateColumns="repeat(12, 1fr)"
                    gap={6}
                    w={"100%"}
                    justify={"center"}
                    mt={4}
                >
                    <>
                        {publications.map((publication)=>(
                            <GridItem colSpan={{base: 12, md: 6, lg: 3}}>
                                {console.log(publication)}
                                <Publication title={publication.title} categoryId={publication.id}  href={publication.url}/>
                            </GridItem>
                        ))}
                    </>


                </Grid>
            </Box>

        </>
    );
};
export default Publications;
