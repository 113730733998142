import React from "react";
import {
    Box, Heading,Text, SimpleGrid
} from "@chakra-ui/react";
import BoardOfDirectors from "./BoardOfDirectors";
import BoardMembers from "./BoardMembers";
import Societies from "./Societies";
import Introduction from "./Introduction";
const AboutUs = () => (
    <>

        <Introduction/>
        <Box objectFit={'fit'} bg={'white'}  px={{base:8,lg:32}} mb={8}>
            <Heading size="lg" my={8} textAlign={'center'}  color={'blue.800'}>Corporate <Box as={'span'} color={'#0093D0'} >Governance</Box> </Heading>

            <SimpleGrid columns={{base:1,lg:2}} gap={8} mb={4}>
                <Box width={{base:"full"}}>
                    <Heading size={{base:'sm',md:'md'}} mb={4}>Ownership of the Corporation</Heading>
                    <Text textAlign={'justify'}>
                        The Corporation is a parastatal wholly owned by the Botswana
                        Government. The Water Utilities Corporation Act defines the raison
                        d’etre for the Corporation as well as the limits within which it can
                        operate, including the roles for the Minister of Land Management,
                        Water and Sanitation Services and Water Resources, the Board, and the
                        Executive Management.
                    </Text>
                </Box>
                <Box width={{base:"full"}}>
                    <Heading  size={{base:'sm',md:'md'}} mb={4}>
                        Corporate Governance Report
                    </Heading>
                    <Text textAlign={'justify'}>
                        WUC ensures water of required standards to improve the lives of citizenry.
                        Our Shareholder has entrusted us with the delivery of the water supply and
                        wastewater management mandate hence a great deal of responsibility on WUC.
                        We do this through ensuring affordability of water through a tariff structure
                        that accommodates low-income households. As we worked towards transforming our
                        business model and creating agility in our processes for greater convenience to
                        our customers, we did not lose sight of balanced operations with risk management practices,
                        frameworks and principles of transparency and sustainability. We continually to look for improvement
                        opportunities in our reporting through the implementation and monitoring of the Strategy while ensuring
                        accountability to our Shareholder and stakeholders.

                    </Text>
                </Box>
            </SimpleGrid>
            <Box>
                <Heading size={{base:'sm',md:'md'}} mb={4}>The Board of Directors</Heading>
                <Text opacity={1} textAlign={'justify'} mb={4}>
                    The Board is responsible for corporate governance and providing strategic direction
                    to the Corporation by approving the strategy and targets. The Board also considers the
                    business impact of WUC operations on water users. .{' '}
                </Text>
            </Box>
            <Box>
                <Heading size={{base:'sm',md:'md'}} mb={4}>Board Appointments</Heading>
                <Text opacity={1} textAlign={'justify'} mb={4}>
                    The WUC Board appointments are made by the Shareholder to provide governance
                    and oversight in the running of Water Utilities Corporation. This is in line
                    with the WUC Act that established the Corporation as an entity.  The Minister
                    of Lands and Water Affairs recognises the importance of varied expertise and experience,
                    leadership abilities, for a Board which is equipped to oversee the works of the Corporation.
                    The appointments are formal with a letter of appointment followed by an induction programme.
                    The induction given to Board members gives them an appreciation and dynamics of water supply.
                </Text>
            </Box>
        </Box>

        {/* <BoardOfDirectors/>*/}
        <BoardMembers/>
        <Societies/>
    </>

);
export default AboutUs