import React from "react";
import {Box, Button, Flex, Icon, Text} from "@chakra-ui/react";
import {FaCircleXmark} from "react-icons/fa6";


const Approved= () => {

    return (
        <>
        <Flex px={2} flexDirection={'column'} justify={'center'} align={'center'} w={'full'} h={'100vh'} >

                <Box >
                    <Icon as={ FaCircleXmark} boxSize={{base:"16",md:"24"}} borderWidth={8} borderRadius={'full'} borderColor={'red.200'} color={"red.500"} />
                </Box>

                <Text textAlign={'center'} fontWeight={'bold'} my={2} fontSize={{base:"20px"}}>
                    Payment Declined
                </Text>
            <Text textAlign={'center'} my={2} fontSize={{base:"20px"}}>
               An error occured while processing your payment.Try again later.
            </Text>
            {/** <Text  my={2} fontSize={{base:"20px"}}>
                {`Payment ID: 12344`}
            </Text>*/}
            <Text textAlign={'center'} my={2} fontSize={{base:"14px"}}>
                {` ${new Date()}`}
            </Text>
            <Button  my={2}  bg={'red.500'} _hover={{bg:'red.400'}} p={4} w={{base:"32"}}>
                OK
            </Button>

        </Flex>
        </>
    )

};


export default Approved;
