import React, { useEffect, useState } from 'react';
import { useDisclosure } from '@chakra-ui/hooks';
import { Icon, Box, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text } from "@chakra-ui/react";
import { FaCircleCheck, FaCircleXmark } from 'react-icons/fa6';
import utils from "../../utils/commonFunctions";
import PaymentReceiptPdf from "../PdfViewer/PaymentReceiptPdf";

const ResponseModal = ({ reference, statusCode,reason }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [success, setSuccess] = useState(false);
    const [payment, setPayment] = useState(null);

    async function getPayment(reference){
        const fetchedData=await utils.getPaymentByReference(reference)
        if(fetchedData){
            setPayment(fetchedData)
        }
    }
    useEffect(() => {
        if (statusCode && reference) {
            if (statusCode === "200") {
                if (reference && reference.startsWith('"') && reference.endsWith('"')) {
                    reference= reference.slice(1, -1); // Remove first and last character (quotes)
                }
                getPayment(reference)
                setSuccess(true);
            } else {
                setSuccess(false);
            }
            onOpen();

            //clear query params
            window.history.pushState({}, document.title, window.location.pathname);
        }
    }, [statusCode, reference, onOpen]);

    return (
        <>
            <Modal minW={'lg'} zIndex={'10000'} closeOnOverlayClick={false} isCentered={true} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        {success ? (
                            <Flex px={2} flexDirection={'column'} justify={'center'} align={'center'} w={'full'} h={'60vh'}>
                                <Box>
                                    <Icon as={FaCircleCheck} boxSize={{ base: "16", md: "24" }} borderWidth={8} borderRadius={'full'} borderColor={'green.200'} color={"green.500"} />
                                </Box>
                                <Text textAlign={'center'} fontWeight={'bold'} my={2} fontSize={{ base: "20px" }}>
                                    The payment was successful.
                                </Text>
                                <Text my={2} fontSize={{ base: "20px" }}>
                                    {`Transaction Ref: ${reference}`}
                                </Text>
                                <Text textAlign={'center'} my={2} fontSize={{ base: "14px" }}>
                                    {new Date().toLocaleString()}
                                </Text>
                                <Button my={2} bg={'green.500'} _hover={{ bg: 'green.400' }} p={4} w={{ base: "32" }} onClick={onClose}>
                                    OK
                                </Button>
                                {success&&payment&&
                                    <PaymentReceiptPdf pdfData={payment}/>
                                }
                            </Flex>
                        ) : (
                            <Flex px={2} flexDirection={'column'} justify={'center'} align={'center'} w={'full'} h={'60vh'}>
                                <Box>
                                    <Icon as={FaCircleXmark} boxSize={{ base: "16", md: "24" }} borderWidth={8} borderRadius={'full'} borderColor={'red.200'} color={"red.500"} />
                                </Box>
                                <Text textAlign={'center'} fontWeight={'bold'} my={2} fontSize={{ base: "20px" }}>
                                    Payment Declined
                                </Text>
                                <Text textAlign={'center'} my={2} fontSize={{ base: "20px" }}>
                                    An error occurred while processing your payment. {reason?`${reason}`:"Try again later"}.
                                </Text>
                                <Text textAlign={'center'} my={2} fontSize={{ base: "14px" }}>
                                    {new Date().toLocaleString()}
                                </Text>
                                <Button my={2} bg={'red.500'} _hover={{ bg: 'red.400' }} p={4} w={{ base: "32" }} onClick={onClose}>
                                    OK
                                </Button>


                            </Flex>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ResponseModal;