import React, {useState} from "react";
import {

    Heading,
    Flex,

} from "@chakra-ui/react";

import placeholder from "../../../../assets/albumPlaceholder.jpg"
const Picture = ({name,  image}) => {
const [isOpen,setIsOpen]=useState(false)
    return(<>

        <Flex
            as={'button'}
            onClick={()=>setIsOpen(true)}
            width="full"
            justifyContent="start"
            alignItems="end"
            height="165px"
            borderRadius="2xl"
            boxShadow="md"
            cursor={'pointer'}
            bgImage={image||placeholder}
            bgRepeat={'no-repeat'}
            bgSize={"100% 165px"}
            transition="transform 0.5s, filter 0.5s ease-in-out"
            _hover={{
                transform: `scale(105%)`,
                filter: "brightness(75%)",
            }}

        >
            <Heading size={'sm'} color={'white'} fontWeight={'medium'} noOfLines={2} bg={'blackAlpha.500'} w={'full'}
                     borderBottomRadius={'2xl'} p={2}>{name}</Heading>

        </Flex>


    </>)
};

export default Picture