import {
    Flex,
    Input,
    Stack,
    Icon,
    Text,
    Button,
    Box,
    Heading,
    useToast,
    FormLabel,
    FormControl,
    VStack,
    HStack,
    Link,
    InputGroup,

} from "@chakra-ui/react";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

import {PropagateLoader} from "react-spinners";
import {CustomGeneralToast} from "../../../utils/customChakraToasts";
import {Link as RouterLink} from "react-router-dom";
import {API} from "../../../utils/api";

import {LiaUnlockAltSolid} from "react-icons/lia";
import enums from "wuc-library/enums";
import utils from "../../../utils/commonFunctions";
function ResetPassword() {

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);

    const {addGeneralToast} = CustomGeneralToast();
    const toast = useToast();



    const handleLogin = async (event) => {
        event.preventDefault()
        setLoading(true); // Ensure loading is indicated at the start
        try {

            if (email === "") {
                toast.closeAll();
                addGeneralToast({
                    message: "Email must be filled in",
                    type: "warning",
                });
                setLoading(false); // Stop loading indication on failure
                return;
            }

            const response = await utils.forgotPassword(email)

            // Consider checking for other response statuses as needed
            if (response.status === 200 || response.status === 201) {
                setShow(true)
            } else {
                toast.closeAll();
                addGeneralToast({
                    message: response.data.message || "Password Reset failed, please try again.",
                    type: "error",
                });
            }
        } catch (e) {
            console.error(e);
            toast.closeAll();
            addGeneralToast({
                message: e?.message || "An error occurred during password reset.",
                type: "error",
            });
        } finally {
            setLoading(false); // Ensure loading is stopped regardless of outcome
        }
    };


    return (
        <>

            <Stack
                maxW={{base: "full", md: 'md'}}
                minW={{base: "full", md: 'md'}}
                minH={'sm'}
                bg={"white"}
                p={8}
                borderRadius={"xl"}
                boxShadow={"md"}
                direction={{base: "column", md: "row"}}
                justify={'center'}
                align={'center'}
            >
                <Stack spacing={4} w={"full"}>


                    {show ?<>
                            <Flex align={"center"} justify="center">
                                <VStack>
                                    <Box
                                        backgroundColor=" #0093D0"
                                        borderRadius="50%"
                                        width="50px"
                                        height="50px"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Icon as={LiaUnlockAltSolid} color="white" boxSize="50%"/>
                                    </Box>
                                    <Heading color="blue.800" as="h6" size="lg">
                                        Reset Link Sent
                                    </Heading>
                                    <Text color={"blue.800"} mt={-1} fontSize={"lg"}>
                                       Password reset link has been sent.Please check your email
                                    </Text>
                                </VStack>
                            </Flex>
                        </>



                        : <form onSubmit={handleLogin}>
                            <Flex align={"center"} justify="center">
                                <VStack>
                                    <Box
                                        backgroundColor=" #0093D0"
                                        borderRadius="50%"
                                        width="50px"
                                        height="50px"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Icon as={LiaUnlockAltSolid} color="white" boxSize="50%"/>
                                    </Box>
                                    <Heading color="blue.800" as="h6" size="lg">
                                        Reset Password
                                    </Heading>
                                    <Text color={"blue.800"} mt={-1} fontSize={"lg"}>
                                        Reset password for your account
                                    </Text>
                                </VStack>
                            </Flex>
                            <FormControl mb={2} id="email">
                                <FormLabel color="blue.800">Email</FormLabel>
                                <InputGroup>
                                    <Input
                                        type={"email"}
                                        name="email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        borderRadius={"2xl"}
                                        focusBorderColor="blue.300"

                                    />
                                </InputGroup>
                            </FormControl>
                            <Stack spacing={6}>
                                <Button
                                    _hover={{
                                        bg: "blue.300",
                                        color: "white",
                                    }}
                                    color="white"
                                    bg=" #0093D0"
                                    type="submit"
                                    variant="solid"
                                    borderRadius={"2xl"}
                                >
                                    {loading ? (
                                        <>
                                            <PropagateLoader size={8} color="white"/>
                                        </>
                                    ) : (
                                        <Text>Reset Password</Text>
                                    )}
                                </Button>

                                <HStack justifyContent="center" alignItems="center">
                                    <Text color={"blue.800"}>Don't have an account?</Text>
                                    <RouterLink to="/register">
                                        <Link color="#0093D0" _hover={{textDecoration: "none"}}>
                                            Register
                                        </Link>
                                    </RouterLink>
                                </HStack>
                            </Stack>
                        </form>}

                </Stack>
            </Stack>
        </>
    );
}

export default ResetPassword;
