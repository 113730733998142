// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .table th, .table td {
    border: 1px solid black;
    border-collapse: collapse;
     padding:4px 16px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ContentPages/Pages.css"],"names":[],"mappings":"CAAC;IACG,uBAAuB;IACvB,yBAAyB;KACxB,gBAAgB;AACrB","sourcesContent":[" .table th, .table td {\n    border: 1px solid black;\n    border-collapse: collapse;\n     padding:4px 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
