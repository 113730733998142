import React from "react";
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody,} from "@chakra-ui/react";

import {PDFViewer} from "@react-pdf/renderer";


const PdfViewerModal = ({PdfTemplate, isOpen,  onClose }) => {
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} bg={'gray.700'} size={{base:'full'}} h={'100%'} isCentered>
                <ModalOverlay />
                <ModalContent bg={'#323639'} h={'full'} m={0}>
                    <ModalCloseButton color={'white'} />
                    <ModalBody h={'full'}>
                       <PDFViewer width={'100%'} height={'100%'}>
                           {PdfTemplate}
                       </PDFViewer>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default PdfViewerModal;
