import React, {useEffect, useState} from "react";
import {
    Box, Table, Thead, Tbody, Tr, Th, Td,
    Flex, Heading, Text, SimpleGrid, ListItem, UnorderedList, Button
} from "@chakra-ui/react";
import utils from "../../../utils/commonFunctions";
import {FormatMoney} from 'format-money-js';
import {Link} from "react-router-dom";

// Initialize format-money-js with the currency and formatting options
const fm = new FormatMoney({
    decimals: 2,
    format: '%v', // Only format value
});


// Define the analyte data as an object of arrays
const analyteData = {
    "Physical Parameters": [
        {name: "Biological Oxygen Demand", cost: 76.90},
        {name: "Chemical Oxygen Demand", cost: 107.65},
        {name: "Chlorine - Free", cost: 29.75},
        {name: "Chlorine - Total", cost: 29.65},
        {name: "Color", cost: 50.10},
        {name: "Dissolved Oxygen", cost: 33.40},
        {name: "Electrical Conductivity", cost: 43.60},
        {name: "pH & Temperature", cost: 43.55},
        {name: "Suspended Solids - Total", cost: 173.21},
        {name: "Suspended Solids - Volatile", cost: 79.00},
        {name: "Total Dissolved Solids (TDS)", cost: 43.60},
        {name: "Turbidity", cost: 38.25},
    ],
    "Cations (Metals) ": [
        {name: "Aluminum", cost: 40.00},
        {name: "Ammonia", cost: 40.00},
        {name: "Arsenic", cost: 40.00},
        {name: "Boron", cost: 40.00},
        {name: "Cobalt", cost: 40.00},
        {name: "Cadmium", cost: 40.00},
        {name: "Calcium", cost: 40.00},
        {name: "Chromium", cost: 40.00},
        {name: "Copper", cost: 40.00},
        {name: "Iron", cost: 40.00},
        {name: "Lead", cost: 40.00},
        {name: "Magnesium", cost: 40.00},
        {name: "Manganese", cost: 40.00},
        {name: "Mercury", cost: 40.00},
        {name: "Nickel", cost: 40.00},
        {name: "Potassium", cost: 40.00},
        {name: "Sodium", cost: 40.00},
        {name: "Uranium", cost: 40.00},
        {name: "Vanadium", cost: 40.00},
        {name: "Zinc", cost: 40.00},
        {name: "Cations - ICP-OES", cost: 559.65},
        {name: "Cations - Discrete Analyser", cost: 427.45},
    ],
    "Anions": [
        {name: "Bromide", cost: 61.05},
        {name: "Chloride", cost: 61.05},
        {name: "Fluoride", cost: 61.05},
        {name: "Nitrate", cost: 61.05},
        {name: "Nitrite", cost: 61.05},
        {name: "Phosphate", cost: 61.05},
        {name: "Sulphate", cost: 61.05},
        {name: "Anions - Discrete Analyser", cost: 427.45},
        {name: "Anions - ICS 3000", cost: 427.45},
    ],
    "Microbiology Parameters": [
        {name: "Algae enumeration", cost: 200.00},
        {name: "Clostridium perfringens", cost: 248.10},
        {name: "Cryptosporidium & Giardia", cost: 3221.90},
        {name: "Escherichia coli (MFT)", cost: 117.40},
        {name: "Escherichia coli (Colilert-18)", cost: 117.40},
        {name: "Faecal coliforms (MFT)", cost: 79.00},
        {name: "Faecal coliforms (Colilert-18)", cost: 79.00},
        {name: "Faecal Streptococci", cost: 80.55},
        {name: "Heterotrophic Plate Count", cost: 322.00},
        {name: "Legionella pneumophila", cost: 51.00},
        {name: "Pseudomonas aeruginosa", cost: 179.75},
        {name: "Total coliforms (MFT)", cost: 117.40},
        {name: "Total coliforms (Colilert-18)", cost: 117.40},
        {name: "Vibrio cholerae", cost: 165.00},
    ],
};

function AnalysisFeesTable({data}) {
    return (
        <Box overflowX="auto">
            <SimpleGrid columns={{base: 1, lg: 2}} spacing={8}>
                {Object.keys(data).map((category) => (
                    <Table variant="striped" key={category}>
                        <Thead>
                            <Tr>
                                <Th>{category}</Th>
                                <Th>Cost (BWP)</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {analyteData[category].map((product, index) => (
                                <Tr key={index}>
                                    <Td>{product.name}</Td>
                                    <Td>{fm.from(product.cost)}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                ))}
            </SimpleGrid>
        </Box>
    );
}


// Define the group cost data
const groupCostData = [
    {
        type: "Basic Drinking Water Human Consumption Test",
        parameters: "pH, turbidity, electrical conductivity, total dissolved solids, E. coli, faecal coliforms, total coliforms, free chlorine, total chlorine, anions, and cations.",
        cost: 1566.40,
    },
    {
        type: "Basic Drinking water test for livestock and poultry",
        parameters: "alkalinity, pH, turbidity, electrical conductivity, total dissolved solids, faecal coliforms, total coliforms, Heterotrophic Plate Count, anions, and cations.",
        cost: 1688.45,
    },
    {
        type: "Basic Water quality test for irrigation",
        parameters: "pH, electrical conductivity, total dissolved solids, suspended solids, E. coli, faecal coliforms, anions, and cations.",
        cost: 1390.15,
    },
    {
        type: "Basic Wastewater Discharge Compliance Test",
        parameters: "pH, temperature, dissolved oxygen, biological oxygen demand, chemical oxygen demand, turbidity, conductivity, total dissolved solids, faecal coliforms, total coliforms, ammonia, free chlorine, total chlorine, anions, and cations.",
        cost: 1637.65,
    },
    {
        type: "New connections to existing WUC network",
        parameters: "Faecal coliforms, free chlorine, total chlorine, total coliforms, E. coli and turbidity.",
        cost: 293.95,
    },
    {
        type: "Treated Water Check",
        parameters: "Electrical conductivity, total dissolved solids, iron, manganese, turbidity, free chlorine, and total chlorine.",
        cost: 221.15,
    },
    {
        type: "Basic Water quality test for treated Bottled water",
        parameters: "pH, total dissolved solids, E. coli, faecal coliforms, total coliforms, P. aeruginosa, Heterotrophic Plate Count, Alkalinity, anions, and cations.",
        cost: 1947.10,
    },
];

function GroupCostTable() {
    return (
        <Box overflowX="auto">
            {/* Group cost table */}
            <Table variant="striped" >
                <Thead>
                    <Tr>
                        <Th>Type of Analysis</Th>
                        <Th>Parameters</Th>
                        <Th>Cost (BWP)</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {groupCostData.map((group, index) => (
                        <Tr key={index}>
                            <Td>{group.type}</Td>
                            <Td>{group.parameters}</Td>
                            <Td>{fm.from(group.cost)}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
}


const ContentPages = () => {

    return (<>
        <Flex
            direction={'column'}
            w={"100%"}
            justify={"center"}
            mt={4}
        >
            <Box textAlign={'justify'} >
                <Heading size={{base: 'md', md: 'lg'}}
                         mb={6} color={'brand.700'}>Laboratory Services</Heading>
                <Box

                >
                    <Box>
                        <Flex direction={{base: 'column', md: 'row'}} justify="space-between" align="center" mb={4}>
                            <Heading as="h2" size="md" mb={{base: 4, md: 0}} flex={1}>
                                Water Quality Section
                            </Heading>
                            {/* Request Quotation Button */}
                            <Button as={Link} to="/self-service-portal/laboratory-services" colorScheme="teal"
                                    size="lg" ml={{md: 4}}>
                                Request Quotation
                            </Button>
                        </Flex>
                        <Heading as="h3" size="md" mb={2}>
                            Laboratory Testing Services
                        </Heading>
                        {/* Flex box for Text and Button */}

                        <Text mb={{base: 4}} >
                            Water Utilities Corporation (WUC) is committed to the effective
                            management of its drinking water supplies to provide a safe,
                            high-quality product that consistently meets the Botswana Standard
                            for Drinking Water Quality Specification (BOS 32:2015), World Health
                            Organisation Drinking Water Quality Guidelines and the Botswana
                            Standard for the Botswana Wastewater Specification Standard (BOS
                            93:2012). A wide variety of parameters are tested in the WUC state
                            of the art laboratory at Mmamashia, while a limited scope of
                            analysis is done at Service Centre laboratories. Water samples are
                            also sent to other strategic laboratory alliances in the region for
                            further testing. The quality of water is tested at various points in
                            the distribution network, including the consumer's tap to maintain
                            the set quality standards and safeguard the consumer's health.
                        </Text>
                        <Text mb={4}>
                            The laboratory (lab) provides analysis of water and wastewater
                            sample matrices using various analytical methodologies and
                            instrumentation. To ensure that quality standards are maintained,
                            the lab participates in proficiency testing schemes and is
                            accredited by the Southern African Development Community
                            Accreditation Service (SADCAS) in accordance with ISO/IEC
                            17025:2017 General requirements for the competence of testing and
                            calibration laboratories.
                        </Text>
                        <Text mb={4}>
                            A comprehensive water quality database is maintained on a
                            Laboratory Information Management System (LIMS).In a drive to align to the Corporation's Vision and Mission, WUC
                            laboratories aim to be a market leader in the testing field and your
                            one stop shop for analysis of ground, surface, drinking, waste and
                            processed water.
                        </Text>
                        <Heading as="h3" size="md" mb={2}>
                            Laboratory Capacity
                        </Heading>
                        <Text mb={4}>
                            The laboratory provides sampling and testing services using various
                            internationally recognised techniques such as Photometry,
                            Electrometry, Colorimetry, Gravimetry, Spectroscopy, Ion
                            Chromatography, membrane filtration and Defined Subtrate
                            Technology®. The main laboratory is located at the Mmamashia Water Treatment
                            Works in Mmamashia, 1.8 km off Gaborone - Francistown, A1 Road,
                            Mmamashia Botswana.
                        </Text>
                        <Text mb={4}>
                            Service Centre laboratories are in Glen Valley (Gaborone), Kasane,
                            Ntimbale (Masunga), Shashe (Francistown), Mambo (Francistown),
                            Selibe-Phikwe, Palapye, Mahalapye, Gantsi and Lobatse. Other
                            upcoming testing facilities include Maun, Molepolole, Letlhakane,
                            Serowe, Hukuntsi and Kanye. Testing at these sites is, however,
                            limited to only some of the analytes.
                        </Text>
                    </Box>

                    <Heading as="h3" size="md" my={2} transform={'capitalize'}>
                        Laboratory Analysis Fees
                    </Heading>
                    <Heading as="h3" size="sm" my={4}>
                        A. Individual Analysis
                    </Heading>
                    <AnalysisFeesTable data={analyteData}/>


                    <Heading as="h3" size="sm" my={4}>
                        B. Group Costs
                    </Heading>
                    <GroupCostTable/>

                    <Box>
                        {/* Section C: Sampling Charges */}
                        <Heading size="sm" my={4}>
                            C. Sampling Charges
                        </Heading>

                        {/* Sampling details */}
                        <Text>
                            Distance travelled in Kilometers on Tarred Road multiplied by mileage charges per Kilometer
                            (BWP 1.75) or
                            Distance travelled in Kilometers on Gravel/Sand Road multiplied by mileage charges per
                            Kilometer (BWP 2.08).
                        </Text>
                        <Text>Handling Fee per batch of samples collected: BWP 53.66</Text>

                        {/* Note */}
                        <Text mt={4} fontWeight="bold">Note:</Text>
                        <UnorderedList>
                            <ListItem>
                                Total cost of sampling and analysis may vary depending on the
                                testing <strong>technique</strong> available at the time of request for analysis
                                and <strong>times</strong> scheduled for sampling being either during working or outside
                                working hours.
                            </ListItem>
                        </UnorderedList>

                        {/* Testing Handling Fees */}
                        <Heading size="sm" mt={8} mb={4}>
                            Testing Handling Fees
                        </Heading>
                        <UnorderedList>
                            <ListItem>
                                <Text display="inline-block" mr={4}>1. Potable Water</Text>
                                <Text display="inline-block" fontWeight="bold">BWP 50.00</Text>
                            </ListItem>
                            <ListItem gap={4}>
                                <Text display="inline-block" mr={4}>2. Wastewater</Text>
                                <Text display="inline-block" fontWeight="bold">BWP 100.00</Text>
                            </ListItem>
                        </UnorderedList>

                        <Text mt={4} fontStyle="italic">The above prices are VAT exclusive.</Text>

                        {/* Turnaround Times */}
                        <Heading size="sm" mt={8} mb={4}>
                            Turnaround Times
                        </Heading>
                        <UnorderedList>
                            <ListItem>
                                <Text display="inline-block" mr={4}>1. Chemistry Analyses</Text>
                                <Text display="inline-block" fontWeight="bold">10 working days</Text>
                            </ListItem>
                            <ListItem>
                                <Text display="inline-block" mr={4}>2. Microbiology Analyses</Text>
                                <Text display="inline-block" fontWeight="bold">4 working days</Text>
                            </ListItem>
                        </UnorderedList>

                        {/* Contact Details */}
                        <Heading size="sm" mt={8} mb={4}>
                            Contact Details
                        </Heading>
                        <Text>Manager – Laboratory Services</Text>
                        <Text>Tel: +267 3604400/01</Text>
                        <Text>Email: mletsamao@wuc.bw</Text>
                        <Text>Website: www.wuc.bw</Text>
                    </Box>
                </Box>
                <Heading size="sm" mt={4}>
                    For further information on WUC products and services Call 0800 555 555
                </Heading>
            </Box>
        </Flex>

    </>);
};
export default ContentPages;
