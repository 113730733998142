import React, {useState, useEffect} from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

// Pages
import Home from "./pages/Home/Home";

// Components
import Sidebar from "./components/SideBar/SideBar";

// Add individual pages for each route
import Dashboard from "./pages/Dashboard/Dashboard";
import NewConnection from "./pages/NewConnection/NewConnection";
import PayBillOnline from "./pages/PayBillOnline/PayBillOnline";
//import ViewAccountBalance from "./pages/ViewAccountBalance/ViewAccountBalance";
import SubmitMeterReading from "./pages/SubmitMeterReading/SubmitMeterReading";
import RequestMonthlyBill from "./pages/RequestMonthlyBill/RequestMonthlyBill";
import ReportLeak from "./pages/ReportLeak/ReportLeak";
import ManageUtilityContracts from "./pages/ManageUtilityContracts/ManageUtilityContracts";
import KnowYourCustomer from "./pages/KnowYourCustomer/KnowYourCustomer";
import LaboratoryServices from "./pages/LaboratoryServices/LaboratoryServices";
import UpdateProfile from "./pages/UpdateProfile/UpdateProfile";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import VerifyAccount from "./pages/VerifyAccount/VerifyAccount";
import {Box} from "@chakra-ui/react";
import PrivateRoute from "./components/Private/PrivateRoute";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import AuthRouter from "./components/Authentication/AuthRouter";
import ReportShortage from "./pages/ReportShortage/ReportShortage";
import ViewQuotation from "./pages/LaboratoryServices/ViewQuotation";
import CustomerServices from "./pages/CustomerServices/CustomerServices";
import MyConnections from "./pages/NewConnection/MyConnections";

import ViewAccountBalanceGuest from "./pages/ViewAccountBalance/ViewAccountBalanceGuest";
import Tenders from "./pages/Tenders/Tenders";
import About from "./pages/AboutUs/About";
import Videos from "./pages/Media/Videos";
import Pictures from "./pages/Media/Pictures";
import Events from "./pages/Media/Events";
import FAQ from "./pages/CustomerServices/Components/FAQ/FAQMainContainer";
import Publications from "./pages/Publications/Publications";
import BuyTender from "./pages/Tenders/BuyTender";
import Projects from "./pages/Projects/Projects";
import ProjectView from "./pages/Projects/ProjectView";
import Disconnection from "./pages/Disconnection/Disconnection";
import BranchLocator from "./pages/BranchLocator/BranchLocator";
import VerificationRouter from "./components/Authentication/VerificationRouter";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ContactUs from "./pages/ContactUs/ContactUs";
import Notices from "./pages/Notices/Notices";
import BillCalculator from "./pages/BillCalculator/BillCalculator";
import BillCalculatorGuest from "./pages/BillCalculator/BillCalculatorGuest";
import UpdateConnection from "./pages/NewConnection/UpdateConnection";
import Mydisconnections from "./pages/Disconnection/Mydisconnections";
import Approved from "./pages/PaymentGateway/Approved";
import Declined from "./pages/PaymentGateway/Declined";
import Maintance from "./pages/Maintance/Maintance";
import ContentPages from "./pages/ContentPages/ContentPages";
import LinkPages from "./pages/ContentPages/LinkPages";
import Survey from "./pages/Surveys/SurveyContainer";
import SurveyAttempt from "./pages/Surveys/SurveyAttempt";
import CreateQuotation from "./pages/LaboratoryServices/CreateQuotation";
import NoticeView from "./pages/Notices/NoticeView";
import ReportLeakGuest from "./pages/ReportLeak/ReportLeakGuest";
import WaterShortageGuest from "./pages/ReportShortage/WaterShortageGuest";
import RequestMonthlyBillGuest from "./pages/RequestMonthlyBill/RequestMonthlyBillGuest";
import SubmitMeterReadingGuest from "./pages/SubmitMeterReading/SubmitMeterReadingGuest";
import Publication from "./pages/Publications/Components/Publication";
import PublicationList from "./pages/Publications/PublicationList";
import News from "./pages/News/News";
import NewsView from "./pages/News/NewsView";
import Thothi from "./components/Thothi/Thothi";
import ReportsGuest from "./pages/Reports/ReportsGuest";
import BuyToken from "./pages/BuyMeterToken/BuyToken";
import BuyTokenGuest from "./pages/BuyMeterToken/BuyTokenGuest";
import LaboratoryPage from "./pages/ContentPages/LaboratoryPage";

function App() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        // Simulate a loading time
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000); // 3 seconds loading time

        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
            >
                <Thothi/>

            </Box>
        );
    }
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route index element={<Home/>}/>
                    <Route path={'content/laboratory-services'} element={<LaboratoryPage/>}/>
                    <Route path={'content/*'} element={<ContentPages/>}/>
                    <Route path={'link-content/*'} element={<LinkPages/>}/>
                    <Route path="customer-services/*">
                        <Route index element={<CustomerServices/>}/>
                        <Route path="faqs" element={<FAQ/>}/>
                    </Route>

                    <Route element={<AuthRouter/>}>
                        <Route path="login" element={<Login/>}/>
                        <Route path="register" element={<Register/>}/>
                        <Route path="forgot-password" element={<ForgotPassword/>}/>
                        <Route path="reset-password/*" element={<ResetPassword/>}/>
                        <Route element={<VerificationRouter/>}>
                            <Route path="verify-account" element={<VerifyAccount/>}/>

                        </Route>
                    </Route>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/self-service-portal/*">
                            <Route index element={<Dashboard/>}/>
                            <Route path="dashboard" index element={<Dashboard/>}/>
                            <Route path="know-your-customer" element={<KnowYourCustomer/>}/>
                            <Route path="new-connection" element={<NewConnection/>}/>
                            <Route path="update-connection" element={<UpdateConnection/>}/>
                            <Route path="connection-applications" element={<MyConnections/>}/>
                            <Route path="disconnection-applications" element={<Mydisconnections/>}/>
                            <Route path="disconnection" element={<Disconnection/>}/>
                            <Route path="update-profile/*">
                                <Route index element={<UpdateProfile/>}/>
                                <Route path="change-password" element={<ChangePassword/>}/>
                            </Route>
                            <Route path="pay-bill-online" element={<PayBillOnline/>}/>
                            <Route
                                path="submit-meter-reading"
                                element={<SubmitMeterReading/>}
                            />

                            <Route
                                path="request-monthly-bill"
                                element={<RequestMonthlyBill/>}
                            />
                            <Route path="report-leak" element={<ReportLeak/>}/>
                            {/**<Route path="*" element={<Maintance/>}/>**/}
                            <Route path="bill-calculator" element={<BillCalculator/>}/>
                            <Route path="report-water-shortage" element={<ReportShortage/>}/>
                            <Route path="manage-utility-contracts/*">
                                <Route index element={<ManageUtilityContracts/>}/>
                                <Route path="disconnection" element={<Disconnection/>}/>
                            </Route>

                            <Route
                                path="prepaid-recharge"
                                element={<BuyToken/>}
                            />
                            <Route path="laboratory-services/*">
                                <Route index element={<LaboratoryServices/>}/>
                                <Route path="view-quotation" element={<ViewQuotation/>}/>
                                <Route path="request-quotation" element={<CreateQuotation/>}/>
                            </Route>
                        </Route>
                    </Route>

                    <Route
                        path="pay-bill-online"
                        element={<ViewAccountBalanceGuest/>}
                    />
                    <Route
                        path="request-monthly-bill"
                        element={<RequestMonthlyBillGuest/>}
                    />
                    <Route
                        path="submit-meter-reading"
                        element={<SubmitMeterReadingGuest/>}
                    />
                    <Route
                        path="report-leak"
                        element={<ReportLeakGuest/>}
                    />
                    <Route
                        path="reports"
                        element={<ReportsGuest/>}
                    />
                    <Route
                        path="report-water-shortage"
                        element={<WaterShortageGuest/>}
                    />
                    <Route
                        path="bill-calculator"
                        element={<BillCalculatorGuest/>}
                    />

                    <Route path="media/*">
                        <Route index element={<Pictures/>}/>
                        {/* <Route path="faq" element={<FAQ/>}/> */}
                        <Route path="pictures" element={<Pictures/>}/>
                        <Route path="videos" element={<Videos/>}/>
                        <Route path="events" element={<Events/>}/>
                    </Route>

                    <Route path="publications/category/*" element={<Publications/>}/>
                    <Route path="publications" element={<PublicationList/>}/>


                    <Route path="tenders/*">
                        {/* <Route path="*" element={<Maintance/>}/> */}
                        <Route index element={<Tenders/>}/>
                        <Route path="tenders" element={<Tenders/>}/>
                        <Route path="buy-tender/:id" element={<BuyTender/>}/>
                    </Route>
                    <Route path="notices/*">
                        <Route index element={<Notices/>}/>
                        <Route path="notices" element={<Notices/>}/>
                        <Route path="view" element={<NoticeView/>}/>
                    </Route>
                    <Route path="projects/*">
                        <Route index element={<Projects/>}/>
                        <Route path="projects" element={<Projects/>}/>
                        <Route path="view" element={<ProjectView/>}/>
                    </Route>
                    <Route
                        path="notices"
                        element={<Notices/>}
                    />
                    <Route
                        path="about"
                        element={<About/>}
                    />
                    <Route
                        path="contact-us"
                        element={<ContactUs/>}
                    />
                    <Route
                        path="survey"
                        element={<Survey/>}
                    />
                    <Route
                        path="surveys/*"
                        element={<SurveyAttempt/>}
                    />

                    <Route
                        path="wuc-branch-locator"
                        element={<BranchLocator/>}
                    />
                    <Route path="/news" element={<News/>}/>
                    <Route path="/news/view/*" element={<NewsView/>}/>

                    <Route path="payments/*">
                        <Route path="approved" element={<Approved/>}/>
                        <Route path="declined" element={<Declined/>}/>
                    </Route>

                    <Route
                        path="prepaid-recharge"
                        element={<BuyTokenGuest/>}
                    />
                    <Route path="/*" element={<PageNotFound/>}/>
                </Routes>
            </div>
        </Router>
    );
}

export default App;
