import React from "react";
import {
    Box, Heading, Flex, Text, Image, Grid, GridItem, Hide,VStack
} from "@chakra-ui/react";
import image1 from "../../../assets/onionTower.png";
import Values from "./Values";
import Vision from "./Vision";
import CorprateProfile from "./CorprateProfile";
const AboutUs = () => (
    <>
        <VStack bg={'white'} py={8} mt={8} >
            <Heading size="lg" mb={8}  textAlign={'start'} color={'blue.800'} textTransform={'capitalize'}>Corporate Profile</Heading>
            <Grid templateColumns='repeat(12, 1fr)' >
                <GridItem colSpan={{base:12,lg:6}} h={'full'} pl={{base:0,lg:32}} px={{base:8}} justify={'center'} textAlign={'start'}>
                    <Heading size="md" mb={4}   color={'blue.800'} textTransform={'capitalize'}>Our  <Box as={'span'} color={'brand.700'} >Background</Box> </Heading>
                    <Text fontWeight={'normal'}  mb={4}  color={'blue.800'} textAlign={'justify'}>
                        Established in 1970 by an Act of Parliament (Laws of Botswana Cap 74:02), Water
                        Utilities Corporation (WUC) is a parastatal organisation wholly owned by the Government
                        of Botswana.  Upon establishment, it was mandated to manage a single project: the supply
                        and distribution of water in what was then called the Shashe Development Area.
                    </Text>
                    <Heading size="md" mb={4}   color={'blue.800'} textTransform={'capitalize'}> <Box as={'span'} color={'brand.700'} >Mandate</Box> </Heading>
                    <Text fontWeight={'normal'}  mb={4}  color={'blue.800'}  textAlign={'justify'}>
                        The Corporation’s mandate is to plan, construct, operate, maintain, and distribute potable
                        water in the entire country, as well as to manage wastewater services as mandated by the Government
                        of the Republic of Botswana. The delivery of uniform service levels, as well as the upgrading of water
                        infrastructure, to meet requisite water and wastewater standards remains a long-term objective that the
                        Corporation is pursuing. The Corporation's customer base was in excess of 550,500 connections at the time
                        of the publishing of this Report.
                    </Text>
                </GridItem>

                    <GridItem colSpan={{base:12,lg:6}} borderRadius={'30px'}   p={8} >
                        <Flex alignItems={'center'} justifyContent={'center'}>
                            <Image  src={image1} alt='WUC Icon' borderRadius={'2xl'}  h={'450px'} w={{base:"full",lg:'500px'}}/>
                        </Flex>
                    </GridItem>
                <GridItem colSpan={{base:12}}>
                    <CorprateProfile/>
                </GridItem>
                <GridItem colSpan={{base:12}} h={'full'} bg={'brand.700'} mt={16}  justify={'center'} textAlign={'start'}>
                    <Vision/>
                </GridItem>
                <GridItem colSpan={{base: 12}} h={'full'} pt={8} mt={16} justify={'center'} textAlign={'start'}>
                    <Box px={{base:0,lg:32}}>
                        <Values/>
                    </Box>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="#0093D0" fill-opacity="1"
                              d="M0,64L60,96C120,128,240,192,360,197.3C480,203,600,149,720,138.7C840,128,960,160,1080,176C1200,192,1320,192,1380,192L1440,192L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
                    </svg>
                </GridItem>
            </Grid>
        </VStack>

    </>

);
export default AboutUs