import React, {useEffect, useState} from "react";
import {
    Box, Flex, FormControl, FormLabel, Grid, GridItem, Heading, Input, InputGroup, Select, Text,
} from "@chakra-ui/react";
import {useUserProfile} from "../../../providers/UserProfileProvider";

const OwnerDetails = () => {
    let {userProfile}=useUserProfile()
    let {personalDetails,address,contactDetails}=userProfile||{}
    const form=JSON.parse(localStorage.getItem('landlordDetails'))
  if(personalDetails&&!form) {
      localStorage.setItem('landlordDetails', JSON.stringify({...personalDetails, ...contactDetails, ...address}))
  }



    const [formData, setFormData] = useState({
        name: form?.name ||null,
        cell: form?.cell ||null,
        tel: form?.tel ||null,
        postal: form?.postal ||null,
        email: form?.email ||null,
        identityNo: form?.identityNo || null,
        identityType: form?.identityType || null,
    });
    useEffect(() => {
        localStorage.setItem('landlordDetails', JSON.stringify(formData));
        const {tel, ...exclTel }=formData
        if(Object.values(exclTel).some(detail => detail === null||detail === ""||!detail)){
            localStorage.setItem('isValidForm',false );
        }
        else{
            localStorage.setItem('isValidForm',true );
        }


    }, [formData]);
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({
            ...prevState, [name]:value
        }));
    };

    return (
        <>
            <Box>
                <Flex mb={4}>
                    <Box>
                        <Heading color={'brand.500'}>Owner / Landlord Details</Heading>
                        <Text color={'gray.500'}>Please provide plot owner's basic information.</Text>
                    </Box>
                </Flex>
                <Grid templateColumns="repeat(12, 1fr)" gap={2} color={"blue.800"}>
                    {/* Kin Name */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired id="name">
                            <FormLabel >Full Name</FormLabel>
                            <InputGroup>
                                <Input   type="text" name="name" id="Name"  value={formData.name} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                    {/* Identity Number */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl id="identityNumber" isRequired>
                            <FormLabel >Identity Number</FormLabel>
                            <InputGroup>
                                <Input  type="number" name="identityNo"  id="Identity Number"
                                       value={formData.identityNo} onChange={handleInputChange}/>
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                    {/* Identity Type */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl id="identityType" isRequired>
                            <FormLabel >Identity Type</FormLabel>
                            <InputGroup>
                                <Select  name="identityType" id="Identity Document Type" placeholder="Select option"
                                        value={formData.identityType} onChange={handleInputChange}>
                                    <option value="Omang">Omang</option>
                                    <option value="Passport">Passport</option>
                                </Select>
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                    {/* Email */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired id="email">
                            <FormLabel>Email</FormLabel>
                            <InputGroup>
                                <Input type="email" name="email" id="Email"  value={formData.email} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>

                    {/* Cell */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired id="cell">
                            <FormLabel >Cell</FormLabel>
                            <InputGroup>
                                <Input  type="number" name="cell" id='Cellphone Number' value={formData.cell} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                    {/* Tel */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl  id="tel">
                            <FormLabel >Tel</FormLabel>
                            <InputGroup>
                                <Input  type="number" name="tel" id='Home Telephone Number' value={formData.tel} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                    {/* Plot No */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired id="plotNo">
                            <FormLabel >Postal Address</FormLabel>
                            <InputGroup>
                                <Input  type="text" name="postal"  id="Postal Address"  value={formData.postal} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>

                </Grid>
            </Box>
        </>
    );
};

export default OwnerDetails;
