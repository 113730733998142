import React from 'react';
import {
  Box,
  Text,
  VStack,
  HStack,
  SimpleGrid,
  Icon,
  Heading, Flex, Show, Hide, Stack, Center,
} from '@chakra-ui/react';

import { HiHome } from 'react-icons/hi2';
import {
  MdPayment,
  MdOutlinePersonSearch,
  MdOutlineNoAccounts,
} from 'react-icons/md';
import { SiReadthedocs } from 'react-icons/si';
import { HiOutlineDocumentArrowUp } from 'react-icons/hi2';
import {Link} from "react-router-dom";
import {BiTransferAlt} from "react-icons/bi";

const CustomBox = ({ icon, text, href }) => {
  return (
      <Link
          to={href}
          style={{textDecoration: 'none'}}
          _hover={{textDecoration: 'none'}}
      >
        <Show below={'md'}>
          <Flex align={'center'} justify={'center'} bg={{base:'transparent', md:'transparent', lg:'#f5f5f7'}} w={{base: 'full', md: 'full'}} borderRadius={50}>
            <VStack
                alignItems='center'
                justifyContent='start'
                cursor='pointer'
                px={2}
                py={1}
            >
              <Flex
                  p={4}
                  bg='brand.700'
                  borderRadius='full'
                  shadow='md'
                  transition='all 0.3s'
                  alignItems='center'
                  justifyContent='center'
                  cursor='pointer'
                  w={{base: '45px', md: '65px'}}
                  h={{base: '45px', md: '65px'}}
              >
                <Icon
                    as={icon}
                    w={6}
                    h={6}
                    color='white'
                    sx={{transition: 'color 0.3s'}}
                    className='icon-color'
                />
              </Flex>
              <Text color='#3C4043' fontSize={{base:'14px',md:'18px'}} textAlign={{base:'center'}}>{text}</Text>
            </VStack>
          </Flex>
        </Show>

        <Hide below={'md'}>
          <Flex align={'center'}  cursor='pointer' bg='#f5f5f7' h='72px' w={{base: 'full', md: '320px'}} borderRadius={50}>
            <HStack
                alignItems='center'
                justifyContent='start'
                px={2}
                py={1}
            >
              <Flex
                  p={4}
                  bg='brand.700'
                  borderRadius='full'
                  shadow='md'
                  transition='all 0.3s'
                  alignItems='center'
                  justifyContent='center'
                  w={{base: '45px', md: '65px'}}
                  h={{base: '45px', md: '65px'}}
              >
                <Icon
                    as={icon}
                    cursor='pointer'
                    w={6}
                    h={6}
                    color='white'
                    sx={{transition: 'color 0.3s'}}
                    className='icon-color'
                />
              </Flex>
              <Text color='#3C4043'>{text}</Text>
            </HStack>
          </Flex>
        </Hide>
      </Link>
  );
};

const SelfServicesBox = () => {
  return (
    <Flex
      mt={'100px'}
      bg='#F7FAFC'
      align='center'
      justify='center'
    >
      <Box  bg='white' w='full' h={{lg:'450px'}} mx={{base:0 ,lg:12}} my={4} boxShadow='md'   py={12}  px={{base:0 ,lg:24}}>
        <VStack pb={8}>
          <Heading size="xl"  textAlign={'center'} color={'blue.800'}>Self-<Box as={'span'} color={'brand.700'} >Service</Box> </Heading>
        </VStack>

        <Flex  w={'full'} justifyContent={'center'} >
          <SimpleGrid
            columns={{base:1,lg:3}}
            spacingX={{base:'40px'}}
            spacingY={{base:'40px',lg:'60px',}}
            w={'full'}
            justifyContent={'center'}
          >
            <CustomBox icon={HiHome} text='Connection Application' href='self-service-portal/connection-applications' />

                <CustomBox
                  icon={BiTransferAlt}
                  text='Prepaid Recharge'
                  href='/prepaid-recharge'
                />

            <CustomBox icon={MdPayment} text='View / Pay Your Bill' href='/pay-bill-online' />
            <CustomBox
              icon={HiOutlineDocumentArrowUp}
              text='Submit Your Meter Readings'
              href='/submit-meter-reading'
            />
            <CustomBox
              icon={MdOutlinePersonSearch}
              text='Know Your Customer (KYC)'
              href='self-service-portal/know-your-customer'
            />
            <CustomBox icon={SiReadthedocs} text='Reports' href='/reports' />
          </SimpleGrid>
        </Flex>
      </Box>
    </Flex>
  );
};

export default SelfServicesBox;
