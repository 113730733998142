import React, {useEffect, useState} from "react";
import {Button, Flex, Grid, GridItem, HStack, useDisclosure, useToast} from "@chakra-ui/react";
import {ArrowBackIcon, ArrowForwardIcon} from "@chakra-ui/icons";
import ContactDetails from "./ContactDetails";
import CustomerDetails from "./CustomerDetails";
import KinDetails from "./KinDetails";
import OwnerDetails from "./OwnerDetails";

import Attachments from "./Attachments";
import {API} from "../../../utils/api";
import {CustomGeneralToast} from "../../../utils/customChakraToasts";
import utils from "../../../utils/commonFunctions";
import ConnectionDetails from "./ConnectionDetails";
import {useUserProfile} from "../../../providers/UserProfileProvider";
import CustomStepper from "./CustomStepper";
import InstructionsModal from "../../../components/CustomModals/InstructionsModal";
import Instructions from "./Instructions";
import SuccessModal from "../../KnowYourCustomer/Components/Modals/SuccessModal";
import {PropagateLoader} from "react-spinners";
import TermsAndConditions from "../../KnowYourCustomer/Components/TermsAndConditions";
import PreviewModal from "./PreviewModal/PreviewModal";
import FailureModal from "../../KnowYourCustomer/Components/Modals/FailureModal";

const NewConnectionForm = () => {
    const [loading, setLoading] = useState(false)
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {userProfile} = useUserProfile() || {}
    let {_id} = userProfile || {}
    const {addGeneralToast} = CustomGeneralToast();
    const toast = useToast();
    const stages = [{stage: 'connectionDetails', title: 'Connection Details'}, {
        stage: 'basic_info',
        title: 'Basic Information'
    }, {stage: 'contact', title: 'Contact Details'}, {stage: 'kin', title: 'Next Of Kin'}, {
        stage: 'owner',
        title: 'Landlord/Owner Details'
    }, {stage: 'attachments', title: 'Attachments'}, {stage: 'terms', title: 'Terms & Conditions'},]

    const [stage, setStage] = useState(localStorage.getItem('stageConnection') || stages[0].stage);
    const [indexConnection, setIndexConnection] = useState(Number(localStorage.getItem('indexConnection')) || 0);
    const [customerDetails, setCustomerDetails] = useState(JSON.parse(localStorage.getItem('customerDetailsConnection')))
    const [connectionDetails, setConnectionDetails] = useState(JSON.parse(localStorage.getItem('connectionDetails')))
    const [nextOfKin, setNextOfKin] = useState(JSON.parse(localStorage.getItem('nextOfKinConnection')))
    const [address, setAddress] = useState(JSON.parse(localStorage.getItem('addressConnection')))
    const [files, setFiles] = useState(JSON.parse(localStorage.getItem('filesConnection')))
    const [ownerDetails, setOwnerDetails] = useState(JSON.parse(localStorage.getItem('ownerOrLandlord')))
    const [consent, setConsent] = useState(false)
    const [isValidForm, setIsValidForm] = useState(false)
    const [success, setSuccess] = useState(false)
    const [failed, setFailed] = useState(false)
    const [message, setMessage] = useState("")
    useEffect(() => {
        localStorage.setItem('stageConnection', 'connectionDetails')
        localStorage.setItem('indexConnection', 0)
        setStage('connectionDetails')
        setIndexConnection(0)
        localStorage.removeItem('customerDetailsConnection')
        localStorage.removeItem('connectionDetails')
        localStorage.removeItem('ownerOrLandlord')
        localStorage.removeItem('nextOfKinConnection')
        localStorage.removeItem('filesConnection')
        localStorage.removeItem('addressConnection')
    }, []);
    useEffect(() => {
        setConsent(localStorage.getItem('consent') ==='true')
        setCustomerDetails(JSON.parse(localStorage.getItem('customerDetailsConnection')))
        setAddress(JSON.parse(localStorage.getItem('addressConnection')))
        setNextOfKin(JSON.parse(localStorage.getItem('nextOfKinConnection')))
        setFiles(JSON.parse(localStorage.getItem('filesConnection')))
        setOwnerDetails(JSON.parse(localStorage.getItem('ownerOrLandlord')))
        setConnectionDetails(JSON.parse(localStorage.getItem('connectionDetails')))
        if (indexConnection < stages.length - 1 && isValidForm) {
            let i = indexConnection + 1
            let stage = stages[i].stage
            localStorage.setItem('stageConnection', stage)
            localStorage.setItem('indexConnection', i)
            setStage(stage);
            setIndexConnection(i);
            setIsValidForm(false)
        }
    }, [isValidForm]);
    const nextStage = () => {
        try {
            const isValid = localStorage.getItem('isValidForm') === 'true';
            if (!isValid) {
                toast.closeAll();
                addGeneralToast({
                    message: "Fill all required fields", type: "warning",
                });
            }
            setIsValidForm(isValid);
        } catch (e) {
            console.log(e)
        }
    };

    const prevStage = () => {
        if (indexConnection > 0) {
            let i = indexConnection - 1
            let stage = stages[i].stage
            localStorage.setItem('stageConnection', stage)
            setStage(stage);
            setIndexConnection(i);

        }
    };
    const handleShowModal = () => {
        nextStage()
        onOpen()
    }
    const handleSumbitKYC = async () => {
        try {

            const {userId, ...customerDetails2} = customerDetails
            let {spouseCustomerNumber} = customerDetails || {}
            const combinedObjects = {...customerDetails2, ...address};
            let {isPlotFenced, connectionType} = connectionDetails || {}

            const {plotNo, street, ward, city, district, ...contactDetails} = address
            const {email, cell, homeTel, workTel, ...addressDetails} = address
            const outputObject = {
                userId: utils.getUserId(),
                profileId: _id,
                customerNumber: null,
                spouseCustomerNumber: spouseCustomerNumber,
                isPlotFenced: isPlotFenced,
                connectionType: connectionType,
                personalDetails: {...customerDetails},
                address: address,
                contactDetails: address,
                ownerOrLandlord: ownerDetails,
                serviceConnectionDetails: connectionDetails,
                nextOfKin: nextOfKin,
                files: files
            };

            Object.entries(combinedObjects).forEach(([key, val]) => {
                let value = val
                if (key === 'spouseCustomerNumber') {
                    outputObject.spouseCustomerNumber = value
                }
                if (key === 'customerNumber') {
                    outputObject.customerNumber = value
                }
                if (customerDetails2.hasOwnProperty(key)) {
                    outputObject.personalDetails[key] = value;
                } else if (addressDetails.hasOwnProperty(key)) {
                    outputObject.address[key] = value;
                } else if (contactDetails.hasOwnProperty(key)) {
                    outputObject.contactDetails[key] = value;
                }

            });
            Object.entries(files).forEach(([key, val]) => {
                let value = val
                if (files.hasOwnProperty(key)) {
                    outputObject.files[key] = value;
                }
            });

            Object.entries(ownerDetails).forEach(([key, val]) => {
                if (ownerDetails.hasOwnProperty(key)) {
                    outputObject.ownerOrLandlord[key] = val;
                }
            });
            Object.entries(connectionDetails).forEach(([key, val]) => {
                if (key === 'connectionType') {
                    outputObject.connectionType = val
                }
                if (key === 'applicantType') {
                    outputObject.applicantType = val
                }
                if (key === "isPlotFenced") {
                    outputObject.isPlotFenced = val
                }

                if (connectionDetails.hasOwnProperty(key)) {
                    outputObject.serviceConnectionDetails[key] = val;
                }
            });
            Object.entries(nextOfKin).forEach(([key, val]) => {
                if (nextOfKin.hasOwnProperty(key)) {
                    outputObject.nextOfKin[key] = val;
                }
            });
            setLoading(true)
            const response = await API.post(`/connection/request/submit`, outputObject);
            setLoading(false)
            if (response.status === 201) {
                setSuccess(true)
                setFailed(false)
                localStorage.setItem('stageConnection', 'connectionDetails')
                localStorage.setItem('indexConnection', 0)
                setStage('connectionDetails')
                setIndexConnection(0)
                localStorage.removeItem('customerDetailsConnection')
                localStorage.removeItem('connectionDetails')
                localStorage.removeItem('ownerOrLandlord')
                localStorage.removeItem('nextOfKinConnection')
                localStorage.removeItem('filesConnection')
                localStorage.removeItem('addressConnection')
            } else {
                setSuccess(false)
                setFailed(true)
            }
        } catch (e) {
            setLoading(false)
            setSuccess(false)
            setFailed(true)
            setMessage(e?.response?.data?.message)
            console.log(e)
        }
    };

    return (<>
            {<InstructionsModal title={"New Connection Application Guide"} Instructions={<Instructions/>}/>}

            <Grid templateColumns="repeat(12, 1fr)" bg="white" borderRadius={'2xl'} mt={{lg:50}}>
                {!loading ? <>
                        <GridItem colSpan={12}>
                            {<CustomStepper name={'stageConnection'} stages={stages} idxName={'indexConnection'}/>}
                        </GridItem>
                        <GridItem colSpan={12} borderRadius={'md'} bg={'white'} p={8}>
                            {stage === 'basic_info' && <CustomerDetails/>}
                            {stage === 'contact' && <ContactDetails/>}
                            {stage === 'kin' && <KinDetails/>}
                            {stage === 'owner' && <OwnerDetails/>}
                            {stage === 'attachments' && <Attachments/>}
                            {stage === 'connectionDetails' && <ConnectionDetails/>}
                            {stage === 'terms' && <TermsAndConditions/>}
                        </GridItem>
                        <GridItem colSpan={12} px={8} pb={8}>
                            <HStack justifyContent={{base: 'center', md: 'end'}}>
                                {(indexConnection > 0) && <Button onClick={prevStage} variant={'outline'} leftIcon={<ArrowBackIcon/>}>
                                    Previous
                                </Button>}
                                {(indexConnection === stages.length - 1) ?
                                    <Button onClick={handleShowModal} rightIcon={<ArrowForwardIcon/>}
                                            variant={'solid'}>Proceed</Button> :
                                    <Button onClick={nextStage} variant={'solid'} rightIcon={<ArrowForwardIcon/>}>
                                        Proceed
                                    </Button>}
                            </HStack>
                        </GridItem>
                    </>

                    : <GridItem colSpan={12} minH={'65vh'} w={'full'} justifyContent={'center'} alignItems={'center'}>
                        <Flex h={'full'} w={'full'} justifyContent={'center'} alignItems={'center'}>
                            <PropagateLoader color="#2BBCF2"/>
                        </Flex>
                    </GridItem>}
            </Grid>
            {consent&&<PreviewModal onClose={onClose} isOpen={isOpen} callback={handleSumbitKYC}/>}
            <SuccessModal isOpen={success} isClosed={!success} />
            <FailureModal isOpen={failed} isClosed={!failed} response={message}/>
        </>

    );
};

export default NewConnectionForm;
