import React, {useEffect, useState} from "react";
import {Button, Flex, Grid, GridItem, HStack, useDisclosure, useToast} from "@chakra-ui/react";
import ContactDetails from "./ContactDetails";
import CustomerDetails from "./CustomerDetails";
import OwnerDetails from "./OwnerDetails";
import {API} from "../../../utils/api";
import {CustomGeneralToast} from "../../../utils/customChakraToasts";
import CustomStepper from "../../NewConnection/Components/CustomStepper";
import InstructionsModal from "../../../components/CustomModals/InstructionsModal";
import Instructions from "./Instructions";
import BankingDetails from "./BankingDetails";
import SuccessModal from "../../KnowYourCustomer/Components/Modals/SuccessModal";
import {PropagateLoader} from "react-spinners";
import TermsAndConditions from "../../KnowYourCustomer/Components/TermsAndConditions";
import PreviewModal from "./PreviewModal/PreviewModal";
import FailureModal from "../../KnowYourCustomer/Components/Modals/FailureModal";
import {ArrowBackIcon, ArrowForwardIcon} from "@chakra-ui/icons";

const DisconnectionForm = () => {
    const [loading, setLoading] = useState(false)
    const {isOpen, onOpen, onClose} = useDisclosure()
    const { addGeneralToast } = CustomGeneralToast();
    const toast = useToast();
    const stages=[
        {stage:'basic_info',title:'Basic Information'},
        {stage:'address',title:'Contact Details'},
        {stage:'owner',title:'Landlord/Owner Details'},
        {stage:'banking',title:'Banking Details'},
        {stage: 'terms', title: 'Terms & Conditions'},
    ]

    const [stage, setStage] = useState(localStorage.getItem('stage')||stages[0].stage);
    const [index, setIndex] = useState(Number(localStorage.getItem('index'))||0);
    const [customerDetails,setCustomerDetails]=useState(JSON.parse(localStorage.getItem('customerDetailsDisconnection')))
    const [address,setAddress]=useState(JSON.parse(localStorage.getItem('addressDisonnection')))
    const [ownerDetails,setOwnerDetails]=useState(JSON.parse(localStorage.getItem('landlordDetails')))
    const [bankingDetails,setBankingDetails]=useState(JSON.parse(localStorage.getItem('banking')))
    const [consent, setConsent] = useState(false)
    const [isValidForm, setIsValidForm] = useState(false)
    const [success, setSuccess] = useState(false)
    const [failed, setFailed] = useState(false)
    const [message, setMessage] = useState("")
    //let contractId = new URLSearchParams(window.location.search).get('id');
    useEffect(() => {
        localStorage.setItem('stage','basic_info')
        localStorage.setItem('index',0)
        setStage('basic_info')
        setIndex(0)
    }, []);
    useEffect(() => {
        setConsent(localStorage.getItem('consent') ==='true')
        setCustomerDetails(JSON.parse(localStorage.getItem('customerDetailsDisconnection')))
        setAddress(JSON.parse(localStorage.getItem('addressDisconnection')))
        setOwnerDetails(JSON.parse(localStorage.getItem('landlordDetails')))
        setBankingDetails(JSON.parse(localStorage.getItem('banking')))

        if (index < stages.length - 1 && isValidForm) {
            let i=index + 1
            let stage=stages[i].stage
            localStorage.setItem('stage',stage)
            localStorage.setItem('index',i)
            setStage(stage);
            setIndex(i);
            setIsValidForm(false)
        }
    }, [isValidForm]);
    const nextStage = () => {
        try{
            const isValid = localStorage.getItem('isValidForm') === 'true';
            if(!isValid){
                toast.closeAll();
                addGeneralToast({
                    message: "Fill all required fields",
                    type: "warning",
                });
            }
            setIsValidForm(isValid);
        }catch(e){
            console.log(e)
        }
    };

    const prevStage = () => {
        if (index > 0) {
            let i=index-1
            let stage=stages[i].stage
            localStorage.setItem('stage',stage)
            setStage(stage);
            setIndex(i);

        }
    };
    const handleShowModal = () => {
        nextStage()
        onOpen()
    }
    const handleSumbitKYC = async () => {
       try{
           const combinedObjects = {...customerDetails, ...address};
           const outputObject = {
               userId:null,
               profileId:null,
               customerNumber:null,
               contractNumber:null,
               personalDetails: {},
               address:{},
               landLordDetails: {},
               bankingDetails:{}
           };

           Object.entries(combinedObjects).forEach(([key, val]) => {
              let  value=val
               if(key==='contractNumber'){
                   outputObject.contractNumber=value
               }
               if(key==='customerNumber'){
                   outputObject.customerNumber=value
               }
               if(key==='profileId'){
                   outputObject.profileId=value
               }
               if(key==='userId'){
                   outputObject.userId=value
               }
               if (customerDetails.hasOwnProperty(key)) {
                   outputObject.personalDetails[key] = value;
               } else if (address.hasOwnProperty(key)) {
                    if(key==='plotNo'){
                        outputObject.address['houseNo'] = value;
                    } else if(key==='postal'){
                       outputObject.address['lotNo'] = value;
                   }else {
                        outputObject.address[key] = value;
                    }

               }

           });
           Object.entries(ownerDetails).forEach(([key, val]) => {
               if (ownerDetails.hasOwnProperty(key)) {
                   outputObject.landLordDetails[key] = val;
                }
           });
           Object.entries(bankingDetails).forEach(([key, val]) => {
               if (bankingDetails.hasOwnProperty(key)) {
                   outputObject.bankingDetails[key] = val;
               }
           });
           setLoading(true)
           const response = await API.put(
               `/connection/disconnect/submit`,
               outputObject
           );
           setLoading(false)

           setMessage(response?.data?.message)
           const data = response.data;
           if (response.status === 201) {
               setSuccess(true)
               setFailed(false)
               //let updatedUtility= await utils.updateUtility(contractId,'awaiting deactivation')
               //navigate('/self-service-portal/manage-utility-contracts')
           } else {
               setSuccess(false)
               setFailed(true)
           }
       }catch (e) {
           setLoading(false)
           setSuccess(false)
           setFailed(true)
           setMessage(e?.response?.data?.message)
           console.log(e)
       }
    };

    return (
        <>
            {

                sessionStorage.getItem('showGuid') === 'true'&&
                <InstructionsModal  title={"Disconnection Application Guide"} Instructions={<Instructions/>}/>
            }

            <Grid templateColumns="repeat(12, 1fr)" bg="white" borderRadius={'2xl'} mt={{lg:50}}>
                {!loading ? <>
                    <GridItem colSpan={12}   >
                    {<CustomStepper  stages={stages} />}
                </GridItem>
                    <GridItem colSpan={12} borderRadius={'md'} bg={'white'} p={8}>
                    {stage === 'basic_info' && <CustomerDetails />}
                    {stage === 'address' && <ContactDetails />}
                    {stage === 'owner' && <OwnerDetails />}
                    {stage === 'banking' && <BankingDetails />}
                    {stage === 'terms' && <TermsAndConditions/>}
                </GridItem>
                        <GridItem colSpan={12} px={8} pb={8}>
                            <HStack justifyContent={{base: 'center', md: 'end'}}>
                                {(index > 0) && <Button onClick={prevStage} variant={'outline'} leftIcon={<ArrowBackIcon/>}>
                                    Previous
                                </Button>}
                                {(index === stages.length - 1) ?
                                    <Button onClick={handleShowModal} rightIcon={<ArrowForwardIcon/>}
                                            variant={'solid'}>Proceed</Button> :
                                    <Button onClick={nextStage} variant={'solid'} rightIcon={<ArrowForwardIcon/>}>
                                        Proceed
                                    </Button>}
                            </HStack>
                        </GridItem>
                    </>
                    : <GridItem colSpan={12} minH={'65vh'} w={'full'} justifyContent={'center'} alignItems={'center'}>
                        <Flex h={'full'} w={'full'} justifyContent={'center'} alignItems={'center'}>
                            <PropagateLoader color="#2BBCF2"/>
                        </Flex>
                    </GridItem>}
            </Grid>
            {consent&&<PreviewModal onClose={onClose} isOpen={isOpen} callback={handleSumbitKYC}/>}
            <SuccessModal isOpen={success} isClosed={!success} link='disconnection-applications'/>
            <FailureModal isOpen={failed} isClosed={!failed}  link='disconnection-applications' response={message}/>
        </>

    );
};

export default DisconnectionForm;
