import {
    Flex,
    Input,
    Stack,
    Icon,
    Text,
    Button,
    Box,
    Heading,
    useToast,
    FormLabel,
    FormControl,
    VStack,
    InputGroup,
    InputRightElement,
    IconButton, List, ListItem, ListIcon, AlertIcon, Alert,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import { useLocation } from 'react-router-dom';
import {PropagateLoader} from "react-spinners";
import {CustomGeneralToast} from "../../../utils/customChakraToasts";
import {ViewIcon, ViewOffIcon} from "@chakra-ui/icons";

import {LiaUnlockAltSolid} from "react-icons/lia";
import utils from "../../../utils/commonFunctions";
import {FaCheckCircle, FaTimesCircle} from "react-icons/fa";

function ResetPassword() {
    const location = useLocation();
    const { isMigrated,message } = location.state || {};
    const [resetToken, setResetToken] = useState('');
    useEffect(() => {
        const pathArray = window.location.pathname.split('/');
        const hashFromPath = pathArray[2];
        setResetToken(hashFromPath);
    }, []);
    const [conPassword, setConPassword] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [showConPassword, setConPasswordShow] = useState(false);
    const [lowerCaps, setLowerCaps] = useState(false);
    const [upperCaps, setUpperCaps] = useState(false);
    const [digit, setDigit] = useState(false);
    const [isLength, setIsLength] = useState(false);
    const [validPass, setValidPass] = useState(false);
    const {addGeneralToast} = CustomGeneralToast();
    const toast = useToast();
    const [success, setSuccess] = useState(false);
    const handleClick = () => setShow(!show);
    const handleClick2 = () => setConPasswordShow(!showConPassword);
    const handleSetPassword = async (password) => {
        setPassword(password);
        let isValid = utils.validatePassword(password);
        setDigit(isValid.digit);
        setUpperCaps(isValid.uppercase);
        setLowerCaps(isValid.lowercase);
        setIsLength(isValid.isLength);
        setValidPass(isValid.isValid)

    };
    const handleLogin = async (event) => {
        event.preventDefault()
        setLoading(true); // Ensure loading is indicated at the start
        try {

            if (password === "") {
                toast.closeAll();
                addGeneralToast({
                    message: "Password must be filled in",
                    type: "warning",
                });
                setLoading(false); // Stop loading indication on failure
                return;
            }
            if (!validPass) {
                toast.closeAll();
                addGeneralToast({
                    message: "Password does not meet minimum requirements", type: "error",
                });
                return;
            }
            if (password !== conPassword) {
                toast.closeAll();
                addGeneralToast({
                    message: "Password does not match", type: "error",
                });
                return;
            }

            const decodedToken=utils.decodeToken(resetToken)
            const response = await utils.setNewPassword(decodedToken?.id, password)
            if (response?.status === 200 || response?.status === 201) {
                toast.closeAll();
                addGeneralToast({
                    message: response?.data?.message || "Password Changed Successfully.",
                    type: "success",
                });
                setSuccess(true)
                // navigate('/login', { replace: true })
            } else {
                setSuccess(false)
                toast.closeAll();
                addGeneralToast({
                    message: response?.data?.message || "Password Reset failed, please try again.",
                    type: "error",
                });
            }
        } catch (e) {
            setSuccess(false)
            console.error(e);
            toast.closeAll();
            addGeneralToast({
                message: e?.message || "An error occurred during password reset.",
                type: "error",
            });
        } finally {
            setLoading(false); // Ensure loading is stopped regardless of outcome
        }
    };


    return (
        <>

            <Stack
                maxW={{base: "full", md: 'md'}}
                minW={{base: "full", md: 'md'}}
                minH={'sm'}
                bg={"white"}
                p={8}
                borderRadius={"xl"}
                boxShadow={"md"}
                direction={{base: "column", md: "row"}}
                justify={'center'}
                align={'center'}
            >
                <Stack spacing={4} w={"full"}>

                    <Flex align={"center"} justify="center">
                        <VStack>
                            <Box
                                backgroundColor=" #0093D0"
                                borderRadius="50%"
                                width="50px"
                                height="50px"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Icon as={LiaUnlockAltSolid} color="white" boxSize={{base:"25%",md:"50%"}}/>
                            </Box>
                            <Heading textAlign={'center'} color="blue.800" as="h6" size="lg">
                                {!success ? 'Create Password':'Password Reset'}
                            </Heading>
                            <Text   textAlign={'center'} color={"blue.800"} mt={-1} fontSize={"lg"}>
                                {!success &&' Set new password for your account'}
                            </Text>
                        </VStack>
                    </Flex>
                    {!success ?
                        <form onSubmit={handleLogin}>
                            {isMigrated&&
                                <Alert borderRadius={'xl'} mb={4} p={2} fontSize={'14px'} status='warning'>
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <FormControl id="password">
                                <FormLabel color="blue.800">New Password</FormLabel>
                                <InputGroup>
                                    <Input
                                        type={show ? "text" : "password"}
                                        name="password"
                                        focusBorderColor="blue.300"
                                        onChange={(e) => handleSetPassword(e.target.value)}
                                        borderRadius={"2xl"}
                                    />
                                    <InputRightElement>
                                        <IconButton
                                            aria-label={show ? "Hide password" : "Show password"}
                                            icon={
                                                show ? (
                                                    <ViewOffIcon color="blue.300"/>
                                                ) : (
                                                    <ViewIcon color="gray.300"/>
                                                )
                                            }
                                            onClick={handleClick}
                                            bg="transparent"
                                            _hover={{bg: "trasparent"}}
                                            _active={{bg: "transparent"}}
                                        />
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                            <FormControl id="password">
                                <FormLabel color="blue.800">Confirm Password</FormLabel>
                                <InputGroup>
                                    <Input
                                        type={showConPassword ? "text" : "password"}
                                        name="password"
                                        focusBorderColor="blue.300"
                                        onChange={(e) => setConPassword(e.target.value)}
                                        borderRadius={"2xl"}
                                    />
                                    <InputRightElement>
                                        <IconButton
                                            aria-label={showConPassword ? "Hide password" : "Show password"}
                                            icon={
                                                showConPassword ? (
                                                    <ViewOffIcon color="blue.300"/>
                                                ) : (
                                                    <ViewIcon color="gray.300"/>
                                                )
                                            }
                                            onClick={handleClick2}
                                            bg="transparent"
                                            _hover={{bg: "trasparent"}}
                                            _active={{bg: "transparent"}}
                                        />
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                            <Stack spacing={6}>
                                <List textAlign={"start"} spacing={3} bg={"white"} borderRightRadius={'2xl'}>
                                    <ListItem>
                                        <ListIcon
                                            as={isLength ? FaCheckCircle : FaTimesCircle}
                                            color={isLength ? "green.500" : "red.500"}
                                        />
                                        Password must contain atleast 8 characters
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon
                                            as={upperCaps ? FaCheckCircle : FaTimesCircle}
                                            color={upperCaps ? "green.500" : "red.500"}
                                        />
                                        Password must contain uppercase letter
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon
                                            as={lowerCaps ? FaCheckCircle : FaTimesCircle}
                                            color={lowerCaps ? "green.500" : "red.500"}
                                        />
                                        Password must contain lowercase letter
                                    </ListItem>
                                    <ListItem>
                                        <ListIcon
                                            as={digit ? FaCheckCircle : FaTimesCircle}
                                            color={digit ? "green.500" : "red.500"}
                                        />
                                        Password must contain a digit
                                    </ListItem>
                                </List>
                                <Button
                                    _hover={{
                                        bg: "blue.300",
                                        color: "white",
                                    }}
                                    color="white"
                                    bg=" #0093D0"
                                    type="submit"
                                    variant="solid"
                                    borderRadius={"2xl"}
                                >
                                    {loading ? (
                                        <>
                                            <PropagateLoader size={8} color="white"/>
                                        </>
                                    ) : (
                                        <Text>Set Password</Text>
                                    )}
                                </Button>
                            </Stack>
                        </form>
                        : (
                            <>
                                <Text  textAlign={'center'} color={"blue.800"} mt={-1} fontSize={"lg"}>
                                    Password reset successfully. Click on the link below to continue
                                </Text>
                                <Link to={'/login'}>
                                    <Text textAlign={'center'} color={'brand.700'}>Proceed to Login</Text>
                                </Link>
                            </>
                        )

                    }

                </Stack>
            </Stack>
        </>
    );
}

export default ResetPassword;
