import {
    Box,
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Link,
    Tr,
    Tbody,
    Td,
    TableContainer,
    Table,
    Thead,
    Th,
    Alert,
    AlertDescription,
    UnorderedList,
    ListItem,
    AlertIcon, Switch, Flex,
} from "@chakra-ui/react";
import {useQuoteCart} from "../../../providers/QuoteCartProvider";
import React, {useEffect, useState} from "react";
import DetailsHeader from "./DetailsHeader";
import utils from "../../../utils/commonFunctions";

const CustomCheckbox = ({isChecked, onChange, isDisabled}) => {
    return <Switch isChecked={isChecked} onChange={onChange} isDisabled={isDisabled} />;
};

const CustomAccordion = () => {
    const {quoteCart, setQuoteCart} = useQuoteCart();
    const [products, setProducts] = useState([]);
    const [AccordionData1, setAccordionData] = useState([]);

    const handleSetCart = (cartItem) => {
        setQuoteCart((prevCart) => {
            const currentCart = Array.isArray(prevCart) ? prevCart : [];
            const existingCartItemIndex = currentCart.findIndex(
                (item) => item.item === cartItem.item
            );

            if (cartItem.value && existingCartItemIndex === -1) {
                return [...currentCart, cartItem];
            } else if (!cartItem.value && existingCartItemIndex !== -1) {
                const newCart = [...currentCart];
                newCart.splice(existingCartItemIndex, 1);
                return newCart;
            }

            return currentCart;
        });
    };

    useEffect(() => {
        const fetchProducts = async () => {
            const data = await utils.fetchLabProducts();
            if (data) {
                setProducts(data);
            }
        };
        fetchProducts();
    }, []);

    useEffect(() => {
        const createData = () => {
            if (products && products.length) {
                const groupedData = products.reduce((acc, product) => {
                    const categoryIndex = acc.findIndex(
                        (item) => item.label === product.category
                    );
                    const productItem = {
                        id: product.materialNumber,
                        label: product.name,
                        net: product.unitPrice,
                    };
                    if (categoryIndex !== -1) {
                        acc[categoryIndex].children.push(productItem);
                    } else {
                        acc.push({
                            label: product.category,
                            children: [productItem],
                        });
                    }
                    return acc;
                }, []);
                setAccordionData(groupedData);
            }
        };
        createData();
    }, [products]);

    return (
        <Box w={"full"} minH="220px" bg={"white"} opacity={1}>
            <DetailsHeader/>
            <Text fontWeight={"semibold"} color={"gray.600"} mb={2}>
                Services Offered
            </Text>
            <Accordion defaultIndex={[0]} allowMultiple style={{border: "white"}}>
                {AccordionData1.length > 0 && (
                    <>
                        {AccordionData1.map((e, key) => (
                            <AccordionItem key={key}>
                                <h2>
                                    <AccordionButton
                                        _hover={{
                                            color: "black",
                                            bg: "#f5f5f7",
                                            textDecoration: "none",
                                            transform: "translateX(5px)",
                                            borderLeft: "4px solid #2BBCF2",
                                        }}
                                        borderRadius={"md"}
                                        _expanded={{
                                            color: "black",
                                            bg: "#f5f5f7",
                                            borderLeft: "4px solid #2BBCF2",
                                        }}
                                    >
                                        <Box
                                            as="span"
                                            fontWeight={"bold"}
                                            fontSize={"md"}
                                            flex="1"
                                            textAlign="left"
                                        >
                                            {e.label}
                                            {e.children?.length < 0 ? (
                                                <Link href={e.href} _hover={{textDecoration: "none"}}>
                                                    <Text ml={2}>{e.label}</Text>
                                                </Link>
                                            ) : (
                                                ""
                                            )}
                                        </Box>
                                        {e.children?.length > 0 && <AccordionIcon/>}
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel>
                                    <TableContainer>
                                        <Table variant="simple">
                                            <Thead>
                                                <Tr>
                                                    <Th fontSize={"sm"} color={"blue.800"}>
                                                        Service
                                                    </Th>
                                                    <Th fontSize={"sm"} textAlign={"center"} color={"blue.800"}>
                                                        Estimated Price
                                                    </Th>
                                                    <Th fontSize={"sm"} color={"blue.800"} isNumeric>
                                                        Select
                                                    </Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {e.children?.map((item, index) => {
                                                    const isChecked = quoteCart.some(
                                                        (cartItem) => cartItem.item === item.label
                                                    );
                                                    return (
                                                        <Tr
                                                            key={index}
                                                            _hover={{bg: "gray.50"}}
                                                            transitionDuration={"0.3s"}
                                                            cursor="pointer"
                                                        >
                                                            <Td>{item.label}</Td>
                                                            <Td textAlign={"center"}>{"P " + item.net}</Td>
                                                            <Td justifyContent="center" isNumeric>
                                                                <CustomCheckbox
                                                                    isChecked={isChecked}
                                                                    onChange={(e) =>
                                                                        handleSetCart({
                                                                            itemId: item.id,
                                                                            item: item.label,
                                                                            net: item.net,
                                                                            value: e.target.checked,
                                                                            quantity: 1,
                                                                        })
                                                                    }
                                                                />{" "}
                                                            </Td>
                                                        </Tr>
                                                    );
                                                })}
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                </AccordionPanel>
                            </AccordionItem>
                        ))}
                    </>
                )}
            </Accordion>
            <Alert
                status="info"
                borderRadius="md"
                bg={"gray.50"}
                color="gray.700"
                fontSize={"12px"}
                fontWeight={"medium"}
                w={"100%"}
                mt={8}
            >
                <AlertIcon boxSize={"50px"}/>
                <AlertDescription>
                    <UnorderedList>
                        <ListItem>
                            Total cost of sampling and analysis may vary depending on the
                            testing technique available at time of request for analysis and
                            times scheduled for sampling being either working or outside
                            working hours.
                        </ListItem>
                        <ListItem>All prices are VAT exclusive</ListItem>
                        <ListItem>All prices are handling fee and sampling fee exclusive </ListItem>
                        <ListItem>Please note that the processing time for your request is estimated to be five (5)
                            working days.</ListItem>
                    </UnorderedList>
                </AlertDescription>
            </Alert>
        </Box>
    );
};

const ServicesOffered = () => {
    return (
        <Box>
            <CustomAccordion/>
        </Box>
    );
};

export default ServicesOffered;
