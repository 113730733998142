
import React from "react";
import BuyToken from "./Components/BuyToken";
import SelfServiceContainer from "../../components/Containers/SelfServiceContainer";
import utils from "../../utils/commonFunctions";
import {Navigate} from "react-router-dom";

const BillCalculatorGuest = () => {

  if (utils.isAuthenticated()) {
    // NAVIGATE TO THE REPORT LEAK PAGE
    return <Navigate to={'/self-service-portal/prepaid-recharge'}/>
  }

  if (!utils.isAuthenticated()) {
    return <>
      <SelfServiceContainer childComponent={<BuyToken/>}/>
    </>
  }
};
export default BillCalculatorGuest;
