import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import {
    Box,
    Flex,
    Icon,
    useColorModeValue,
    Text,
    Drawer,
    DrawerContent,
    Divider,
    Image,
    CloseButton, useToast, IconButton, Spacer, useMediaQuery
} from "@chakra-ui/react";
import {
    FiHome,
    FiPlusCircle,
    FiCreditCard,
    FiEdit,
    FiMail,
    FiAlertTriangle,
    FiTool,
    FiLogOut,
    FiMinusCircle,
} from "react-icons/fi";
import { MdOutlinePersonSearch } from "react-icons/md";
import { LiaFileInvoiceSolid } from "react-icons/lia";

import Logo from "./../../assets/LogoWithWhiteBG.png";
import utils from "../../utils/commonFunctions.js";
import { GoReport } from "react-icons/go";
import { useDrawer } from '../../providers/NavBarSliderProvider';
import { IoCalculatorOutline } from "react-icons/io5";
import { CustomThemedToast } from "../../utils/customChakraToasts";
import {BiTransferAlt} from "react-icons/bi";

const LinkItems = [
    {name: "Dashboard", icon: FiHome, route: "/self-service-portal/dashboard"},
    {
        name: "Know Your Customer",
        icon: MdOutlinePersonSearch,
        route: "/self-service-portal/know-your-customer",
    },
    {
        name: "Request Connection ",
        icon: FiPlusCircle,
        route: "/self-service-portal/connection-applications",
    },
    {
        name: "Request Disconnection",
        icon: FiMinusCircle,
        route: "/self-service-portal/disconnection-applications",
    },
    {
        name: "Prepaid Recharge ",
        icon: BiTransferAlt,
        route: "/self-service-portal/prepaid-recharge",
    },
    {
        name: "Check / Pay Bill",
        icon: FiCreditCard,
        route: "/self-service-portal/pay-bill-online",
    },
    {
        name: "Bill Calculator",
        icon: IoCalculatorOutline,
        route: "/self-service-portal/bill-calculator",
    },
    {
        name: "Submit Meter Reading",
        icon: FiEdit,
        route: "/self-service-portal/submit-meter-reading",
    },
    {
        name: "Request Monthly Bill",
        icon: FiMail,
        route: "/self-service-portal/request-monthly-bill",
    },
    {
        name: "Report Leak",
        icon: FiAlertTriangle,
        route: "/self-service-portal/report-leak",
    },
    {
        name: "Report Water Shortage",
        icon: GoReport,
        route: "/self-service-portal/report-water-shortage",
    },
    {
        name: "Manage Utility Contracts",
        icon: FiTool,
        route: "/self-service-portal/manage-utility-contracts",
    },
    {
        name: "Laboratory Services",
        icon: LiaFileInvoiceSolid,
        route: "/self-service-portal/laboratory-services",
    },
];
// if( process.env.REACT_APP_ENV!=='PRODUCTION') {
//     LinkItems.push(
//         {
//             name: "Prepaid Recharge ",
//             icon: BiTransferAlt,
//             route: "/self-service-portal/prepaid-recharge",
//         }
//     )
// }
const Sidebar = () => {
    const location = useLocation();
    const { isOpen, closeDrawer } = useDrawer();
    const [activeLink, setActiveLink] = useState(""); // State to track the active link
    const bgColor = useColorModeValue("gray.100", "gray.900"); // Call useColorModeValue outside of conditional logic

    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location.pathname]);

    // Check authentication status
    if (!utils.isAuthenticated()) {
        return null;  // Do not render the sidebar if the user is not authenticated
    }

    return (
        <Box minH="100vh" bg={bgColor}>
            <SidebarContent
                onClose={() => closeDrawer}
                display={{ base: "none", md: "block" }}
                setActiveLink={setActiveLink}
                activeLink={activeLink}
            />
            <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={closeDrawer}
                returnFocusCloseDrawer={false}
                onOverlayClick={closeDrawer}
                size="xs"
            >
                <DrawerContent>
                    <SidebarContent onClose={closeDrawer} />
                </DrawerContent>
            </Drawer>
            <Box ml={{ base: 0, md: 80 }} p="4">
                <Outlet />
            </Box>
        </Box>
    );
};

const SidebarContent = ({ onClose, setActiveLink, activeLink, ...rest }) => {
    const trackColor = useColorModeValue("gray.200", "gray.700");
    const navigate = useNavigate();
    const toast = useToast();
    const { addThemedToast } = CustomThemedToast();
    const { name } = utils?.getUser() || {};
    const [isLargerThanTabletSmallerThanLargerScreen] = useMediaQuery("(min-width: 769px) and (max-width: 1024px)");

    const handleLogout = () => {
        toast.closeAll();
        addThemedToast({
            message: "Logging you out...",
            variant: "lightBlue",
        });
        setTimeout(() => {
            utils.logout();
            navigate('/login');
            toast.closeAll();
        }, 1500);
    };
    return (
        <Flex
            direction="column"
            bg={useColorModeValue("white", "gray.900")}
            zIndex={1}
            borderRight="1px"
            borderRightColor={useColorModeValue("gray.200", "gray.700")}
            w={isLargerThanTabletSmallerThanLargerScreen ? { base: "full", md: 80, lg:"17rem"} : { base: "full", md: 80}}
            h={'100vh'}
            pos="fixed"
            {...rest}
        >
            {/**header */}
            <Box
                bg={useColorModeValue("gray.200", "gray.800")}
                px={{ base: "2", md: "4" }}
                py={{ base: "3", md: "5" }}
                my={2}
                mx={{ base: "1", md: "2" }}
                borderRadius={{ base: "5", md: "10" }}
            >
                <Flex
                    direction={{ base: "column", md: "row" }}
                    align="center"
                    justify={{ base: "center", md: "space-between" }}
                >
                    <Image src={Logo} maxH="50px" mb={{ base: "2", md: "0" }} />{" "}
                    {/* Margin bottom only on smaller screens */}
                    <Flex
                        direction="column"
                        align={{ base: "center", md: "flex-start" }}
                        textAlign={{ base: "center", md: "left" }}
                    >
                        <Text fontSize="md" fontWeight="bold">
                            Water Utilities Corporation
                        </Text>
                        <Text fontSize="sm" fontWeight="semibold">
                            Self Service Portal
                        </Text>
                        {name && <Text fontSize="sm" isTruncated>{name}</Text>}

                        <CloseButton
                            position="absolute"
                            top={2}
                            right={2}
                            display={{ base: "flex", md: "none" }}
                            onClick={onClose}
                            color="brand.500"
                        />
                    </Flex>
                </Flex>
            </Box>

            <Divider mb={2} />
            {/**nav items */}
            <Box
                h={{ base: "420px", md: '520px' , lg: '520px'}}
                overflowY="auto"
                sx={{
                    "&::-webkit-scrollbar": {
                        width: "4px", // Thinner scrollbar
                    },
                    "&::-webkit-scrollbar-track": {
                        background: trackColor,
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "brand.500", // Using brand color
                        borderRadius: "8px",
                        border: `2px solid ${trackColor}`,
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        background: "brand.600", // Slightly darker brand color on hover
                    },
                }}
            >
                {LinkItems.map((link) => (
                    <NavItem
                        key={link.name}
                        icon={link.icon}
                        route={link.route}
                        isActive={activeLink === link.route}
                    >
                        {link.name}
                    </NavItem>
                ))}
            </Box>

            <Spacer />

            <Divider mt={2} />
            <Flex as="button" w={'full'} onClick={handleLogout} gap={3} justify={'center'} align={'center'} px="4" py="5">
                <IconButton
                    p={2}
                    as={FiLogOut}
                    isRound
                    colorScheme="red"
                    variant="solid"
                    boxShadow={'md'}
                    cursor={'pointer'}
                    bg='white'
                    color="red.400"
                    _hover={{
                        bg: 'red.400',
                        color: 'white'
                    }}
                />
                <Text color={'red.400'}>Logout</Text>

            </Flex>
        </Flex>
    );
};

const NavItem = ({ icon, children, isActive, route, ...rest }) => {
    const activeBg = useColorModeValue("brand.500", "brand.500");
    const textColor = isActive ? "white" : "inherit";

    return (
        <NavLink
            to={route}
            style={{ textDecoration: "none" }}
            _focus={{ boxShadow: "none" }}
            {...rest}
        >
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                bg={isActive ? activeBg : "transparent"}
                color={textColor}
                _hover={{
                    bg: "brand.500",
                    color: "white",
                }}
            >
                {icon && <Icon mr="4" fontSize="16" as={icon} />}
                {children}
            </Flex>
        </NavLink>
    );
};

export default Sidebar;
