import React, {useEffect, useState} from "react";
import {
    Button,
    FormControl,
    FormLabel, HStack,
    Input,
    InputGroup, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay,
    Select, SimpleGrid,
    Stack, Switch, Text,
    Textarea,
    useToast,
} from "@chakra-ui/react";
import {API} from "../../../utils/api";
import {CustomGeneralToast} from "../../../utils/customChakraToasts";
import {PropagateLoader} from "react-spinners";
import BillAlert from "../../../components/PortalContainer/BillAlert";
import utils from "../../../utils/commonFunctions";
import {useUserProfile} from "../../../providers/UserProfileProvider";
import {useQuoteCart} from "../../../providers/QuoteCartProvider";

const WaterShortageModal = () => {


    const [name, setName] = useState( "");
    const [cell, setCell] = useState( "");
    const [number, setNumber] = useState("");
    const [utility, setUtility] = useState("");
    const [villageOrTown, setVillageOrTown] = useState("");
    const [physicalAddress, setPhysicalAddress] = useState("");
    const [email, setEmail] = useState( "");
    const [daysWithoutWater, setDaysWithoutWater] = useState("");
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const {addGeneralToast} = CustomGeneralToast();
    const reportWaterShortage = async () => {
        if (!name || !cell || !email || !daysWithoutWater || !villageOrTown || !physicalAddress) {
            toast.closeAll();
            addGeneralToast({
                message: "All fields must be filled in",
                type: "warning",
            });
            setLoading(false);
            return;
        }
        setLoading(true);
        let body = {
            name: name,
            customerNumber: number,
            contractNumber: utility,
            contactDetails: {
                email: email,
                phone: cell
            },
            village: villageOrTown,
            address: physicalAddress,
            noOfDays: daysWithoutWater,
            createdBy: name
        }
        if (!number && !utility) {
            body = {
                name: name,
                contactDetails: {
                    email: email,
                    phone: cell
                },
                village: villageOrTown,
                address: physicalAddress,
                noOfDays: daysWithoutWater,
                createdBy: name
            }
        }
        try {
            const response = await API.post(`/shortages/`, body);

            const data = response.data;
            if (response.status === 201) {
                addGeneralToast({
                    message: data.message,
                    type: "success"
                });
                setEmail("")
                setName("")
                setUtility("")
                setCell("")
                setNumber("")
                setDaysWithoutWater("")
                setPhysicalAddress("")
                setUtility("")
                setVillageOrTown("")
            } else {
                toast.closeAll();
                addGeneralToast({
                    message: data.message,
                    type: "error",
                });
            }
        } catch (e) {
            toast.closeAll();
            addGeneralToast({
                message: e?.message ? e?.message : e.statusText,
                type: "error",
            });
        } finally {
            setLoading(false);
        }
    };
    return <>

        <Stack spacing={1}  justify={'center'} w="full" h={"full"}>

            {/* Utility Number input with condition */}
            <FormControl isRequired id="utilityNumber">
                <FormLabel>Customer Name</FormLabel>
                <InputGroup>
                    <Input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </InputGroup>
            </FormControl>
            <SimpleGrid columns={{base: 1, lg: 2}} gap={2} mt={2}>
                <FormControl id="customerNumber">
                    <FormLabel>Customer Number</FormLabel>

                    <Input
                        type="number"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                    />
                </FormControl>

                <FormControl id="contractNumber">
                    <FormLabel>Utility Number</FormLabel>
                    <>
                        <Input
                            type="number"
                            value={utility}
                            onChange={(e) => setUtility(e.target.value)}
                        />


                    </>
                </FormControl>
                <FormControl isRequired id="customerCell">
                    <FormLabel>Customer Cell No</FormLabel>
                    <Input
                        type="tel"
                        value={cell}
                        onChange={(e) => setCell(e.target.value)}
                    />
                </FormControl>
                <FormControl isRequired id="email">
                    <FormLabel>Customer Email</FormLabel>
                    <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                </FormControl>
                <FormControl isRequired id="villageOrTown">
                    <FormLabel>Days without Water</FormLabel>
                    <Input type="number" value={daysWithoutWater}
                           onChange={(e) => setDaysWithoutWater(e.target.value)}/>
                </FormControl>
                {/* Village/Town input */}
                <FormControl isRequired id="villageOrTown">
                    <FormLabel>Village/Town</FormLabel>
                    <Input
                        type="text"
                        value={villageOrTown}
                        onChange={(e) => setVillageOrTown(e.target.value)}
                    />
                </FormControl>
            </SimpleGrid>

            {/* Physical Address input */}
            <FormControl isRequired id="physicalAddress">
                <FormLabel>Physical Address</FormLabel>
                <Textarea
                    value={physicalAddress}
                    onChange={(e) => setPhysicalAddress(e.target.value)}
                />
            </FormControl>
            <Stack spacing={6} mt={2}>
                {/* Submit button */}
                <Button onClick={!loading ? reportWaterShortage : undefined} mt={2}>
                    {!loading ? "Submit Report" : <PropagateLoader size={8} color="white"/>}
                </Button>
            </Stack>

        </Stack>
    </>;
};

export default WaterShortageModal;
