import axios from 'axios';
import utils from "./commonFunctions";

// Function to get token from localStorage
const getToken = () => localStorage.getItem('authToken');

// Function to create an Axios instance
const createAPIInstance = (baseURL) => {
    const instance = axios.create({
        baseURL,
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0',
        },
    });

    // Request Interceptor
    instance.interceptors.request.use(config => {
        // Set the x-api-key
        config.headers['x-api-key'] = process.env.REACT_APP_X_API_KEY;

        // Get token and set Authorization header
        const token = getToken();
        if (token) {
            config.headers['Authorization'] = `${token}`;
        }

        // Handle FormData content type
        if (config.data instanceof FormData) {
            delete config.headers['Content-Type'];
        }

        console.log(`[Request] ${config.method.toUpperCase()}: ${config.url}`);
        return config;
    }, error => Promise.reject(error));

    // Response Interceptor
    instance.interceptors.response.use(
        response => response,
        error => {
            if (!error.response) {
                console.error('Network Error:', error);
                return Promise.reject(new Error('Network Error - Please check your internet connection.'));
            }

            const { status, data } = error.response;
            let errorMessage = data?.message || 'An unexpected error occurred. Please try again later.';

            // User-friendly error messages based on status, if no specific message from server
            if (!data?.message) {
                switch (status) {
                    case 400:
                        console.error('Bad Request:', error);
                        errorMessage = 'Bad Request - Check your input.';
                        break;
                    case 401:
                        utils.logout();
                        errorMessage = 'Unauthorized - Session expired. Please log in again.';
                        break;
                    case 403:
                        errorMessage = 'Forbidden - You do not have permission to perform this action.';
                        break;
                    case 404:
                        errorMessage = 'Not Found - The requested resource was not found.';
                        break;
                    case 500:
                        errorMessage = 'Internal Server Error - Please try again later.';
                        break;
                    case 503:
                        errorMessage = 'Service Unavailable - The server is temporarily unable to service your request.';
                        break;
                    default:
                        console.error(`Unhandled status code: ${status}`, error);
                        break;
                }
            }
            return Promise.reject(new Error(errorMessage));
        }
    );

    return instance;
};

// Creating instance with base URL from environment variables
export const API = createAPIInstance(process.env.REACT_APP_API_BASE_URL);
