import React, { useEffect, useState } from "react";
import {
    Box, Flex, Grid, GridItem, Heading, Text,
} from "@chakra-ui/react";
import utils from "../../../utils/commonFunctions";
import DropBox from "./DropBox";
import enums from "wuc-library/enums";
import { useUserProfile } from "../../../providers/UserProfileProvider";

const Attachments = () => {
    const { userProfile } = useUserProfile() || {};
    const [loading, setLoading] = useState(false);

    // Set initial state with local storage or default to empty object.
    const [files, setFiles] = useState(() => JSON.parse(localStorage.getItem('files')) || {});
    const customerDetails = JSON.parse(localStorage.getItem('customerDetails')) || {};
    // Determine initial marital status from customer details stored in local storage.
    const [married, setMarried] = useState(() => {
        return customerDetails.maritalStatus === enums.MaritalStatus.MARRIED;
    });

    useEffect(() => {
        // Initialize files state with userProfile files on the first render only.
        if (userProfile?.files && Object.keys(files).length === 0) {
            setFiles(userProfile.files);
        }
    }, [userProfile, files]);

    useEffect(() => {
        // Ensure idKey is always part of the dataForm.
        let dataForm = (customerDetails.maritalStatus === enums.MaritalStatus.MARRIED)
            ? { maritalProofKey: files?.maritalProofKey || null, idKey: files.idKey || null, ...files }
            : { idKey: files.idKey || null };

        console.log("Dataform content: ", dataForm);

        // Check if idKey is present and valid, along with other fields.
        const isValidForm = dataForm.idKey !== null && !Object.values(dataForm).some(value => value == null);

        // Update localStorage with the latest files state and isValidForm flag.
        localStorage.setItem('files', JSON.stringify(files));
        localStorage.setItem('isValidForm', isValidForm.toString());
    }, [files]);


    useEffect(() => {
        // Conditional update for maritalProofKey based on married state.
        if (!married) {
            const { maritalProofKey, ...restFiles } = files;
            setFiles(restFiles);
        }
    }, [married]);

    const handleFileChange = async (e) => {
        const { name, files: inputFiles } = e.target;
        let userId=utils.getUserId()
        if (inputFiles.length > 0) {
            setLoading(true);
            try {
                let response = await utils.handleFileUpload(inputFiles[0], `kyc/${userId}`);
                setFiles(prevFiles => ({
                    ...prevFiles,
                    [name]: response.data?.fileUrl
                }));
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Box>
            <Flex mb={4}>
                <Box>
                    <Heading color={'brand.500'}>Attachments</Heading>
                    <Text color={'gray.500'}>Upload the required documents in PDF format.</Text>
                </Box>
            </Flex>
            <Grid templateColumns="repeat(12, 1fr)" gap={2} color={"blue.800"}>
                <GridItem colSpan={{ base: 12, md: 6 }}>
                    <DropBox label={"Identity Proof Document"}
                             name={"idKey"} loading={loading} callback={handleFileChange} filePath={files.idKey}
                             instruction={`Citizens are to provide Omang copy certified by relevant authorities. Non-citizens are to provide certified passport copy.`}
                    />
                </GridItem>
                {married && (
                    <GridItem colSpan={{ base: 12, md: 6 }}>
                        <DropBox label={"Marital Proof Document"} filePath={files.maritalProofKey}
                                 name={"maritalProofKey"} loading={loading} callback={handleFileChange}
                                 instruction={`Please attach certified copy of FORM B or Marriage Certificate. All documents must be
                                    certified by relevant authorities.`}
                        />
                    </GridItem>
                )}
            </Grid>
        </Box>
    );
};

export default Attachments;
