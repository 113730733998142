import React from "react";
import PortalContainer from "../../components/PortalContainer/PortalContainer";
import utils from "../../utils/commonFunctions";
import LabRequestsTable from "./Components/LabRequestsTable";

const LaboratoryServices = () => {
  const isAuth = utils.isAuthenticated;
  console.log(isAuth());
  return (
    <>
      <PortalContainer
        Title={"Laboratory Services"}
        childComponent={<LabRequestsTable />}
      />
    </>
  );
};

export default LaboratoryServices;
