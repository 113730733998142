import React, {useEffect, useState} from "react";
import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel, Alert, AlertDescription, AlertIcon,
    Box,
    Button, Checkbox, CheckboxGroup,
    Divider,
    Flex, FormControl, FormLabel,
    Grid,
    GridItem,
    IconButton,
    Input, ListItem,
    Spacer, Stack, Switch, Table, Tbody, Td,
    Text,
    Textarea, Tfoot, Th, Thead,
    Tooltip, Tr, UnorderedList,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import {BiTrash} from "react-icons/bi";
import {useQuoteCart} from "../../../providers/QuoteCartProvider";
import AlertModal from "./CustomModal/AlertModal";
import utils from "../../../utils/commonFunctions";
import {CustomGeneralToast} from "../../../utils/customChakraToasts";
import LoaderModal from "./CustomModal/LoaderModal";
import {useNavigate} from "react-router-dom";
import CustomModal from "./CustomModal/CustomModal";
import AssistanceModal from "./CustomModal/AssistanceModal";

const {FormatMoney} = require("format-money-js");
const fm = new FormatMoney({
    decimals: 2,
});

const QuotationCart = () => {
    const {quoteCart, setQuoteCart, customer, setCustomer} = useQuoteCart();
    const [total, setTotal] = useState(0);
    const [value, setValue] = useState(1);
    const [itemTotal, setItemTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [assistanceSuccess, setAssistanceSuccess] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const {onOpen, isOpen, onClose} = useDisclosure();
    const {onOpen: onOpenCustomModal, isOpen: isOpenCustomModal, onClose: onCloseCustomModal} = useDisclosure();
    const {
        onOpen: onOpenAssistanceModal,
        isOpen: isOpenAssistanceModal,
        onClose: onCloseAssistanceModal
    } = useDisclosure();
    const {addGeneralToast} = CustomGeneralToast();
    const navigate = useNavigate();
    const toast = useToast();
    const [action, setAction] = useState(null)
    const [type, setType] = useState("")
    const [submit, setSubmit] = useState(true)
    const [samplingLocation, setSamplingLocation] = useState("")
    const [sampling, setSampling] = useState(false)
    const [noOfSamples, setNoOfSamples] = useState(1)
    const [waterType, setWaterType] = useState([])

    const [buttonText, setButtonText] = useState("Request Assistance with quotation");

    const toggleText = () => {
        setButtonText(prevText => prevText === "Request Assistance with quotation" ? "Continue to submit quotation" : "Request Assistance with quotation");
        setSubmit(!submit)
    }


    const CustomCheckbox = ({isChecked, onChange, isDisabled}) => {
        return <Switch isChecked={isChecked} onChange={onChange} isDisabled={isDisabled}/>;
    };
    useEffect(() => {
        if (type && type === "submit") {
            setAction({
                type: type,
                callback: submitQuote
            })
        }
        if (type && type === "cancel") {
            setAction({
                type: type,
                callback: handleCancel
            })
        }
    }, [type]);
    useEffect(() => {
        if (action) {
            onOpenCustomModal()
            setType("")
        }
    }, [action]);

    useEffect(() => {
        calculateTotal();
    }, [quoteCart, itemTotal, value]);

    const handleInputChange = (e) => {
        if (loading) {
            return;
        }
        let {value, id} = e.target;
        const minValue = 1;
        value = value === "" ? minValue : value;
        value = Number(value) >= minValue ? Number(value) : minValue;
        setValue(value);
        quoteCart.map((item) => {
            if (item["item"] === id) {
                item["itemTotal"] = Number(item["net"]) * Number(value);
                item["quantity"] = Number(value);
                setItemTotal(Number(item['net']) * Number(value));
            }
        });
        setQuoteCart(quoteCart);
        calculateTotal();
    };

    const calculateTotal = () => {
        if (loading) {
            return;
        }
        let total = 0;
        quoteCart.map((item) => {
            total += Number(item.net * item.quantity);
        });
        setTotal(total);
    };

    const deleteCartItem = (itemId) => {
        if (loading) {
            return;
        }
        setQuoteCart((prevCart) =>
            prevCart.filter((item) => item.itemId !== itemId)
        );
        setItemTotal(1);
        calculateTotal();
    };

    const mapArrayToProducts = (array) => {
        return array.map((item) => ({
            number: item.itemId.toString(),
            material: item.item,
            quantity: item.quantity.toString(),
            net: item.net.toString()
        }));
    };

    function validateCustomer(obj) {
        for (const key in obj) {
            if (!obj[key] && obj[key] === 'emailAddress') {
                toast.closeAll();
                addGeneralToast({
                    message: `Customer ${key} is required`,
                    type: "warning",
                });
                return false;
            }
        }
        return true;
    }

    const validateSamplingInfo = () => {
        if (waterType.length === 0) {
            toast.closeAll();
            addGeneralToast({
                message: `Please select at least 1 water type.`,
                type: "warning",
            });
            return false;
        }
        if (sampling) {
            if (!samplingLocation) {
                toast.closeAll();
                addGeneralToast({
                    message: `Please provide sampling location.`,
                    type: "warning",
                });
                return false;
            }
            if (!noOfSamples || noOfSamples < 1) {
                toast.closeAll();
                addGeneralToast({
                    message: `Please provide no of samples required.`,
                    type: "warning",
                });
                return false;
            }
        }
        return true
    };
    const submitQuote = async () => {
        try {
            if (!quoteCart.length) {
                toast.closeAll();
                addGeneralToast({
                    message: "Select at least 1 item",
                    type: "warning",
                });
                return;
            }
            if (!validateCustomer(customer)) {
                return;
            }
            if (!validateSamplingInfo()) {
                return;
            }

            setLoading(true);
            const labItems = mapArrayToProducts(quoteCart);

            const labQuote = {
                emailAddress: customer.emailAddress,
                zQuotationHeader: {
                    webDocNumber: null,
                },
                gtPartners: {
                    item: [customer],
                },
                gtServiceItems: {
                    item: labItems,
                },
                samplingLocation: samplingLocation,
                sampling: sampling,
                waterType: waterType,
                noOfSamples: noOfSamples
            };
            let response = await utils.requestLabQuotation(labQuote);
            if (response) {
                setSuccess(true);
                setQuoteCart([]);
            } else {
                setSuccess(false);
            }
            setLoading(false);
            onOpen();
        } catch (e) {
            setLoading(false);
            setSuccess(false);
        }
    };

    const handleCancel = async () => {
        setQuoteCart([]);
        navigate('/self-service-portal/laboratory-services');
    };

    const [comments, setComments] = useState("");

    const handleAssistance = async () => {
        try {

            if (!quoteCart.length) {
                toast.closeAll();
                addGeneralToast({
                    message: "Select at least 1 item",
                    type: "warning",
                });
                return;
            }
            if (!validateSamplingInfo()) {
                return;
            }
            if (!comments) {
                toast.closeAll();
                addGeneralToast({
                    message: "Quotation message is required",
                    type: "warning",
                });
                return;
            }
            setLoading(true);
            let data = {
                items: quoteCart,
                comments: comments,
            };
            let response = await utils.requestAssistanceWithQuotation(data);
            if (response) {
                setComments("");
                setQuoteCart([]);
                setLoading(false);
                setAssistanceSuccess(true)
                // navigate('/self-service-portal/laboratory-services');
            } else {
                setAssistanceSuccess(false)
                setLoading(false);
            }
            onOpenAssistanceModal();
        } catch (e) {
            console.error(e);
        }
    };

    function handleWaterTypeChange(e) {
        const value = e.target.value;
        if (e.target.checked) {
            setWaterType(prevWaterType => [...prevWaterType, value]);
        } else {
            setWaterType(prevWaterType => prevWaterType.filter(type => type !== value));
        }
    }

    return (
        <>
            <AlertModal isOpen={isOpen} onClose={onClose} success={success}/>
            <AssistanceModal isOpen={isOpenAssistanceModal} onClose={onCloseAssistanceModal}
                             success={assistanceSuccess}/>
            <LoaderModal isOpen={loading}/>
            <Text fontWeight={"semibold"} color={"gray.600"} mb={6} textAlign={'start'}>
                Services selected for quotation
            </Text>
            <Box maxH="400px" overflowY="auto">
                <Table variant="simple" size="sm">
                    <Thead>
                        <Tr>
                            <Th colSpan={5} color="gray.600" fontWeight="semibold">Service</Th>
                            <Th textAlign="center" color="gray.600" fontWeight="semibold">Qty</Th>
                            <Th textAlign="end" color="gray.600" fontWeight="semibold">Price</Th>
                            <Th textAlign="end" color="gray.600" fontWeight="semibold">Total</Th>
                            <Th isNumeric></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {quoteCart?.length > 0 && quoteCart.map((item) => (
                            <Tr key={item.itemId}>
                                <Td colSpan={5}>
                                    <Tooltip label={item.item}>
                                        <Text isTruncated>{item.item}</Text>
                                    </Tooltip>
                                </Td>
                                <Td>
                                    <Flex justify="center">
                                        <Input
                                            type="number"
                                            defaultValue="1"
                                            w="16"
                                            h={8}
                                            my={2}
                                            textAlign="center"
                                            fontSize="sm"
                                            id={item.item}
                                            onChange={handleInputChange}
                                        />
                                    </Flex>
                                </Td>
                                <Td textAlign="end">
                                    <Text isTruncated px={1}>{fm.from(Number(item.net))}</Text>
                                </Td>
                                <Td textAlign="end">
                                    <Text isTruncated
                                          px={1}>{fm.from(Number(item?.itemTotal)) || fm.from(Number(item?.net))}</Text>
                                </Td>
                                <Td textAlign="end">
                                    <IconButton
                                        variant="solid"
                                        bg="transparent"
                                        aria-label="Send email"
                                        icon={<BiTrash/>}
                                        size="sm"
                                        fontSize="15px"
                                        color="red.500"
                                        _hover={{bg: "red.100"}}
                                        onClick={() => deleteCartItem(item.itemId)}
                                    />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                    <Tfoot>
                        <Tr>
                            <Td colSpan={7}>
                                <Text
                                    py={4}
                                    fontSize="md"
                                    fontWeight="semibold"
                                    color="gray.600"
                                    textTransform="capitalize"
                                >
                                    Total Estimate
                                </Text>
                            </Td>
                            <Td textAlign="end" colSpan={2}>
                                <Text
                                    textAlign="end"
                                    fontWeight="semibold"
                                    color="gray.600"
                                    fontSize="lg"
                                >
                                    {fm.from(Number(total), {symbol: "P "})}
                                </Text>
                            </Td>
                        </Tr>
                    </Tfoot>
                </Table>
            </Box>

            <Accordion allowToggle style={{border: "white"}}>
                <AccordionItem>
                    <h2>
                        <AccordionButton
                            onClick={toggleText}
                            _hover={{
                                color: "brand.700",
                                textDecoration: "none",
                            }}
                            borderRadius={"md"}
                            _expanded={{
                                color: "brand.500",
                            }}
                            color="brand.500"
                        >
                            <Box as="span" fontSize={"sm"} flex="1" textAlign="right">
                                {buttonText}
                            </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel>
                        <Textarea
                            placeholder="Please add any additional information that you may find relevant"
                            _placeholder={{
                                color: "gray.300",
                                fontStyle: "italic",
                                fontWeight: "thin",
                            }}
                            size="sm"
                            resize={"none"}
                            borderRadius={"lg"}
                            onChange={(e) => setComments(e.target.value)}
                        />

                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            <Flex direction={{base: 'column', md: 'row'}} justify={'start'} bg={'blue.50'} boxShadow={'md'}
                  borderRadius={'2xl'} p={4}>
                <FormControl isRequired>
                    <FormLabel>Water Type</FormLabel>
                    <CheckboxGroup value={waterType}>
                        <Stack direction={{base: 'column', md: 'row'}}>
                            <Checkbox value="Potable Water" colorScheme='brand' onChange={handleWaterTypeChange}>
                                Potable Water
                            </Checkbox>
                            <Checkbox value="Non Potable Water" colorScheme='brand' onChange={handleWaterTypeChange}>
                                Non-Potable Water
                            </Checkbox>
                        </Stack>
                    </CheckboxGroup>
                </FormControl>
                <FormControl>
                    <Stack spacing={5} direction='row'>
                        <FormLabel htmlFor="sampling-checkbox">Sampling Required?</FormLabel>
                        <Switch onChange={(e) => setSampling(e.target.checked)}/>

                    </Stack>
                    <Stack>
                        <Text fontSize={'xs'} textAlign={'start'} fontStyle={'italic'}>Indicate if sampling is required
                            for these service items.</Text>
                    </Stack>
                    {sampling &&
                        <Flex direction={{base: 'column', md: 'row'}} bg={'white'} p={4} borderRadius={'2xl'}>
                            <FormControl isRequired>
                                <FormLabel>No Of Samples</FormLabel>
                                <Flex justify="start">
                                    <Input
                                        type="number"
                                        defaultValue={1}
                                        w="16"
                                        h={8}
                                        my={2}
                                        textAlign="center"
                                        fontSize="sm"
                                        onChange={(e) => setNoOfSamples(e.target.value)}
                                    />
                                </Flex>
                            </FormControl>

                            <FormControl isRequired>
                                <FormLabel>Sampling Location</FormLabel>
                                <Textarea
                                    placeholder="Please provide sampling location"
                                    _placeholder={{
                                        color: "gray.300",
                                        fontStyle: "italic",
                                        fontWeight: "thin",
                                    }}
                                    size="sm"
                                    resize={"none"}
                                    borderRadius={"lg"}
                                    onChange={(e) => setSamplingLocation(e.target.value)}
                                />
                            </FormControl>
                        </Flex>
                    }
                </FormControl>
            </Flex>

            <Stack direction={'row'} justify={'end'}>
                <Button
                    _hover={{
                        bg: "#69C5EC",
                        color: "white",
                        borderColor: "blue.800",
                    }}
                    bg="brand.500"
                    color="white"
                    size="md"

                    mt={4}
                    boxShadow={'md'}
                    cursor={"pointer"}
                    onClick={submit ? () => setType('submit') : () => handleAssistance()}
                    disabled={loading}
                >
                    {submit ? "Submit" : "Request Assistance"}
                </Button>
                <Button
                    _hover={{
                        bg: "white",
                        color: "gray.700",
                        borderColor: "blue.800",
                    }}
                    onClick={() => setType('cancel')}
                    bg="white"
                    boxShadow={'md'}
                    color="gray.600"
                    type="submit"
                    size="md"

                    borderRadius="2xl"
                    mt={4}
                    cursor={"pointer"}
                >
                    Discard
                </Button>
            </Stack>

            {action && <CustomModal type={action.type} callback={action.callback} isOpen={isOpenCustomModal}
                                    onClose={onCloseCustomModal}/>}
            <Alert
                status="info"
                borderRadius="md"
                bg={"gray.50"}
                color="gray.700"
                fontSize={"12px"}
                fontWeight={"medium"}
                w={"100%"}
                mt={8}
                textAlign={'start'}
            >
                <AlertIcon boxSize={"50px"}/>
                <AlertDescription>
                    <UnorderedList>
                        <ListItem>
                            Total cost of sampling and analysis may vary depending on the
                            testing technique available at time of request for analysis and
                            times scheduled for sampling being either working or outside
                            working hours.
                        </ListItem>
                        <ListItem>All prices are VAT exclusive</ListItem>
                        <ListItem>All prices are handling fee and sampling fee exclusive </ListItem>
                        <ListItem>Please note that the processing time for your request is estimated to be five (5)
                            working days.</ListItem>
                    </UnorderedList>
                </AlertDescription>
            </Alert>
        </>
    );
};

export default QuotationCart;
