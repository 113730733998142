import React, { useRef, useState } from 'react';
import {
    Box,
    Input,
    InputGroup,
    InputRightElement,
    IconButton,
    Hide,
    useDisclosure
} from '@chakra-ui/react';
import { FaSearch } from "react-icons/fa";
import SearchModal from "./components/SearchModal";

const SearchBox = () => {
    const [inputFocus, setInputFocus] = useState(false);
    const inputRef = useRef(null);
    const { onOpen, isOpen, onClose } = useDisclosure({
        onClose: () => setInputFocus(false)
    });

    const handlePlaceholderClick = () => {
        setInputFocus(true);
        inputRef.current?.focus();
    };

    return (
        <>
            <Box
                onClick={() => handlePlaceholderClick()}
                display='flex'
                justifyContent='flex-end'
                alignItems='center'
            >
                <Hide below={'lg'}>

                            <IconButton
                                aria-label="Search"
                                icon={<FaSearch color="white" />}
                                bg="brand.700"
                                _hover={{ bg: "brand.500" }}
                                borderRadius="full"
                                onClick={onOpen}
                            />

                </Hide>
            </Box>
            <SearchModal isOpen={isOpen} onClose={onClose} />
        </>
    );
};

export default SearchBox;
