import React from "react";
import {Text} from "@chakra-ui/react";

const Info= ({text,color='gray.500'}) => {
    return <>
        <Text justifyContent={'justify'} color={color} fontSize={'xs'} fontStyle={'italic'}>
            {text}
        </Text>
    </>
};

export default Info;
