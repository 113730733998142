import React from "react";
import PublicViewContainer from "../../components/Containers/PublicViewContainer";
import NoticesComponent from "./Components/Notices";



const Notices= () => {
    return <PublicViewContainer childComponent={<NoticesComponent/>}/>
}

export default Notices
