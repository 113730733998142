import React, {useEffect, useState} from "react";
import CustomerDetails from "./CustomerDetails";
import CustomStepper from "../../NewConnection/Components/CustomStepper";
import {Button, Flex, Grid, GridItem, HStack, useDisclosure, useToast, useMediaQuery, Box} from "@chakra-ui/react";
import {ArrowBackIcon, ArrowForwardIcon} from "@chakra-ui/icons";
import ContactDetails from "./ContactDetails";
import KinDetails from "./KinDetails";
import Attachments from "./Attachments";
import {API} from "../../../utils/api";
import {CustomGeneralToast} from "../../../utils/customChakraToasts";
import utils from "../../../utils/commonFunctions";
import SuccessModal from "./Modals/SuccessModal";
import {PropagateLoader} from "react-spinners";
import TermsAndConditions from "./TermsAndConditions";
import PreviewModal from "./Modals/PreviewModal/PreviewModal";
import FailureModal from "./Modals/FailureModal";


const KnowYourCustomerForm = () => {
    const [loading, setLoading] = useState(false)
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {addGeneralToast} = CustomGeneralToast();
    const toast = useToast();
    const stages = [
        {stage: 'basic_info', title: 'Basic Information'},
        {stage: 'contact', title: 'Contact Details'},
        {stage: 'kin', title: 'Next Of Kin'},
        {stage: 'attachments', title: 'Attachments'},
        {stage: 'terms', title: 'Terms & Conditions'},
    ]

    const [stage, setStage] = useState(localStorage.getItem('stage') || stages[0].stage);
    const [index, setIndex] = useState(Number(localStorage.getItem('index')) || 0);
    const [customerDetails, setCustomerDetails] = useState(JSON.parse(localStorage.getItem('customerDetails')))
    const [nextOfKin, setNextOfKin] = useState(JSON.parse(localStorage.getItem('nextOfKin')))
    const [address, setAddress] = useState(JSON.parse(localStorage.getItem('address')))
    const [files, setFiles] = useState(JSON.parse(localStorage.getItem('files')))
    const [consent, setConsent] = useState(false)
    const [isValidForm, setIsValidForm] = useState(false)
    const [success, setSuccess] = useState(false)
    const [failed, setFailed] = useState(false)
    const [message, setMessage] = useState("")
    const [isLargerThanTabletSmallerThanLargerScreen] = useMediaQuery("(min-width: 769px) and (max-width: 1024px)");
    const [isLargestScreens] = useMediaQuery("(min-width: 1025px)");
    const [isMobileScreens] = useMediaQuery("(max-width: 473px)");

    useEffect(() => {
        localStorage.setItem('stage', stages[0].stage)
        localStorage.setItem('index', 0)
        setStage(stages[0].stage)
        setIndex(0)
    }, []);

    useEffect(() => {
        setConsent(localStorage.getItem('consent') ==='true')
        setCustomerDetails(JSON.parse(localStorage.getItem('customerDetails')))
        setAddress(JSON.parse(localStorage.getItem('address')))
        setNextOfKin(JSON.parse(localStorage.getItem('nextOfKin')))
        setFiles(JSON.parse(localStorage.getItem('files')))
        if (index < stages.length - 1 && isValidForm) {
            let i = index + 1
            let stage = stages[i].stage
            localStorage.setItem('stage', stage)
            localStorage.setItem('index', i)
            setStage(stage);
            setIndex(i);
            setIsValidForm(false)
        }
    }, [isValidForm]);

    const nextStage = async () => {
        try {
            const isValid = localStorage.getItem('isValidForm') === 'true';
            if (!isValid) {
                toast.closeAll();
                addGeneralToast({
                    message: "Fill all required fields",
                    type: "warning",
                });
            }

            let validateCusNum=JSON.parse(localStorage.getItem('customerDetails'))?.customerNumber
            let validateSpouseCusNum=JSON.parse(localStorage.getItem('customerDetails'))?.spouseCustomerNumber
            console.log('outside',stage,validateCusNum)
            if(stage==='basic_info'&&validateCusNum){
                console.log('inside')
                let result=await utils.validateCustomerNo({customerNumber:validateCusNum})
                console.log(result)
                if(!result){
                    toast.closeAll();
                    addGeneralToast({
                        message: "Customer number is not valid",
                        type: "error",
                    });
                    return
                }
            }
            setIsValidForm(isValid);
        } catch (e) {
            console.log(e)
        }
    };
    const prevStage = () => {
        if (index > 0) {
            let i = index - 1
            let stage = stages[i].stage
            localStorage.setItem('stage', stage)
            setStage(stage);
            setIndex(i);
        }
    };
    const handleShowModal = () => {
        nextStage()
        onOpen()
    }
    const handleSumbitKYC = async () => {
        try {

            const {userId, ...customerDetails2} = customerDetails
            const combinedObjects = {...customerDetails2, ...address};
            const outputObject = {
                userId: utils.getUserId(),
                customerNumber: null,
                spouseCustomerNumber: null,
                personalDetails: {},
                address: {},
                contactDetails: {},
                nextOfKin: {},
                files: {},
                status:'Submitted'
            };


            const {plotNo, street, ward, city, district, ...contactDetails} = address
            const {email, cell, homeTel, workTel, ...addressDetails} = address

            Object.entries(combinedObjects).forEach(([key, val]) => {
                let value = val
                if (key === 'customerNumber') {
                    outputObject.customerNumber = value
                }
                if (customerDetails2.hasOwnProperty(key)) {
                    outputObject.personalDetails[key] = value;
                } else if (addressDetails.hasOwnProperty(key)) {
                    outputObject.address[key] = value;
                } else if (contactDetails.hasOwnProperty(key)) {
                    outputObject.contactDetails[key] = value;
                }
            });
            Object.entries(nextOfKin).forEach(([key, val]) => {
                let value = val
                if (nextOfKin.hasOwnProperty(key)) {
                    outputObject.nextOfKin[key] = value;
                }
            });

            Object.entries(files).forEach(([key, val]) => {
                if (files.hasOwnProperty(key)) {
                    outputObject.files[key] = val;
                }
            });
            setLoading(true)
            const response = await API.put(
                `/kyc/update`,
                outputObject
            );
            setLoading(false)
            const data = response.data;
            if (response.status === 200) {
                setSuccess(true)
                setFailed(false)
                localStorage.removeItem('files')
                localStorage.removeItem('customerDetails')
                localStorage.removeItem('addressDetails')
                localStorage.removeItem('nextOfKin')
                localStorage.removeItem('index')
                localStorage.removeItem('stage')
                localStorage.removeItem('isValidForm')

            } else {
                setSuccess(false)
                setFailed(true)
            }
        } catch (e) {
            setLoading(false)
            setSuccess(false)
            setFailed(true)
            setMessage(e?.response?.data?.message)
            console.log(e)
        }
    };

    return (
        <>
            <Grid templateColumns="repeat(12, 1fr)" bg="white" borderRadius={'2xl'} px={isLargerThanTabletSmallerThanLargerScreen ? 10 : 0}>
                {!loading?
                    <>
                        { isMobileScreens ?? <Box h={'150px'} backgroundColor={'transparent'}></Box> }
                        <GridItem colSpan={12} pt={isLargestScreens ? {lg:20} : {sm:20}}>
                            {<CustomStepper stages={stages}/>}
                        </GridItem>

                        <GridItem colSpan={12} borderRadius={'md'} bg={'white'} p={8}>
                            {stage === 'basic_info' && <CustomerDetails/>}
                            {stage === 'contact' && <ContactDetails/>}
                            {stage === 'kin' && <KinDetails/>}
                            {stage === 'attachments' && <Attachments/>}
                            {stage === 'terms' && <TermsAndConditions/>}
                        </GridItem>
                        <GridItem colSpan={12} px={8} pb={8}>
                            <HStack justifyContent={{base: 'center', md: 'end'}}>
                                {(index > 0) && <Button onClick={prevStage} variant={'outline'} leftIcon={<ArrowBackIcon/>}>
                                    Previous
                                </Button>}
                                {(index === stages.length - 1) ?
                                    <Button onClick={handleShowModal} rightIcon={<ArrowForwardIcon/>}
                                            variant={'solid'}>Proceed</Button>
                                    :
                                    <Button onClick={nextStage} variant={'solid'} rightIcon={<ArrowForwardIcon/>}>
                                        Proceed
                                    </Button>
                                }
                            </HStack>
                        </GridItem>
                    </>

                :
                <GridItem colSpan={12} minH={'65vh'} w={'full'} justifyContent={'center'} alignItems={'center'}>
                    <Flex h={'full'} w={'full'}  justifyContent={'center'} alignItems={'center'}>
                        <PropagateLoader color="#2BBCF2" />
                    </Flex>
                </GridItem>}
            </Grid>
            {consent&&<PreviewModal onClose={onClose} isOpen={isOpen} callback={handleSumbitKYC}/>}
            <SuccessModal isOpen={success} isClosed={!success}/>
            <FailureModal isOpen={failed} isClosed={!failed} response={message}/>
        </>

    );
};

export default KnowYourCustomerForm;
