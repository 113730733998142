import React from "react";
import {
    Box, Heading, Flex, Text, Image, GridItem, Grid
} from "@chakra-ui/react";
import director1 from "../../../../src/assets/Members/Directors/Katse.JPG";
import director2 from "../../../../src/assets/Members/Directors/Mathangwane.JPG";
import director3 from "../../../../src/assets/Members/Directors/Mmolawa.JPG";
import director4 from "../../../../src/assets/Members/Directors/Matsiara1.jpg";
import director5 from "../../../../src/assets/Members/Directors/Kelobang.JPG";
import director6 from "../../../../src/assets/Members/Directors/Letshwiti1.jpg";
import director7 from "../../../../src/assets/Members/Directors/Kenabatho.JPG";
import director8 from "../../../../src/assets/Members/Directors/img.png";
import bgImage from "../../../../src/assets/Members/Directors/directorsbackground.png";

const Director = ({name,position,image}) => (
    <>

        <Box
            width="full"
            display="flex"
            alignItems="space-between"
            borderRadius="2xl"
            boxShadow="md"
            bg={'white'}
            p={4}
            gap={4}
        >
            <Image
                height={{base:"120px",md:"141"}}
                width={{base:"141px",md:"176px"}}
                borderRadius="2xl"
                alt="Board Memeber"
                src={image}
            />
            <Box display="flex" justifyContent="center" alignItems="center">
                <Box display="block" alignItems="flex-start">
                    <Heading size="md" color="facebook.800">
                        {name}
                    </Heading>
                    <Text height="20px" color="gray.500">
                        {position}
                    </Text>
                </Box>
            </Box>
        </Box>
    </>
);
const BoardOfDirectors = () => {
    const Directors = [
        {
            name: 'Mr Noble Katse',
            position: 'Board Chairman',
            image: director1
        },
        {
            name: 'Ms Wandipa Kelobang',
            position: 'Board Member',
            image: director5
        },
        {
            name: 'Mr Mclean Letshwiti',
            position: 'Board Member',
            image: director6
        },
        {
            name: 'Mr. Nchidzi Mmolawa',
            position: 'Board Member',
            image: director3
        },
        {
            name: 'Prof. Kebuang Kenabatho',
            position: 'Board Member',
            image: director7

        },
        {
            name: 'Mr. Galeitsiwe Ramokapane',
            position: 'Board Member',
            image: director8

        },
        {
            name: 'Ms Bogadi Mathangwane',
            position: 'Board Member',
            image: director2
        },
        {
            name: 'Mr Gaetshwane Matsiara',
            position: 'Board Member',
            image: director4
        },





    ]
    return (
        <>
            <Flex justify={'center'} align={'center'} bgImage={`url(${bgImage})`} objectFit={'fit'}
                  bgPosition="center"
                  bgRepeat="no-repeat"
                  bgSize="cover" p={8} w={'full'}>
                <Box>
                    <Heading size="xs" mb={2} textAlign={'center'} color={'blue.800'}>Members</Heading>
                    <Heading size="lg" mb={4} textAlign={'center'} color={'blue.800'}>Board Of <Box as={'span'} color={'#0093D0'} >Directors</Box> </Heading>
                    <Grid templateColumns='repeat(12, 1fr)' gap={4}>
                        {Directors.map((director, index) => (
                            <GridItem key={index} colSpan={{ base:12, md: 6 }}>
                                <Director name={director.name} position={director.position} image={director.image} />
                            </GridItem>
                        ))}
                    </Grid>
                </Box>


            </Flex>
        </>
    )
};
export default BoardOfDirectors