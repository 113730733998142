import React from "react";
import { NavLink } from "react-router-dom";
import {
    Box,
    Flex,
    Icon,
    useColorModeValue,
    Text,
    Drawer,
    DrawerContent,
    Divider,
    Image,
    CloseButton,
    DrawerOverlay,
} from "@chakra-ui/react";
import {
    FiHome,
    FiBookOpen,
    FiEdit,
} from "react-icons/fi";
import { MdOutlinePermMedia } from "react-icons/md";
import { LiaProjectDiagramSolid } from "react-icons/lia";

import Logo from "./../../assets/LogoWithWhiteBG.png";
import utils from "../../utils/commonFunctions.js";
import { useDrawer } from '../../providers/NavBarSliderProvider';
import { FaRegAddressCard } from "react-icons/fa6";
import { RiCustomerService2Line } from "react-icons/ri";
import { GrContact } from "react-icons/gr";
import SidebarFooter from "./SideBarFooter";

const LinkItems = [
    { name: "Home", icon: FiHome, route: "/" },
    {
        name: "About WUC",
        icon: FaRegAddressCard,
        route: "/about",
    },
    {
        name: "Customer Services",
        icon: RiCustomerService2Line,
        route: "/customer-services",
    },
    {
        name: "Careers",
        icon: FiBookOpen,
        route: "https://careers.wuc.bw/",
    },
    {
        name: "Tenders",
        icon: FiEdit,
        route: "/tenders",
    },
    {
        name: "Media",
        icon: MdOutlinePermMedia,
        route: "/media",
    },
    {
        name: "Projects",
        icon: LiaProjectDiagramSolid,
        route: "/projects",
    },
    {
        name: "Contact Us",
        icon: GrContact,
        route: "/contact-us",
    }
];

const Sidebar = () => {
    const { isOpen, closeDrawer } = useDrawer();

    return (
        <Box minH="100vh" w={{ base: 'full', md: 80 }}>
            <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={closeDrawer}
                returnFocusCloseDrawer={true}
                size="full" // Ensures the drawer covers the full viewport on mobile and tablet
            >
                <DrawerOverlay />
                <DrawerContent
                    w={{ base: 'full', md: 80 }}
                    maxW={{ base: 'full', md: 80 }}
                    bg={useColorModeValue("gray.900", "gray.800")} // Adjust the background color here
                    color={useColorModeValue("white", "gray.200")} // Adjust the text color accordingly
                >
                    <SidebarContent onClose={closeDrawer} />
                </DrawerContent>
            </Drawer>
        </Box>
    );
};

const SidebarContent = ({ onClose, setActiveLink, activeLink, ...rest }) => {
    const trackColor = useColorModeValue("gray.200", "gray.700");
    const { name } = utils?.getUser() || {};

    return (
        <Box
            bg={useColorModeValue("gray.900", "gray.800")} // Adjust the background color here
            color={useColorModeValue("white", "gray.200")} // Adjust the text color here
            zIndex={1}
            borderRight="1px"
            borderRightColor={useColorModeValue("gray.200", "gray.700")}
            w={{ base: 'full', md: 80 }}
            h={{ base: "100vh", md: "100vh" }}
            pos="fixed"
            {...rest}
        >
            {/**header */}
            <Box
                bg={useColorModeValue("gray.800", "gray.700")}
                px={{ base: "2", md: "4" }}
                py={{ base: "3", md: "5" }}
                my={2}
                mx={{ base: "1", md: "2" }}
                borderRadius={{ base: "5", md: "10" }}
            >
                <Flex
                    direction={{ base: "column", md: 'column', lg: "row" }}
                    align="center"
                    justify={{ base: "center", md: "center", lg: "space-between" }}
                >
                    <Image src={Logo} maxH="50px" mb={{ base: "2", md: "0" }} />{" "}
                    {/* Margin bottom only on smaller screens */}
                    <Flex
                        direction="column"
                        align={{ base: "center", md: "center", lg: "flex-start" }}
                        textAlign={{ base: "center", md: "center", lg: "left" }}
                    >
                        <Text fontSize="md" fontWeight="bold">
                            Water Utilities Corporation
                        </Text>

                        {name && <Text fontSize="sm" isTruncated>{name}</Text>}

                        <CloseButton
                            position="absolute"
                            top={2}
                            right={2}
                            display={{ base: "flex", md: "flex", lg: "none" }}
                            onClick={onClose}
                            color="brand.700"
                        />
                    </Flex>
                </Flex>
            </Box>

            <Divider mb={2} />
            {/**nav items*/}
            <Box
                h={{ base: "768px" }}
                overflowY="auto"
                sx={{
                    "&::-webkit-scrollbar": {
                        width: "4px", // Thinner scrollbar
                    },
                    "&::-webkit-scrollbar-track": {
                        background: trackColor,
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "brand.700", // Using brand color
                        borderRadius: "8px",
                        border: `2px solid ${trackColor}`,
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        background: "brand.600", // Slightly darker brand color on hover
                    },
                }}
            >
                {LinkItems.map((link) => (
                    <NavItem
                        key={link.name}
                        icon={link.icon}
                        route={link.route}
                        isActive={activeLink === link.name}
                        onClick={() => setActiveLink(link.name)}
                    >
                        {link.name}
                    </NavItem>
                ))}
            </Box>

            <Divider />
            {/**footer */}
            <Flex mt={4} justify={'center'} pos={'absolute'} bottom={-5} w={'full'} align={'center'} px={4} py={2} >
                <SidebarFooter />
            </Flex>

        </Box>
    );
};


const NavItem = ({ icon, children, isActive, onClick, route, ...rest }) => {
    const activeBg = useColorModeValue("brand.700", "brand.700");
    const textColor = isActive ? "white" : "inherit";

    return (
        <NavLink
            to={route}
            style={{ textDecoration: "none" }}
            _focus={{ boxShadow: "none" }}
            onClick={onClick}
            {...rest}
        >
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                bg={isActive ? activeBg : "transparent"}
                color={textColor}
                _hover={{
                    bg: "brand.700",
                    color: "white",
                }}
            >
                {icon && <Icon mr="4" fontSize="16" as={icon} />}
                {children}
            </Flex>
        </NavLink>
    );
};

export default Sidebar;
