import React from "react";
import {Box, Button, Flex, Icon, Text} from "@chakra-ui/react";
import {MdSettings} from "react-icons/md";
import {Link} from "react-router-dom";


const Approved= () => {

    return (
        <>
        <Flex px={2} flexDirection={'column'} justify={'center'} align={'center'} w={'full'} h={'100vh'} >

                <Box >
                    <Icon as={ MdSettings} boxSize={{base:"16",md:"24"}} borderWidth={8} borderRadius={'full'} borderColor={'red.200'} color={"red.500"} />
                </Box>

                <Text textAlign={'center'} fontWeight={'bold'} my={2} fontSize={{base:"30px"}}>
                   Site Page is still under maintance
                </Text>
            <Text textAlign={'center'} my={2} fontSize={{base:"20px"}}>
               We are working hard to improve user experience.Stay tuned.
            </Text>

            <Text textAlign={'center'} my={2} fontSize={{base:"14px"}}>
                {` ${new Date()}`}
            </Text>
            <Link to={'/'}>
                <Button  my={2}  bg={'red.500'} _hover={{bg:'red.400'}} p={4}>
                    Back to Homepage
                </Button>
            </Link>

        </Flex>
        </>
    )

};


export default Approved;
