import {
  Box,
  Flex,
  Heading,
  Text,
  Grid,
  GridItem,
  Divider,
  Image,
  Button,
  ButtonGroup,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Page,
  Text as PdfText,
  View,
  Document,
  StyleSheet,
  Image as PdfImage,
  pdf,
} from '@react-pdf/renderer';
import { useQuoteCart } from "../../../providers/QuoteCartProvider";
import React, { useEffect, useState } from "react";
import Logo from "../../../assets/LogoWithWhiteBG.png";
import utils from "../../../utils/commonFunctions";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomGeneralToast } from "../../../utils/customChakraToasts";
import StatusTag from "./StatusTag";
import CustomModal from "./CustomModal/CustomModal";
import PaymentSummaryModal from "../../../components/PayGate/PaymentSummaryModal";
import { useUserProfile } from "../../../providers/UserProfileProvider";
import contact from "../../../components/PortalContainer/Contact";
const { FormatMoney } = require("format-money-js");
const fm = new FormatMoney({ decimals: 2 });

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontFamily: 'Helvetica',
  },
  grid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap', // Allows items to wrap to the next line
    justifyContent: 'space-between',
    alignItems: 'flex-start', // Align items to the top
    marginBottom: 10,
    padding: 10,
    backgroundColor: '#E6F0F8',
    borderRadius: 5,
  },
  gridItem: {
    flex: 1,
    minWidth: '40%',
    padding: 10,
  },
  gridItemCentered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexBasis: '40%',
    padding: 10,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 4,
  },
  box: {
    width: 100,
    height: 100,
    marginBottom: 10,
    borderRadius: 5,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
  },
  heading: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'left',
  },
  semiboldText: {
    fontSize: 12,
    fontWeight: 'bold',
    marginRight: 4,
  },
  text: {
    fontSize: 12,
  },
  serviceHeader: {
    fontSize: 12,
    fontWeight: 'bold',
    padding: '5px 0',
    width: '20%',
    textAlign: 'left',
  },
  totalText: {
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 40,
    textAlign: 'right',
  },
});

const ViewQuotation = () => {
  const id = new URLSearchParams(useLocation().search).get('quote');
  const { quoteCart, setQuoteCart } = useQuoteCart();
  const [quote, setQuote] = useState([]);
  const [quoteItems, setQuoteItems] = useState([]);
  const [customer, setCustomer] = useState({});
  const [customerData, setCustomerData] = useState({});
  const [status, setStatus] = useState(null);
  const { addGeneralToast } = CustomGeneralToast();
  const navigate = useNavigate();
  const toast = useToast();
  const { onOpen: onOpenCustomModal, isOpen: isOpenCustomModal, onClose: onCloseCustomModal } = useDisclosure();
  const [action, setAction] = useState(null);
  const [total, setTotal] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  let { userProfile } = useUserProfile();
  let { contactDetails } = userProfile || {};
  let user = utils.getUser();

  useEffect(() => {
    setCustomerData({
      customerNumber: customer?.pNumber,
      fullName: customer?.name,
      email: quote?.emailAddress,
      postalCode: '0000',
      phone: contactDetails?.cell || user.phone
    });
  }, [customer]);

  const generatePdfBlob = async () => {

  if (!customer && !quote ) {
    alert("Required data is missing. Please wait until all data is loaded.");
    return;
  }

    const blob = await pdf(
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.grid}>
            <View style={styles.gridItemCentered}>
              <View style={styles.box}>
                <PdfImage src={Logo} alt="Logo" style={{ width: '100%', height: '100%' }} />
              </View>
            </View>
            <View style={styles.gridItem}>
              <View style={styles.grid}>
                <View style={styles.flex}>
                  <PdfText style={styles.heading}>Customer Information</PdfText>
                </View>
                <View style={styles.flex}>
                  <PdfText style={styles.semiboldText}>Name: </PdfText>
                  <PdfText style={styles.text}>{customer?.name}</PdfText>
                </View>
                <View style={styles.flex}>
                  <PdfText style={styles.semiboldText}>Email: </PdfText>
                  <PdfText style={styles.text}>{quote?.emailAddress}</PdfText>
                </View>
                <View style={styles.flex}>
                  <PdfText style={styles.semiboldText}>Address: </PdfText>
                  <View>
                    <PdfText style={styles.text}>{customer?.street}</PdfText>
                    <PdfText style={styles.text}>{customer?.city}</PdfText>
                    <PdfText style={styles.text}>{'0000'}</PdfText>
                  </View>
                </View>
                <View style={styles.flex}>
                  <PdfText style={styles.semiboldText}>Account Number: </PdfText>
                  <PdfText style={styles.text}>{customer?.pNumber !== "OTC-QUOTE" ? customer?.pNumber : ""}</PdfText>
                </View>
                <View style={styles.flex}>
                  <PdfText style={styles.semiboldText}>Quotation Number: </PdfText>
                  <PdfText style={styles.text}>{quote?.quotationNumber || "N/A"}</PdfText>
                </View>
              </View>
            </View>
          </View>

          {/* Grid for Service Items */}
          <View style={styles.grid}>
            <PdfText style={styles.serviceHeader}>Service</PdfText>
            <PdfText style={styles.serviceHeader}>Qty</PdfText>
            <PdfText style={styles.serviceHeader}>Unit Price</PdfText>
            <PdfText style={styles.serviceHeader}>Tax Price</PdfText>
            <PdfText style={styles.serviceHeader}>Total</PdfText>
            {quoteItems?.map((item, index) => (
              <React.Fragment key={index}>
                <PdfText style={styles.serviceHeader}>{item?.material}</PdfText>
                <PdfText style={styles.serviceHeader}>{item?.quantity}</PdfText>
                <PdfText style={styles.serviceHeader}>{fm.from(Number(item?.net), { symbol: "P " })}</PdfText>
                <PdfText style={styles.serviceHeader}>{fm.from(Number(item?.tax), { symbol: "P " })}</PdfText>
                <PdfText style={styles.serviceHeader}>{fm.from(Number((Number(item?.net) + Number(item?.tax)) * item?.quantity), { symbol: "P " })}</PdfText>
              </React.Fragment>
            ))}
            <PdfText style={styles.totalText}>Total Price: {"P " + total}</PdfText>
          </View>
        </Page>
      </Document>
    ).toBlob();

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'wuc_lab_quotation.pdf');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const calculateTotal = (items) => {
    let total = 0;
    if (items && items.length) {
      items.forEach((item) => {
        const gross = Number(item.tax || 0) + Number(item.net);
        total += gross * item.quantity;
      });
      setTotal(total.toFixed(2));
    }
  };

  useEffect(() => {
    if (action) {
      onOpenCustomModal();
      setStatus("");
    }
  }, [action]);

  const fetchQuote = async () => {
    const response = await utils.getLabQuotations({ id });
    if (response && response.docs) {
      let doc = response.docs[0];
      if (doc) {
        setQuoteItems(doc.gtServiceItems.item);
        setQuote(doc);
        setCustomer(doc.gtPartners.item[0]);
      }
    }
  };

  useEffect(() => {
    if (id) fetchQuote();
  }, [id]);

  useEffect(() => {
    if (quoteItems.length) calculateTotal(quoteItems);
  }, [quoteItems]);

  return (
    <Flex justify={'center'} mx={{ md: 16 }}>
      <Box w={'full'} h={'full'}>
        <Box bg={'white'} borderRadius='2xl' py={{ md: 16 }} px={{ md: 32 }} w={'full'}>
          <Grid templateColumns="repeat(12, 1fr)" w={"100%"} mb={16} bg={'blue.50'} borderRadius={'2xl'} p={4}>
            <GridItem justify={'center'} colSpan={4} >
              <Box bg={'white'} borderRadius={'2xl'} p={4} w={"140px"} h={'140px'}>
                <Image src={Logo} alt="Logo" w='full' h={'full'} />
              </Box>
            </GridItem>
            <GridItem colSpan={8}>
              <Grid templateColumns="repeat(12, 1fr)" gap={2} w={"100%"}>
                <GridItem colSpan={12}>  <Heading fontSize={"md"} color={"blue.800"} >
                  Customer Information
                </Heading></GridItem>
                <GridItem colSpan={6}>
                  <Flex h={'full'}>
                    <Box fontSize="sm">
                      <Flex><Text fontWeight={'semibold'} color={"blue.800"}>Name:{" "}</Text><Text>{customer.name}</Text></Flex>
                      <Flex><Text fontWeight={'semibold'} color={"blue.800"}>Email:{" "}</Text><Text>{quote.emailAddress}</Text></Flex>
                      <Flex><Text fontWeight={'semibold'} color={"blue.800"}>Address:{" "}</Text>
                        <Box>
                          <Text>{customer.street}</Text>
                          <Text>{customer.city}</Text>
                          <Text>{'0000'}</Text>
                        </Box>
                      </Flex>
                    </Box>
                  </Flex>
                </GridItem>
                <GridItem colSpan={6}>
                  <Flex h={'full'}>
                    <Box>
                      <Box fontSize="sm">
                        <Flex><Text fontWeight={'semibold'} color={"blue.800"}>Account Number:{" "}</Text><Text>{customer.pNumber !== "OTC-QUOTE" ? customer.pNumber : ""}</Text></Flex>
                        <Flex><Text fontWeight={'semibold'} color={"blue.800"}>Quotation Number:{" "}</Text><Text>{quote?.quotationNumber || 'N/A'}</Text></Flex>
                        <Flex><Text fontWeight={'semibold'} color={"blue.800"}>Quotation Status:{" "}</Text><StatusTag status={quote?.status || "N/A"} /></Flex>
                      </Box>
                    </Box>
                  </Flex>

                </GridItem>
              </Grid>
            </GridItem>
          </Grid>
          <Grid templateColumns="repeat(12, 1fr)" minH={'200px'} justify={'center'}>
            <GridItem colSpan={3}><Flex justify={'start'} color={'gray.600'} fontWeight={'semibold'}>Service</Flex></GridItem>
            <GridItem colSpan={2}><Flex justify={'center'} color={'gray.600'} fontWeight={'semibold'}>Qty</Flex></GridItem>
            <GridItem colSpan={2}><Flex justify={'end'} color={'gray.600'} fontWeight={'semibold'}>Unit Price</Flex></GridItem>
            <GridItem colSpan={2}><Flex justify={'end'} color={'gray.600'} fontWeight={'semibold'}>Tax Price</Flex></GridItem>
            <GridItem colSpan={3}><Flex justify={'end'} color={'gray.600'} fontWeight={'semibold'}>Total</Flex></GridItem>
            {quoteItems?.length > 0 &&
              quoteItems.map((item) => (
                <>
                  <GridItem colSpan={3} ><Flex justify={'start'} fontSize={'sm'} isTruncated >{item.material}</Flex></GridItem>
                  <GridItem colSpan={2} ><Flex justify={'center'} fontSize={'sm'}>{item.quantity}</Flex></GridItem>
                  <GridItem colSpan={2} ><Flex justify={'end'} fontSize={'sm'} textAlign={'end'}> {fm.from(Number(item?.net), { symbol: "P " })}</Flex></GridItem>
                  <GridItem colSpan={2} ><Flex justify={'end'} fontSize={'sm'}> {fm.from(Number(item?.tax), { symbol: "P " })}</Flex></GridItem>
                  <GridItem colSpan={3} ><Flex justify={'end'} fontSize={'sm'}> {fm.from(Number((Number(item?.net) + Number(item?.tax)) * item?.quantity), { symbol: "P " })}</Flex></GridItem>
                </>
              ))}
            <GridItem colSpan={12}><Flex justify={'end'} color={'gray.600'} fontWeight={'semibold'}>Total Price: {"P " + total}</Flex></GridItem>

          </Grid>

          <Divider orientation="horizontal" bg={'blue.300'} w={"full"} />
          <Flex py={'8'}>
            {/** <Textarea
                placeholder="Please add any additional information that you may find relevant"
                _placeholder={{
                  color: "gray.300",
                  fontStyle: "italic",
                  fontWeight: "thin",
                }}
                size="sm"
                resize={"none"}
                borderRadius={"2xl"}
                bg={'gray.100'}
                w={'450px'}
            />**/}
            <Flex w={'full'} justify={'end'} gap={4}>
              {quote?.status === "Ready" && <Button
                variant={'ghost'}
                _hover={{ bg: "blue.100" }}
                py="{4}"
                fontSize="md"
                fontWeight="medium"
                color="blue.400"
                textTransform="capitalize"
                onClick={generatePdfBlob}
              >
                Download
              </Button>}
              {quote?.status === "Ready" && <Button
                variant={'ghost'}
                _hover={{ bg: "green.100" }}
                py="{4}"
                fontSize="md"
                fontWeight="medium"
                color="green.400"
                textTransform="capitalize"
                onClick={() => setStatus('Accepted')}
              >
                Accept Quotation
              </Button>}

              {quote?.status === "Ready" &&
                <Button
                  variant={'ghost'}
                  py="{4}"
                  fontSize="md"
                  fontWeight="medium"
                  color="red.400"
                  _hover={{ bg: "red.100" }}
                  textTransform="capitalize"
                  onClick={() => setStatus('Rejected')}
                >
                  Reject Quotation
                </Button>
              }

              {quote?.status === "Accepted" &&
                <Button
                  variant={'ghost'}
                  py="{4}"
                  fontSize="md"
                  fontWeight="medium"
                  color="green.400"
                  _hover={{ bg: "green.100" }}
                  textTransform="capitalize"
                  onClick={() => onOpen()}
                >
                  Pay Now
                </Button>
              }
            </Flex>
          </Flex>
        </Box>

        <Flex justify={'end'} align={"center"} p={8}>
          <ButtonGroup>
            <Button bg={'white'} color={'gray.600'} _hover={{ color: 'gray.600' }} px={8} py={6} onClick={() => {
              navigate('/self-service-portal/laboratory-services')
            }}>Back </Button>
          </ButtonGroup>
        </Flex>
        
      </Box>

      {action && <CustomModal type={action.type} callback={action.callback} isOpen={isOpenCustomModal} onClose={onCloseCustomModal} />}

      <PaymentSummaryModal
        isOpen={isOpen}
        onClose={onClose}
        balance={total}
        quotationNumber={quote?.quotationNumber}
        customerNo={customer?.customerNumber}
        paymentType={'LabPayment'}
        customer={customerData}
      />
    </Flex>

  );
};

export default ViewQuotation;
