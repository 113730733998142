import {
    Flex,
    Stack,
    Button,
    Thead,
    Tr,
    Th,
    Table,
    TableContainer, Tbody, Td, Heading, Spacer, useDisclosure, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, Text, useBreakpointValue
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import LeakReportModal from "./LeakReportModal";
import moment from "moment";
import Paginate from "../../../components/Paginate/Paginate";
import utils from "../../../utils/commonFunctions";
import { useQuoteCart } from "../../../providers/QuoteCartProvider";
import { HashLoader } from "react-spinners";

const LeaksTable = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [checkedBills, setCheckedBills] = useState({ docs: [], totalPages: 1 });
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const { refreshFlag, setRefreshFlag } = useQuoteCart();

    const displayAsAccordion = useBreakpointValue({ base: true, md: false });

    useEffect(() => {
        const fetchData = async () => {
            const userId = utils.getUserId();
            const threeMonthsAgo = new Date();
            threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
            threeMonthsAgo.setHours(0, 0, 0, 0);
            const query = {
                createdBy: userId,
                result: 'true',
                pageNumber: currentPage,
                pageSize: 4
            };
            setLoading(true);
            const fetchedBills = await utils.getLeaks(query);
            setCheckedBills(fetchedBills);
            setLoading(false);
            setRefreshFlag(false);
        };
        if (!isOpen) {
            fetchData();
        }
    }, [currentPage, isOpen, refreshFlag]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <Stack gap={0} p={8} bg={'white'} w="full" h={{ base: 'full' }}>
                <Flex
                    align={{ base: 'flex-start', md: 'center' }}
                    direction={{ base: 'column', md: 'row' }}
                    w={"full"}
                    py={2.5}
                    px={{ base: 2, lg: 8 }}
                    gap={{ base: 2, md: 0 }}
                >
                    <Heading
                        color={'blue.800'}
                        fontSize={{ base: 'lg', md: 'xl' }}
                    >
                        Leak Reports
                    </Heading>

                    <Spacer display={{ base: 'none', md: 'block' }} />

                    <Button
                        onClick={onOpen}
                        variant={'solid'}
                        leftIcon={<FaPlus fontSize={'lg'} fontWeight={'bold'} />}
                        mt={{ base: 2, md: 0 }}
                    >
                        Report Leak
                    </Button>
                </Flex>

                {displayAsAccordion ? (
                    <Accordion allowMultiple>
                        {!loading ? (
                            checkedBills?.docs?.length > 0 ? (
                                checkedBills.docs.map((bill, index) => (
                                    <AccordionItem key={index}>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left">
                                                {bill.ticket}
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel pb={4}>
                                            <Text><strong>Customer Number:</strong> {bill.customerNumber}</Text>
                                            <Text><strong>Contract Number:</strong> {bill.contractNumber}</Text>
                                            <Text><strong>Date Reported:</strong> {moment(bill.createdDate).format("DD-MM-YYYY")}</Text>
                                            <Text><strong>Date Actioned:</strong> {bill?.actionedDate ? moment(bill.actionedDate).format("DD-MM-YYYY") : <Badge colorScheme={'yellow'}>Pending</Badge>}</Text>
                                        </AccordionPanel>
                                    </AccordionItem>
                                ))
                            ) : (
                                <Flex justifyContent="center" align="center" w="full" h="full" py={16}>
                                    <Heading size="lg" color="gray.500">No Record Found</Heading>
                                </Flex>
                            )
                        ) : (
                            <Flex justifyContent="center" align="center" w="full" h="full">
                                <HashLoader color="#2bbcf2" />
                            </Flex>
                        )}
                    </Accordion>
                ) : (
                    <TableContainer px={{ base: 2, lg: 8 }} w="full" h="full">
                        <Table colorScheme="gray">
                            <Thead>
                                <Tr>
                                    <Th color="blue.800" fontSize={'sm'} textTransform="capitalize">Ticket Number</Th>
                                    <Th color="blue.800" fontSize={'sm'} textTransform="capitalize">Customer Number</Th>
                                    <Th color="blue.800" fontSize={'sm'} textTransform="capitalize">Contract Number</Th>
                                    <Th color="blue.800" fontSize={'sm'} textTransform="capitalize">Date Reported</Th>
                                    <Th color="blue.800" fontSize={'sm'} textTransform="capitalize">Date Actioned</Th>
                                </Tr>
                            </Thead>
                            {!loading ? (
                                checkedBills?.docs?.length > 0 && (
                                    <Tbody>
                                        {checkedBills.docs.map((bill, index) => (
                                            <Tr key={index}>
                                                <Td>{bill.ticket}</Td>
                                                <Td>{bill.customerNumber}</Td>
                                                <Td>{bill.contractNumber}</Td>
                                                <Td>{moment(bill.createdDate).format("DD-MM-YYYY")}</Td>
                                                <Td>{bill?.actionedDate ? moment(bill.actionedDate).format("DD-MM-YYYY") : <Badge colorScheme={'yellow'}>Pending</Badge>}</Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                )
                            ) : (
                                <Flex justifyContent="center" align="center" w="full" h="full">
                                    <HashLoader color="#2bbcf2" />
                                </Flex>
                            )}
                        </Table>
                    </TableContainer>
                )}
                <Paginate
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    numberOfPages={checkedBills?.totalPages || 1}
                />
            </Stack>
            <LeakReportModal isOpen={isOpen} onClose={onClose} />
        </>
    );
};

export default LeaksTable;
