
import React from "react";
import BuyToken from "./Components/BuyToken";
import PortalContainer from "../../components/PortalContainer/PortalContainer";
import CheckBillTable from "../PayBillOnline/Components/CheckBillTable";
import SelfServiceContainer from "../../components/PortalContainer/SelfServiceContainer";
import TransactionsTable from "./Components/TransactionsTable";

const BillCalculatorGuest = () => {
  return <>
    <PortalContainer childComponent={<SelfServiceContainer childComponent={<BuyToken/>} table={<TransactionsTable/>}/>}  Title={'Prepaid Recharge'}/>
  </>
};
export default BillCalculatorGuest;
