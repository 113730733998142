import {
    Input,
    Stack,
    Button,
    FormLabel,
    FormControl,
    useToast,
    Select,
    HStack, Text, Switch,
} from "@chakra-ui/react";
import {useEffect, useState} from "react";

import {PropagateLoader} from "react-spinners";
import {CustomGeneralToast} from "../../../utils/customChakraToasts";
import utils from "../../../utils/commonFunctions";
import {API} from "../../../utils/api";
import BillAlert from "../../../components/PortalContainer/BillAlert";
import {useUserProfile} from "../../../providers/UserProfileProvider";
import {useQuoteCart} from "../../../providers/QuoteCartProvider";

const SubmitMeterReadingForm = () => {

    const [number, setNumber] = useState("");
    const [reading, setReading] = useState("");
    const [utility, setUtility] = useState("");
    const [loading, setLoading] = useState(false);
    const {addGeneralToast} = CustomGeneralToast();
    const toast = useToast();

    const submitMeterReading = async () => {
        if (number === "" || utility === "" || reading === "") {
            toast.closeAll();
            addGeneralToast({
                message: "All fields must be filled in",
                type: "warning",
            });
            setLoading(false);
            return;
        }
        setLoading(true);
        try {
            const response = await API.post(
                `/meter/reading`,
                {
                    customerNumber: number,
                    contractNumber: utility,
                    meterReading: reading,
                    createdBy: number
                }
            );
            const data = await response.data;
            if (response.status === 200) {
                setLoading(false);
            } else {
                toast.closeAll();
                addGeneralToast({
                    message: data.message,
                    type: "error",
                });
                setLoading(false);
                setReading("")
                setNumber("")
                setUtility("")
            }
        } catch (e) {
            setLoading(false);
            toast.closeAll();
            console.log(e)
            addGeneralToast({
                message: e?.message? e?.message : e.statusText,
                type: "error",
            });
        }

    };

    return (
        <>
            <Stack spacing={1} w="full" h={'full'} px={4}>
                <BillAlert/>
                {/* Similar FormControl components for 'Customer Cell No' and 'Customer Number' */}
                <FormControl id="customerNumber">
                    <FormLabel>Customer Number</FormLabel>
                    <Input
                        isRequired
                        type="number"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                    />
                </FormControl>
                <FormControl id="contractNumber">
                    <FormLabel>Utility Number</FormLabel>
                    <>

                        <Input
                            isRequired
                            value={utility}
                            type="number"
                            onChange={(e) => setUtility(e.target.value)}
                        />


                    </>
                </FormControl>
                <FormControl id="customerNumber">
                    <FormLabel color="blue.800">Meter Reading</FormLabel>
                    <Input
                        type="text"
                        value={reading}
                        onChange={(e) => setReading(e.target.value)}
                    />
                </FormControl>


                <Stack spacing={6} mt={2}>
                    {loading ? (
                        <Button variant="solid">
                            <PropagateLoader size={8} color="white"/>
                        </Button>
                    ) : (
                        <Button
                            type="submit"
                            variant="solid"
                            onClick={submitMeterReading}
                        >
                            Submit Meter Reading
                        </Button>
                    )}
                </Stack>

            </Stack>
        </>
    );
};

export default SubmitMeterReadingForm;
