import React from 'react';
import KnowYourCustomerForm from './Components/KnowYourCustomerForm';
import PortalContainer from '../../components/PortalContainer/PortalContainer';
import InstructionsModal from "../../components/CustomModals/InstructionsModal";
import Instructions from "./Components/Instructions";

const KnowYourCustomer = () => {

  return <>
   <InstructionsModal  title={"KYC Verification Guide"} Instructions={<Instructions/>}/>
  <PortalContainer Title={"KYC"} childComponent={<KnowYourCustomerForm/>}/>
</>;
};

export default KnowYourCustomer;
