import React from "react";

import Publication from "./Components/Publication";

const Publications = () => {
    return (<>
        <Publication/>
    </>);
};

export default Publications;
