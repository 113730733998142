import React, {useState} from "react";
import {
    Box,
    Button,
    Flex,
    Heading,
} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {AreaInput, CityInput} from "../Applications/Forms/Inputs";

const NoticeForm = () => {
    const navigate = useNavigate();
    const [filter, setFilter] = useState({
        city: null,
        area: null
    });

    async function getNotices() {
        navigate('/notices', { state: { filter } });
    }

    function handleInputChange(e) {
        const { name, value } = e.target;
        setFilter(prev => ({
            ...prev,
            [name]: value,
            area: name === 'city' ? null : prev.area // Reset area when city changes
        }));
    }

    return (
        <>
            <Flex pos='relative' w={{base: "full"}} h={'full'} direction={'column'} justify={'center'} align={'center'}>
                <Heading size={'lg'} color={'brand.700'}>Notices</Heading>
                <Flex direction={'column'} mt={8} w={"full"} h={'full'}>
                    <CityInput label={'City'} value={filter.city} isRequired={false} callback={handleInputChange}/>
                    <Box mt={4}>
                        <AreaInput value={filter.area} cityName={filter.city} isRequired={false} callback={handleInputChange}/>
                    </Box>


                    <Flex justifyContent="center" mt="6">
                        <Button w={{base: 'full'}} bg="brand.700" onClick={getNotices}>
                            Check Notices
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
}

const Notices = () => {
    return (
        <Flex justify={'center'} w={"full"} h={'full'}>
            <NoticeForm />
        </Flex>
    );
}

export default Notices;
