import React, { useEffect, useState } from "react";
import { Box, Flex, Text, Button, SimpleGrid } from "@chakra-ui/react";
import moment from 'moment';
import utils from "../../../utils/commonFunctions";
import { useLocation } from 'react-router-dom';

const Notice = () => {
    const [notice, setNotice] = useState(null);

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };

    const query = useQuery();
    const id = query.get('id');

    useEffect(() => {
        async function fetchNotice() {
            if (id) {
                let response = await utils.fetchNotices({ id: id });
                if (response) setNotice(response[0]);
            }
        }
        fetchNotice();
    }, [id]);

    return (
        <>
            {notice && (
                <Flex bg={'white'} borderRadius={'2xl'} boxShadow={'md'} direction={'column'} color={'gray.600'} px={{ base: 4, md: 8 }} w={'full'} py={4}>
                    <Box w={'full'} textAlign={'justify'}>
                        <SimpleGrid columns={2} w={'full'}>
                            <Flex w='full' justify={'start'} textTransform={"capitalize"}>
                                <Box as={'span'} fontWeight={'semibold'} textTransform={"capitalize"}>City:</Box>
                                {notice?.cityName || "N/A"}
                            </Flex>
                            <Flex w={'full'} justify={'end'} fontWeight={'semibold'}>
                                <Box as={'span'}>Date:</Box>
                                {moment(notice.dateCreated).format("MMMM D YYYY")}
                            </Flex>
                        </SimpleGrid>
                        <Flex mt={4} w='full' justify={'start'} textTransform={"capitalize"}>
                            <Box as={'span'}>Area:</Box> {notice?.areaName || "N/A"}
                        </Flex>
                        <Flex my={4} w='full' fontSize={'20px'} fontWeight={'semibold'} justify={'center'} textTransform={'capitalize'}>
                            {notice.title}
                        </Flex>
                        <Text>
                            <div dangerouslySetInnerHTML={utils.sanitizeHtml(notice?.description)} />
                        </Text>
                    </Box>
                </Flex>
            )}
        </>
    );
};

export default Notice;
