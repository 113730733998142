import React from "react";
import {
    Box,
    Flex,
    Icon,
    Text,
     Heading,
} from "@chakra-ui/react";

import {TbRosetteNumber1, TbRosetteNumber2, TbRosetteNumber3, TbRosetteNumber4} from "react-icons/tb";

const KycAlertModal = ({isOpen}) => {

    return (
        < >
            <Text my={2} fontSize={"md"} alignText={'center'}>
                Welcome to our KYC (Know Your Customer) verification guide. Completing your KYC verification
                helps us ensure the security and integrity of our platform. This process involves several steps
                and requires you to provide specific documents.
                Please follow the instructions below carefully.
            </Text>
            <Text fontSize={"md"}>Please follow instructions below:</Text>
            <Flex align={"start"} gap={2} my={1}>
                <Icon as={TbRosetteNumber1} fontSize={'24px'} mt={1}  color="green.400" />
                <Text fontSize={"md"}><Box as="span" fontWeight="bold">Personal Information:</Box>Enter your personal information as they appear on your government-issued
                    ID Document.</Text>
            </Flex>
            <Flex align={"start"} gap={2} my={1}>
                <Icon as={TbRosetteNumber2} fontSize={'24px'} mt={1}  color="green.400" />
                <Text fontSize={"md"}><Box as="span" fontWeight="bold">Contact & Address Information:</Box>Provide your current address and contact details.Include your phone number and email address for us to contact you if needed.</Text>
            </Flex>
            <Flex align={"start"} gap={2} my={1}>
                <Icon as={TbRosetteNumber3} fontSize={'24px'} mt={1}  color="green.400" />
                <Text fontSize={"md"}><Box as="span" fontWeight="bold">Next of Kin Information:</Box>Enter the full name, relationship, and contact details of your next of kin.This information is necessary for emergency contact purposes.</Text>
            </Flex>
            <Flex align={"start"} gap={2} my={1}>
                <Icon as={TbRosetteNumber4} fontSize={'24px'} mt={1}  color="green.400" />
                <Text fontSize={"md"}><Box as="span" fontWeight="bold">Attachments:</Box> You will be required to provide proof identity and or proof of marital status.</Text>
            </Flex>
            <Box>
                <Heading size={'md'} color={'brand.500'}> Required Documents</Heading>
                <Text> Before you begin, ensure you have the following documents ready:</Text>

                <Flex align={"start"} gap={2} my={1}>
                    <Icon as={TbRosetteNumber1} fontSize={'24px'} mt={1}  color="green.400" />
                    <Text fontSize={"md"}><Box as="span" fontWeight="bold">Government-Issued ID Document:</Box> For Citizens a valid and Certified copy of Omang is required.Non-citizens must provide a certified copy of Passport.Ensure that these documents are valid and has your photo clearly visible.</Text>
                </Flex>
                <Flex align={"start"} gap={2} my={1}>
                    <Icon as={TbRosetteNumber2} fontSize={'24px'} mt={1}  color="green.400" />
                    <Text fontSize={"md"}><Box as="span" fontWeight="bold">Marriage Proof Document:</Box> If you married please provide FORM B if married in community of property, otherwise you are required to provide a certified copy of Marriage Ceritificate</Text>
                </Flex>


            </Box>

        </>

    );
};

export default KycAlertModal;
