import {
  Flex,
  Input,
  Stack,
  Icon,
  Image,
  Button,
  Box,
  Heading,
  useToast,
  FormLabel,
  FormControl,
  VStack,
  HStack,
  Link,
  InputGroup,
  InputRightElement,
  IconButton, Text, List, ListItem, ListIcon,

} from "@chakra-ui/react";
import React, { useState } from "react";
import { PropagateLoader } from "react-spinners";
import { CustomGeneralToast } from "../../../utils/customChakraToasts";
import { Link as RouterLink } from "react-router-dom";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { LiaUnlockAltSolid } from "react-icons/lia";
import Footer from "../../../components/Footer/PortalFooter/Footer";
import {API} from "../../../utils/api";
import utils from "../../../utils/commonFunctions";
import {FaCheckCircle, FaTimesCircle} from "react-icons/fa";
function ChangePassword() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [conPassword, setConPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showOld, setShowOld] = useState(false);
  const [lowerCaps, setLowerCaps] = useState(false);
  const [upperCaps, setUpperCaps] = useState(false);
  const [digit, setDigit] = useState(false);
  const [isLength, setIsLength] = useState(false);
  const [validPass, setValidPass] = useState(false);
const userId=utils.getUserId()
  const { addGeneralToast } = CustomGeneralToast();
  const toast = useToast();

  const handleClick = () => setShow(!show);
  const handleClickNew = () => setShowNew(!showNew);
  const handleClickOld = () => setShowOld(!showOld);
  const handleSetPassword = async (password) => {
    setNewPassword(password);
    let isValid = utils.validatePassword(password);
    setDigit(isValid.digit);
    setUpperCaps(isValid.uppercase);
    setLowerCaps(isValid.lowercase);
    setIsLength(isValid.isLength);
    setValidPass(isValid.isValid)

  };
  const handleChangePassword=async(event)=>{
    event.preventDefault()
    if (!oldPassword||!newPassword||!conPassword) {
      toast.closeAll();
      addGeneralToast({
        message: "All fields must be filled in",
        type: "warning",
      });
      setLoading(false);
      return;
    }

    if (!validPass) {
      setLoading(false);
      toast.closeAll();
      addGeneralToast({
        message: "Password does not meet minimum requirements", type: "error",
      });
      return;
    }
    if (newPassword !== conPassword) {
      setLoading(false);
      toast.closeAll();
      addGeneralToast({
        message: "Password does not match", type: "error",
      });
      return;
    }
    try {
      setLoading(true);
      const response = await API.put(`/auth/password/change`,
          {userId,oldPassword,newPassword});
      const message=response.data.message
      if (response.status === 200) {
        setLoading(false);
        toast.closeAll();
        addGeneralToast({
          message: message,
          type: "success",
        });
      } else {
        setLoading(false);
        toast.closeAll();
        addGeneralToast({
          message:message,
          type: "error",
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error resetting password:", error.response.data);
      toast.closeAll();
      addGeneralToast({
        message: error.response.data.message,
        type: "error",
      });
    }
  }


  return (
    <>
      <Flex
        flexDirection="column"
        alignItems="center" // Align children vertically
        justifyContent="center"
        px={{ base: "4", md: "10" }}
        py={10}
        mx="auto"
        bg={'white'}
        w={'full'}
        borderRadius={'2xl'}
      >
              <Flex align={"center"} justify="center" mb={8}>
                <VStack>
                  <Box
                    backgroundColor="blue.300"
                    borderRadius="50%"
                    width="100px"
                    height="100px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Icon as={LiaUnlockAltSolid} color="white" boxSize="50%" />
                  </Box>
                  <Heading color="blue.800" as="h6" size="lg">
                    Change Password
                  </Heading>
                  <Text textAlign={'center'} mt={4} fontSize={'sm'} w={{base:'full',md:'md'}}>
                    To change your password, please fill in the fields below. Your password must
                    contain at least 8 characters, it must also include at least one upper case letter, at least one lower case letter and, at least one number
                  </Text>
                </VStack>
              </Flex>
        <Flex direction={'column'} w={{base:'full',md:'md'}}>
          <form style={{width: '100%'}} onSubmit={handleChangePassword}>
            <FormControl mb={2} id="password">
              <FormLabel color="blue.800">Old Password</FormLabel>
              <InputGroup>
                <Input
                    type={showOld ? "text" : "password"}
                    name="password"
                    focusBorderColor="blue.300"
                    onChange={(e) => setOldPassword(e.target.value)}
                />
                <InputRightElement>
                  <IconButton
                      aria-label={showOld ? "Hide password" : "Show password"}
                      icon={
                        showOld ? (
                            <ViewOffIcon color={"gray.400"}/>
                        ) : (
                            <ViewIcon color={"gray.400"}/>
                        )
                      }
                      bg={'transparent'}

                      onClick={handleClickOld}
                      _active={{color: "#4699bc"}}
                      _hover={{bg: 'transparent'}}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl mb={2} id="password">
              <FormLabel color="blue.800">New Password</FormLabel>
              <InputGroup>
                <Input
                    type={showNew ? "text" : "password"}
                    name="password"
                    focusBorderColor="blue.300"
                    onChange={(e) => handleSetPassword(e.target.value)}
                />
                <InputRightElement>
                  <IconButton
                      aria-label={showNew ? "Hide password" : "Show password"}
                      icon={
                        showNew ? (
                            <ViewOffIcon color={"gray.400"}/>
                        ) : (
                            <ViewIcon color={"gray.400"}/>
                        )
                      }
                      bg={'transparent'}

                      onClick={handleClickNew}
                      _active={{color: "#4699bc"}}
                      _hover={{bg: 'transparent'}}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl mb={2} id="password">
              <FormLabel color="blue.800">Confirm Password</FormLabel>
              <InputGroup>
                <Input
                    type={show ? "text" : "password"}
                    name="password"
                    focusBorderColor="blue.300"
                    onChange={(e) => setConPassword(e.target.value)}
                />
                <InputRightElement>
                  <IconButton
                      aria-label={show ? "Hide password" : "Show password"}
                      icon={
                        show ? (
                            <ViewOffIcon color={"gray.400"}/>
                        ) : (
                            <ViewIcon color={"gray.400"}/>
                        )
                      }
                      bg={'transparent'}

                      onClick={handleClick}
                      _active={{color: "#4699bc"}}
                      _hover={{bg: 'transparent'}}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Stack spacing={6}> mt={8}
              <List textAlign={"start"} spacing={3} bg={"white"} borderRightRadius={'2xl'}>
                <ListItem>
                  <ListIcon
                      as={isLength ? FaCheckCircle : FaTimesCircle}
                      color={isLength ? "green.500" : "red.500"}
                  />
                  Password must contain atleast 8 characters
                </ListItem>
                <ListItem>
                  <ListIcon
                      as={upperCaps ? FaCheckCircle : FaTimesCircle}
                      color={upperCaps ? "green.500" : "red.500"}
                  />
                  Password must contain uppercase letter
                </ListItem>
                <ListItem>
                  <ListIcon
                      as={lowerCaps ? FaCheckCircle : FaTimesCircle}
                      color={lowerCaps ? "green.500" : "red.500"}
                  />
                  Password must contain lowercase letter
                </ListItem>
                <ListItem>
                  <ListIcon
                      as={digit ? FaCheckCircle : FaTimesCircle}
                      color={digit ? "green.500" : "red.500"}
                  />
                  Password must contain a digit
                </ListItem>
              </List>
            </Stack>
            <Stack spacing={6} mt={4}>
              {loading ? (
                  <Button bg={"blue.300"} _hover={{bg: "#00ace6"}}>
                    <PropagateLoader size={8} color="white"/>
                  </Button>
              ) : (
                  <Button
                      _hover={{
                        bg: "blue.300",
                        color: "white",
                        borderColor: "blue.800",
                      }}
                      colorScheme="brand"
                      type="submit"
                      variant="solid"
                  >
                    Change Password
                  </Button>
              )}

            </Stack>
          </form>

        </Flex>


      </Flex>
    </>
  );

};
export default ChangePassword;
