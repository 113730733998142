import React from "react";
import {
    Box, Heading, Flex, Text, Image, GridItem, Grid
} from "@chakra-ui/react";
import Senai from "../../../assets/Members/Members/Senai.JPG";
import Leonard from "../../../assets/Members/Members/Leonard.JPG";
import Mogodi from "../../../assets/Members/Members/Mogodi.JPG";
import director4 from "../../../assets/Members/Members/img.png";
import Ditsabatho from "../../../assets/Members/Members/Ditsabatho.JPG";
import Motlhanka from "../../../assets/Members/Members/Motlhanka.JPG";
import Moathodi from "../../../assets/Members/Members/Moathodi.JPG";
import Ziga from "../../../assets/Members/Members/Ziga.JPG";
import Tshiamiso from "../../../assets/Members/Members/Tshiamiso.JPG";
import Sedingwe from "../../../assets/Members/Members/Sedingwe.JPG";
const Member = ({name,position,image}) => (
    <>


            <Box display="flex" justifyContent="space-between">
                <Box
                    width={{base:"full",lg:'268px'}}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="244px"
                    p={6}
                    borderRadius="2xl"
                    boxShadow="md"
                    bg={'white'}

                >
                    <Image height="165px" width="224px" borderRadius="2xl" objectFit={'cover'} objectPosition={'0 -25px'}   src={image}/>
                    <Heading size="xs" fontWeight={'normal'} mt={2} color="blue.800" textAlign={'center'}>
                        {name}
                    </Heading>
                    <Text color="gray.500" fontWeight={'normal'} textAlign={'center'}>{position}</Text>
                </Box>
            </Box>



    </>
);
const BoardOfMembers = () => {
    const Directors = [
        {
            name: 'Mr Gaselemogwe Senai',
            position: 'Chief Executive Officer',
            image: Senai
        },
        {
            name: 'Mr Goitseone Tshiamiso',
            position: 'Chief Operations Officer',
            image: Tshiamiso
        },
        {
            name: 'Mr Inocent Leonard',
            position: 'Wastewater Director',
            image: Leonard
        },
        {
            name: 'Ms Felicity Ziga',
            position: 'Corporation Secretary',
            image: Ziga
        },
        {
            name: 'Mr Thabang Mogodi',
            position: 'Acting Chief Financial Officer',
            image: Mogodi
        },
        {
            name: 'Mr Teddy Ditsabatho',
            position: 'Water Resources Director',
            image: Ditsabatho
        },
        {
            name: 'Mr Ntshambiwa Moathodi ',
            position: 'Technical Services Director',
            image: Moathodi
        },
        {
            name: 'Mrs Leah Uananisa',
            position: 'Acting Chief information Officer',
            image: director4
        },
        {
            name: 'Ms Tebogo Mogolodi',
            position: 'Acting Customer Care Director',
            image: director4
        },

        {
            name: 'Mr Letlhokwa Motladiile',
            position: 'Acting Human Resources Director',
            image: director4
        },
        {
            name: 'Mr Peter  Sedingwe',
            position: 'Director: Strategy and Corporate Affairs',
            image: Sedingwe
        },
        {
            name: 'Ms Barulaganye Agnes Motlhanka',
            position: 'Internal Audit Director',
            image: Motlhanka
        },


    ]
    return (
        <>
            <Flex justify={'center'} align={'center'} bg={'white'} p={8} w={'full'}>
                <Box>
                    <Heading size="xs" mb={2} textAlign={'center'} color={'blue.800'}>Members</Heading>
                    <Heading size="lg" mb={4} textAlign={'center'} color={'blue.800'}>Corporate Management <Box as={'span'} color={'#0093D0'} >Team</Box> </Heading>
                    <Grid align={'center'} templateColumns='repeat(12, 1fr)' gap={4}>
                        {Directors.map((director, index) => (
                            <GridItem key={index} colSpan={{ base:12, md: 3 }} >
                                <Member name={director.name} position={director.position} image={director.image} />
                            </GridItem>
                        ))}
                    </Grid>
                </Box>
            </Flex>
        </>
    )
};
export default BoardOfMembers