import React from 'react';
import VerifyAccountComponent from './Components/VerifyAccount';
import AuthenticationContainer from '../../components/Authentication/AuthenticationContainer';

const VerifyAccount = () => {
  return (
    <AuthenticationContainer Children={ <VerifyAccountComponent />}/>

    
  );
}

export default VerifyAccount;
