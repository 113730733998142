import React, {useEffect, useState} from "react";
import {
    Box, Flex, Grid, GridItem, Heading, Text
} from "@chakra-ui/react";
import {Link, useNavigate} from "react-router-dom";
import kyc from "../../../assets/Customer Services/KYC.png";
import forms from "../../../assets/Customer Services/forms.png";
import standards from "../../../assets/Customer Services/Standards.png";
import faqs from "../../../assets/Customer Services/FAQ.png";
import survey from "../../../assets/Customer Services/surveys.png";
import dams from "../../../assets/Customer Services/FAQ.png";
import notices from "../../../assets/Customer Services/Notices.png";
import waste from "../../../assets/Customer Services/FAQ.png";
import meter from "../../../assets/Customer Services/Smart.png";
import tariffs from "../../../assets/Customer Services/Tarrifs.png";
import utils from "../../../utils/commonFunctions";


const CustomerServicesComponent = ({title,brief,url,image}) => {

    return (
    <>
        <Link to={url}>
            <Flex
                  borderRadius="2xl"
                  w="full"
                  h={{ base: '200px' }}
                  bgImage={`url(${image})`}
                  bgPosition="center"
                  bgRepeat="no-repeat"
                  boxShadow="lg"
                  bgSize="cover"
                  align="end"
                  color="white"
                  position="relative"
                  overflow="hidden"
                  _hover={{ '> .backdrop': { opacity: 1 } }} // Targeting child with class `backdrop` on hover
            >
                {/* Backdrop */}
                <Box
                    className="backdrop"
                    position="absolute"
                    top={0}
                    right={0}
                    bottom={0}
                    left={0}
                    bg="blackAlpha.400" // Semi-transparent black backdrop
                    opacity={0} // Initially hidden
                    transition="opacity 0.3s ease-in-out"
                />
                <Box bgBlendMode={'color-burn'} blur={'5px'} bg={'blackAlpha.500'} w={'full'}  p={4} >
                    <Heading fontSize={{base:'xl',lg:'3xl'}}>{title}</Heading>
                    <Text noOfLines={2}>{brief}</Text>
                </Box>
            </Flex>
        </Link>

    </>
  );
};
const PageComponent = ({title,brief,url,image,pageName,category}) => {
    return (
        <>
            <Link to={`/link-content/${pageName.toLowerCase().replace(/\s+/g, '-')}`} state={{pageName:pageName,category:category}} >
                <Flex
                    borderRadius="2xl"
                    w="full"
                    h={{ base: '200px' }}
                    bgImage={`url(${image})`}
                    bgPosition="center"
                    bgRepeat="no-repeat"
                    boxShadow="lg"
                    bgSize="cover"
                    align="end"
                    color="white"
                    position="relative"
                    overflow="hidden"
                    _hover={{ '> .backdrop': { opacity: 1 } }} // Targeting child with class `backdrop` on hover
                >
                    {/* Backdrop */}
                    <Box
                        className="backdrop"
                        position="absolute"
                        top={0}
                        right={0}
                        bottom={0}
                        left={0}
                        bg="blackAlpha.400" // Semi-transparent black backdrop
                        opacity={0} // Initially hidden
                        transition="opacity 0.3s ease-in-out"
                    />
                    <Box bgBlendMode={'color-burn'} blur={'5px'} bg={'blackAlpha.500'} w={'full'}  p={4} >
                        <Heading fontSize={{base:'xl',lg:'3xl'}}>{title}</Heading>
                        <Text noOfLines={2}>{brief}</Text>
                    </Box>
                </Flex>
            </Link>

        </>
    );
};

const PublicationsComponent = ({title,brief,image,categoryName}) => {

    const [categories, setCategories] = useState("")
    const navigate = useNavigate()
    const formatUrl = (input) => {
        return input
            .toLowerCase()
            .replace(/\s+/g, '-')
    };
    const getCategories = async () => {
        try {
            let categories = await utils.fetchPublicationsCategories({name:categoryName})
            setCategories(categories)
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        getCategories()
    }, []);
    const handleClick = (categoryId,categoryName, href) => {
        navigate(formatUrl(href), {state: {category: categoryId,name:categoryName}});
    };

    return (
        <>
            {categories && categories?.length > 0 && categories.map((category) => (
            <Box onClick={() => handleClick(category._id,category.name, `/publications/${category.name.toLowerCase()}`)}>
                <Flex
                    borderRadius="2xl"
                    w="full"
                    h={{ base: '200px' }}
                    bgImage={`url(${image})`}
                    bgPosition="center"
                    bgRepeat="no-repeat"
                    boxShadow="lg"
                    bgSize="cover"
                    align="end"
                    color="white"
                    position="relative"
                    overflow="hidden"
                    _hover={{ '> .backdrop': { opacity: 1 } }} // Targeting child with class `backdrop` on hover
                >
                    {/* Backdrop */}
                    <Box
                        className="backdrop"
                        position="absolute"
                        top={0}
                        right={0}
                        bottom={0}
                        left={0}
                        bg="blackAlpha.400" // Semi-transparent black backdrop
                        opacity={0} // Initially hidden
                        transition="opacity 0.3s ease-in-out"
                    />
                    <Box bgBlendMode={'color-burn'} blur={'5px'} bg={'blackAlpha.500'} w={'full'}  p={4} >
                        <Heading fontSize={{base:'xl',lg:'3xl'}}>{title}</Heading>
                        <Text noOfLines={2}>{brief}</Text>
                    </Box>
                </Flex>
            </Box>
            ))}
        </>
    );
};
const CustomerServices = ({}) => {
    return (<>
            <Grid
                templateColumns="repeat(12, 1fr)"
                gap={4}
                w={"100%"}
                justify={"center"}
                mt={4}
            >
                <GridItem colSpan={{base: 12, md: 6, lg: 3}}>
                    <PageComponent title={'Tariffs'} brief={""}  image={tariffs} category={'Your Water Account'} pageName={'Our Tariffs'}/>
                </GridItem>
                <GridItem colSpan={{base: 12, md: 6, lg: 3}}>
                    <CustomerServicesComponent title={'KYC'} brief={""} url={'/self-service-portal/know-your-customer'} image={kyc}/>
                </GridItem>
                <GridItem colSpan={{base: 12, md: 6, lg: 3}} >
                    <PageComponent title={'Standards'} brief={""} category={'Residential'} pageName={'WUC Service Standards'}  image={standards}/>
                </GridItem>
                <GridItem colSpan={{base: 12, md: 6, lg: 3}}>
                    <CustomerServicesComponent title={'FAQs'} brief={""} url={'/customer-services/faqs'} image={faqs}/>
                </GridItem>
                <GridItem colSpan={{base: 12, md: 6, lg: 3}}>
                    <CustomerServicesComponent title={'Survey'} brief={""} url={'/survey'} image={survey}/>
                </GridItem>

                <GridItem colSpan={{base: 12, md: 6, lg: 3}}>
                    <PageComponent title={'Dam Levels'} brief={""}  category={'Commercial'} pageName={'Dam Levels'}  image={dams}/>
                </GridItem>
                <GridItem colSpan={{base: 12, md: 6, lg: 3}}>
                    <CustomerServicesComponent title={'Notices'} brief={""} url={"/notices"} image={notices}/>
                </GridItem>
                <GridItem colSpan={{base: 12, md: 6, lg: 3}}>
                    <PageComponent title={'Waste Water '} brief={""} category={'Commercial'} pageName={'Waste Water Services'}  image={waste}/>
                </GridItem>
                <GridItem colSpan={{base: 12, md: 6, lg: 3}}>
                    <CustomerServicesComponent title={'Smart Metering'} brief={""} url={'https://sapweb.wuc.bw:18043/zwebapps/zsmart_met_ui5/index.html'} image={meter}/>
                </GridItem>
                <GridItem colSpan={{base: 12, md: 6, lg: 3}}>
                    <PublicationsComponent title={'Forms'} brief={""} url={'/forms'} image={forms} categoryName={'Forms'}/>
                </GridItem>
            </Grid>

    </>);
};
export default CustomerServices;
