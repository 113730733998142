import {
  Flex,
  Input,
  Stack,
  Icon,
  Text,
  Button,
  Box,
  Heading,
  useToast,
  FormLabel,
  FormControl,
  VStack,
  HStack,
  Link,
  InputGroup,
  InputRightElement,
  IconButton, useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { PropagateLoader } from "react-spinners";
import { CustomGeneralToast } from "../../../utils/customChakraToasts";
import { Link as RouterLink } from "react-router-dom";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { API } from "../../../utils/api";
import utils from "../../../utils/commonFunctions";
import { LiaUnlockAltSolid } from "react-icons/lia";
import enums from "wuc-library/enums";
import { useRedirectUrl } from "../../../providers/AuthProvider";
import { safePreventDefault } from "react-slick/lib/utils/innerSliderUtils";
import MigratedAlert from "./MigratedAlert";

function Login() {
  const { redirectUrl } = useRedirectUrl() || {};
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [isMigrated, setMigrated] = useState(false);
  const [message, setMessage] = useState(false);
  const { addGeneralToast } = CustomGeneralToast();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const handleClick = () => setShow(!show);

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      if (email === "" || password === "") {
        toast.closeAll();
        addGeneralToast({
          message: "Username and Password must be filled in",
          type: "warning",
        });
        setLoading(false);
        return;
      }

      const response = await API.post(`/auth/signin`, { email, password });
      if (response.status === 202) {
        await utils.forgotPassword(email)
        setLoading(false); // Stop loading here as well
        setMigrated(true)
        onOpen()
        setMessage(response.data.message)
        return;
      }

      if (response.status === 200 || response.status === 201) {
        const { token } = response.data;
        let role = utils.getUserRole(token);
        let isVerified = utils.getIsVerified(token);
        let requirePasswordChange = utils.getRequirePasswordChange(token);

        if (role !== enums.UserRole.USER) {
          toast.closeAll();
          addGeneralToast({
            message: "Invalid Credentials",
            type: "error",
          });
        } else {
          if (token) {
            localStorage.setItem("userToken", token);
          }
          if (isVerified && !requirePasswordChange) {
            localStorage.setItem("authToken", "Bearer " + token);
            localStorage.removeItem("userToken");
            if (redirectUrl) {
              window.location.href = redirectUrl;
            } else {
              window.location.href = "/self-service-portal/dashboard";
            }
          } else if (!isVerified) {
            await utils.requestNewOTP(email, enums.OTPUsage.REGISTRATION);
            navigate("/verify-account", { replace: true });
          } else {
            navigate("/reset-password", { replace: true });
          }
        }
      }
    } catch (e) {
      console.log(e);
        toast.closeAll();
        addGeneralToast({
          message: e?.message || "An unexpected error occurred.",
          type: "error",
        });
      utils.sendMessageToBugNinja("LOGIN ERROR ", e.message);
    } finally {
      setLoading(false); // Ensure loading is stopped regardless of outcome
    }
  };

  return (
      <>
        {isMigrated&&<MigratedAlert isOpen={isOpen} onClose={onClose} message={message}/>}

        <Stack
            maxW={{ base: "full", md: "md" }}
            minW={{ base: "full", md: "md" }}
            bg={"white"}
            p={8}
            borderRadius={"xl"}
            boxShadow={"md"}
            direction={{ base: "column", md: "row" }}
        >
          <Stack spacing={4} w={"full"}>
            <Flex align={"center"} justify="center">
              <VStack>
                <Box
                    backgroundColor=" #0093D0"
                    borderRadius="50%"
                    width="50px"
                    height="50px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                  <Icon as={LiaUnlockAltSolid} color="white" boxSize="50%" />
                </Box>
                <Heading color="blue.800" as="h6" size="lg">
                  Welcome
                </Heading>
                <Text color={"blue.800"} mt={-1} fontSize={"lg"}>
                  Sign in to your account
                </Text>
              </VStack>
            </Flex>
            <form onSubmit={handleLogin}>
              <FormControl id="email">
                <FormLabel color="blue.800">Email</FormLabel>
                <InputGroup>
                  <Input
                      type={"email"}
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      borderRadius={"2xl"}
                      focusBorderColor="blue.300"
                  />
                </InputGroup>
              </FormControl>
              <FormControl id="password">
                <FormLabel color="blue.800">Password</FormLabel>
                <InputGroup>
                  <Input
                      type={show ? "text" : "password"}
                      name="password"
                      focusBorderColor="blue.300"
                      onChange={(e) => setPassword(e.target.value)}
                      borderRadius={"2xl"}
                  />
                  <InputRightElement>
                    <IconButton
                        aria-label={show ? "Hide password" : "Show password"}
                        icon={
                          show ? <ViewOffIcon color="blue.300" /> : <ViewIcon color="gray.300" />
                        }
                        onClick={handleClick}
                        bg="transparent"
                        _hover={{ bg: "transparent" }}
                        _active={{ bg: "transparent" }}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <HStack spacing={40} my={2} justifyContent="end" alignItems="center">
                <RouterLink to="/forgot-password">
                  <Link color="#0093D0" _hover={{ textDecoration: "none" }}>
                    Forgot Your Password?
                  </Link>
                </RouterLink>
              </HStack>
              <Stack spacing={6}>
                <Button
                    _hover={{
                      bg: "blue.300",
                      color: "white",
                    }}
                    color="white"
                    bg=" #0093D0"
                    type="submit"
                    variant="solid"
                    borderRadius={"2xl"}
                >
                  {loading ? (
                      <>
                        <PropagateLoader size={8} color="white" />
                      </>
                  ) : (
                      <Text>Sign in</Text>
                  )}
                </Button>

                <HStack justifyContent="center" alignItems="center">
                  <Text color={"blue.800"}>Don't have an account?</Text>
                  <RouterLink to="/register">
                    <Link color="#0093D0" _hover={{ textDecoration: "none" }}>
                      Register
                    </Link>
                  </RouterLink>
                </HStack>
              </Stack>
            </form>
          </Stack>
        </Stack>
      </>
  );
}

export default Login;
