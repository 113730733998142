import React from "react";
import RegisterComponent from "./Components/Register";
import AuthenticationContainer from "../../components/Authentication/AuthenticationContainer";

const Register = () => {
  return (
      <AuthenticationContainer Children={ <RegisterComponent />}/>
  );
};

export default Register;