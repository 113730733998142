import SelfServiceContainer from "../../components/Containers/SelfServiceContainer";
import React from "react";

import SubmitMeterReadingFormGuest from "./Components/SubmitMeterReadingFormGuest";
const SubmitMeterReadingGuest = () => {
  return (
      <SelfServiceContainer childComponent={<SubmitMeterReadingFormGuest/>}/>
  );
};

export default SubmitMeterReadingGuest;
