import {
    Input,
    Stack,
    Button,
    FormLabel,
    FormControl,
    HStack,
    InputGroup,
    useToast,
    Select,
    Text,
    Switch,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { PropagateLoader } from "react-spinners";
import { CustomGeneralToast } from "../../../utils/customChakraToasts";
import utils from "../../../utils/commonFunctions";
import {API} from "../../../utils/api";
import ViewBalanceModal from "./ViewBalanceModal";
import PaymentSummaryModal from "../../../components/PayGate/PaymentSummaryModal";
import BillAlert from "../../../components/PortalContainer/BillAlert";
import { useUserProfile } from "../../../providers/UserProfileProvider";
import ResponseModal from "../../../components/PayGate/ResponseModal";
import {useQuoteCart} from "../../../providers/QuoteCartProvider";

const CheckBill = () => {
    const userId = utils.getUserId();
    const [customer,setCustomer]=useState(null)
    let {userProfile}=useUserProfile()
    let {personalDetails,contactDetails,customerNumber}=userProfile||{}
    const {billBalance,setBillBalance}=useQuoteCart()
    const [name, setName] = useState("");
    const [cell, setCell] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState(customerNumber||"");
    const [utility, setUtility] = useState("");
    const [utilityContracts, setUtilityContracts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isViewBalanceModalOpen, setIsViewBalanceModalOpen] = useState(false);
    const [viewResponseModalOpen, setViewResponseModalOpen] = useState(false);
    const [isPaymentSummaryModalOpen, setIsPaymentSummaryModalOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [checkForOthers, setCheckForOthers] = useState(false);
    const [balance, setBalance] = useState(0);
    const {refreshFlag,setRefreshFlag}=useQuoteCart()
    const { addGeneralToast } = CustomGeneralToast();
    const toast = useToast();

    // get query params from url
    const [reference, setReference] = useState("");
    const [statusCode, setStatusCode] = useState();
    const [reason, setReason] = useState();

    useEffect(() => {

        // get query params from url
        const urlParams = new URLSearchParams(window.location.search);
        // http://localhost:3000/self-service-portal/pay-bill-online?reference=%223MtlqLMI%22&code=200

        setStatusCode(urlParams.get("code"));
        setReference(urlParams.get("reference"));
        setReason(urlParams.get("reason"));

        if (urlParams.has("reference") && urlParams.has("code")) {
            setViewResponseModalOpen(true);
        }
        const getUtilityContracts = async () => {
            setUtilityContracts(await utils.getUtilityContractsByCustomerNumber(customerNumber));
        };
        if (!checkForOthers && customerNumber !== "") {
            getUtilityContracts();
        }
    }, []);

    useEffect(()=>{
        if(!checkForOthers && customerNumber){
            setCustomer({
                fullName:personalDetails?.firstName+" "+personalDetails?.lastName,
                phone:contactDetails?.cell,
                customerNumber:customerNumber,
                contractNumber:utility,
                customerId:userId,
                email:contactDetails?.email
            })
        }else{
            setCustomer({
                fullName:name,
                phone:cell,
                email:email,
                customerId:userId,
                customerNumber:number,
                contractNumber:utility
            })
        }

    },[name,cell,email,number,utility,userProfile])
    useEffect(() => {
        if(!checkForOthers){
            setCustomer({
                fullName:personalDetails?.firstName+" "+personalDetails?.lastName,
                phone:contactDetails?.cell,
                customerNumber:customerNumber,
                contractNumber:"",
                customerId:userId,
                email:contactDetails?.email
            })
        }else{
            setCustomer({
                fullName:"",
                phone:"",
                email:"",
                customerId:userId,
                customerNumber:"",
                contractNumber:""
            })
        }
    }, [checkForOthers]);
    const customerMap={
        fullName:"Customer Name",
        phone: "Customer Cell Number",
        email:"Customer Email",
        customerNumber:"Customer Number",
        contractNumber:"Utility Number",

    }
    function checkCustomerIsValid(customer) {
        for (let key in customer) {
            if (customer[key] === null || customer[key] === undefined || customer[key] === "") {
                toast.closeAll();
                addGeneralToast({
                    message: `${customerMap[key]} is a required field`,
                    type: "warning",
                });
                setLoading(false);
                return false;
            }
        }
        return true
    }

    const getBillAmount = async () => {

        if(! checkCustomerIsValid(customer)){
            return
        }
        setShow(false);
        setLoading(true);
        try {
            const response = await API.post(
                `/meter/balance-check`,
                {
                    customerNumber: number,
                    contractNumber: utility,
                    createdBy: userId||email,
                }
            );

            const data = response.data;
            if (response.status === 200) {
                setBalance(Number(data.balance));
                setShow(true);
                setLoading(false);
                setIsViewBalanceModalOpen(true);
                setRefreshFlag(true)

            } else {
                handleErrorResponse(data.message);
            }
        } catch (e) {
            await utils.sendMessageToBugNinja('ERROR FETCHING ACCOUNT BALANCE', e.message, e);
            handleErrorResponse(e?.message ? e.message : "Could not fetch customer account balance.Please contact administrator");
        }
    };

    const handleErrorResponse = (message) => {
        setLoading(false);
        setBalance(0);
        toast.closeAll();
        addGeneralToast({
            message,
            type: "error",
        });
        setShow(false);
    };

    const closeViewBalanceModal = () => {
        setIsViewBalanceModalOpen(false);
    };
    const closePaymentSummaryModal = () => {
        setIsPaymentSummaryModalOpen(false);
    };
    const updatePaymentSummaryModal = (newState) => {
        setIsPaymentSummaryModalOpen(newState);
    };

    return (
        <>
            <Stack spacing={1} justify="center" px={4} w="full" h="full">
                <BillAlert />
                {checkForOthers && (
                    <>
                        <FormControl isRequired id="customerName">
                            <FormLabel>Customer Name</FormLabel>
                            <InputGroup>
                                <Input
                                    type="text"
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </InputGroup>
                        </FormControl>
                        <FormControl isRequired id="customerCell">
                            <FormLabel>Customer Cell Number</FormLabel>
                            <InputGroup>
                                <Input
                                    type="text"
                                    onChange={(e) => setCell(e.target.value)}
                                />
                            </InputGroup>
                        </FormControl>
                        <FormControl isRequired id="email">
                            <FormLabel>Customer Email</FormLabel>
                            <InputGroup>
                                <Input
                                    type="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </InputGroup>
                        </FormControl>
                    </>
                )}

                <FormControl isRequired id="customerNumber">
                    <FormLabel>Customer Number</FormLabel>
                    <Input
                        isRequired
                        type="number"
                        value={!utils.isAuthenticated() || checkForOthers ? undefined : number}
                        onChange={(e) => setNumber(e.target.value)}
                        isDisabled={!utils.isAuthenticated() || !checkForOthers}
                    />
                </FormControl>

                <FormControl isRequired id="contractNumber">
                    <FormLabel>Utility Number</FormLabel>
                    {checkForOthers ? (
                        <Input
                            isRequired
                            type="number"
                            onChange={(e) => setUtility(e.target.value)}
                        />
                    ) : (
                        <Select
                            isRequired
                            placeholder="Select Utility"
                            onChange={(e) => setUtility(e.target.value)}
                        >
                            {utilityContracts?.length > 0 && utilityContracts.map((contract) => (
                                <option key={contract?.contractNumber} value={contract?.contractNumber}>
                                    {contract?.contractNumber}
                                </option>
                            ))}
                        </Select>
                    )}
                </FormControl>

                <Stack spacing={6} mt={2}>
                    {loading ? (
                        <Button variant="solid">
                            <PropagateLoader size={8} color="white" />
                        </Button>
                    ) : (
                        <Button
                            type="submit"
                            variant="solid"
                            onClick={getBillAmount}
                        >
                            Get Bill Amount
                        </Button>
                    )}
                </Stack>

                {utils.isAuthenticated() && (
                    <HStack mt={2} justifyContent="center" alignItems="center">
                        <Text color="blue.800">Check Bill For Others</Text>
                        <Switch id='bill-for-others' onChange={(e) => setCheckForOthers(e.target.checked)} />
                    </HStack>
                )}
            </Stack>

            {isViewBalanceModalOpen && (
                <ViewBalanceModal
                    isOpen={isViewBalanceModalOpen}
                    onClose={closeViewBalanceModal}
                    balance={balance}
                    onUpdatePaymentSummaryModal={updatePaymentSummaryModal}
                />
            )}

            {isPaymentSummaryModalOpen && (
                <PaymentSummaryModal
                    isOpen={isPaymentSummaryModalOpen}
                    onClose={closePaymentSummaryModal}
                    balance={billBalance}
                    customerNo={customer?.customerNumber}
                    contractNumber={customer?.contractNumber}
                    paymentType={'BillPayment'}
                    customer={customer}
                />
            )}

            {viewResponseModalOpen && (
                <ResponseModal
                    reference={reference}
                    statusCode={statusCode}
                    reason={reason}
                />
            )}
        </>
    );
};

export default CheckBill;
