import React from "react";
import CustomerServicesComponent from "./Components/LinkPages";
import PublicViewContainer from "../../components/Containers/PublicViewContainer";


const LinkPages = ({}) => {
    return (<>
      <PublicViewContainer childComponent={<CustomerServicesComponent/>}/>
    </>);
};

export default LinkPages;
