import React from "react";
import { Box } from "@chakra-ui/react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import TopBanner from "../BottomBanner/TopBanner";
import image1 from "../../assets/bgn-industries.png";

const PublicViewContainer = ({ childComponent }) => {
    return (
        <>
            <Header />
            <TopBanner />
            <Box
                px={{ base: 4, lg: 48 }}
                py={{ base: 8, lg: 20 }}
                objectFit={'fit'}
                bg={'gray.50'}
                bgPosition="center"
                bgRepeat="no-repeat"
                bgSize="cover"
            >
                {childComponent}
            </Box>
            <Footer />
        </>
    );
};

export default PublicViewContainer;
