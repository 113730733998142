import {
    Flex,
    Stack,
    Button,
    Thead,
    Tr,
    Th,
    Table,
    TableContainer, Tbody, Td, Heading, Spacer, IconButton, Tooltip, TagLabel, Tag, useDisclosure,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";

import {Link} from "react-router-dom";


import {FaCircleXmark, FaPlus} from "react-icons/fa6";
import ManageUtilityModal from "./ManageUtilityModal";
import utils from "../../../utils/commonFunctions";
import Paginate from "../../../components/Paginate/Paginate";
import {useQuoteCart} from "../../../providers/QuoteCartProvider";
import {HashLoader} from "react-spinners";

const ManageUtilityTable = () => {
    const userId=utils.getUserId()
    const [utility, setUtility] = useState([]);
    const [loading, setLoading] = useState(true);
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [currentPage, setCurrentPage] = useState(1);
    const {refreshFlag,setRefreshFlag}=useQuoteCart()
    const getUtility = async () => {
        let contracts=await utils.getUtilityContracts(
            {
                userId:userId,
                pageNumber: currentPage,
                pageSize: 4
            }
        )
       setUtility(contracts)
        setRefreshFlag(false)
    };

    useEffect(() => {
        // Simulate a loading time
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000); // 2 seconds loading time
        return () => clearTimeout(timer);
    }, [])

    useEffect(() => {
        getUtility()
    }, [currentPage,loading,refreshFlag]);

    return <>
        <Stack  gap={0} justify={'center'} py={8} px={{base:2,lg:8}} bg={'white'} boxShadow="md"  w="full"  borderRadius={'2xl'} >
            <Flex align={'center'} w={"full"}  py={2.5} px={{base:2,lg:8}}>
                <Heading color={'blue.800'} fontSize={'xl'}>Utility Contracts List</Heading>
                <Spacer/>
                <Button onClick={onOpen} variant={'solid'} leftIcon={<FaPlus fontSize={'lg'} fontWeight={'bold'}/>}>Add
                    Utitlity</Button>
            </Flex>
            <TableContainer w="full" h={"full"}  px={{base:2,lg:8}}>
                <Table colorScheme='gray'>
                    <Thead>
                        <Tr>
                            <Th color={'blue.800'} textTransform={'capitalize'} fontSize={'sm'}>Name</Th>
                            <Th color={'blue.800'} textTransform={'capitalize'} fontSize={'sm'}>Customer No</Th>
                            <Th color={'blue.800'} textTransform={'capitalize'} fontSize={'sm'}>Utitlity No</Th>
                            <Th color={'blue.800'} textTransform={'capitalize'} fontSize={'sm'}>Plot No</Th>
                            <Th color={'blue.800'} textTransform={'capitalize'} fontSize={'sm'}>Status</Th>
                            {/**<Th color={'blue.800'} textTransform={'capitalize'} fontSize={'sm'}>Action</Th>**/ }
                        </Tr>
                    </Thead>
                    {!loading&&
                        <>
                        {utility?.docs?.length > 0 &&
                            <Tbody>
                                {utility?.docs?.map((contract, index) => (
                                    < Tr key={index}>
                                        <Td>{contract?.name}</Td>
                                        <Td>{contract?.customerNumber}</Td>
                                        <Td>{contract?.contractNumber}</Td>
                                        <Td>{contract?.plot}</Td>
                                        <Td>
                                            <Tag size='lg' colorScheme= {contract?.status==="active"?'green':'red'} borderRadius='full'>
                                                <TagLabel>{contract?.status}</TagLabel>
                                            </Tag>
                                        </Td>
                                        {/**
                                            <Td>
                                                {contract?.status==="active"?
                                                    <Tooltip label={'Request For  Disconnection'}>
                                                        <Link to={`disconnection?utilityContractNumber=${contract.utilityContractNumber}&id=${contract._id}`}>
                                                            <IconButton
                                                                bg={'red.500'}
                                                                _hover={{bg: 'red.600'}}
                                                                icon={<FaCircleXmark/>}
                                                            />
                                                        </Link>
                                                    </Tooltip>

                                                    :
                                                    !                                                <Tooltip label={'Request For  Disconnection'}>
                                                        <IconButton

                                                            bg={'gray.500'}
                                                            icon={<FaCircleXmark/>}
                                                        />

                                                    </Tooltip>
                                                }
                                            </Td>**/
                                        }

                                    </Tr>
                                ))}
                            </Tbody>
                        }
                    </>
                    }
                    
                </Table>
                {loading&&
                    <Flex justifyContent="center" align={'center'} width="full" h={'full'}>
                    <HashLoader color="#2bbcf2"/>
                    </Flex>
                }
                {utility?.length<=0&&
                    <Flex justifyContent="center" align={'center'} width="full" h={'full'}>
                        <Heading size={'lg'} color={'gray.500'}>No Utility Contracts</Heading>
                    </Flex>
                }
            </TableContainer>
            { (!utility||utility.docs?.length <=0) &&
                <Flex justifyContent="center" align="center" w="full" h="full" py={16}>
                    <Heading size="lg" color="gray.500">No Record Found</Heading>
                </Flex>
            }
            <Paginate
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                numberOfPages={utility?.totalPages||1}
            />

        </Stack>
        <ManageUtilityModal isOpen={isOpen} onClose={onClose}/>
    </>
        ;
};

export default ManageUtilityTable;
