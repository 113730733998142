import React, {useEffect, useState} from 'react';
import {Box, Flex, Heading, HStack, Icon, Image, Spacer, Text, VStack} from '@chakra-ui/react';
import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

// Import local images
import image1 from '../../assets/latest-news.jpg';

import {CgArrowLongRight} from "react-icons/cg";
import utils from "../../utils/commonFunctions";
import moment from "moment";
import {Link, useNavigate} from "react-router-dom";

const NewsCard = ({news}) => {
    const navigate=useNavigate()
    const handleNavigate = (news) => {
        navigate(`/news/view/${news.title.toLowerCase().replace(/\s+/g, '-')}`, { state: {news} });
    };
    return (
        <VStack spacing={0}>
            <Image src={news?.images?.[0]||image1} w={"full"} h={'164px'}/>
            <VStack
                width='full'
                height='164px'
                borderTop='5px solid #0093d0'
                align='left'
                pt={2}
            >
                <Text color='#0093d0' fontSize='xl' textAlign='left' fontWeight='bold'>
                    {news?.title}
                </Text>
                <Text color='black' fontSize='14px' textAlign='left' noOfLines={1}>
                    <div dangerouslySetInnerHTML={utils.sanitizeHtml(news?.description)}/>
                </Text>
                <HStack spacing={4} pt={2}>
                    <Text color='#0093d0' align='left' fontSize='12px' fontWeight='bold'>
                        {moment(news?.datePublished).format("MMMM D YYYY")}
                    </Text>
                    <Spacer/>

                        <Text
                            onClick={()=>handleNavigate(news)}
                            color='black'
                            textDecoration='underline'
                            fontSize='12px'
                            pl={10}
                            _hover={{
                                cursor: 'pointer',
                            }}
                        >
                            See more
                        </Text>

                        <Icon as={CgArrowLongRight}
                              _hover={{
                                  cursor: 'pointer',
                              }}
                              onClick={()=>handleNavigate(news)}/>


                </HStack>
            </VStack>
        </VStack>
    );
};

const News = () => {
    const [news,setNews]=useState([])
    useEffect(() => {
        async function getNews() {
            let fetchedNews= await utils.fetchNews()
            if(fetchedNews?.length) {setNews(fetchedNews)}
        }
        getNews()
    }, []);
    return (
        <Box mt={'100px'} bg='#F7FAFC' display='flex' align='center' justify='center' w={'full'}>
            <Box bg='white' w='100%' h='auto' my={4} boxShadow='md'>
                <VStack pt={4}>
                    <VStack py={8}>
                        <Heading size="xl"  textAlign={'center'} color={'brand.700'}>News </Heading>
                    </VStack>
                </VStack>

              <Box mx={{base:8,lg:32}} py={8}  maxW={{base:"lg",lg:'8xl'}}>
                  {news?.length?
                      <Box>
                          <Splide
                              options={{
                                  type: 'loop',
                                  gap: '1rem',
                                  autoplay: true,
                                  drag:"free",
                                  arrows:false,
                                  pauseOnHover: false,
                                  resetProgress: false,
                                  perPage: 4,
                                  breakpoints: {
                                      768: {
                                          perPage: 1,
                                      },
                                      1024: {
                                          perPage: 2,
                                      },
                                  },
                              }}
                          >
                              {news?.length&&news.map((news, index) => (
                                  <SplideSlide key={index}>
                                      <NewsCard news={news}/>
                                  </SplideSlide>
                              ))}
                          </Splide>
                          <Link textAlign={'center'}  to={'/news'}><Text color={'brand.700'}>See all</Text></Link>
                      </Box>
                      :
                      <Text justifyContent={'center'} align={'center'} fontWeight={'semibold'} color={'gray.500'} fontSize={'2xl'}>
                          No Latest News
                      </Text>
                  }

              </Box>

            </Box>
        </Box>
    );
};

export default News;
