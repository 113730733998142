import React, { useState, useEffect } from "react";
import {
    Box, Divider, Flex, FormControl, FormLabel, Grid, GridItem, Heading, Input, InputGroup, Text, useToast,
} from "@chakra-ui/react";
import { CustomGeneralToast } from "../../../utils/customChakraToasts";
import {useUserProfile} from "../../../providers/UserProfileProvider";
import utils from "../../../utils/commonFunctions";
import {AreaInput, CityInput} from "../../../components/Applications/Forms/Inputs";



const ContactDetails = () => {



    let {userProfile}=useUserProfile()
    let {contactDetails,address}=userProfile||{}
    const form = JSON.parse(localStorage.getItem('address')) || {};
    if(userProfile&&!form?.cell){
        localStorage.setItem('address',JSON.stringify({...address,...contactDetails}))
    }


    const [formData, setFormData] = useState({
        cell: form?.cell || null,
        homeTel: form?.homeTel || null,
        workTel: form?.workTel || null,
        email: form?.email || utils.getUserEmail(localStorage.getItem("authToken")),
        plotNo: form?.plotNo || null,
        postal: form?.postal || null,
        ward: form?.ward || null,
        street: form?.street || null,
        city: form?.city || null,
        district: form?.district || null,
    });

    // Effect to handle localStorage update on formData change
    useEffect(() => {
        localStorage.setItem('address', JSON.stringify(formData));
        const { workTel,homeTel, ...other }=formData
        if(Object.values(other).some(detail => detail === null||detail === ""||!detail)){
            localStorage.setItem('isValidForm',false );
        }
        else{
            localStorage.setItem('isValidForm',true );
        }


    }, [formData]);
    const handleInputChange = (e) => {
        const {name, value,id} = e.target;
        setFormData(prevState => ({
            ...prevState, [name]: value
        }));
    };

    return (
        <>
            <Box>
                <Flex mb={4}>
                    <Box>
                        <Heading color={'brand.500'}>Contact Details</Heading>
                        <Text color={'gray.500'}>Please provide your contact details for future communication and verification.</Text>
                    </Box>
                </Flex>
                <Grid templateColumns="repeat(12, 1fr)" gap={2} color={"blue.800"}>
                    {/* Cell */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired id="cell">
                            <FormLabel >Cell</FormLabel>
                            <InputGroup>
                                <Input type="number" name="cell" id='Cellphone Number' value={formData.cell} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                    {/* Tel */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl  id="tel">
                            <FormLabel >Home Tel</FormLabel>
                            <InputGroup>
                                <Input type="number" name="homeTel" id='Home Telephone Number' value={formData.homeTel} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>

                    {/* Tel */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl  id="tel">
                            <FormLabel >Work Tel</FormLabel>
                            <InputGroup>
                                <Input type="number" name="workTel" id='Work Telephone Number' value={formData.workTel} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                    {/* Email */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired id="email">
                            <FormLabel >Email</FormLabel>
                            <InputGroup>
                                <Input isDisabled  type="email" name="email" id={'Email Address'}  value={formData.email} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                    <GridItem my={4} colSpan={12}>
                        <Heading fontSize={'md'}>Address Info</Heading>
                        <Divider />
                    </GridItem>
                    {/* Plot No */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired id="plotNo">
                            <FormLabel >Plot No</FormLabel>
                            <InputGroup>
                                <Input type="text" name="plotNo" id={'Plot No'} value={formData.plotNo} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                    {/* Plot No */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired id="plotNo">
                            <FormLabel >Postal Address</FormLabel>
                            <InputGroup>
                                <Input type="text" name="postal"  id="Postal Address"  value={formData.postal} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                    {/* City / Town / Village */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <CityInput callback={handleInputChange} value={formData.city}/>
                    </GridItem>
                    {/* Area / Ward */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        {console.log(formData?.city)}
                        <AreaInput callback={handleInputChange} value={formData.ward} cityName={formData?.city}/>
                    </GridItem>
                    {/* District */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired id="district">
                            <FormLabel >District</FormLabel>
                            <InputGroup>
                                <Input type="text" name="district" id="District" focusBorderColor="blue.300" value={formData.district} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                    {/* Street / Road Name */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired id="street">
                            <FormLabel >Street / Road Name</FormLabel>
                            <InputGroup>
                                <Input type="text" name="street" id={'Street / Road Name'} value={formData.street} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                </Grid>
            </Box>
        </>
    );
};

export default ContactDetails;
