import React from "react";

import {
    Badge,
    Box, Button,

    Heading,
    Icon,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList, Text,
    Tooltip,
    VStack
} from "@chakra-ui/react";
import {MdNotifications} from "react-icons/md";


const CustomMenuButton = ({icon, color, bg, tip, url,onClick,showBadge}) => {
    return (
        <>
            <Tooltip label={tip} fontSize={['xs']} bg={'gray.600'} fontWeight={'light'} border={'2xl'}>
                <VStack bg={`${bg}`} color={`${color}`} boxShadow={'md'} p={2} borderRadius={'2xl'}
                        _hover={{bg: `${color}`, color: `${bg}`, transform: 'scale(1.1)'}} cursor={'pointer'}
                        transition="background-color 0.25s ease-in-out, color 0.25s ease-in-out, transform 0.25s ease-in-out">

                    {url&&
                    <Link href={url} textDecoration={"none"} display="flex" alignItems="center"
                          justifyContent="center">
                        <Icon as={icon} boxSize={'24px'}/>
                    </Link>}
                    {!url&&!onClick&&
                        <Link href={url} textDecoration={"none"} display="flex" alignItems="center"
                              justifyContent="center">
                            <Icon as={icon} boxSize={'24px'}/>
                        </Link>}

                    {onClick && (
                        <Link as="button" onClick={onClick} textDecoration="none" display="flex" alignItems="center" justifyContent="center">
                            <Icon as={icon} boxSize="24px" />
                        </Link>
                    )}

                </VStack>
            </Tooltip>

        </>
    )

}
const CustomMenu = ({icon, color, bg, tip, url}) => {
    return (
        <>
            <Menu>
                <Tooltip label={tip} fontSize={['xs']} bg={'gray.600'} fontWeight={'light'} border={'2xl'}>
                    <MenuButton>
                        <CustomMenuButton icon={icon} color={'brand.500'} bg={'white'} tip={'notifications'}/>
                    </MenuButton>
                </Tooltip>
                <MenuList w={['450px']} borderRadius={'2xl'} borderColor={'white'} mt={[4]} p={[4]}>
                    <MenuItem display={'flex'} alignItems={'center'} bg={'transparent'} _hover={{bg: 'gray.50'}}
                              borderRadius={'2xl'}>
                        <Box display={'flex'} alignItems={'center'} bg={'brand.500'} p={2} borderRadius={'2xl'}>
                            <Icon as={MdNotifications} color={'white'} boxSize={'32px'}/>
                        </Box>
                        <Box textAlign={'start'} p={2} borderRadius={'2xl'}>
                            <Heading fontSize={'md'} color={'blue.800'} fontWeight={'bold'}>Monthly Bill Request</Heading>
                            <Text fontSize={'sm'} color={'gray.600'} fontWeight={'bold'}>Request for your monthly bill succesfull.Please check your email</Text>
                        </Box>
                    </MenuItem>
                    <MenuItem display={'flex'} alignItems={'center'} bg={'transparent'} _hover={{bg: 'gray.50'}}
                              borderRadius={'2xl'}>
                        <Box display={'flex'} alignItems={'center'} bg={'brand.500'} p={2} borderRadius={'2xl'}>
                            <Icon as={MdNotifications} color={'white'} boxSize={'32px'}/>
                        </Box>
                        <Box textAlign={'start'} p={2} borderRadius={'2xl'}>
                            <Heading fontSize={'md'} color={'blue.800'} fontWeight={'bold'}>Monthly Bill Request</Heading>
                            <Text fontSize={'sm'} color={'gray.600'} >Request for your monthly bill succesfull.Please check your email</Text>
                        </Box>
                    </MenuItem>
                </MenuList>

            </Menu>
        </>
    );
};

export {CustomMenu, CustomMenuButton};
