import React from 'react';
import NewConnectionForm from './Components/NewConnectionForm';
import PortalContainer from '../../components/PortalContainer/PortalContainer';
import KycAlertModal from "../KnowYourCustomer/Components/Modals/KycAlertModal";



const NewConnection= () => {
    return <>
        <KycAlertModal />
        <PortalContainer Title={"New Connection"} childComponent={<NewConnectionForm/>}/>
    </>;
};

export default NewConnection;
