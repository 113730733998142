import React from "react";
import {Box, Button, Flex, Heading,  Icon, Image, Text, VStack} from "@chakra-ui/react";
import WUCLogo from "../../assets/BlueLogoNoBG.png";
import {FaFacebook, FaInstagram, FaTwitter, FaWhatsapp} from "react-icons/fa";


const Contact = () => {
    return (
        <>
            <Box colSpan={4} bg="white"  boxShadow={'md'} borderRadius={'2xl'}  pb={4} >
                <Flex position="relative" justifyContent="center" alignItems="center" w="full" h="140px" bg="gray.50" borderTopRadius="2xl">
                    {/* The container for the logo */}
                    <Box
                        position="absolute"
                        bottom="-60px" // Adjust so the logo overlaps the bottom edge as needed
                        w="full"
                        display="flex"
                        justifyContent="center"
                        zIndex="2" // Ensure the logo is above all other content
                    >

                        <Image
                            src={WUCLogo} // Replace with your logo's import
                            alt="Water Utilities Logo"
                            boxSize="140px" // Adjust the size as needed
                            objectFit="contain"
                            bg={'white'}
                            borderRadius={'full'}

                        />

                    </Box>

                    {/* Additional content can go here. The logo will overlap this content due to the z-index */}
                </Flex>
                <Heading display={'flex'} justifyContent={'center'} color={'blue.800'} fontSize={'xl'} alignText={'center'} w={'full'}  px={8} bg={'white'} mt={16} mb={2}>
                    Water Utilities Corporation
                </Heading>
                <Flex justifyContent="center"  bg="white"  gap={4} alignItems={'end'} flexGrow={4}  px={8}   pb={4}>

                    {[FaFacebook, FaTwitter, FaInstagram, FaWhatsapp].map(
                        (SocialIcon, index) => (
                            <Button
                                key={index}
                                rounded="full"
                                w="40px"
                                h="40px"
                                bg="brand.500"

                                _hover={{ bg: "brand.500" }}
                            >
                                <Icon as={SocialIcon} color="white" />
                            </Button>
                        )
                    )}
                </Flex>

                <VStack spacing={2} align="center" fontSize="sm"  w={'full'}  textAlign="center" fontWeight={'bold'} color={'gray.700'} px={8} py={1}>
                    <Text >Email: contactcentre@wuc.bw</Text>
                    <Text >Toll Free: 0800-555-555</Text>
                    <Text >Tel: 399-25-00</Text>
                    <Text >USSD: *186#</Text>
                    <Text >SMS: 16299</Text>

                </VStack>

            </Box>

        </>
    );
};

export default Contact;
