import React, {useEffect, useState} from "react";
import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    GridItem, Heading,
    Input,
    InputGroup,
    Select,
    Textarea,
    Text,
    useToast,
    Icon
} from "@chakra-ui/react";

import {CustomGeneralToast} from "../../../utils/customChakraToasts";
import {API} from "../../../utils/api";
import {MdMailOutline, MdPhoneInTalk} from "react-icons/md";
import {FaWhatsapp} from "react-icons/fa";
import {FaRegMessage} from "react-icons/fa6";
import {IoLocationOutline} from "react-icons/io5";
import {GiRotaryPhone} from "react-icons/gi";

const enums = require('wuc-library/enums')

const ContactUsForm = () => {
    const {addGeneralToast} = CustomGeneralToast();
    const toast = useToast();
    const [formData, setFormData] = useState({
        firstname: null,
        lastname: null,
        email: null,
        cell: null,
        message: null,
        subject: null
    });
    const [loading, setLoading] = useState(false);

    const resetFormData = () => {
        setFormData({
            firstname: "",
            lastname: "",
            email: "",
            cell: "",
            message: "",
            subject: ""
        });
    }

    const sendMessage = async () => {
        if (!formData.cell || !formData.message || !formData.subject || !formData.lastname || !formData.firstname || !formData.email) {
            toast.closeAll();
            addGeneralToast({
                message: "All fields must be filled in",
                type: "warning",
            });
            setLoading(false);
            return;
        }
        setLoading(true);
        try {
            const response = await API.post(
                `/common/notification/send`,
                {
                    recipient: process.env.REACT_APP_CONTACT_CENTER_EMAIL,
                    type: enums.NotificationTypes.EMAIL,
                    subject: formData.subject,
                    message: `<div>
                         <p>${formData.message}</p> 
                         <p style="font-weight: bold">Regards,</p> 
                         <p>${formData.firstname}  ${formData.lastname}</p> 
                         <p>Cell: ${formData.cell}</p> 
                    </div>`
                }
            );

            const data = await response.data;
            if (response.status === 200) {
                toast.closeAll();
                addGeneralToast({
                    message: data.message,
                    type: "success",
                });
                resetFormData(); // Reset the form after successful submission
            } else {
                toast.closeAll();
                addGeneralToast({
                    message: data.message || "Error occurred while sending message",
                    type: "error",
                });
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
            toast.closeAll();
            addGeneralToast({
                message: e.response ? e.response.data.message : "Error occurred while sending message",
                type: "error",
            });
        } finally {
            resetFormData(); // Always reset form data after attempting to send the message
            setLoading(false); // Ensure loading state is updated
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState, [name]: value
        }));
    };

    return (
        <>
            <Flex
                w="full"
                bg={'white'}
                direction={"column"}
                py={16}
                px={{base: 4, lg: 16}}
                borderRadius={'2xl'}
            >
                <Flex direction={"column"} p={4}>
                    <Heading size={'lg'}>Contact Us</Heading>
                    <Text color={'gray.500'}>Any question or remarks? Just write us a message!</Text>
                </Flex>
                <Grid w={'full'} templateColumns="repeat(12, 1fr)" gap={8}>
                    <GridItem colSpan={{base: 9, md: 12, lg: 4}} order={{base: 2, md: 2, lg: 1}} bg={'brand.700'} borderRadius={'2xl'} p={4} color={'white'}>
                        <Heading size={'md'} mb={8} fontWeight={'medium'}>Contact Information</Heading>
                        <Flex alignItems={'center'} gap={4} fontSize={'md'} mb={6}>
                            <Icon as={MdPhoneInTalk} boxSize={6}/> <Text>0800 555 555</Text>
                        </Flex>
                        <Flex alignItems={'center'} gap={4} fontSize={'md'} mb={6}>
                            <Icon as={FaWhatsapp} boxSize={6}/> <Text>+267 73 042 819</Text>
                        </Flex>
                        <Flex alignItems={'center'} gap={4} fontSize={'md'} mb={6}>
                            <Icon as={FaRegMessage} boxSize={6}/> <Text>SMS: 16299</Text>
                        </Flex>
                        <Flex alignItems={'center'} gap={4} fontSize={'md'} mb={6}>
                            <Icon as={GiRotaryPhone} boxSize={6}/> <Text>399 25 00</Text>
                        </Flex>
                        <Flex alignItems={'center'} gap={4} fontSize={'md'} mb={6}>
                            <Icon as={MdMailOutline} boxSize={6}/> <Text>contactcentre@wuc.bw</Text>
                        </Flex>
                        <Flex alignItems={'center'} gap={4} fontSize={'md'} mb={6}>
                            <Icon as={IoLocationOutline} boxSize={6}/> <Text>Branch Locator</Text>
                        </Flex>
                    </GridItem>
                    <GridItem colSpan={{base: 9, md: 12, lg: 8}} order={{base: 1, md: 1, lg: 2}}>
                        <Grid w={'full'} templateColumns="repeat(12, 1fr)" gap={2} color={"blue.800"}>
                            <GridItem colSpan={{base: 12, md: 6}}>
                                <FormControl isRequired>
                                    <FormLabel>Firstname</FormLabel>
                                    <InputGroup>
                                        <Input type="text" name={'firstname'} value={formData?.firstname} onChange={handleInputChange}/>
                                    </InputGroup>
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={{base: 12, md: 6}}>
                                <FormControl isRequired>
                                    <FormLabel>Lastname</FormLabel>
                                    <InputGroup>
                                        <Input type="text" name={'lastname'} value={formData?.lastname} onChange={handleInputChange}/>
                                    </InputGroup>
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={{base: 12, md: 6}}>
                                <FormControl isRequired>
                                    <FormLabel>Email Address</FormLabel>
                                    <InputGroup>
                                        <Input type="email" name="email" value={formData?.email} onChange={handleInputChange}/>
                                    </InputGroup>
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={{base: 12, md: 6}}>
                                <FormControl isRequired>
                                    <FormLabel>Cell Number</FormLabel>
                                    <InputGroup>
                                        <Input type="number" name="cell" value={formData?.cell} onChange={handleInputChange}/>
                                    </InputGroup>
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={{base: 12}}>
                                <FormControl isRequired>
                                    <FormLabel>Select Subject</FormLabel>
                                    <Select name={'subject'} value={formData?.subject} onChange={handleInputChange} placeholder={'Select subject'}>
                                        <option value='General Inquiry'>General Inquiry</option>
                                        <option value='Customer Service'>Service Inquiry</option>
                                        <option value='Complaint'>Complaint</option>
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={{base: 12}}>
                                <FormControl isRequired>
                                    <FormLabel>Message</FormLabel>
                                    <InputGroup>
                                        <Textarea borderRadius={'2xl'} type="text" name={'message'} value={formData?.message} onChange={handleInputChange}/>
                                    </InputGroup>
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={{base: 12}}>
                                <Flex justify={'end'} w={'full'}>
                                    <Button onClick={sendMessage} bg={'brand.700'} w={'200px'} isLoading={loading} isDisabled={loading}>Send Message</Button>
                                </Flex>
                            </GridItem>
                        </Grid>
                    </GridItem>
                </Grid>
            </Flex>
        </>
    );
};

export default ContactUsForm;
