import React from "react";
import PublicViewContainer from "../../components/Containers/PublicViewContainer";
import MediaComponents from "./Components/MediaComponents";
import PictureComponent from "./Components/Pictures/Pictures";



const Pictures= () => {
    return <PublicViewContainer childComponent={<MediaComponents childComponent={<PictureComponent/>}/>}/>
}

export default Pictures
