import {
  Box,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";

const MiniStatistics = (props) => {
  const { title, stat, icon } = props;
  const [isLargerThanTabletSmallerThanLargerScreen] = useMediaQuery("(min-width: 769px) and (max-width: 1024px)");

  return (
    <Stat
      px={ isLargerThanTabletSmallerThanLargerScreen ? {lg: 3} :{ base: 4, sm: 6, md: 2, lg: 10 }} // Responsive padding on the x-axis
      py={{ base: 3, sm: 4, md: 5 }} // Responsive padding on the y-axis
      shadow={"xl"}
      borderColor={"brand.500"}
      bg={"white"}
      rounded={"lg"}
    >
      <Flex
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box
          mr={ isLargerThanTabletSmallerThanLargerScreen ? {lg: 0} : { base: 2, md: 4, lg: 5 }}
          color={useColorModeValue("gray.800", "gray.200")}
          fontSize={{ base: "sm", md: "sm", lg: "md" }}
          bg={"gray.50"}
          borderRadius={"lg"}
          p={2}
        >
          {icon}
        </Box>
        <Box
          maxWidth="100%"
        >
          <StatLabel
            fontWeight={"medium"}
            isTruncated
            color="#2A4365"
            fontSize={{ base: "sm", md: "sm", lg: "sm" }}
            maxWidth="100%"
            textOverflow="ellipsis" 
            whiteSpace="nowrap"
          >
            {title}
          </StatLabel>
          <StatNumber
            fontSize={{ base: "xl", md: "xl", lg: "3xl" }}
            fontWeight={"medium"}
            color="#2A4365"
            isTruncated
            maxWidth="100%"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {stat}
          </StatNumber>
        </Box>
      </Flex>
    </Stat>
  );
};

export default MiniStatistics;
