import React, {useEffect, useState} from "react";
import {
    Box,
    Text,
    HStack,
    Slide,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Icon,
    useColorModeValue,
    Button,
    GridItem,
    Grid,
    Tooltip, useDisclosure,
} from "@chakra-ui/react";
import utils from "../../utils/commonFunctions"
import {GoChevronRight} from "react-icons/go";
import CustomModal from "../../pages/LaboratoryServices/Components/CustomModal/CustomModal";

import {FaRegFilePdf} from "react-icons/fa6";
import {Link} from "react-router-dom";
import PdfViewerModal from "../PdfViewer/PdfViewerModal";

const CustomAccordion = (AccordionData = []) => {
    const AccordionData2 = AccordionData.AccordionData;
    const [isModalOpen, setModalOpen] = useState(false);
    const {isOpen,onOpen,onClose}=useDisclosure()
    const [url,setUrl]=useState()
    useEffect(() => {
        if(url){
            onOpen()
        }
    }, [url])
    const handleView=(url)=>{
        setUrl(url)
    }
    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };
    return (<Box
            w={"410px"}
            minH="50px"
            p={4}
            borderRadius={"xl"}
            opacity={1}
            shadow={"xl"}
        >
            <Accordion
                allowToggle
                defaultIndex={[0]}
                allowMultiple
                style={{border: "white"}}
            >
                {AccordionData2.length > 0 && (<>
                        {AccordionData2.map((e, key) => (<AccordionItem key={key}>
                                <h2 key={key}>
                                    <AccordionButton
                                        _hover={{
                                            color: "black",
                                            bg: "#f5f5f7",
                                            textDecoration: "none",
                                            transform: "translateX(5px)",
                                            borderLeft: "4px solid #2BBCF2",
                                        }}
                                        borderRadius={"md"}
                                        _expanded={{
                                            color: "black",
                                            bg: "#f5f5f7",
                                            textDecoration: "none",
                                            transform: "translateX(5px)",
                                            borderLeft: "4px solid #2BBCF2",
                                        }}
                                    >
                                        <Box
                                            as="span"
                                            fontWeight={"bold"}
                                            fontSize={"md"}
                                            flex="1"
                                            textAlign="left"
                                        >
                                            {e.label}
                                            {e.children?.length < 0 ? (
                                                <Link to={e.href} state={{id: e.id}} _hover={{textDecoration: "none"}}>
                                                    <Text ml={2}>{e.label}</Text>
                                                </Link>) : ("")}
                                        </Box>
                                        {e.children?.length > 0 && <AccordionIcon/>}
                                    </AccordionButton>
                                </h2>
                                {e.children?.map((item, index) => (<AccordionPanel key={index} pb={0}>
                                        {/**Open request modal */}
                                        {item.modal ? (<>
                                                <Button
                                                    onClick={openModal}
                                                    bg={"transparent"}
                                                    color="gray.700"
                                                    p={0}
                                                    _hover={{bg: "transparent"}}
                                                >
                                                    <HStack
                                                        _hover={{transform: "translateX(5px)"}}
                                                        transitionDuration={"0.3s"}
                                                        cursor="pointer"
                                                    >
                                                        <Icon as={GoChevronRight}/>
                                                        <Text ml={2} fontWeight={"normal"}>
                                                            {item.label}
                                                        </Text>
                                                    </HStack>
                                                </Button>
                                                <CustomModal
                                                    isOpen={isModalOpen}
                                                    isClosed={closeModal}
                                                />
                                            </>) : (<>

                                                {item.isForm ? (
                                                    <>
                                                        <Accordion
                                                            allowToggle
                                                            allowMultiple
                                                            style={{border: "white"}}


                                                        >
                                                            <AccordionItem>
                                                                <AccordionButton py={-2} _hover={{bg:'white'}} mx={-2} borderRadius={"xl"}>
                                                                    <GridItem  as="span"

                                                                               flex="1"
                                                                               textAlign="left" colSpan={12}>
                                                                        <HStack
                                                                            mx={-1}
                                                                            textDecoration= "none"
                                                                            transform= "translateX(5px)">
                                                                            <Icon as={GoChevronRight}/>
                                                                            <Text  as="span"
                                                                                  flex="1"
                                                                                  textAlign="left" noOfLines={2}>{item.label}</Text>
                                                                        </HStack>
                                                                    </GridItem>
                                                                    <AccordionIcon/>
                                                                </AccordionButton>
                                                                <AccordionPanel>

                                                                    <Box borderRadius={'md'}  bg= "gray.50">
                                                                        {item.url.map((link) => (<Tooltip label={'Download attachment'}>
                                                                            <Box cursor={'pointer'} onClick={()=>handleView(link.link)}>
                                                                                <Grid templateColumns='repeat(12, 1fr)'
                                                                                      _hover={{transform: "translateX(5px)"}}
                                                                                      transitionDuration={"0.3s"}
                                                                                      cursor="pointer">
                                                                                    <GridItem colSpan={12} as={'flex'}
                                                                                              justify={'center'} align={'center'}>
                                                                                        <HStack py={2}  pl={10}>
                                                                                            <Icon as={FaRegFilePdf}
                                                                                                  color={'blue.800'}/>
                                                                                            <Text ml={1}
                                                                                                  noOfLines={2}>{link.title}</Text>
                                                                                        </HStack>

                                                                                    </GridItem>
                                                                                </Grid>
                                                                            </Box>
                                                                        </Tooltip>))}
                                                                    </Box>
                                                                </AccordionPanel>
                                                            </AccordionItem>

                                                        </Accordion>

                                                    </>


                                                ) : (<>
                                                        <Link to={item.href}
                                                              state={{id: item.id}}
                                                              _hover={{textDecoration: "none"}}
                                                        >
                                                            {utils.isAuthenticated !== item.isAuthenticated &&
                                                                <Grid  templateColumns='repeat(12, 1fr)'
                                                                      _hover={{transform: "translateX(5px)"}}
                                                                      transitionDuration={"0.3s"}
                                                                      cursor="pointer">
                                                                    <GridItem mx={2} colSpan={12}>
                                                                        <HStack>
                                                                            <Icon as={GoChevronRight}/>
                                                                            <Text
                                                                                  noOfLines={2}>{item.label}</Text>
                                                                        </HStack>
                                                                    </GridItem>
                                                                </Grid>}
                                                        </Link>
                                                    </>)}
                                            </>

                                        )}
                                    </AccordionPanel>))}
                            </AccordionItem>))}
                    </>)}
            </Accordion>
        <PdfViewerModal filePath={url} isOpen={isOpen} onOpen={onOpen} onClose={onClose}/>
        </Box>);
};

const MenuLink_V1 = ({isOpen, left, children = []}) => {
    const trackColor = useColorModeValue("gray.200", "gray.700");
    return (<Box
            position="absolute"
            zIndex="10"
            left={left}
            transform={"translateX(-50%)"}
            top="120px"
        >
            {isOpen && (<Slide
                    in={true}
                    animateOpacity
                    direction="bottom"
                    style={{
                        marginTop: isOpen ? "0" : "-20px",
                        opacity: isOpen ? 1 : 0,
                        transition: "margin 0.2s ease-out, opacity 0.2s ease-out",
                    }}
                >
                    <Box
                        h={"fit-content"}
                        bg={"white"}
                        position="absolute"
                        zIndex="10"
                        w="fit-content"
                        left="0"
                        mt={-1}
                        boxShadow="xl"
                        borderRadius={10}
                        overflowY={"auto"}
                        overflowX={"hidden"}
                        sx={{
                            "&::-webkit-scrollbar": {
                                width: "4px", // Thinner scrollbar
                            }, "&::-webkit-scrollbar-track": {
                                background: "white",
                            }, "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "brand.500", // Using brand color
                                borderRadius: "8px", border: `2px solid ${trackColor}`,
                            }, "&::-webkit-scrollbar-thumb:hover": {
                                background: "brand.600", // Slightly darker brand color on hover
                            },
                        }}
                    >
                        <HStack spacing={0} align="start">
                            <CustomAccordion AccordionData={children}/>
                        </HStack>
                    </Box>
                </Slide>)}
        </Box>);
};

export {MenuLink_V1};
