import React from 'react';
import PortalContainer from "../../components/PortalContainer/PortalContainer";
import RequestBill from "./Components/RequestBill";
import SelfServiceContainer from "../../components/PortalContainer/SelfServiceContainer";
import MonthlyBillTable from "./Components/MonthlyBillTable";

const RequestMonthlyBill = () => {
  return (
  <PortalContainer childComponent={<SelfServiceContainer childComponent={<RequestBill />} table={<MonthlyBillTable/>}/>}  Title={'Request Monthly Bill'}/>

);
};

export default RequestMonthlyBill;
