import React from 'react'
import {Box} from '@chakra-ui/react'


const DefaultTemplate = ({ Content, Title, icon }) => {
    return (
        <>
            <Box p={8} >
                {Content}
            </Box>
        </>
    );
};

export default DefaultTemplate;
