import React, {createContext, useState, useContext, useEffect} from 'react';

// Create the context
const QuoteCartContext = createContext();

// Create a provider component
export const QuoteCartProvider = ({ children }) => {
  const [quoteCart, setQuoteCart] = useState([]);
  const [cartSize, setCartSize] = useState(0);
  const [customer, setCustomer] = useState({});
  const [billBalance, setBillBalance] = useState({});
  const [refreshFlag, setRefreshFlag] = useState(false);
  useEffect(() => {
    console.log(quoteCart)
    setCartSize(quoteCart?.length)
  }, [quoteCart]);
  return (
    <QuoteCartContext.Provider value={{ cartSize,quoteCart, setQuoteCart,customer,setCustomer,billBalance,setBillBalance,refreshFlag,setRefreshFlag }}>
      {children}
    </QuoteCartContext.Provider>
  );
};

// Custom hook to access the context
export const useQuoteCart = () => useContext(QuoteCartContext);
