import {
    Alert,
    AlertDescription, AlertIcon, AlertTitle,
    Button,
    Modal, ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay, Text,
    useDisclosure
} from "@chakra-ui/react";
import {ModalFooter} from "@chakra-ui/modal";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

function MigratedAlert({isOpen,onClose,message}) {
    const navigate=useNavigate()
    const backToLogin = () => {
        onClose()
        navigate('/login')

    }

    return (
        <>
            <Modal isCentered   closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent >
                    <ModalBody boxShadow={'lg'} p={0}>
                        <Alert
                            status='warning'
                            variant='subtle'
                            flexDirection='column'
                            alignItems='center'
                            justifyContent='center'
                            textAlign='center'
                            height='200px'
                            borderRadius={'xl'}
                            p={4}
                        >
                            <AlertIcon boxSize='40px' mr={0} />

                            <AlertDescription maxWidth='sm'>
                                <Text> {message}</Text>
                                <Text my={2} fontWeight={'semibold'}>Password reset link has been sent to your email.</Text>
                                <Button variant={'ghost'}  onClick={backToLogin}>
                                    Back to login
                                </Button>
                            </AlertDescription>

                        </Alert>

                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
export default MigratedAlert