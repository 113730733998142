import React, { Component } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, pdf } from '@react-pdf/renderer';
import bgImage from '../../assets/BlueLogoNoBG.png';
import logoImage from '../../assets/BlueLogoNoBG.png';
import { MdDownload } from "react-icons/md";
import { Button } from "@chakra-ui/react";
import moment from "moment";
import { API } from '../../utils/api';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFF',
        position: 'relative',
        fontFamily: 'Helvetica',
        fontSize: 11,
        padding: 40,
    },
    logo: {
        width: 80,
        height: 80,
        position: 'absolute',
        top: 30,
        left: '60%',
        marginLeft: -40,
    },
    background: {
        position: 'absolute',
        top: '30%',
        left: '15%',
        transform: 'translate(-30%, 0%)',
        opacity: 0.1,
        width: '100%',
    },
    header: {
        marginTop: 70,
        marginBottom: 30,
        fontSize: 20,
        textAlign: 'center',
        color: "#2BBCF2",
        fontWeight: 'bold',
    },
    grid: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 15,
    },
    col: {
        width: '50%',
        marginBottom: 15,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 8,
    },
    label: {
        fontWeight: 'bold',
        marginRight: 8,
    },
    address: {
        fontWeight: 'bold',
        marginRight: 8,
        color: "#2BBCF2",
    },
    fieldValue: {
        flex: 1,
        textAlign: 'justify',
    },
});

const Grid = ({ children }) => <View style={styles.grid}>{children}</View>;
const Col = ({ children }) => <View style={styles.col}>{children}</View>;
const Row = ({ children }) => <View style={styles.row}>{children}</View>;

class PaymentReceiptPdf extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lastExecutionTime: 0,
        };
    }

    componentDidMount() {
        this.executeFunction();
    }

    generatePdfBlob = async () => {
        const { pdfData } = this.props; // Data to be displayed on the PDF
        const blob = await pdf(
            <Document>
                <Page size='A4' style={styles.page}>
                    <Image style={styles.background} src={bgImage} />
                    <Image style={styles.logo} src={logoImage} />
                    <Text style={styles.header}>ONLINE PAYMENT RECEIPT</Text>
                    <Grid>
                        <Col>
                            <Text style={styles.address}>Water Utilities Corporation</Text>
                            <Text style={styles.address}>Sedibeng House</Text>
                            <Text style={styles.address}>P/Bag 00276</Text>
                            <Text style={styles.address}>Gaborone</Text>
                        </Col>
                    </Grid>
                    <Grid>
                        <Col>
                            <Row>
                                <Text style={styles.label}>Reference :</Text>
                                <Text style={styles.value}>{pdfData?.reference}</Text>
                            </Row>
                            <Row>
                                <Text style={styles.label}>Payment Date :</Text>
                                <Text style={styles.value}>{moment(pdfData?.editedDate).format('DD-MM-YYYY, HH:mm:ss')}</Text>
                            </Row>
                            <Row>
                                <Text style={styles.label}>Receipt Issued By :</Text>
                                <Text style={styles.value}>WUC Corporate Website</Text>
                            </Row>
                            <Row>
                                <Text style={styles.label}>Customer Name :</Text>
                                <Text style={styles.value}>{pdfData?.fullName}</Text>
                            </Row>
                            <Row>
                                <Text style={styles.label}>Amount Paid (Pula) :</Text>
                                <Text style={styles.value}>{pdfData?.amount}</Text>
                            </Row>
                        </Col>
                    </Grid>
                </Page>
            </Document>
        ).toBlob();

        return blob;
    };

    executeFunction = async () => {
        const { lastExecutionTime } = this.state;
        if (Date.now() - lastExecutionTime > 60000) {
            await this.sendNotification(await this.generatePdfBlob());
            this.setState({ lastExecutionTime: Date.now() });
        }
    };

    generatePOP = async (blob) => {
        try {
            // Upload the PDF
            const formData = new FormData();
            formData.append('file', blob, 'payment_receipt.pdf');
            formData.append('folderPath', 'LabPOP');

            const response = await API.post(`${process.env.REACT_APP_API_BASE_URL}/common/file/upload`, formData);

            console.log('PDF uploaded successfully', response.data.data.fileUrl);
            return response.data.data.fileUrl;
        } catch (error) {
            console.error('Error uploading PDF', error);
            return null;
        }
    };

    getGreeting = () => {
        const now = new Date();
        const hours = now.getHours();

        if (hours < 12) {
            return 'Good Morning!';
        } else if (hours < 17) {
            return 'Good Afternoon!';
        } else if (hours < 20) {
            return 'Good Day!';
        } else {
            return 'Good Evening!';
        }
    };

    sendNotification = async (blob) => {
        try {
            const attachments = [{
                filename: 'Lab Request Proof of Payment',
                path: await this.generatePOP(blob),
                contentType: 'application/pdf'
            }];

            const requestBody = {
                type: "Email",
                recipient: await this.fetchGroupEmails(),
                subject: "Laboratory Service Proof of Payment",
                message: `<h1>${this.getGreeting()}</h1><p>A new Laboratory Service Proof of Payment has been recieved submitted.</p><p>Find attached Proof of proof of payment with payment details.</p>`,
                attachments: attachments
            };

            const response1 = await API.post(`${process.env.REACT_APP_API_BASE_URL}/common/notification/send`, JSON.stringify(requestBody));
            console.log("send notification response: ", response1);
            return response1.data;

        } catch (error) {
            console.error('Error sending notification', error);
        }
    };

    fetchGroupEmails = async () => {
        try {
            const response = await API.get(`${process.env.REACT_APP_API_BASE_URL}/labs/email`, {
                params: {
                    category: 'Laboratory Supervisors',
                    pageNumber: 1,
                    pageSize: 1000,
                }
            });
            console.log("fetch lab admins response", response.data.data.docs.map(admin => admin.email).join(','));
            return response.data.data.docs.map(admin => admin.email).join(',') || "";
        } catch (error) {
            console.error('Error fetching group emails:', error);
            return "";
        }
    };

    downloadAndUploadPdf = async () => {
        try {
            const blob = await this.generatePdfBlob();

            // Download the PDF
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'payment_receipt.pdf';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            await this.sendNotification(blob);

        } catch (error) {
            console.error('Error uploading PDF', error);
        }
    };

    render() {
        return (
            <Button rightIcon={<MdDownload />} bg='red.400' _hover={{ bg: 'red.500' }} variant='solid' onClick={this.downloadAndUploadPdf}>
                Download Receipt
            </Button>
        );
    }
}

export default PaymentReceiptPdf;
