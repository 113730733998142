import React from "react";
import {
    Box, Heading, Flex, Text, SimpleGrid,
} from "@chakra-ui/react";
import {RiCustomerService2Fill, RiTeamFill} from "react-icons/ri";
import {TbBulbFilled} from "react-icons/tb";
import {FaHandshakeSimple,} from "react-icons/fa6";
import {IoShieldCheckmarkSharp} from "react-icons/io5";
import {HiBadgeCheck} from "react-icons/hi";


const Value = ({icon, title, brief}) => (
    <>
        <Flex
            justifyContent="start"
            alignItems="center"
            flexDirection="column"
            width={{base:'full'}}
            height={'280px'}
            borderRadius={'2xl'}
            boxShadow={'md'}
            p={4}
            _hover={{bg: 'gray.100', cursor: "pointer"}}
        >

            <Flex bg={'blue.100'} borderRadius={'full'} h={{base: 14}} w={{base: 14}} justify={'center'}
                  align={'center'} fontSize={40} color={'brand.700'}>
                {icon}
            </Flex>
            <Heading as="h5" size="md" isTruncated color="brand.700" my={2}>
                {title}
            </Heading>
            <Text textAlign={'center'} color={'blue.800'}>
                {brief}
            </Text>
        </Flex>

    </>

);
const Values = () => (
    <Flex direction={'column'} justify={'center'} align={'center'}>
        <Heading size="lg" my={8} textAlign={'center'} color={'blue.800'}>Our <Box as={'span'} color={'#0093D0'}>Values</Box></Heading>
        <Heading size="md" mb={4}   color={'blue.800'} textTransform={'capitalize'}>Value Statements <Box as={'span'} color={'brand.700'} ></Box> </Heading>
        <Text fontWeight={'normal'}  mb={4}  color={'blue.800'}  textAlign={'justify'}>
           What core principles will guide the way we work together as colleagues and for our clients?
        </Text>
        <SimpleGrid  as={'flex'} alignItems={'center'} justify={'center'} maxW={'7xl'} columns={{base:1,md:2,lg:3}} px={{base:8,lg:4}} gap={4}>
            <Value
                icon={<IoShieldCheckmarkSharp/>}
                title={"Reliability"}
                brief={"We consistently deliver our services and work in a trustworthy,dependable, honest, and ethical way."}
            />
            <Value
                icon={<RiTeamFill/>}
                title={"Collaboration"}
                brief={"We have a joint approach to work that always strives to provide\n" +
                    "the best solutions to our internal and external stakeholders.\n" }

            />
            <Value
                icon={<HiBadgeCheck/>}
                title={"Service Excellence"}
                brief={"We will continue to improve our processes and apply\n" +
                    "practical and contemporary solutions to our service delivery."}
            />

            <Value
                icon={<RiCustomerService2Fill/>}
                title={"Customer – Centric"}
                brief={"Our customers are our first priority."}
            />


            <Value
                icon={<FaHandshakeSimple/>}
                title={"Accountability"}
                brief={"We take responsibility for our actions. (Do what we say we will do)"}
            />

            <Value
                icon={<TbBulbFilled/>}
                title={"Sustainability"}
                brief={"We provide and manage resources to meet social, economic, governance and environmental requirements without compromising future needs."}
            />

        </SimpleGrid>


    </Flex>

);
export default Values

