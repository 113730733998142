import React, {useEffect, useState} from "react";
import {
    Box, Flex, FormControl, FormLabel, Grid, GridItem, Heading, Input, InputGroup,Text,
} from "@chakra-ui/react";


const BankingDetails = () => {
    const form=JSON.parse(localStorage.getItem('banking')) 
    const [formData, setFormData] = useState({
        name: form?.name ||null,
        branch: form?.branch ||null,
        branchCode: form?.branchCode ||null,
        accountNumber: form?.accountNumber ||null,
    });
    useEffect(() => {
        localStorage.setItem('banking', JSON.stringify(formData));
        if(Object.values(formData).some(detail => detail === null||detail === ""||!detail)){
            localStorage.setItem('isValidForm',false );
        }
        else{
            localStorage.setItem('isValidForm',true );
        }


    }, [formData]);
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({
            ...prevState, [name]: value
        }));
    };

    return (
        <>
            <Box>
                <Flex mb={4}>
                    <Box>
                        <Heading color={'brand.500'}>Banking Details</Heading>
                        <Text color={'gray.500'}>Please provide your banking information.</Text>
                    </Box>
                </Flex>
                <Grid templateColumns="repeat(12, 1fr)" gap={2} color={"blue.800"}>
                    {/* Kin Name */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired id="name">
                            <FormLabel >Bank Name</FormLabel>
                            <InputGroup>
                                <Input type="text" name="name" id="Name"  value={formData.name} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>

                    {/* Kin Middle Name */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl  isRequired id="branch">
                            <FormLabel >Branch Name</FormLabel>
                            <InputGroup>
                                <Input  type="text" name="branch" id="Branch"   value={formData.branch} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>

                    {/* Kin BranchCode */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired id="branchCode">
                            <FormLabel >Branch Code</FormLabel>
                            <InputGroup>
                                <Input type="text" name="branchCode" id="BranchCode" value={formData.branchCode} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>



                    {/* Kin AccountNumber */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired id="accountNumber">
                            <FormLabel>Account Number</FormLabel>
                            <InputGroup>
                                <Input type="number" name="accountNumber" id="AccountNumber"  value={formData.accountNumber} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                </Grid>
            </Box>
        </>
    );
};

export default BankingDetails;
