import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { Flex, Box, Badge, Heading, Progress, IconButton, Card, CardBody } from '@chakra-ui/react';
import ImageCarousel from './ImageCarousel';

const ViewProjectModal = ({ isOpen, onClose, projectData }) => {
    return (
        <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} size={'6xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{projectData.title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody px={8}>
                    <ViewContainer projectData={projectData} />
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

const ViewContainer = ({ projectData }) => {
    const [project, setProject] = useState({
      title: '',
      value: '',
      description: '',
      images: [],
      pdfs: [],
      closingDate: '',
      startDate: ''
    });
    const [progressValue, setProgressValue] = useState(0);
  
    useEffect(() => {
      const fetchProjectData = async () => {
        try {
          if (projectData) {
  
            // Calculate progress value
            const currentDate = new Date();
            const startDate = new Date(projectData.startDate);
            const closingDate = new Date(projectData.closingDate);
            const projectDuration = closingDate.getTime() - startDate.getTime();
            const timeElapsed = currentDate.getTime() - startDate.getTime();
            const progressValue = (timeElapsed / projectDuration) * 100;
            setProgressValue(progressValue);
  
          }
        } catch (error) {
          console.error("Error fetching project data:", error);
        }
      };
  
      fetchProjectData();
    }, [projectData]);
  
    console.log("project", projectData);
  
    return (
      <Card maxW='full' mb={8} marginBottom={8}>
        <CardBody>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            justify="space-between"
            w="100%"
            p={4}
          >
            <Box
              flex={{ base: '100%', md: '50%' }}
              bg="gray.200"
              p={4}
              m={2}
              borderRadius="md"
              boxShadow="md"
            >
              <ImageCarousel images={projectData.imagesDocumentsKeys}/>
            </Box>
            <Box
              flex={{ base: '100%', md: '50%' }}
              bg="white"
              p={4}
              m={2}
              borderRadius="md"
              align="start"
              width={{ base: '100%', md: '45%' }}
            >
            <Flex spacing='4'>
                <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap' mb={4}>
                <Box>
                    <Heading size='sm' mb={5}>Title: {projectData.title}</Heading>
                    <Text mb={5}>
                    Start Date:
                    <Badge ml='1' colorScheme='green'>
                        {projectData.startDate}
                    </Badge>,
                    Closing Date:
                    <Badge ml='1' colorScheme='green'>
                        {projectData.closingDate}
                    </Badge>
                    </Text>
                </Box>
                </Flex>
                <IconButton
                variant='ghost'
                colorScheme='gray'
                aria-label='See menu' />
            </Flex>
            <Progress hasStripe value={progressValue || 0} />
            <Heading size='sm' mt={8}>Description:</Heading>
            <Text ms={4} mb={5}>
                <div dangerouslySetInnerHTML={{ __html: projectData.description }} />
            </Text>
            </Box>
          </Flex>
        </CardBody>
      </Card>
    );
  };

export default ViewProjectModal;
