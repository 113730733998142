import React, {useEffect, useState} from "react";
import {
    Button,
    FormControl,
    FormLabel, HStack,
    Input,
    InputGroup, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay,
    Radio,
    RadioGroup, Select, SimpleGrid,
    Stack, Switch, Text,
    Textarea,
    useToast,
} from "@chakra-ui/react";
import {API} from "../../../utils/api";
import {CustomGeneralToast} from "../../../utils/customChakraToasts";
import {PropagateLoader} from "react-spinners";
import BillAlert from "../../../components/PortalContainer/BillAlert";
import utils from "../../../utils/commonFunctions";
import {useUserProfile} from "../../../providers/UserProfileProvider";
import {useQuoteCart} from "../../../providers/QuoteCartProvider";

const LeakReportModal = ({isOpen,onClose}) => {
    const userId=utils.getUserId()
    const {userProfile} = useUserProfile() || {}
    const {refreshFlag,setRefreshFlag}=useQuoteCart()
    const getInitialName = () => {
        if (personalDetails && personalDetails.firstName && personalDetails.lastName) {
            return `${personalDetails.firstName} ${personalDetails.lastName}`;
        }
        return "";  // Default return if personalDetails are not available
    };


    const {customerNumber,personalDetails,contactDetails,} = userProfile || {}
    const [name, setName] = useState(getInitialName);
    const [cell, setCell] = useState(contactDetails?.cell||"");
    const [number, setNumber] = useState(customerNumber||"");
    const [leakLocation, setLeakLocation] = useState("");
    const [villageOrTown, setVillageOrTown] = useState("");
    const [physicalAddress, setPhysicalAddress] = useState("");
    const [email, setEmail] = useState(contactDetails?.email||"");
    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const {addGeneralToast} = CustomGeneralToast();
    const [checkForOthers, setCheckForOthers] = useState(false);
    const [utilityContracts, setUtilityContracts] = useState([]);
    const [utility, setUtility] = useState("");
    useEffect(() => {
        if(!isOpen){
            setCheckForOthers(false)
        }
    }, [isOpen]);
    useEffect(() => {
        if(!checkForOthers){
            setName(getInitialName)
            setNumber(customerNumber)
            setCell(contactDetails?.cell)
            setEmail(contactDetails?.email)
        }
    }, [checkForOthers]);
    useEffect(() => {
        const getUtilityContracts = async () => {
            setUtilityContracts(await utils.getUtilityContractsByCustomerNumber(customerNumber))
        };
        if(!checkForOthers&&customerNumber!==""){ getUtilityContracts()}
    }, []);
    const reportLeak = async () => {
        if (!name || !cell || !utility||!number|| !leakLocation || !villageOrTown || !physicalAddress||!email||!comment) {
            toast.closeAll();
            addGeneralToast({
                message: "All fields must be filled in",
                type: "warning",
            });
            setLoading(false);
            return;
        }
        setLoading(true);
        try {
            const response = await API.post(`/leaks/`,
                {
                    name: name,
                    customerNumber: number,
                    contractNumber: utility,
                    contactDetails:{
                        email:email,
                        phone: cell
                    },
                    where: leakLocation,
                    village: villageOrTown,
                    address: physicalAddress,
                    createdBy:userId||name,
                    comment:comment
                });
            if (response.status === 201) {
                toast.closeAll();
                addGeneralToast({
                    message: "Leak Report Submitted Successfully",
                    type: "success",
                });
                setEmail("")
                setName("")
                setUtility("")
                setCell("")
                setNumber("")
                setComment("")
                setLeakLocation("")
                setPhysicalAddress("")
                setUtility("")
                setVillageOrTown("")
                onClose()
                setRefreshFlag(true)
            } else {
                toast.closeAll();
                addGeneralToast({
                    message: "Leak Report Unsuccessful",
                    type: "error",
                });
            }
        } catch (e) {
            console.log(e)
            toast.closeAll();
            addGeneralToast({
                message: e?.message? e?.message : e.statusText,
                type: "error",
            });
        } finally {
            setLoading(false);
        }
    };
    return <>
        <Modal size={{base:'full',md:'xl'}} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody py={10}>
                    <Stack spacing={1} justify={'center'} w="full" h={"full"}>
                        <BillAlert
                            message={`To manually enter customer and utility contract number, use the radio button at the bottom of the form.`}
                        />
                        <SimpleGrid columns={{base:1,lg:2}} gap={2} mt={2}>
                            <FormControl isRequired id="customerNumber">
                                <FormLabel>Customer Number</FormLabel>
                                {!utils.isAuthenticated()||checkForOthers ? (
                                    <Input
                                        isRequired
                                        type="number"
                                        onChange={(e) => setNumber(e.target.value)}
                                    />): <Input
                                    isRequired
                                    isDisabled
                                    type="number"
                                    value={number}
                                    onChange={(e) => setNumber(e.target.value)}
                                />}
                            </FormControl>
                            <FormControl isRequired id="contractNumber">
                                <FormLabel>Utility Number</FormLabel>
                                <>
                                    {!utils.isAuthenticated()|| checkForOthers ? (
                                        <Input
                                            isRequired
                                            type="number"
                                            value={utility}
                                            onChange={(e) => setUtility(e.target.value)}
                                        />
                                    ) : (
                                        <Select
                                            isRequired
                                            placeholder="Select Utility"
                                            onChange={(e) => setUtility(e.target.value)}
                                        >

                                            {utilityContracts?.length > 0 && utilityContracts.map((contract) => (
                                                <option key={contract?.contractNumber} value={contract?.contractNumber}>
                                                    {contract?.contractNumber}
                                                </option>
                                            ))}

                                        </Select>
                                    )}

                                </>
                            </FormControl>
                            <FormControl isRequired id="customerName">
                                <FormLabel>Customer Name</FormLabel>
                                <Input type="text" value={name} onChange={(e) => setName(e.target.value)}/>
                            </FormControl>
                            <FormControl isRequired id="customerCell">
                                <FormLabel>Customer Cell No</FormLabel>
                                <Input type="number" value={cell} onChange={(e) => setCell(e.target.value)}/>
                            </FormControl>
                            <FormControl isRequired id="email">
                                <FormLabel>Email</FormLabel>
                                <InputGroup>
                                    <Input type="emal" value={email} onChange={(e) => setEmail(e.target.value)}/>
                                </InputGroup>
                            </FormControl>
                            <FormControl isRequired id="villageOrTown">
                                <FormLabel>Village/Town</FormLabel>
                                <Input type="text" value={villageOrTown} onChange={(e) => setVillageOrTown(e.target.value)}/>
                            </FormControl>
                        </SimpleGrid>
                        <FormControl isRequired id="comment">
                            <FormLabel>Message</FormLabel>
                            <Textarea value={comment} onChange={(e) => setComment(e.target.value)}/>
                        </FormControl>
                        <FormControl isRequired id="physicalAddress">
                            <FormLabel>Physical Address</FormLabel>
                            <Textarea value={physicalAddress} onChange={(e) => setPhysicalAddress(e.target.value)}/>
                        </FormControl>
                        <FormControl isRequired id="leakLocation">
                            <FormLabel>Leak Location</FormLabel>
                            <RadioGroup value={leakLocation} onChange={setLeakLocation}>
                                <Stack direction="row">
                                    <Radio value="Inside Plot">Inside Plot</Radio>
                                    <Radio value="Outside Plot">Outside Plot</Radio>
                                </Stack>
                            </RadioGroup>
                        </FormControl>
                        <Stack spacing={6} mt={2}>
                            <Button
                                onClick={!loading ? reportLeak : undefined}
                            >
                                {!loading ? "Submit Leak Report" : <PropagateLoader size={8} color="white"/>}
                            </Button>
                        </Stack>
                        {utils.isAuthenticated()&&
                            <HStack mt={2} justifyContent="center" alignItems="center">
                                <Text color={"blue.800"}>Report Water Leaks For Others</Text>
                                <Switch id='bill-for-others' onChange={(e) => setCheckForOthers(e.target.checked)}/>
                            </HStack>
                        }
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    </>;
};

export default LeakReportModal;
