import { useEffect, useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Spinner, Center, Text } from '@chakra-ui/react';
import { PropagateLoader } from 'react-spinners';
import {usePrepaid} from "../../providers/PrepaidProvider";

const DpoModal = ({ isOpen, onClose, redirectUrl}) => {
    const [loading, setLoading] = useState(true);
    const {dpoResponse,setDpoResponse}=usePrepaid()
    const handleIframeLoad = (event) => {
            const receivedObject = event.data;
            // Conditional logic based on the received value
            if (receivedObject.data?.jfdhjuewhifhdie === 'hdfeyugvrejgtfdewklfh') {
                setDpoResponse({
                    message:receivedObject.message,
                    meterNo:receivedObject.data.meterNo,
                    token:receivedObject?.data?.token||null,
                })
               // onClose()
            }
    };

    const handleIframeLoaded = () => {
        setLoading(false);
    };

    useEffect(() => {
        window.addEventListener('message', handleIframeLoad);
        return () => {
            window.removeEventListener('message', handleIframeLoad);
        };
    }, []);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="70%">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Payment Process</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {loading && (
                        <Center height="80vh">
                            <PropagateLoader size={15} color="blue" />
                        </Center>
                    )}
                    <iframe
                        src={redirectUrl}
                        style={{ width: '100%', height: '80vh', border: 'none', display: loading ? 'none' : 'block' }}
                        title="Payment Process"
                        onLoad={handleIframeLoaded}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default DpoModal;
