import React, {useEffect, useState} from "react";
import {
    Box, Checkbox, CheckboxGroup, Divider,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    Input,
    InputGroup,
    Radio,
    RadioGroup,
    Stack,
    Text,
} from "@chakra-ui/react";
import enums from "wuc-library/enums";
import { useLocation } from 'react-router-dom';
import {AreaInput, CityInput} from "../../../components/Applications/Forms/Inputs";
const ConnectionDetails = () => {
    const location = useLocation();

    let {serviceConnectionDetails,connectionType,applicantType,isPlotFenced,isExistingConnection,_id}=location?.state?.application||{}
    const form=JSON.parse(localStorage.getItem('connectionDetails'))
    if(serviceConnectionDetails &&!form){
        let isExisitingConnection2 =  isExistingConnection ?  isExistingConnection.toString() : 'false';
        let isPlotFenced2 = isPlotFenced ? isPlotFenced.toString() : 'false';
        localStorage.setItem('connectionDetails',JSON.stringify({id:_id, ...serviceConnectionDetails,isExistingConnection:isExisitingConnection2,connectionType,applicantType,isPlotFenced:isPlotFenced2}))
    }

    const [formData, setFormData] = useState({
        id: _id||null,
        connectionType: form?.connectionType ||[],
        isExistingConnection: form?.isExistingConnection ||null,
        applicantType: form?.applicantType ||null,
        isPlotFenced: form?.isPlotFenced ||null,
        plotNo:form?.plotNo||null,
        ward:form?.ward||null,
        city:form?.city||null
    });
    useEffect(() => {
        localStorage.setItem('connectionDetails', JSON.stringify(formData));
        const { id,...other }=formData
        if(Object.values(other).some(detail => detail === null||detail === ""||!detail)){
            localStorage.setItem('isValidForm',false );
        }
        else{
            localStorage.setItem('isValidForm',true );
        }


    }, [formData]);
    const handleInputChange = (e) => {
        const {name,checked, value} = e.target;
       // let val=(checked)?checked:value
        setFormData(prevState => ({
            ...prevState, [name]: value
        }));
    };
    const handleCheckboxChange = (e) => {
        const { name, checked, value } = e.target;
        let conArr = formData[name]|| []; // Ensure it's an array, even if undefined initially
        if (checked) {
            // Add the value if it's not already in the array
            if (!conArr.includes(value)) {
                conArr.push(value); // Use push to add the value to the array
            }
        } else {
            // Remove the value if unchecked
          conArr = conArr.filter(item => item !== value); // Use filter to remove the value
        }

        setFormData(prevState => ({
            ...prevState,
            [name]: conArr
        }));
    };


    return (
        <>
            <Box>
                <Flex mb={4}>
                    <Box>
                        <Heading color={'brand.500'}>Connection Type Details</Heading>
                        <Text color={'gray.500'}>Provide information for required connection service </Text>
                    </Box>
                </Flex>

                <Grid templateColumns="repeat(12, 1fr)" gap={2} color={"blue.800"}>
                    {/* ConnectionType */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired>
                            <FormLabel >Connection Type</FormLabel>
                            <CheckboxGroup value={formData.connectionType}>
                                <Stack spacing={5} direction='row'>
                                    <>
                                        {Object.entries(enums.ConnectionType).map(([key, label]) => (
                                            <Checkbox key={key} name='connectionType' id='Connection Type' value={label} onChange={handleCheckboxChange}>
                                                {label}
                                            </Checkbox>
                                        ))}
                                    </>

                                </Stack>
                            </CheckboxGroup>
                        </FormControl>
                    </GridItem>
                    {/* ApplicantType */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired id="relationship">
                            <FormLabel >Applicant Type</FormLabel>
                            <RadioGroup defaultValue='1' value={formData.applicantType} name={'applicantType'} >
                                <Stack spacing={5} direction='row'>
                                    {Object.entries(enums.ApplicantType).map(([key, label]) => (
                                        <Radio key={key} value={label} name='applicantType' id='ApplicantType' onChange={handleInputChange}>
                                            {label}
                                        </Radio>
                                    ))}
                                </Stack>

                            </RadioGroup>
                        </FormControl>
                    </GridItem>
                    {/* IsExistingConnection */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired id="relationship">
                            <FormLabel >Connection Type</FormLabel>
                            <RadioGroup defaultValue='1' value={formData.isExistingConnection} name={'isExistingConnection'} >
                                <Stack spacing={5} direction='row'>
                                    <Radio  value='false' name={'isExistingConnection'} id={'IsExistingConnection'} onChange={handleInputChange} >
                                        New Connection
                                    </Radio>
                                    <Radio  value='true' name={'isExistingConnection'} id={'IsExistingConnection'} onChange={handleInputChange} >
                                        Existing Connection
                                    </Radio>
                                </Stack>
                            </RadioGroup>
                        </FormControl>
                    </GridItem>
                    {/* Fenced */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired id="relationship">
                            <FormLabel >Plot Fenced & Labelled?</FormLabel>
                            <RadioGroup defaultValue='1' value={formData.isPlotFenced} name={'isPlotFenced'} >
                                <Stack spacing={5} direction='row'>
                                    <Radio  value='true' name={'isPlotFenced'} id={'Plot Fenced & Labelled'} onChange={handleInputChange} >
                                        Yes
                                    </Radio>
                                    <Radio  value='false' name={'isPlotFenced'} id={'Plot Fenced & Labelled'} onChange={handleInputChange} >
                                        No
                                    </Radio>
                                </Stack>
                            </RadioGroup>
                        </FormControl>
                    </GridItem>
                    <GridItem my={4} colSpan={12}>
                        <Heading fontSize={'md'}>Service Connection Details</Heading>
                        <Divider />
                    </GridItem>
                    {/* Plot No */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired id="plotNo">
                            <FormLabel >Plot No</FormLabel>
                            <InputGroup>
                                <Input type="text" name="plotNo"  id="Plot Number"  value={formData.plotNo} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                    {/* City / Town / Village */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <CityInput callback={handleInputChange} value={formData.city}/>
                    </GridItem>
                    {/* Area / Ward */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <AreaInput callback={handleInputChange} value={formData.ward} cityName={formData?.city}/>
                    </GridItem>


                </Grid>
            </Box>
        </>
    );
};

export default ConnectionDetails;
