import React, {useEffect, useState} from "react";
import { FormControl, FormLabel, Select } from "@chakra-ui/react";
import utils from "../../../utils/commonFunctions";

export const AreaInput = ({value,callback,cityName,isRequired=true}) => {
    const [areas, setAreas] = useState([]);

    const getAreas = async () => {
        try {
            let fetchedAreas = await utils.fetchAreas({cityName:cityName});
            if (fetchedAreas && fetchedAreas.length > 0) {
                setAreas(fetchedAreas);
            } else {
                setAreas([]);
            }
        } catch (e) {
            console.error('Failed to fetch areas:', e);
            setAreas([]);
        }
    };

    useEffect(() => {
        if (cityName) {
            getAreas();
        }
    }, [cityName]); // Fetch areas whenever cityName changes

    return (
        <FormControl  isRequired={isRequired}>
            <FormLabel htmlFor="area">Area / Ward</FormLabel>
            <Select id="area" name='ward' placeholder="Select area" value={value} onChange={(e)=>callback(e)}>
                {areas.map((area) => (
                    <option key={area._id} value={area.name}>{area.name}</option>
                ))}
            </Select>
        </FormControl>
    );
};

export const CityInput = ({value,callback,isRequired=true,placeholder='Select city',label='City / Town / Village'}) => {
    const [cities,setCities]=useState([])
    const getCities = async () => {
        try {
            let cities = await utils.fetchCities({pageSize:90000});
            if (cities && cities.length > 0) {
                setCities(cities);
            } else {
                setCities([]);
            }
        } catch (e) {
            console.error('Failed to fetch cities:', e);
            setCities([]);
        }
    };
    useEffect(() => {
        getCities();
    }, []);

    return (
        <FormControl isRequired={isRequired}>
            <FormLabel htmlFor="city">{label}</FormLabel>
            <Select name="city" placeholder={placeholder} value={value}  onChange={(e)=>callback(e)}>
                {cities.map((city) => (
                    <option key={city._id} value={city.name}>{city.name}</option>
                ))}
            </Select>
        </FormControl>
    );
};
