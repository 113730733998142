import React, { useState, useEffect } from 'react';
import { Box, Image, Text, VStack, Heading } from '@chakra-ui/react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import utils from "../../utils/commonFunctions";

// Import local images
import image1 from './../../assets/D2 12.jpg';

const Carousel = () => {
  const [slides, setSlides] = useState([
    {
      imageKey: image1,
      title: '',
      description: '',
    },
  ]);

  useEffect(() => {
    const fetchBanners = async () => {
      const response = await utils.fetchBanners({
        published: true,
        position: "Slide",
      });
      if (response && response.length) setSlides(response);
    };
    fetchBanners();
  }, []);

  return (
      <Box h={{base:"150px",md:"260px",lg:"350px",xl:'450px'}} mb={{base:"-50px" ,md:"-70px",lg:"-50px",xl:"-140px"}} mt={{base:"30px" ,md:"88px",lg:'-80px', xl:0}} bg={'white'}>
          <Splide
              options={{
                  type: 'loop',
                  autoplay: true,
                  autoHeight:true,
                  interval: 5000, // Slide change interval (in milliseconds)
                  pauseOnHover: false,
                  pagination: true, // Enable pagination by default
                  breakpoints: {
                      425: {
                          pagination: false, // Disable pagination on devices smaller than 425px
                      },
                  },
                  arrows: false,
                  speed: 500,
              }}
          >
              {slides.map((slide, index) => (
                  <SplideSlide
                      key={`slide-${index}`}
                  >
                      <Box
                          shadow="md"
                          pos="relative"
                      >
                          <Image
                              src={slide.imageKey}
                              alt={slide.title}
                              w="100%"
                              h={{base:"250px",lg:"450px"}}// Adjust height for small and larger screens
                              objectFit={{base:"contain",xl:"contain"}}
                          />
                          {slide?.hasText && (
                              <VStack
                                  p="8px 12px"
                                  pos="absolute"
                                  top={{ base: "100px", md: "150px" }} // Adjust position for small and large screens
                                  textAlign="center"
                                  w="full"
                                  mb="8"
                                  color="white"
                                  spacing={4}
                              >
                                  <Heading as="h1" size={{ base: "xl", md: "3xl" }}>
                                      {slide.title}
                                  </Heading>
                                  <Box maxHeight="300px" maxW={{ base: "90%", md: "650px" }}>
                                      <Text fontSize={{ base: "16px", md: "18px" }}>
                                          <div
                                              dangerouslySetInnerHTML={utils.sanitizeHtml(
                                                  slide?.description
                                              )}
                                          />
                                      </Text>
                                  </Box>
                              </VStack>
                          )}
                      </Box>
                  </SplideSlide>
              ))}
          </Splide>
      </Box>

  );
};

export default Carousel;
