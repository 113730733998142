import React from "react";
import CustomerServicesComponent from "./Components/CustomerServices";
import PublicViewContainer from "../../components/Containers/PublicViewContainer";


const CustomerServices = ({}) => {
    return (<>
      <PublicViewContainer childComponent={<CustomerServicesComponent/>}/>
    </>);
};

export default CustomerServices;
