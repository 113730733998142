import React, { useEffect, useState } from "react";
import { Box, Flex, Heading, Link, Text } from "@chakra-ui/react";
import utils from "../../../utils/commonFunctions";
import { useLocation } from "react-router-dom";

const LinkViewer = ({ link, title }) => {
    return (
        <Flex direction={"column"} w={"100%"} justify={"center"} mt={4}>
            <Link href={link} textDecoration={'none'}>
                <Box bg={'blue.50'} p={6} borderRadius={'2xl'} color={'blue.700'} textDecoration={'none'}>
                    {title}
                </Box>
            </Link>
        </Flex>
    );
};

const LinkPages = () => {
    const { state } = useLocation();
    const filter = state;

    const [page, setPage] = useState(null);

    const handleViewPage = async () => {
        if (filter) {
            try {
                const fetchedPage = await utils.fetchPages(filter);

                if (fetchedPage) {
                    setPage(fetchedPage[0]);
                }
            } catch (error) {
                console.error("Failed to fetch page:", error);
                setPage(null);
            }
        } else {
            console.log("filter ", filter, state);
            setPage(null);
        }
    };

    useEffect(() => {
        handleViewPage();
    }, []);

    return (
        <Flex direction={"column"} w={"100%"} justify={"center"} mt={4}>
            {page && (
                <>
                    <Heading size={{ base: "md", md: "lg" }} mb={6} color={"brand.700"}>
                        {page.pageName}
                    </Heading>
                    <Box>
                        {page.revisions &&
                            page.revisions.map((rev) => {
                                if (rev && rev.active) {
                                    return (
                                        <div key={rev._id}>
                                            {rev.isForm
                                                ? rev.url.map((url) => (
                                                    <LinkViewer
                                                        key={url.link} // Adding key prop to prevent React warning
                                                        link={url.link}
                                                        title={url.title}
                                                    />
                                                ))
                                                : null}
                                            <div
                                                dangerouslySetInnerHTML={utils.sanitizeHtml(rev?.description)}
                                            />
                                        </div>
                                    );
                                }
                                return null; // Return null if not active
                            })}
                    </Box>
                </>
            )}
        </Flex>
    );
};

export default LinkPages;
