import {
    Input,
    Stack,
    Button,
    FormLabel,
    FormControl,
    InputGroup,
    useToast,
    Select, HStack, Text, Switch,

} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";

import {useNavigate} from "react-router-dom";
import {PropagateLoader} from "react-spinners";
import {CustomGeneralToast} from "../../../utils/customChakraToasts";
import {API} from "../../../utils/api";
import BillAlert from "../../../components/PortalContainer/BillAlert";
import utils from "../../../utils/commonFunctions";
import {useUserProfile} from "../../../providers/UserProfileProvider";
import {useQuoteCart} from "../../../providers/QuoteCartProvider";
import {ContractNumberInput, CustomerNumberInput} from "../../../components/Applications/Forms/Inputs";

const SubmitMeterReadingForm = () => {
    const userId=utils.getUserId()

    const [number, setNumber] = useState("");
    const [utility, setUtility] = useState("");
    const [loading, setLoading] = useState(false);
    const [checkForOthers, setCheckForOthers] = useState(false);
    const [success, setSuccess] = useState(false);
    const {addGeneralToast} = CustomGeneralToast();
    const toast = useToast();
    const {refreshFlag,setRefreshFlag}=useQuoteCart()

    useEffect(() => {
        if(success){
            setNumber("")
            setUtility("")
        }
    }, [success,checkForOthers]);

    const requestBill= async () => {
        if (number === "" || utility === "") {
            toast.closeAll();
            addGeneralToast({
                message: "All fields must be filled in",
                type: "warning",
            });
            setLoading(false);
            return;
        }
        setLoading(true);
        try {
            const response = await API.post(
                `/meter/monthly-bill`,
                {
                    customerNumber: number,
                    contractNumber: utility,
                    createdBy: userId,
                }
            );

            const data = await response.data;
            if (response.status === 200) {
                toast.closeAll();
                addGeneralToast({
                    message: data.message,
                    type: "success",
                });
                setLoading(false);
                setSuccess(true)
                setRefreshFlag(true)
            } else {
                toast.closeAll();
                addGeneralToast({
                    message: data.message,
                    type: "error",
                });
                setLoading(false);

            }
        } catch (e) {
            setLoading(false);
            toast.closeAll();
            console.log(e)
            addGeneralToast({
                message: e?.message? e?.message : e.statusText,
                type: "error",
            });
        }

    };
    const handleSetUtility = (e) => {
        const {value} = e.target;
        setUtility(value)
    };
    const handleSetNumber = (e) => {
        const {value,name} = e.target;
        setNumber(value)
    };
    return <>

        <Stack spacing={1} justify={'center'} w="full" h={"full"} px={4}>
            <BillAlert/>
            {checkForOthers ? (
                <FormControl isRequired id="customerNumber">
                    <FormLabel>Customer Number</FormLabel>
                    <Input
                        type="number"
                        placeholder={"Customer Number"}
                        onChange={(e) => setNumber(e.target.value)}
                    />
                </FormControl>
            ) : (
                <CustomerNumberInput
                    value={number}
                    callback={handleSetNumber}
                />
            )}
            {checkForOthers ? (
                <FormControl isRequired id="contractNumber">
                    <FormLabel>Utility Number</FormLabel>
                    <Input
                        type="number"
                        placeholder={"Utility Number"}
                        onChange={(e) => setUtility(e.target.value)}
                    />
                </FormControl>
            ) : (
                <ContractNumberInput
                    value={ utility}
                    customerNumber={number}
                    callback={handleSetUtility}
                />
            )}


            <Stack spacing={6} mt={2}>
                {loading ? (
                    <Button variant={'solid'}>
                        <PropagateLoader size={8} color={'white'}/>
                    </Button>
                ) : (
                    <Button
                        type="submit"
                        variant="solid"
                        onClick={requestBill}
                    >
                        Request Monthly Bill
                    </Button>
                )}
            </Stack>
            {utils.isAuthenticated()&&
                <HStack mt={2} justifyContent="center" alignItems="center">
                    <Text color={"blue.800"}>Utility Contract not listed?</Text>
                    <Switch id='bill-for-others' onChange={(e) => setCheckForOthers(e.target.checked)}/>
                </HStack>
            }

        </Stack>


    </>;
};

export default SubmitMeterReadingForm;
