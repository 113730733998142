import {
    Flex,
    Stack,
    Button,
    Thead,
    Tr,
    Th,
    Table,
    TableContainer, Tbody, Td, Heading, Spacer, IconButton, Tooltip, TagLabel, Tag, useDisclosure, Hide, Show,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";

import {Link} from "react-router-dom";
import {API} from "../../../utils/api";
import {FaPlus} from "react-icons/fa6";
import utils from "../../../utils/commonFunctions";
import Paginate from "../../../components/Paginate/Paginate";
import {MdFileOpen} from "react-icons/md";
import PdfModal from "../../../components/PdfViewer/PdfModal";
import DisconnectionRequestPdf from "../../../components/PdfViewer/DisconnectionRequestPdf";

const ConnectionTable = () => {
    const {isOpen,onOpen,onClose} = useDisclosure();

    const [currentPage, setCurrentPage] = useState(1);
    const userId = utils.getUserId();
    // Date filter for the last 3 months
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
    threeMonthsAgo.setHours(0, 0, 0, 0);



    const [applications, setApplications] = useState([]);
    const [pdfData, setPdfData] = useState([]);

    const getConnections= async () => {

        try {

            const response = await API.get(
                `/connection/find/disconnection-requests`,
                {params:{
                        id: null,
                        customerNumber:null,
                        profileId:null,
                        userId:userId,
                        contractNumber:null,
                        pageNumber:currentPage,
                        pageSize:4
                    }}
            );
            const data = await response.data;
            console.log(data.data)
            if (response.status === 200) {
                setApplications(data.data)
            }

        } catch (e) {
            console.log(e)
        }

    };
    useEffect(() => {
        getConnections();
    }, [currentPage]);

    const handleView= async (application) => {
        try {
            setPdfData(application)
            onOpen()
        } catch (e) {
            console.log(e)
        }

    };
    return <>
        <Stack gap={0} justify={'center'} p={{base: 4, md: 8}} bg={'white'} boxShadow="md" w="full" minH={{base: '60vh', md: '69vh'}} borderRadius={'2xl'}>
            <Flex   justify={{base:'start'}} align={'center'}  w={"full"} bg={'white'}>
                <Heading color={'blue.800'} fontSize={{base:'md',md:'xl'}}>Disconnections List</Heading>
                <Spacer/>
                <Link to={'/self-service-portal/disconnection'}>
                    <Hide below={'md'}>
                        <Button  variant={'solid'} leftIcon={<FaPlus fontSize={'lg'} fontWeight={'bold'}/>}>New Application</Button>
                    </Hide>
                    <Show below={'md'}>
                        <IconButton   variant={'solid'} size={'sm'} icon={<FaPlus fontSize={'sm'} fontWeight={'bold'}/>}/>
                    </Show>
                </Link>
            </Flex>
            <TableContainer w="full" h={"full"} bg={'white'}  p={10}>
                <Table  colorScheme='gray'>
                    <Thead>
                        <Tr  >
                            <Th color={'blue.800'} textTransform={'capitalize'} fontSize={'sm'}>Name</Th>
                            <Th color={'blue.800'} textTransform={'capitalize'} fontSize={'sm'}>Customer Number</Th>
                            <Th color={'blue.800'} textTransform={'capitalize'} fontSize={'sm'}>Contract Number</Th>
                            <Th color={'blue.800'} textTransform={'capitalize'} fontSize={'sm'}>Service Area/Ward</Th>
                            <Th color={'blue.800'} textTransform={'capitalize'} fontSize={'sm'}>Status</Th>
                            <Th color={'blue.800'} textTransform={'capitalize'} fontSize={'sm'}>Action</Th>
                        </Tr>
                    </Thead>

                    <Tbody>
                        {(applications?.docs?.length>0)&&
                            <>
                                {
                                    applications.docs.map((application)=>(
                                        <Tr>

                                            <Td>{application.personalDetails.firstName+" "+application.personalDetails.lastName}</Td>
                                            <Td>{application.customerNumber}</Td>
                                            <Td >{application.contractNumber}</Td>
                                            <Td >{application.address.city}</Td>
                                            <Td >
                                                <Tag size='lg' colorScheme='gray' borderRadius='full'>
                                                    <TagLabel>Submitted</TagLabel>
                                                </Tag>
                                            </Td>
                                            <Td  >
                                                {/** <Tooltip label={'Edit Application'}>
                                                 <IconButton
                                                 isDisabled={application?.status!==enums.ConnectionRequestSta
                                                 tus.SUBMITTED}
                                                 onClick={()=>handleUpdate(application?._id)}
                                                 bg={'orange.500'}
                                                 _hover={{bg:'orange.600'}}
                                                 aria-label=''
                                                 icon={<MdEdit />}
                                                 />

                                                 </Tooltip>*/}
                                                <Tooltip label={'View Application'}>
                                                    <IconButton
                                                        onClick={()=>handleView(application)}
                                                        ml={2}
                                                        bg={'gray.500'}
                                                        _hover={{bg:'gray.600'}}
                                                        aria-label=''
                                                        icon={<MdFileOpen />}
                                                    />
                                                </Tooltip>

                                            </Td>
                                        </Tr>
                                    ))
                                }
                            </>
                        }
                    </Tbody>
                </Table>
            </TableContainer>
            <PdfModal PdfTemplate={  <DisconnectionRequestPdf pdfData={pdfData}/>} isOpen={isOpen} onClose={onClose} />
            { (!applications||applications.docs?.length <=0) &&
                <Flex justifyContent="center" align="center" w="full" h="full" py={16}>
                    <Heading size="lg" color="gray.500">No Record Found</Heading>
                </Flex>
            }
            <Paginate
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                numberOfPages={applications?.totalPages||1}
            />
        </Stack>

    </>;
};

export default ConnectionTable;