import React, { useEffect, useState } from "react";
import {
    Button, Checkbox, useToast, InputLeftAddon,
    Flex, FormControl, FormLabel, Grid, GridItem, Input, InputGroup, Textarea, useDisclosure
} from "@chakra-ui/react";
import { Box } from "@chakra-ui/layout";
import PaymentSummaryModal from "../../../components/PayGate/PaymentSummaryModal";
import {API} from "../../../utils/api";
import { useParams } from "react-router-dom";
import utils from "../../../utils/commonFunctions";
const CryptoJS = require('crypto-js');

const BuyTender = ({ tenderId }) => {
    const terminalId = process.env.PAYTENDER_TERMINAL_ID
    const [formData, setFormData] = useState({
        p1: terminalId,
        firstName: null,
        middleName: null,
        lastName: null,
        relationship: null,
        kinCell: null,
        fullName: null,
        email: null,
        phone: null,
        address: null,
        tenderId: null,
        isYouth: false,
        fees: null,
        title:null
    });
    const [tender, setTender] = useState([])
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { id } = useParams(); // This will get the ID from the URL


    const fetchTenders = async () => {
        try {
            const response = await API.get(`/tenders/${id}`);
            setTender(response.data);
            setFormData(prevState => ({
                ...prevState,
                tenderId: id,
                title:response.data.title,
                fees: response.data.fees
            }));
            
        } catch (error) {
            await utils.sendMessageToBugNinja('ERROR FETCHING TENDERS', error.message, error);
            console.error("Error fetching tenders: ", error);
        }
    };

    useEffect(() => {
        fetchTenders();
    }, [])

    const handleSubmit = () => {
        if (!formData.fullName) {
            return toast({
                title: "Error",
                description: "Company name is required",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }

        if (!formData.tenderId) {
            return toast({
                title: "Error",
                description: "Tender number is required",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }

        if (!formData.email) {
            return toast({
                title: "Error",
                description: "Email is required",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }

        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            return toast({
                title: "Error",
                description: "Email format is incorrect, expected format 'john@abc.com'!",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }

        if (!formData.phone) {
            return toast({
                title: "Error",
                description: "Cell number is required",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }

        if (formData.phone.length != 8) {
            return toast({
                title: "Error",
                description: "Cell number must be 8 digits!",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }

        if (!formData.address) {
            return toast({
                title: "Error",
                description: "Address is required",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }

        onOpen();

    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleTenderPrice = () => {
        setFormData(prevState => {
            const isYouth = !prevState.isYouth;
            const fees = isYouth ? tender.fees * 0.5 : tender.fees;
            return {
                ...prevState,
                isYouth,
                fees
            };
        });
    }

    return (
        <>
            {/* <Form> */}
            <Flex
                w="full"
                bg={'white'}
                align={'center'}
                justify={'center'}
                py={16}
                px={{ base: 4, lg: 12 }}
                borderRadius={'2xl'}
            >
                <Grid w={'full'} templateColumns="repeat(12, 1fr)" gap={6} color={"blue.800"}>
                    <GridItem colSpan={{ base: 12, md: 6 }}>
                        <FormControl isRequired>
                            <FormLabel>Name Of Company</FormLabel>
                            <InputGroup>
                                <Input type="text" name="fullName" onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                    <GridItem colSpan={{ base: 12, md: 6 }}>
                        <FormControl isRequired>
                            <FormLabel>Email Address</FormLabel>
                            <InputGroup>
                                <Input type="email" name="email" onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                    <GridItem colSpan={{ base: 12, md: 6 }}>
                        <FormControl isRequired>
                            <FormLabel>Cell</FormLabel>
                            <InputGroup>
                                <Input type="number" name="phone" onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                    <GridItem colSpan={{ base: 12, md: 6 }}>
                        <FormControl isRequired>
                            <FormLabel>Postal Address</FormLabel>
                            <InputGroup>
                                <Textarea borderRadius={'2xl'} type="text" name="address" onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                    <GridItem colSpan={{ base: 12, md: 6 }} >
                        <FormControl>
                            <FormLabel fontWeight={'semibold'} mb={2}>Tender fees:<Box as={'span'}>P{formData.fees}</Box></FormLabel>
                            <InputGroup>
                                <Checkbox name="isYouth" onChange={handleTenderPrice}>Youth Discount</Checkbox>
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                    <GridItem colSpan={{ base: 12 }}>
                        <Flex justify={'end'}>
                            <Button type="button" onClick={handleSubmit}> Submit </Button>
                        </Flex>
                    </GridItem>
                </Grid>

                <PaymentSummaryModal
                    isOpen={isOpen}
                    balance={formData.fees}
                    tenderId={tenderId}
                    onClose={onClose}
                    terminalId={terminalId}
                    paymentType={'TenderPayment'}
                    customer={formData}
                    isYouth={formData.isYouth}
                />

            </Flex>
            {/* </Form> */}
        </>
    );
};

export default BuyTender;
