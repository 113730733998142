import React, {useState, useRef, useEffect} from "react";
import {
    Flex,
    Box,
    Text,
    VStack,
    Icon,
    Link,
    SimpleGrid,
    Grid,
    GridItem,
    Hide,
} from "@chakra-ui/react";
import {GiWheelbarrow} from "react-icons/gi";
import {IoMdPerson} from "react-icons/io";
import {TiHome} from "react-icons/ti";
import {FaBriefcase} from "react-icons/fa";
import {QuickLinksMenu} from "./QuickLinksMenu";
import {MenuLink_V1} from "./MenuLink";

import {useInViewport} from "react-in-viewport";
import utils from "../../utils/commonFunctions";
import {pageCategories} from "wuc-library/enums";

const CustomBox = ({icon, text, href, left, menuItems}) => {
    const [isHovered, setIsHovered] = useState(false);
    const boxRef = useRef(null); // Reference to the CustomBox
    const [menuTop, setMenuTop] = useState("0px"); // State for MenuLink top position

    useEffect(() => {
        if (boxRef.current) {
            setMenuTop(`${boxRef.current.offsetHeight}px`); // Set top position based on the height of the box
        }
    }, [boxRef]);

    return (
        <Box
            ref={boxRef}
            position="relative"
            h={{base:'full',md:"120px"}}
            onMouseEnter={() => {
                setIsHovered(true);
            }}
            onMouseLeave={() => {
                setIsHovered(false);
            }}
        >
                <VStack
                    spacing={4}
                    alignItems="center"
                    justifyContent="start"
                    cursor="pointer"

                >
                    <Flex
                        p={4}
                        bg={isHovered ? "#0093D0" : "#3c4043"}
                        borderRadius="full"
                        shadow="md"
                        transition="all 0.3s"
                        _hover={{

                            ".icon-color": {
                                color: "white",
                            },
                        }}

                        alignItems="center"
                        justifyContent="center"
                        cursor="pointer"
                        w={{base: "65px", md: "65px"}}
                        h={{base: "65px", md: "65px"}}
                    >
                        <Icon
                            as={icon}
                            boxSize={{base: 6, md: 6}}
                            color="white"
                            sx={{transition: "color 0.3s"}}
                            className="icon-color"
                        />
                    </Flex>
                    <Hide below={'sm'}>
                        <Text color={isHovered ? "#0093D0" : "#3c4043"} textAlign={{base: 'center', md: 'start'}}
                              fontSize={{base: "12px", md:'16px',lg: '18px'}} fontWeight="bold">
                            {text}
                        </Text>
                    </Hide>

                </VStack>

            {isHovered && (
                <MenuLink_V1 isOpen={isHovered} left={left} children={menuItems}/>
            )}{" "}
        </Box>
    );
};

const QuickLinks = () => {
    const [pages, setPages] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const waterPages = [
                {
                    label:pageCategories.YOUR_WATER_ACCOUNT,
                    children: [
                        { href: "/login", label: "Login / Register", isAuthenticated: true },
                        { href: "/self-service-portal/new-connection", label: "New Connection", protected: true },
                        { href: "/pay-bill-online", label: "Check / Pay Bill Now", protected: true, category: "Your water account" },
                        { href: "/reports", label: "Reports", protected: true, category: "Your water account" },
                        //{ href: "/report-water-shortage", label: "Report Water Shortage", protected: true, category: "Your water account" },
                        //{ href: "/report-leak", label: "Report Leak", protected: true, category: "Your water account" },
                        { href: "/submit-meter-reading", label: "Submit Meter Reading", protected: true, category: "Your water account" },
                        { href: "/request-monthly-bill", label: "Request Monthly Bill", protected: true, category: "Your water account" },
                        { href: "/bill-calculator", label: "Bill Calculator", protected: true, category: "Your water account" },
                    ],
                }
            ];
            const Labs = [
                {
                    label:'Our Services',
                    children: [
                        { href: "/laboratory-services", label: "Laboratory Services 2"},
                    ],
                }
            ];
            const categories = [
                pageCategories.YOUR_WATER_ACCOUNT,
                pageCategories.SERVICES,
                pageCategories.RESIDENTIAL,
                pageCategories.COMMERCIAL
            ];

            const pagesByCategory = {};

            try {
                for (const category of categories) {
                    const pages = await utils.fetchPagesWithChildren(category);
                    pagesByCategory[category] = pages && pages.length > 0 ? pages.flatMap(page => {
                        if (!page.parentPage) {
                            return {
                                label: page.pageName,
                                children: page.children ? page.children.map(child => ({
                                    href: `/content/${child.pageName.toLowerCase().replace(/\s+/g, '-')}`,
                                    label: child.pageName,
                                    id: child._id,
                                    isForm:child.isForm,
                                    url :child?.revisions?.find(revision => revision?.active)?.url||{}

                                })) : []
                            };
                        }
                        return [];
                    }) : [];
                }

                // Ensure the key exists before spreading it to avoid any runtime error
                pagesByCategory[pageCategories.YOUR_WATER_ACCOUNT] = [
                    ...waterPages,
                    ... (pagesByCategory[pageCategories.YOUR_WATER_ACCOUNT] || []) // Safely handle possibly undefined array

                ];
                setPages(pagesByCategory);
            } catch (error) {
                console.error("Failed to fetch pages:", error);
            }
        };

        fetchData();
    }, []); // Empty dependency array means this effect runs once on mount

    return (
        <Grid w="full" h="25px" templateColumns="repeat(12, 1fr)" bg={'white'}>
            {/**QuickLinks Label*/}
            <Hide below={'md'}>
                <GridItem colSpan={{base: 1, lg: 1}}>
                    <QuickLinksMenu/>
                </GridItem>
            </Hide>


            <GridItem colSpan={{base: 12, lg: 11}}>
                <SimpleGrid  columns={{base:4}}   spacingX={{base: "60px", md: "20px"}} py={6} px={8} mt={-2}>
                    <CustomBox
                        icon={IoMdPerson}
                        text="Your Water Account"
                        href="#"
                        left={{base:"-40px",md:"-70px"}}
                        menuItems={pages[pageCategories.YOUR_WATER_ACCOUNT]}
                    />
                    <CustomBox
                        icon={GiWheelbarrow}
                        text="Our Services"
                        href="#"
                        left={{base:"-140px",md:"-70px"}}
                        menuItems={pages[pageCategories.SERVICES]}
                    />
                    <CustomBox
                        icon={TiHome}
                        text="Residential"
                        href="#"
                        left={{base:"-230px",md:"-200px"}}
                        menuItems={pages[pageCategories.RESIDENTIAL]}
                    />
                    <CustomBox
                        icon={FaBriefcase}
                        text="Commercial"
                        href="#"
                        left={{base:"-320px",md:"-300px"}}
                        menuItems={pages[pageCategories.COMMERCIAL]}
                    />
                </SimpleGrid>
            </GridItem>
        </Grid>
    );
};

export default QuickLinks;
