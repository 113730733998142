import React from 'react';
import PortalContainer from "../../components/PortalContainer/PortalContainer";
import ManageUtilityTable from "./components/ManageUtilityTable";

const ManageUtilityContracts = () => {
  return (
      <PortalContainer childComponent={ <ManageUtilityTable/>}  Title={'Manage Utility Contracts'}/>
  )};

export default ManageUtilityContracts;
