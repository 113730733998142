import React from "react";
import ServicesOffered from "./ServicesOffered";
import {
  
  Grid,
  GridItem,
  
} from "@chakra-ui/react";
import QuotationCart from "./QuotationCart";


const QuotationForm = () => {
    return (
    <>

      <Grid
        templateColumns="repeat(12, 1fr)"
        gap={6}
        w={"100%"}
        justify={"center"}
        mt={4}
      >
        <GridItem colSpan={{base:12}} p={"10"} borderRadius="2xl" bg={"white"}>
          <ServicesOffered />
        </GridItem>
      </Grid>

    </>
  );
};

export default QuotationForm;
