import PortalContainer from "../../components/PortalContainer/PortalContainer";
import CheckBill from "./Components/CheckBill";
import SelfServiceContainer from "../../components/PortalContainer/SelfServiceContainer";
import React from "react";
import CheckBillTable from "./Components/CheckBillTable";


const PayBillOnline = () => {
  return <>
    <PortalContainer childComponent={<SelfServiceContainer childComponent={<CheckBill /> } table={<CheckBillTable/>}/>}  Title={'Pay Bill Online'}/>
  </>
};

export default PayBillOnline;
