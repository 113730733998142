import React, {useEffect, useState} from "react";
import {
    Box,
    Heading,
    Flex,
    Image,
    Text,
    GridItem,
    Grid, useDisclosure,

} from "@chakra-ui/react";

import PhotoViewer from "./PhotoViewer";
import Picture from "./Picture";
import placeholder from"../../../../assets/albumPlaceholder.jpg";
import {usePhoto} from "../../../../providers/PhotosProvider";
import utils from "../../../../utils/commonFunctions";

const Pictures = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { setPhoto } = usePhoto();
    const [albums, setAlbums] = useState([]);
    const [selectedAlbum, setSelectedAlbum] = useState([]);

    useEffect(() => {
        async function fetchAlbums() {
            setAlbums(await utils.fetchPhotoAlbums());
        };
        fetchAlbums();
    }, []);

    const handleView = (index) => {
        setSelectedAlbum(albums[index]);
        onOpen();
    };

    return (
        <>
            <Flex justify="center" align="center" bg="white" py={8} px={{ base: 0, md: 8 }} w="full">
                <Box>
                    <Heading size="xs" mb={2} textAlign="center" color="blue.800">Media</Heading>
                    <Heading size="lg" mb={4} textAlign="center" color="blue.800">Pictures</Heading>
                    <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                        {albums.map((album, index) => (
                            <GridItem key={index} colSpan={{ base: 12, md: 6, lg: 4 }} onClick={() => handleView(index)}>
                                <Box position="relative" shadow="md" overflow="hidden" height="250px" width="100%">
                                    <Image src={album.cover || placeholder} alt={album.title} objectFit="cover" boxSize="100%" />
                                    <Text position="absolute" bottom="0" width="100%" textAlign="center" bg="rgba(0, 0, 0, 0.5)" color="white" p={2}>{album.title}</Text>
                                </Box>
                            </GridItem>
                        ))}
                    </Grid>
                </Box>
            </Flex>
            {selectedAlbum.photos && (
                <PhotoViewer PicturesArr={selectedAlbum.photos} isModalOpen={isOpen} onClose={onClose} />
            )}
        </>
    );
};


export default Pictures