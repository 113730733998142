import React, {useEffect, useState, useCallback } from "react";
import {
    Flex,
    Image,
    Center, VStack,
    Text, Heading
} from "@chakra-ui/react";
import {API} from "../../utils/api";
import PollClosed from "../../assets/closed.png";
import PollQuestions from "./PollQuestions";
import utils from "../../utils/commonFunctions";
import axios from "axios";
import {HashLoader} from "react-spinners";

const Survey = () => {
    const [ipAddress, setIpAddress] = useState('');
    const [questionIndex, setQuestionIndex] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [questions, setQuestions] = useState([]);

    const getPolls = useCallback(async () => {
        setLoading(true);
        try {
            const response = await API.get(
                `/common/polls?forSurvey=false`
            );
            const data = await response.data;
            if (response.status === 200) {
                setQuestions(data);
            } else {
                setQuestions([]);
            }
        } catch (e) {
            utils.sendMessageToBugNinja('FAILED TO FETCH IP POLLS ', e.message);
            setQuestions([]);
            console.log(e);
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchIPAddress = async () => {
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            setIpAddress(response.data.ip);
        } catch (error) {
            console.error('Error fetching IP address: ', error);
            utils.sendMessageToBugNinja('FAILED TO FETCH IP ADDRESS ', error.message);
            setIpAddress('');
        }
    };

    useEffect(() => {
        fetchIPAddress();
        getPolls();
    }, [getPolls]);


    const filteredQuestions = questions.filter((question) =>
        !question.ipAddresses.includes(ipAddress)
    );

    const refreshSurvey = () => {
        fetchIPAddress();
        getPolls();
    };
    if (isLoading) {
        return (
            <Flex justifyContent="center" align="center" w="full" h="full">
            <HashLoader color="#2bbcf2"/>
            </Flex>
        );
    }

    //if the filteredQuestions array is empty, it means the user has already voted on all the questions
    if (filteredQuestions.length === 0) {
        return (
            <Flex w={'full'} justify={'center'}>
                <VStack
                    spacing={4}
                    align='stretch'
                >
                <Center>
                    <Heading size={'lg'} color={'brand.700'}>Polls</Heading>
                </Center>
                <Center>
                    <Image
                        borderRadius='10px'
                        boxSize='150px'
                        src={PollClosed}
                        alt='poll is closed'
                    />         
                </Center>   
                <Text color='black' fontSize='18px' textAlign='center'>There are no poll questions at the moment!</Text>
            </VStack>
            </Flex>
        );
    }

    return (
        <>
            <Flex w={'full'}>
                <PollQuestions questions={filteredQuestions[questionIndex]} ipAddress={ipAddress} refreshSurvey={refreshSurvey}/>
            </Flex>
        </>
    );
};

export default Survey;
