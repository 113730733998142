import PublicViewContainer from "../../components/Containers/PublicViewContainer";
import React, { useEffect, useState } from 'react';
import {
    Box,
    Text,
    SimpleGrid,
    Flex,
    Icon,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    FormControl,
    InputGroup,
    Input,
    InputRightElement,
    IconButton, Heading
} from "@chakra-ui/react";
import { SiGooglemaps } from "react-icons/si";
import utils from "../../utils/commonFunctions";
import { SearchIcon } from "@chakra-ui/icons";

const BranchCard = ({ name, description, branchMap }) => {
    return (
        <Box borderRadius={'2xl'} bg={'blue.50'} p={4}>
            <Text color={'brand.500'} fontSize={'18px'} fontWeight={'semibold'} textTransform={'uppercase'}>{name}</Text>
            <Box mt={4}>
                <Text>
                    <div dangerouslySetInnerHTML={utils.sanitizeHtml(description)} />
                </Text>
            </Box>
            {branchMap &&
                <Box mt={4}>
                    <MapLink mapEmbed={branchMap} />
                </Box>
            }
        </Box>
    );
};

const MapLink = ({ mapEmbed }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const extractSrc = (mapEmbed) => {
        const srcMatch = mapEmbed.match(/src="([^"]+)"/);
        return srcMatch ? srcMatch[1] : null;
    };

    return (
        <>
            <Flex cursor={'pointer'} onClick={onOpen} justify={'space-between'} align={'center'} _hover={{ color: 'green.400' }} py={4} color={'red.400'}>
                <Text>Locate on Google Maps</Text>
                <Icon as={SiGooglemaps} />
            </Flex>

            <Modal onClose={onClose} isOpen={isOpen} isCentered size={{ base: 'full', md: 'xl' }}>
                <ModalOverlay />
                <ModalContent w={'full'} h={'450px'}>
                    <ModalCloseButton />
                    <ModalBody h={'full'}>
                        <iframe
                            src={extractSrc(mapEmbed)}
                            width="100%"
                            height="100%"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

const Branches = () => {
    const [branches, setBranches] = useState([]);
    const [filter, setFilter] = useState({
        name: '',
        pageNumber: 1,
        pageSize: 200
    });

    useEffect(() => {
        const getBranches = async () => {
            const fetchedBranches = await utils.fetchBranches(filter);
            if (fetchedBranches) {
                setBranches(fetchedBranches);
            }
        };

        getBranches();
    }, [filter]);

    return (
        <>
            <FormControl my={4} bg={'white'}>
                <InputGroup>
                    <Input
                        type="text"
                        name="name"
                        focusBorderColor="brand.700"
                        placeholder="Search branch name"
                        value={filter.name}
                        onChange={(e) => {
                            const { name, value } = e.target;
                            setFilter(prev => ({ ...prev, [name]: value }));
                        }}
                    />
                    <InputRightElement>
                        <IconButton
                            icon={<SearchIcon color={"gray.400"} />}
                            bg={'transparent'}
                            _active={{ color: "#4699bc" }}
                            _hover={{ bg: 'transparent' }}
                            aria-label={'Search button'}
                        />
                    </InputRightElement>
                </InputGroup>
            </FormControl>

            {branches.length ? (
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} gap={4}>
                    {branches.map((branch) => (
                        <BranchCard
                            key={branch.id}
                            name={branch.name}
                            description={branch.description}
                            branchMap={branch.branchMap}
                        />
                    ))}
                </SimpleGrid>
            ) : (
                <Heading color={'gray.400'} w={'full'} textAlign={'center'} my={8}>No Record Found</Heading>

            )}
        </>
    );
};

const BranchLocator = () => {
    return (
        <PublicViewContainer childComponent={<Branches />} />
    );
};

export default BranchLocator;
