import React, { useEffect, useState } from "react";
import {
    Flex, Stack, Heading, Table, TableContainer, Thead, Tr, Th, Tbody, Td, Tag, TagLabel
} from "@chakra-ui/react";
import moment from "moment";
import Paginate from "../../../components/Paginate/Paginate";
import utils from "../../../utils/commonFunctions";
import {useQuoteCart} from "../../../providers/QuoteCartProvider";
import {HashLoader} from "react-spinners";
const CheckBillTable = () => {
    const [checkedBills, setCheckedBills] = useState({ docs: [], totalPages: 1 });
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const {refreshFlag,setRefreshFlag}=useQuoteCart()

    useEffect(() => {
        const fetchData = async () => {
            const userId = utils.getUserId();
            // Date filter for the last 3 months
            const threeMonthsAgo = new Date();
            threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
            threeMonthsAgo.setHours(0, 0, 0, 0);
            const query = {
                id: userId,
                result: 'true',
                createdDateGte:threeMonthsAgo,
                pageNumber: currentPage,
                pageSize: 6
            };

            setLoading(true);

            const fetchedBills = await utils.getMonthlyBills(query);
            setCheckedBills(fetchedBills);
            setLoading(false);
            setRefreshFlag(false)
        };

        fetchData();
    }, [currentPage,refreshFlag]);

    return (
        <Stack p={8} bg="white" w="full" h="full" borderRadius={'2xl'}>
            <Flex align="center" py={2.5} px={8} w="full">
                <Heading color="blue.800" fontSize="xl">Successful Monthly Bill Requests</Heading>
            </Flex>
            <TableContainer p={8} w="full" h="full">
                <Table colorScheme="gray">
                    <Thead >
                        <Tr>
                            <Th color="blue.800"  fontSize={'sm'} textTransform="capitalize">Customer Number</Th>
                            <Th color="blue.800" fontSize={'sm'} textTransform="capitalize">Contract Number</Th>
                            <Th color="blue.800" fontSize={'sm'} textTransform="capitalize">Status</Th>
                            <Th color="blue.800" fontSize={'sm'} textTransform="capitalize">Request Date</Th>
                        </Tr>
                    </Thead>
                    {!loading ? (
                        checkedBills?.docs?.length > 0 && (
                            <Tbody>
                                {checkedBills.docs.map((bill, index) => (
                                    <Tr key={index}>
                                        <Td>{bill.customerNumber}</Td>
                                        <Td>{bill.contractNumber}</Td>
                                        <Td>
                                             <Tag size='lg' colorScheme= {(bill.result==='true')?'green':'red'} borderRadius='full'>
                                                <TagLabel>{(bill.result==='true')?'Success':'Failed'}</TagLabel>
                                            </Tag>
                                        </Td>
                                        <Td>{moment(bill.dateCreated).format("DD-MM-YYYY")}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        )
                    ) : (
                        <Flex justifyContent="center" align="center" w="full" h="full">
                        <HashLoader color="#2bbcf2"/>
                        </Flex>
                    )}
                </Table>
            </TableContainer>
            { (!checkedBills||checkedBills.docs.length <=0) &&
                <Flex justifyContent="center" align="center" w="full" h="full" py={16}>
                    <Heading size="lg" color="gray.500">No Record Found</Heading>
                </Flex>
            }
            <Paginate
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                numberOfPages={checkedBills?.totalPages||1}
            />
        </Stack>
    );
};

export default CheckBillTable;
