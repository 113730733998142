import {Box, Flex, Heading, Hide, Show, SimpleGrid, Text} from "@chakra-ui/react";

const StepperChild = ({name,stage,index,title}) => {
   const active = stage === localStorage.getItem(name);
    return (

        <>
            <Hide below={'md'}>
                {active?
                    <Flex justifyContent="start" alignItems="center" p={4} color="brand.500" borderColor="white" bg={'brand.500'}  borderRightRadius={'2xl'} borderTopLeftRadius={'2xl'} >
                        <Flex justifyContent={'center'} align={'center'} borderRadius={'full'} borderWidth={'2px'} bg={'white'} borderColor={'white'} p={4} w={8} h={8}  mr={2} >
                            <Text  fontWeight={'bold'} textAlign={'center'} >{index}</Text>
                        </Flex>
                        <Box>
                            <Heading fontSize="sm" fontWeight={'bold'}  color={'white'} textTransform={'capitalize'}>{title}</Heading>

                        </Box>
                    </Flex>
                    :
                    <Flex justifyContent="start" alignItems="center" p={4} color="brand.500" borderColor="white" >
                        <Flex justifyContent={'center'} align={'center'} borderRadius={'full'} borderWidth={'2px'} borderColor={'brand.500'} p={4} w={8} h={8}   mr={2} >
                            <Text  fontWeight={'bold'} textAlign={'center'} >{index}</Text>
                        </Flex>
                        <Box>
                            <Heading fontSize="sm" fontWeight={'bold'}  color="brand.500" textTransform={'capitalize'}>{title}</Heading>

                        </Box>
                    </Flex>
                }
            </Hide>
            <Show below={'sm'}>
                    <Flex justifyContent="center" alignItems="center" p={4} color="brand.500" borderColor="white" bg={'brand.500'}  borderRightRadius={'2xl'} borderTopLeftRadius={'2xl'} >
                        <Flex justifyContent={'center'} align={'center'} borderRadius={'full'} borderWidth={'2px'} bg={'white'} borderColor={'white'} p={4} w={8} h={8}  mr={2} >
                            <Text  fontWeight={'bold'} textAlign={'center'} >{index}</Text>
                        </Flex>
                        <Box>
                            <Heading fontSize="sm" fontWeight={'bold'}  color={'white'} textTransform={'capitalize'}>{title}</Heading>
                        </Box>
                    </Flex>

            </Show>




        </>

    );
};
const CustomStepper = ({name='stage',idxName='index',stages=[]}) => {
    const idx = localStorage.getItem(idxName);
    return (
        <>
        <Hide below={'md'}>
            <SimpleGrid columns={stages.length} bg={'gray.50'} borderTopRadius={'2xl'}>
                {stages.length>0&&
                    <>
                        {stages.map((stage,index)=>(
                            <StepperChild name={name} stage={stage.stage} index={index+1} title={stage.title}/>
                        ))}
                    </>
                }
            </SimpleGrid>
        </Hide>
        <Show below={'sm'}>
            <StepperChild name={name} stage={ stages[Number(idx)]?.stage} index={Number(idx)+1} title={ stages[Number(idx)]?.title}/>
        </Show>
        </>

    );
};

export default CustomStepper;
