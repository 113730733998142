import React, { useEffect, useState } from "react";
import { background, border, FormControl, FormLabel} from "@chakra-ui/react"; 
import utils from "../../../utils/commonFunctions";
import Select from 'react-select'; 
const styles = {
    control: (baseStyles,state) => ({
        ...baseStyles,
        borderRadius: '15px',
        padding:'4px 0',
        borderColor: state.isFocused ? 'grey' : 'platinum-229', // Use state.isFocused for active/focus state
        textAlign: 'left',
        '&:hover': {
            borderColor: 'lightgrey', // Correctly set hover border color
        },
        
    }),
    
}

export const SelectInput = ({value,callback,label,isRequired=true,options,placeholder,name,id}) => {
   
    return (
        <FormControl isRequired={isRequired}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <Select 
                id={id}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={(e) => callback({target:{...e,name}})}
                options={options}
                isSearchable 
                styles={styles}
            />
        </FormControl>
    );
};

export const AreaInput = ({value,callback,cityName,isRequired=true}) => {
    const [areas, setAreas] = useState([]);
    const [areasOptions, setAreasOptions] = useState([]);
    const [option, setOption] = useState(value);
    console.log(value,"values")
    const getAreas = async () => {
        try {
            let fetchedAreas = await utils.fetchAreas({cityName: cityName});
           //let fetchedAreas = await utils.fetchAreas({});
            if (fetchedAreas && fetchedAreas.length > 0) {
                setAreasOptions(fetchedAreas.map(area => ({
                    label: area.name,
                    value: area.name,
                    name: 'ward'
                })));  
               
                setAreas(fetchedAreas);
               
            } else {
                setAreas([]);
            }
        } catch (e) {
            console.error('Failed to fetch areas:', e);
            setAreas([]);
        }
    };

    useEffect(() => {
        if (cityName) {
            getAreas();
            //setOption(null)
        }
    }, [cityName]);
    useEffect(() => {
       setOption(utils.getOptionByValue(areasOptions,value))
    }, [areasOptions]);
    return (
        <FormControl isRequired={isRequired}>
            <FormLabel htmlFor="area">Area / Ward</FormLabel>
            <Select 
                id="area"
                name="ward"
                placeholder="Select area"
                // value={{value:"All", label:"All",name:"ward"}}
                 value={option}
                options={areasOptions}
                onChange={
                    (e) => {
                        callback({ target: e });
                        let x = utils.getOptionByValue(areasOptions,e?.value)
                        setOption(x);
                        console.log("inside onchange value",e.value)
                        console.log("inside onchange x",x)
                        
                        //setOption(value?.target?.value);
                    }
                }

                styles={styles}
            />
               
        </FormControl>
    );
};

export const CityInput = ({value,callback,isRequired=true,placeholder='Select city',label='City / Town / Village'}) => {
    const [cityOptions, setCityOptions] = useState([]);
    useEffect(() => {
       
    }, [cityOptions]);
    const getCities = async () => {
        try {
            let fetchedCities = await utils.fetchCities({ pageSize: 90000 });
            if (fetchedCities && fetchedCities.length > 0) {
                setCityOptions(fetchedCities.map(city => ({
                    label: city.name,
                    value: city.name,
                    name: 'city'
                })));  
            } 
        } catch (e) {
            console.error('Failed to fetch cities:', e);
        }
    };
    useEffect(() => {
        getCities();
    }, []);
   
    return (
        <FormControl isRequired={isRequired}>
            <FormLabel htmlFor="city">{label}</FormLabel>
            <Select 
                id="city"
                name="city"
                placeholder={placeholder}
                value={utils.getOptionByValue(cityOptions,value)} 
                onChange={(e) => callback({target:{...e}})}
                options={cityOptions}
                isSearchable 
                styles={styles}
            />
        </FormControl>
    );
};

export const CustomerNumberInput = ({checkForOthers=false, value,callback,isRequired=true,placeholder='Select Customer Number',label='Customer Number'}) => {
    const [customerNumberOptions, setCustomerNumberOptions] = useState([]);
    let userId=utils.getUserId()
    const getUtilityContracts = async () => {
        let utilityContracts = await utils.getCustomerAndContractNumbers({userId})
        if (utilityContracts?.customerNumbers?.length) {
            setCustomerNumberOptions(utilityContracts.customerNumbers.map(number => ({
                label: number,
                value: number,
                name: 'customerNumber'
            })));
        }
    };
    useEffect(() => {
        if (!checkForOthers) {
            getUtilityContracts();
        }
    }, []);

    return (
        <FormControl isRequired={isRequired}>
            <FormLabel htmlFor="customerNumber">{label}</FormLabel>
            <Select
                id="customerNumber"
                name="customerNumber"
                placeholder={placeholder}
                value={utils.getOptionByValue(customerNumberOptions,value)}
                onChange={(e) => callback({target:{...e}})}
                options={customerNumberOptions}
                isSearchable
                styles={styles}
            />
        </FormControl>
    );
};
export const ContractNumberInput = ({value,callback,customerNumber,isRequired=true}) => {
    const [contractNumberOptions, setContractNumberOptions] = useState([]);
    const [option, setOption] = useState(value);

    let userId=utils.getUserId()
    const getContractNumbers = async () => {
        try {
            let fetchedContractNumbers = await utils.getCustomerAndContractNumbers({userId,customerNumber});
            if (fetchedContractNumbers && fetchedContractNumbers.contractNumbers.length > 0) {
                setContractNumberOptions(fetchedContractNumbers.contractNumbers.map(contractNumber => ({
                    label: contractNumber,
                    value: contractNumber,
                    name: 'utility'
                })));
            } else {
                //setAreas([]);
            }
        } catch (e) {
            console.error('Failed to fetch contract numbers:', e);
        }
    };

    useEffect(() => {
        if (customerNumber) {
            getContractNumbers();
        }
    }, [customerNumber]);

    useEffect(() => {
        setOption(utils.getOptionByValue(contractNumberOptions,value))
    }, [contractNumberOptions]);
    return (
        <FormControl isRequired={isRequired}>
            <FormLabel htmlFor="contractNumber">Utility Number</FormLabel>
            <Select
                id="utility"
                name="contractNumber"
                placeholder="Select Utility Number"
                value={option}
                options={contractNumberOptions}
                onChange={
                    (e) => {
                        callback({ target: e });
                        let x = utils.getOptionByValue(contractNumberOptions,e?.value)
                        setOption(x);
                        console.log("inside onchange value",e.value)
                        console.log("inside onchange x",x)
                        //setOption(value?.target?.value);
                    }
                }
                styles={styles}
            />

        </FormControl>
    );
};