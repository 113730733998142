import React from "react";
import {
    Box, Flex, Heading, VStack
} from "@chakra-ui/react";
import Poll from "../../../components/Polls/Poll";
import Notices from "../../../components/Notices/Notice";
import Survey from "../../../components/Survey/Survey";
import svgUrl from '../../../assets/Svg/wave.svg';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

const Publication = ({ Child }) => {
    return (
        <Flex
            cursor="pointer"
            h="full"
            direction="column"
            bg="white"
            boxShadow="md"
            borderRadius="2xl"
            p={8}
        >
            <Flex
                width="full"
                h="full"
                justifyContent="center"
                alignItems="start"
                borderTopRadius="2xl"
            >
                {Child}
            </Flex>
        </Flex>
    );
};

const Publications = () => {
    return (
        <Box bg="white">
            <Flex
                bgImage={`url(${svgUrl})`}
                bgPosition="center"
                bgRepeat="no-repeat"
                bgSize="cover"
                px={{ base: 4, lg: 8 }}
                py={{ base: 8 }}
                justify={'center'}
            >
                <Flex direction={'column'}  w={'full'} maxW={'8xl'} >
                    <Flex justify="center" align="center" p={8} w="full">
                        <Box w="full">
                            <VStack>
                                <Heading size="xl" textAlign="center" color="brand.700">
                                    Feedback
                                </Heading>
                            </VStack>
                        </Box>
                    </Flex>
                    <Splide
                        options={{
                            perPage: 3, // 3 slides for (1024 or larger)
                            arrows: false, // Enable navigation arrows
                            pagination: true,
                            gap: '1rem',
                            breakpoints: {
                                768: {
                                    perPage: 2, // 2 slides for screens  less than 1024px
                                },
                                640: {
                                    perPage: 1, // 1 slide for screens 640px or smaller
                                },
                            },
                        }}
                        aria-label="Feedback Publications"
                    >
                        <SplideSlide>
                            <Publication Child={<Notices />} />
                        </SplideSlide>
                        <SplideSlide>
                            <Publication Child={<Poll />} />
                        </SplideSlide>
                        <SplideSlide>
                            <Publication Child={<Survey />} />
                        </SplideSlide>
                    </Splide>
                </Flex>

            </Flex>
        </Box>
    );
};

export default Publications;
