import React from "react";
import PublicViewContainer from "../../components/Containers/PublicViewContainer";
import ProjectsComponent from "./Components/Project";



const Projects= () => {
    return <PublicViewContainer childComponent={<ProjectsComponent/>}/>
}

export default Projects
