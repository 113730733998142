import React from "react";
import {
    Heading, Flex, Text, SimpleGrid,
} from "@chakra-ui/react";


const Vision = ({title,brief}) => (
    <>
        <Flex
            justifyContent="start"
            alignItems="center"
            flexDirection="column"
            width='full'
            p={4}
            color={'white'}
        >
            <Heading as="h5" size="xl" color={'whiteAlpha.700'}  my={2}>
                {title}
            </Heading>
            <Text textAlign={'center'}  fontSize={'lg'} >
                {brief}
            </Text>
        </Flex>

    </>

);
const Values = () => (
    <>
        <SimpleGrid columns={{base:1,lg:2}} px={{base:4,md:32}} py={8}>
            <Vision
                title={"Our Vision"}
                brief={"We will be a leading water utility service provider by 2025."}
            />
            <Vision
                title={" Our Mission"}
                brief={"We are a service provider that strives to guarantee an efficient and responsible supply of high-quality potable water and wastewater management services which supports the social and economic development of Botswana."}
            />
        </SimpleGrid>

    </>

);
export default Values

