import React from "react";
import {Flex} from "@chakra-ui/react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import TopBanner from "../BottomBanner/TopBanner";
const SelfServiceContainer = ({ childComponent}) => {
    return (
        <>
            <Header/>
            <TopBanner/>
            <Flex bg={'gray.50'} justify={'center'} w={'full'}  py={32} >
                <Flex  bg={'white'} p={4} borderRadius={'2xl'} boxShadow={'md'} justify={'center'} align={'center'} w={'xl'}   >
                    {childComponent}
                </Flex>
            </Flex>
            <Footer/>
        </>
    )
}
    export default SelfServiceContainer
