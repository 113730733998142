import React, {useEffect, useState} from "react";
import {
    Box, Flex, FormControl, FormLabel, Grid, GridItem, Heading, Input, InputGroup,Text,
} from "@chakra-ui/react";
import {useUserProfile} from "../../../providers/UserProfileProvider";

const CustomerDetails = () => {


    let {userProfile}=useUserProfile()
    let {personalDetails,customerNumber,_id,userId}=userProfile||{}
    let profileId=_id
    let {firstName,lastName,middleName}=personalDetails||{}
    //let contractNumber = new URLSearchParams(window.location.search).get('utilityContractNumber');
    const form = JSON.parse(localStorage.getItem('customerDetailsDisconnection'));

    if(profileId&&!form) {
        localStorage.setItem('customerDetailsDisconnection', JSON.stringify({
            firstName,
            lastName,
            middleName,
            userId,
            profileId,
            customerNumber,
            contractNumber: JSON.parse(localStorage.getItem('customerDetailsDisconnection'))?.contractNumber || ''
        }))
    }
    const [formData, setFormData] = useState({
        userId: userId,
        profileId:_id,
        customerNumber: form?.customerNumber || null,
        contractNumber: form?.contractNumber || null,
        firstName: form?.firstName || null,
        middleName: form?.middleName || null,
        lastName: form?.lastName || null
    });

    // Effect to handle localStorage update on formData change
    useEffect(() => {
        localStorage.setItem('customerDetailsDisconnection', JSON.stringify(formData));
        const { middleName,spouseCustomerNumber, ...exclMiddleName }=formData

        let dataForm=exclMiddleName
        if (Object.values(dataForm).some(detail => detail === null||detail === ""||!detail)) {
            localStorage.setItem('isValidForm', 'false');
        } else {
            localStorage.setItem('isValidForm', 'true');
        }

    }, [formData]);



    const handleInputChange = (e) => {
        const {name, value,id} = e.target;
        setFormData(prevState => ({
            ...prevState, [name]: value
        }));
    };

    return (<>
            <Box>
                <Flex mb={4}>
                    <Box>
                        <Heading color={'brand.500'}>Personal Details</Heading>
                        <Text color={'gray.500'}>Your simple personal information required for identification</Text>
                    </Box>
                </Flex>
                <Grid templateColumns="repeat(12, 1fr)" gap={2} color={"blue.800"}>
                    {/* Cutomer Number */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl id="customerNumber" isRequired>
                            <FormLabel >Customer Number</FormLabel>
                            <InputGroup>
                                <Input  type="number" name="customerNumber"  id="Customer Number"
                                       value={formData.customerNumber} onChange={handleInputChange}/>
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                    {/* Spouse number */}
                    { (<GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired>
                            <FormLabel >Contract Number</FormLabel>
                            <InputGroup>
                                <Input  type="number" name="contractNumber" id="Utility Contract Number"
                                       value={formData.contractNumber} onChange={handleInputChange}/>
                            </InputGroup>
                        </FormControl>
                    </GridItem>)}
                    {/* Firstname */}
                    <GridItem colSpan={{base:12,lg:4}}>
                        <FormControl id="firstname" isRequired >
                            <FormLabel>Firstname</FormLabel>
                            <InputGroup>
                                <Input  type="text" name="firstName" id='Firstname' value={formData.firstName} onChange={handleInputChange}/>
                            </InputGroup>
                        </FormControl>
                    </GridItem>

                    {/* Middle Name */}
                    <GridItem colSpan={{base:12,lg:4}}>
                        <FormControl id="middlename" >
                            <FormLabel>Middle Name</FormLabel>
                            <InputGroup>
                                <Input  type="text" name="middleName" id='Middlename' value={formData.middleName}
                                       onChange={handleInputChange}/>
                            </InputGroup>
                        </FormControl>
                    </GridItem>

                    {/* Lastname */}
                    <GridItem colSpan={{base:12,lg:4}}>
                        <FormControl id="lastname" isRequired>
                            <FormLabel >Lastname</FormLabel>
                            <InputGroup>
                                <Input  type="text" name="lastName" id="Lastname"
                                       value={formData.lastName} onChange={handleInputChange}/>
                            </InputGroup>
                        </FormControl>
                    </GridItem>

                </Grid>
            </Box>
        </>);
};

export default CustomerDetails;
