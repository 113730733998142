import React, {useEffect, useState } from "react";
import {
    Flex,
    Heading, Progress,
    Radio,
    RadioGroup,
    Spacer
    } from "@chakra-ui/react";
import {API} from "../../utils/api";
import {HashLoader} from "react-spinners";


const PollQuestions = ({ title = 'Polls', questions, ipAddress, refreshSurvey, optionsHeight = 'full' }) => {
    const [answer, setAnswer] = useState(null);
    const [percentage, setPercentageArr] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [alreadyVoted, setAlreadyVoted] = useState(false);
    const [isLoading, setLoading] = useState(true);

    const handleInputChange = (e) => {
        if (!alreadyVoted) {
            setAnswer(e.target.value);
            setSubmitted(true);
        }
    };

    const handleSetVoted = () => {
        const hasVoted = questions?.ipAddresses?.some(ip => ip === ipAddress);
        setAlreadyVoted(hasVoted);
    };

    const handleRefresh = () => {
        refreshSurvey();
    };

    async function getPollVotes() {
        try {
            const response = await API.get(`/common/polls/${questions._id}`);
            if (response.status === 200) {
                setPercentageArr(calculatePercentageShares(response.data.votes));
            }
        } catch (e) {
            console.error(e);
        }
    }

    const calculatePercentageShares = (numbersArray) => {
        const total = numbersArray.reduce((acc, curr) => acc + Number(curr), 0);
        return numbersArray.map(num => ((Number(num) / total) * 100).toFixed(1));
    };

    useEffect(() => {
        if (!isLoading) {
            handleSetVoted();
            getPollVotes();
        }
        // set submitted to false
        setSubmitted(false);
    }, [questions, isLoading,submitted]);
    useEffect(() => {
        async function vote() {
            try {

               let res = await API.put(`/common/polls/vote/${questions._id}`,
                    {answer:answer,ipAddress:ipAddress}
                );

                if (res.status === 200) {
                    // console.log('voted successfully');
                    // console.log(res.data);
                    handleRefresh();
                }

            } catch (e) {
                console.error(e);
            }
        }
        if(submitted){
            vote();
            handleRefresh();
        }

    }, [submitted]);
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 5000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Flex w={"full"} h={'full'} direction={'column'}  >
            <Heading size={'lg'} color={'brand.700'} textAlign={'center'}>{title}</Heading>
            {!isLoading ? (
                <>
                    <Heading size={'sm'} mt={8} fontWeight={'medium'} color={'gray.800'}>{questions?.question}</Heading>
                    <RadioGroup w={"full"} h={'full'} mt={4} name={'applicant'}  width={{ base:'full' }}>
                        {questions && questions.options.map((option, index) => (
                            <Flex key={index} direction='column' w={"full"} h={optionsHeight} mb={2} border={'gray.50'}
                                  borderWidth={'1px'} borderRadius={'xl'} p={2}
                                  _hover={{ borderColor: 'brand.700' }}>
                                <Flex w={"full"}>
                                    {!alreadyVoted &&!submitted?
                                        <Radio value={`${index}`} id={index} onChange={handleInputChange} >
                                            <Heading size={'sm'} color={'blue.800'}>{option}</Heading>
                                        </Radio>
                                        :
                                        <Heading size={'sm'} color={'blue.800'}>{option}</Heading>
                                    }
                                    <Spacer />
                                    {(submitted || alreadyVoted) && (
                                        <Heading size={'sm'} color={'brand.700'}>{percentage[index]||0}%</Heading>
                                    )}
                                </Flex>
                                {(submitted || alreadyVoted) && (
                                    <Progress w={"full"} colorScheme='blue' mt={2} borderRadius={'2xl'} size='sm'
                                              value={percentage[index]||0} />
                                )}
                            </Flex>
                        ))}
                    </RadioGroup>
                </>
            ) : (
                <Flex justifyContent="center" align="center" w="full" h="full">
                <HashLoader color="#2bbcf2"/>
                </Flex>
            )}
        </Flex>
    );
};

export default PollQuestions;