import React from "react";
import PublicViewContainer from "../../components/Containers/PublicViewContainer";
import MediaComponents from "./Components/MediaComponents";
import VideoComponent from "./Components/Videos/Videos";


const Videos= () => {
    return <PublicViewContainer childComponent={<MediaComponents childComponent={<VideoComponent/>}/>}/>
}

export default Videos
