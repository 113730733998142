import React, { useEffect, useState } from "react";
import {
    Box, Flex, Grid, GridItem, Heading, Text,
} from "@chakra-ui/react";
import utils from "../../../utils/commonFunctions";
import DropBox from "../../../pages/KnowYourCustomer/Components/DropBox";
import enums from "wuc-library/enums";
import { useUserProfile } from "../../../providers/UserProfileProvider";
import { useLocation } from "react-router-dom";

const Attachments = () => {
    const location = useLocation();
    const { userProfile } = useUserProfile();
    const [loading, setLoading] = useState(false);

    // Initialize form data from local storage or fallback to empty defaults
    const [formData, setFormData] = useState(() => {
        const files = JSON.parse(localStorage.getItem('filesConnection')) || {};
        return {
            idKey: files.idKey || null,
            maritalProofKey: files.maritalProofKey || null,
            leeseOrTitleDeed: files.leeseOrTitleDeed || null
        };
    });

    // Determine initial marital status from customer details stored in local storage.
    const [maritalStatus, setMaritalStatus] = useState(() => {
        const customerDetails = JSON.parse(localStorage.getItem('customerDetails')) || {};
        return customerDetails.maritalStatus === enums.MaritalStatus.MARRIED;
    });

    // Load initial data from location or userProfile into state only once
    useEffect(() => {
        const initialFiles = location?.state?.application?.files || userProfile?.files || {};
        if (Object.keys(initialFiles).length > 0 && !JSON.parse(localStorage.getItem('filesConnection'))) {
            setFormData(prev => ({
                ...prev,
                ...initialFiles
            }));
            localStorage.setItem('filesConnection', JSON.stringify({
                ...formData,
                ...initialFiles
            }));
        }
    }, [location, userProfile]);

    useEffect(() => {
        // Save to local storage whenever formData changes
        localStorage.setItem('filesConnection', JSON.stringify(formData));
        //maritalProofKey is only required if marital status is Married
        const { maritalProofKey, ...exclMaritalProof }=formData

        let dataForm=(maritalStatus)? formData:exclMaritalProof
       // console.log(maritalStatus,formData?.maritalStatus!==enums.MaritalStatus.MARRIED,dataForm)
        // Update validity of the form
        const isValidForm = !Object.values(dataForm).some(value => value == null);
        localStorage.setItem('isValidForm', isValidForm.toString());
        // Conditional update for maritalProofKey based on marital status.
        if (!maritalStatus && formData.maritalProofKey) {
            const { maritalProofKey, ...restFiles } = formData;
            setFormData(restFiles);
        }
    }, [formData, maritalStatus]);

    const handleFileChange = async (e) => {
        const { name, files: inputFiles } = e.target;
        if (inputFiles.length > 0) {
            setLoading(true);
            try {
                let userId=utils.getUserId()
                let response = await utils.handleFileUpload(inputFiles[0], `connection/individual/${userId}`);

                setFormData(prevState => ({
                    ...prevState,
                    [name]: response.data?.fileUrl
                }));
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Box>
            <Flex mb={4}>
                <Box>
                    <Heading color={'brand.500'}>Attachments</Heading>
                    <Text color={'gray.500'}>Upload the required documents in PDF format.</Text>
                </Box>
            </Flex>
            <Grid templateColumns="repeat(12, 1fr)" gap={2} color={"blue.800"}>
                <GridItem colSpan={{ base: 12, md: 6 }}>
                    <DropBox label={"Identity Proof Document"}
                             name={"idKey"} loading={loading} callback={handleFileChange} filePath={formData.idKey}
                             instruction="Citizens are to provide Omang copy certified by relevant authorities. Non-citizens are to provide certified passport copy."
                    />
                </GridItem>
                {maritalStatus && (
                    <GridItem colSpan={{ base: 12, md: 6 }}>
                        <DropBox label={"Marital Proof Document"} filePath={formData.maritalProofKey}
                                 name={"maritalProofKey"} loading={loading} callback={handleFileChange}
                                 instruction="Please attach certified copy of FORM B or Marriage Certificate. All documents must be
                                    certified by relevant authorities."
                        />
                    </GridItem>
                )}
                <GridItem colSpan={{ base: 12, md: 6 }}>
                    <DropBox label={"Attach Lease Agreement / Title Deed"} filePath={formData.leeseOrTitleDeed}
                             name={"leeseOrTitleDeed"} loading={loading} callback={handleFileChange}
                             instruction="Applicants are to provide Title Deed/Lease copy certified by relevant authorities."
                    />
                </GridItem>
            </Grid>
        </Box>
    );
};

export default Attachments;
