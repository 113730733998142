import React from 'react';
import DisconnectionForm from './Components/DisconnectionForm';
import PortalContainer from '../../components/PortalContainer/PortalContainer';
import KycAlertModal from "../KnowYourCustomer/Components/Modals/KycAlertModal";


const Disconnection= () => {
    return <>
        <KycAlertModal />
        <PortalContainer Title={"Disconnection"} childComponent={<DisconnectionForm/>}/>
    </>;
};

export default Disconnection;
