import React, { useEffect, useState } from "react";
import { Box, Flex, Image } from "@chakra-ui/react";
import CustomBreadcrumb from "../CustomBreadCrumb/CustomBreadCrumb";
import utils from "../../utils/commonFunctions";
import image1 from "./../../assets/D1 21.jpg";

const TopBanner = () => {
    const [banner, setBanner] = useState({ imageKey: image1 });

    useEffect(() => {
        const fetchBanners = async () => {
            const response = await utils.fetchBanners({ published: true, position: "Top" });
            if (response && response.length) {
                setBanner(response[0]);
            }
        };
        fetchBanners();
    }, []);

    return (
        <Flex
            align="center"
            width="full"
            height="300px"
            position="relative"
            px={16}
        >
            <Image
                src={banner.imageKey}
                alt="Banner"
                width="100%"
                height="100%"
                objectFit="cover"
                position="absolute"
                top={0}
                left={0}
                zIndex={-1}
            />
            <Box position="relative" zIndex={1}>
                <CustomBreadcrumb base="Home" color="white" />
            </Box>
        </Flex>
    );
};

export default TopBanner;
