import PortalContainer from "../../components/PortalContainer/PortalContainer";
import SelfServiceContainer from "../../components/PortalContainer/SelfServiceContainer";
import React from "react";
import BillCalculatorComponent from "./Components/BillCalculator";

const BillCalculator = () => {
  return <>
    <PortalContainer childComponent={<SelfServiceContainer childComponent={<BillCalculatorComponent /> } />}  Title={'Bill Calculator'}/>
  </>
};

export default BillCalculator;
