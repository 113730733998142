import React, { useEffect, useState } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Button,
    Flex,
    FormControl,
    Heading,
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useBreakpointValue
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { MdDownload } from "react-icons/md";
import { BiSolidFilePdf } from "react-icons/bi";
import { SearchIcon } from "@chakra-ui/icons";
import utils from "../../../utils/commonFunctions";

const Publications = () => {
    const location = useLocation();
    const [title, setTitle] = useState('');
    const [filter, setFilter] = useState({
        categoryId: location?.state?.category || '',
        title: ''
    });
    const [publications, setPublications] = useState([]);
    const isMobile = useBreakpointValue({ base: true, md: false });

    useEffect(() => {
        setTitle('')
        setFilter({
            title: '',
            categoryId: location?.state?.category,
            status: 'Active',
        });
    }, [location.state]);

    useEffect(() => {
        setFilter(prevFilter => ({
            ...prevFilter,
            title: title === "" ? null : title
        }));
    }, [title]);

    useEffect(() => {
        const getPublications = async (filter) => {
            try {
                let publications = await utils.fetchPublications(filter);
                setPublications(publications);
            } catch (e) {
                console.log(e);
            }
        };
        if (filter.categoryId || filter.title) {
            getPublications(filter);
        }
    }, [filter]);

    function handleDownload(pdfUrl) {
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = pdfUrl.split('/').pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <Box bg={'white'}>
            <Flex direction={'column'} justify={'center'} align={'center'} bg={'white'} p={8} w={'full'}>
                <Box w={"full"}>
                    <Heading size="lg" mb={2} color={'blue.800'}>{location.state?.name || 'Publications'} </Heading>
                </Box>
                <Box w={"full"}>
                    <FormControl my={4}>
                        <InputGroup>
                            <Input
                                type="text"
                                name="title"
                                focusBorderColor="brand.700"
                                placeholder={'Search title'}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            <InputRightElement>
                                <IconButton
                                    icon={<SearchIcon color={"gray.400"} />}
                                    bg={'transparent'}
                                    _active={{ color: "#4699bc" }}
                                    _hover={{ bg: 'transparent' }}
                                />
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>

                    {isMobile ? (
                        <Accordion allowToggle>
                            {publications && publications.length > 0 ? (
                                publications.map((publication, index) => (
                                    <AccordionItem key={index}>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left">
                                                <Flex align="center" gap={2} fontWeight="medium" textTransform="uppercase" color="gray.500">
                                                    <Icon as={BiSolidFilePdf} color="red.400" boxSize={4} />
                                                    <Text fontSize="sm">{publication.title}</Text>
                                                </Flex>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel pb={4}>
                                            <Text mb={2} fontSize="xs">{publication.extension}</Text>
                                            <Button
                                                bg="brand.700"
                                                rightIcon={<MdDownload />}
                                                onClick={() => handleDownload(`${publication.fileUrl}`)}
                                                size="sm"
                                                fontSize="xs"
                                            >
                                                Download
                                            </Button>
                                        </AccordionPanel>
                                    </AccordionItem>
                                ))
                            ) : (
                                <Flex justifyContent={'center'} alignItems={'center'}>
                                    <Heading color={'gray.400'} my={8}>No Record Found</Heading>
                                </Flex>
                            )}
                        </Accordion>
                    ) : (
                        <TableContainer mb={4} overflowX="auto">
                            <Table variant="striped" colorScheme="gray">
                                <Thead>
                                    <Tr>
                                        <Th fontSize={['xs', 'sm']} border="none">Title</Th>
                                        <Th fontSize={['xs', 'sm']} textAlign="right" border="none">Download</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {publications && publications.length > 0 ? (
                                        publications.map((publication, index) => (
                                            <Tr key={index}>
                                                <Td borderLeftRadius="2xl" border="none">
                                                    <Flex align="center" gap={2} fontWeight="medium" textTransform="uppercase" color="gray.500">
                                                        <Icon as={BiSolidFilePdf} color="red.400" boxSize={[4, 6]} />
                                                        <Flex direction="column">
                                                            <Text fontSize={['sm', 'md']}>{publication.title}</Text>
                                                            <Flex gap={1} fontSize="xs">
                                                                <Text textTransform="uppercase">{publication.extension}</Text>
                                                            </Flex>
                                                        </Flex>
                                                    </Flex>
                                                </Td>
                                                <Td borderRightRadius="2xl" textAlign="right" border="none">
                                                    <Flex justify="end">
                                                        <Button
                                                            bg="brand.700"
                                                            rightIcon={<MdDownload />}
                                                            onClick={() => handleDownload(`${publication.fileUrl}`)}
                                                            size={['sm', 'md']}
                                                            fontSize={['xs', 'sm']}
                                                        >
                                                            Download
                                                        </Button>
                                                    </Flex>
                                                </Td>
                                            </Tr>
                                        ))
                                    ) : (
                                        <Flex justifyContent={'center'} alignItems={'center'} colSpan={{ base: 12 }}>
                                            <Heading color={'gray.400'} my={8}>No Record Found</Heading>
                                        </Flex>
                                    )}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    )}
                </Box>
            </Flex>
        </Box>
    );
};

export default Publications;
