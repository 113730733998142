import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

const ImageCarousel = ({images}) => {

  const splideOptions = {
    type: 'loop',
    perPage: 1,
    // autoplay: true,
    pauseOnHover: true,
    pagination: false,
    arrows: true,
  };

  return (
    <Box
      flex="1"
      bg="gray.200"
      p={4}
      mx={4}
      shadow="md"
      rounded="md"
      width={{ base: '99%', md: '99%' }}
      height={{ base: '500px', md: '500px' }}
      overflow="hidden"
    >
      <Splide options={splideOptions}>
        {images.map((src, index) => (
          <SplideSlide key={index}>
            <Image
              objectFit="fill"
              src={src.key}
              mx={'auto'}
              alt={`Slide ${index}`}
              borderRadius="md"
              width="auto"
              height={{ base: '450', md: '450' }}
              fallbackSrc='https://via.placeholder.com/1200'
            />
          </SplideSlide>
        ))}
      </Splide>
    </Box>
  );
};

export default ImageCarousel;
