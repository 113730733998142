import React from "react";
import PublicViewContainer from "../../components/Containers/PublicViewContainer";
import MediaComponents from "./Components/MediaComponents";
import Events from "./Components/Events/Events";



const Pictures= () => {
    return <PublicViewContainer childComponent={<MediaComponents childComponent={<Events/>}/>}/>
}

export default Pictures
