import {

    Box,Flex,Icon, Spacer,
    Text
} from "@chakra-ui/react";
import {GoChevronRight} from "react-icons/go";
import React from "react";
import {Link} from "react-router-dom";


import { useLocation } from "react-router-dom";

const CustomButton = ({ label, url }) => {
    const location = useLocation();
    const active = location.pathname === url;

    return (
        <Link to={url} style={{ textDecoration: 'none' }}>
            <Flex
                align={'center'}
                p={4}
                w={'full'}
                color={active ? "#0093D0" : 'black'} // Active color change
                fontWeight={'medium'}
                textAlign={'start'}
                mb={4}
                bg={'white'}
                borderColor={'gray.50'}
                borderLeftColor={active ? "#0093D0" : "transparent"}
                borderLeftWidth={active ? "4px" : "0px"}
                _hover={{
                    color: "#0093D0",
                    textDecoration: "none",
                    transform: "translateX(1px)",
                    borderLeft: "4px solid #0093D0",
                }}
                borderRadius={"md"}
            >
                <Text>{label}</Text>
                <Spacer />
                <Icon as={GoChevronRight} />
            </Flex>
        </Link>
    );
};


const SideNav = () => {
    const MediaLinks = [
        {
            id: 'pictures',
            url: "/media/pictures",
            label: "Pictures"
        },
        {
            id: 'videos',
            url: "/media/videos",
            label: "Videos"
        },
        {
            id: 'events',
            url: "/media/events",
            label: "Events"
        }
    ]

    return (
        <Box
            w={"full"}
            minH="full"
            py={8}
            px={{base:4,lg:8}}
            bg={"gray.100"}
            textAlign={'start'}
        >
            {MediaLinks.map((mediaLink) => (
                <CustomButton
                    key={mediaLink.id}
                    label={mediaLink.label}
                    url={mediaLink.url}
                />
            ))}
        </Box>
    );
};

export default SideNav