import React from "react";
import PortalContainer from "../../components/PortalContainer/PortalContainer";
import ViewQuotationComponent from "../LaboratoryServices/Components/ViewQuotation";
const ViewQuotation = () => {
  return (
    <>
      <PortalContainer
        Title={"View Quotation"}
        childComponent={<ViewQuotationComponent />}
      />
    </>
  );
};

export default ViewQuotation;
