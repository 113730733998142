import React from "react";
import DashboardHeader from "../../components/Header/Portal/DashboardHeader";
import {
    Box,
    Container,
    Grid,
    GridItem,
    useMediaQuery,
    VStack,
    StackDivider,
    HStack,
    useToast,
    Button, useDisclosure, Alert, AlertIcon, AlertTitle, AlertDescription, CloseButton, Flex, Hide
} from "@chakra-ui/react";
import Footer from "../Footer/PortalFooter/Footer";
import Sidebar from "../../components/SideBar/SideBar";
import { useUserProfile } from "../../providers/UserProfileProvider";
function KYCAlert() {
    let { userProfile } = useUserProfile() || {}
    console.log(userProfile)
    let open = userProfile == null || (typeof userProfile === 'object' && Object.keys(userProfile).length === 0);
    const {
        isOpen: isVisible,
        onClose,
        onOpen,
    } = useDisclosure({ defaultIsOpen: open })

    return isVisible && (
        <Alert status='warning' pos={'sticky'} top={0} zIndex={'999999999'}>
            <AlertIcon />
            <Flex direction={{ base: 'column', lg: 'row' }} flex='1'>
                <AlertTitle >Important Notice: Update Your KYC Details!</AlertTitle>
                <Hide below={'md'}>
                    <AlertDescription>
                        In order to comply with regulatory requirements, we kindly request that you update your KYC (Know Your Customer) information.
                    </AlertDescription>
                </Hide>
            </Flex>
            <CloseButton
                alignSelf='flex-start'
                ml='auto'
                onClick={onClose}
            />
        </Alert>

    )
}


const PortalContainer = ({ childComponent }) => {
    const [isLargerThanTabletSmallerThanLargerScreen] = useMediaQuery("(min-width: 769px) and (max-width: 1024px)");
    const [isLessThanTabletScreens] = useMediaQuery("(max-width: 482px)");
    if (isLessThanTabletScreens) {
        return (
            <><Grid
                templateColumns={{ base: "1fr" }}
                flex="1"
                width={"100%"}
                px={{ base: 4, md: 8 }}
                gap={6}
            >
                <Box
                    position="fixed"
                    width={{ sm: "100%", md: "90%", lg: "80%" }}
                    justifyContent={'center'}
                    pl={{ lg: 8 }}
                    px={8}
                    pb={38}
                    mx={"auto"}
                    backgroundColor={'white'}
                    zIndex={1}
                >
                    <DashboardHeader />
                </Box>
                <Box
                    flex="1"
                    py={10}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    pt={40}
                >
                    {childComponent}
                </Box>
                <Box position="relative" mt="auto" width="100%">
                    <Footer />
                </Box>
            </Grid>
                <Box h={'5px'} backgroundColor={'red'}>
                    <Sidebar />
                </Box>
            </>
        );
    }

    if (isLargerThanTabletSmallerThanLargerScreen) {
        return (
            <>
            <KYCAlert />
            <Grid
                templateColumns={"20% 80%"}
                flex="1"
                gap={6}
            >
                <Box
                    display={"block"}
                >
                    <Sidebar />
                </Box>

                <Box
                    width={"100%"}
                    px={5}
                >
                    <Box
                        position="fixed"
                        justifyContent={'center'}
                        pl={8}
                        mx={4}
                        backgroundColor={'white'}
                        zIndex={1}
                    >
                        <DashboardHeader />
                    </Box>
                    <Box
                        flex="1"
                        py={10}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        pt={30}
                    >
                        {childComponent}
                    </Box>
                    <Box position="relative" mt="auto" width="100%">
                        <Footer />
                    </Box>
                </Box>
                {/* </Box> */}
            </Grid>
            </>
        )
    }
    return (
        <>
            <KYCAlert />
            <Grid
                templateColumns={"20% 80%"}
                flex="1"
                gap={6}
            >
                <Box
                    display={"block"}
                >
                    <Sidebar />
                </Box>

                <Box
                    width={"100%"}
                    px={5}
                >
                    <Box
                        position="fixed"
                        justifyContent={'center'}
                        pl={8}
                        mx={4}
                        backgroundColor={'white'}
                        zIndex={1}
                    >
                        <DashboardHeader />
                    </Box>
                    <Box
                        flex="1"
                        py={10}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        pt={30}
                    >
                        {childComponent}
                    </Box>
                    <Box position="relative" mt="auto" width="100%">
                        <Footer />
                    </Box>
                </Box>
                {/* </Box> */}
            </Grid>
        </>
    );
};

export default PortalContainer;
