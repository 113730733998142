import React, {useEffect, useState} from "react";
import { Box, Image } from "@chakra-ui/react";

// Import local images
import image1 from "./../../assets/D1.jpg";
import utils from "../../utils/commonFunctions";


const BottomBanner = () => {
    const [banner,setBanner] = useState({imageKey: image1})
    useEffect(()=>{
        const fetchBanners =async ()=>{
            const response = await utils.fetchBanners({published:true, position:"Bottom" });
            if(response&&response.length)
                setBanner(response[0]);
        }
        fetchBanners()
    },[])
  return (
    <Box
      width="full"
      height="300px"
      objectFit={'fit'}
    >
        <Image src={banner.imageKey}  alt="bottom banner image" h="full" width='full'/>
    </Box>
  );
};
export default BottomBanner;
