import React from 'react';
import {Flex} from "@chakra-ui/react";
import ReactPlayer from "react-player";

const VideoPlayer = ({ videoUrl }) => (
    <Flex w={'full'} h={'full'} className='video-player'>
        <ReactPlayer url={videoUrl}  width='100%' height='100%' controls/>
    </Flex>
);

export default VideoPlayer;
