import SelfServiceContainer from "../../components/Containers/SelfServiceContainer";
import React from "react";
import BillCalculatorComponent from "./Components/BillCalculator";


const BillCalculatorGuest = () => {
  return <>
    <SelfServiceContainer childComponent={<BillCalculatorComponent />}/>
  </>
};

export default BillCalculatorGuest;
