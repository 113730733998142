import React, {useEffect, useState} from "react";
import {
    Box, Flex, FormControl, FormLabel, Grid, GridItem, Heading, Input, InputGroup, Select, Text,
} from "@chakra-ui/react";
import {useUserProfile} from "../../../providers/UserProfileProvider";

const NextOfKin = () => {
    let {userProfile}=useUserProfile()||{}
    let {nextOfKin}=userProfile||{}
    const form=JSON.parse(localStorage.getItem('nextOfKin'))
    if(userProfile&&!form){
        localStorage.setItem('nextOfKin',JSON.stringify({...nextOfKin}))
    }

    const [formData, setFormData] = useState({
        firstName: form?.firstName ||null,
        middleName: form?.middleName ||null,
        lastName: form?.lastName ||null,
        relationship: form?.relationship ||null,
        cell: form?.cell ||null,
    });
    useEffect(() => {
        localStorage.setItem('nextOfKin', JSON.stringify(formData));
        const { middleName, ...exclMiddleName }=formData
        if(Object.values(exclMiddleName).some(detail => detail === null||detail === ""||!detail)){
            localStorage.setItem('isValidForm',false );
        }
        else{
            localStorage.setItem('isValidForm',true );
        }
    }, [formData]);
    const handleInputChange = (e) => {
        const {name, value,id} = e.target;
        setFormData(prevState => ({
            ...prevState, [name]: value
        }));
    };

    return (
        <>
            <Box>
                <Flex mb={4}>
                    <Box>
                        <Heading color={'brand.500'}>Next Of Kin</Heading>
                        <Text color={'gray.500'}>Enter the contact details of your next of kin for emergency purposes.</Text>
                    </Box>
                </Flex>
                <Grid templateColumns="repeat(12, 1fr)" gap={2} color={"blue.800"}>
                    {/* Kin Firstname */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired id="firstName">
                            <FormLabel >Firstname</FormLabel>
                            <InputGroup>
                                <Input type="text" name="firstName" id="Firstname"  value={formData.firstName} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>

                    {/* Kin Middle Name */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl id="kinMiddlename">
                            <FormLabel >Middle Name</FormLabel>
                            <InputGroup>
                                <Input type="text" name="kinMiddlename" id="Middlename"   value={formData.kinMiddlename} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>

                    {/* Kin Lastname */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired id="lastName">
                            <FormLabel >Lastname</FormLabel>
                            <InputGroup>
                                <Input type="text" name="lastName" id="Lastname" value={formData.lastName} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>

                    {/* Relationship */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired id="relationship">
                            <FormLabel >Relationship</FormLabel>
                            <Select type="text" name="relationship" id="Relationship" placeholder="-- Select relationship --"
                                    value={formData.relationship} onChange={handleInputChange}>
                                <option value="spouse">Spouse</option>
                                <option value="parent">Parent</option>
                                <option value="sibling">Sibling</option>
                                <option value="child">Child</option>
                                <option value="friend">Friend</option>
                                <option value="other">Other</option>
                            </Select>
                        </FormControl>
                    </GridItem>

                    {/* Kin Cell */}
                    <GridItem colSpan={{base:12,lg:6}}>
                        <FormControl isRequired id="cell">
                            <FormLabel>Cell</FormLabel>
                            <InputGroup>
                                <Input type="number" name="cell" id="Cell"  value={formData.cell} onChange={handleInputChange} />
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                </Grid>
            </Box>
        </>
    );
};

export default NextOfKin;
