import React, { useEffect, useState, createContext, useContext, useRef } from 'react';
import utils from "../utils/commonFunctions";

const SearchContext = createContext(null);

export const SearchProvider = ({ children }) => {
    const [searchResults, setSearchResults] = useState([]);
    const [pagination, setPagination] = useState({
        pageData: [],
        page: 1,
        limit: 5,
        totalPages: 1,
        totalCount: 0
    });
    const [query, setQuery] = useState({
        page: 1,
        limit: 5,
        query: ""
    });

    const fetchTimeoutRef = useRef(null);

    const search = async () => {
        try {
            const data = await utils.search(query);
            setPagination({
                pageData: [],
                limit: data?.pageSize,
                page: data?.page,
                totalPages: data?.totalPages,
                totalCount: data?.totalCount
            });
            setSearchResults(data?.data || []);
        } catch (error) {
            console.error("Error fetching search results:", error);
        }
    };

    const setCurrentPage = (page) => {
        if (page > 0 && page <= pagination.totalPages) {
            setPagination((prev) => ({
                ...prev,
                page
            }));
        }
    };

    const setCurrentPageData = () => {
        if (pagination.page <= pagination.totalPages) {
            const start = (pagination.page - 1) * pagination.limit;
            const end = start + pagination.limit;
            const pageData = searchResults.slice(start, end);
            setPagination((prev) => ({
                ...prev,
                pageData
            }));
        }
    };

    useEffect(() => {
        if (fetchTimeoutRef.current) {
            clearTimeout(fetchTimeoutRef.current);
        }
        fetchTimeoutRef.current = setTimeout(() => {
            search();
        }, 500);

        return () => {
            if (fetchTimeoutRef.current) {
                clearTimeout(fetchTimeoutRef.current);
            }
        };
    }, [query]);

    useEffect(() => {
        setCurrentPageData();
    }, [searchResults, pagination.page]);

    return (
        <SearchContext.Provider value={{ search, searchResults, query, setQuery, setCurrentPage, pagination }}>
            {children}
        </SearchContext.Provider>
    );
};

export const useSearch = () => useContext(SearchContext);
