import React from "react";
import CustomerServicesComponent from "./Components/LaboratoryPage";
import PublicViewContainer from "../../components/Containers/PublicViewContainer";
import './Pages.css'

const LaboratoryPage = ({}) => {
    return (<>
      <PublicViewContainer childComponent={<CustomerServicesComponent/>}/>
    </>);
};

export default LaboratoryPage;
