import React from 'react';
import PortalContainer from '../../components/PortalContainer/PortalContainer';
import KycAlertModal from "../KnowYourCustomer/Components/Modals/KycAlertModal";
import UpdateConnectionForm from "./Components/UpdateConnectionForm";


const NewConnection= () => {
    return <>
        <KycAlertModal />
        <PortalContainer Title={"New Connection"} childComponent={<UpdateConnectionForm/>}/>
    </>;
};

export default NewConnection;
