import React, {useEffect, useState} from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  Image,
  Spacer,
  Stack,
  StackDivider,
  Text,
  VStack, Show, Hide

} from "@chakra-ui/react";
import WUCLogo from "./../../../assets/LogoWithWhiteBG.png";


import { MdEdit} from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import {Link} from "react-router-dom";
import {useUserProfile} from "../../../providers/UserProfileProvider";
import enums from "wuc-library/enums";
import utils from "../../../utils/commonFunctions";
const KycBadge = ({status}) => {
  return(
      <Badge
          borderRadius={"xl"}
          py={0.5}
          px={2}
          ml={{base:0,lg:"2"}}
          textAlign={'center'}
          colorScheme={status===enums.ConnectionRequestStatus.APPROVED?"green":"red"}
      >
        {status===enums.ConnectionRequestStatus.APPROVED?"KYC Compliant":"Failed KYC Compliance"}

      </Badge>
  )
}
const ViewProfile = () => {
  const {userProfile}=useUserProfile()
  const {firstName,lastName,identityNo,identityType}=userProfile?.personalDetails||{}
  const {plotNo,city}=userProfile?.address||{}
  const {workTel,cell,email}=userProfile?.contactDetails||{}
  const {status,customerNumber}=userProfile||{}
  const [user,setUser]=useState(null)
  const [contracts,setContracts]=useState(null)

  let fullname=(firstName && lastName)?`${firstName} ${lastName}`:utils.getUser()?.name
  let userEmail=(email)?email:utils.getUser()?.email
  useEffect(() => {
    const getUser =async ()=>{
      setUser(await utils.fetchUser())
    }
    setUser(getUser())
  }, []);
  return (
    <>
      <VStack
        mt="2"
        minH={["699px"]}
        align={"start"}
        justifyContent={"start"}
        color="blue.800"
      >
        <Grid
          templateColumns="repeat(12, 1fr)"
          gap={6}
          w={"100%"}
          justify={"center"}
        >
          <GridItem colSpan={{base:12,lg:8}} p={"12"} borderRadius="lg" bg={"white"}>
            <Stack direction={{base:'column',lg:'row'}} alignItems="center">

              <Avatar
                alignSelf="center"
                justify={"center"}
                size="lg"
                name={fullname}
              />
              <Stack direction={{base:'column'}}  textAlign={{base:'center',lg:"start"}} spacing={{base:'2px'}} justify={'center'}  verticalAlign={"center"}>
                <Heading
                  color="blue.800"
                  as="h6"
                  fontWeight={"normal"}
                  size="md"

                >
                  {fullname}

                  <Show above={'lg'}>
                    <KycBadge status={status}/>
                  </Show>

                </Heading>
                <Text fontSize={"sm"}  >{userEmail}</Text>
                <Show below={'md'}>
                  <KycBadge status={status}/>
                </Show>
              </Stack>

              <Spacer />
              <Link to={'/self-service-portal/know-your-customer'}>
                <Button leftIcon={MdEdit()}>Update Profile</Button>
              </Link>

            </Stack>
            <GridItem colSpan={6} bg={"white"}>
              <Box>
                <Heading
                  color="blue.800"
                  as="h6"
                  fontWeight="semibold"
                  size="md"
                  my={4}
                  pb={2}
                >
                  Profile Details
                </Heading>
              </Box>
               <Grid templateColumns="repeat(12, 1fr)" gap={{base:2,lg:8}}>
                <GridItem colSpan={{base:12,lg:6}}>
                  <Box>
                    <Heading
                      color="blue.800"
                      as="h6"
                      fontWeight="bold"
                      size="sm"
                      mb={1}
                    >
                      First Name
                    </Heading>
                    <Text textTransform={'capitalize'} fontSize="sm">{firstName||user?.firstName} </Text>
                  </Box>
                </GridItem>

                <GridItem colSpan={{base:12,lg:6}}>
                  <Box>
                    <Heading
                      color="blue.800"
                      as="h6"
                      fontWeight="bold"
                      size="sm"
                      mb={1}
                    >
                      Last Name
                    </Heading>
                    <Text  textTransform={'capitalize'} fontSize="sm">{lastName||user?.lastName} </Text>
                  </Box>
                </GridItem>
                <GridItem colSpan={{base:12,lg:6}}>
                  <Box>
                    <Heading
                      color="blue.800"
                      as="h6"
                      fontWeight="bold"
                      size="sm"
                      mb={1}
                    >
                      Identity Document Type
                    </Heading>
                    <Text  textTransform={'capitalize'} fontSize="sm">{identityType} </Text>
                  </Box>
                </GridItem>
                <GridItem colSpan={{base:12,lg:6}}>
                  <Box>
                    <Heading
                      color="blue.800"
                      as="h6"
                      fontWeight="bold"
                      size="sm"
                      mb={1}
                    >
                      Identity Number
                    </Heading>
                    <Text  textTransform={'capitalize'} fontSize="sm">{identityNo} </Text>
                  </Box>
                </GridItem>
                <GridItem colSpan={{base:12,lg:6}}>
                  <Box>
                    <Heading
                      color="blue.800"
                      as="h6"
                      fontWeight="bold"
                      size="sm"
                      mb={1}
                    >
                      City / Town
                    </Heading>
                    <Text  textTransform={'capitalize'} fontSize="sm">{city} </Text>
                  </Box>
                </GridItem>
                <GridItem colSpan={{base:12,lg:6}}>
                  <Box>
                    <Heading
                      color="blue.800"
                      as="h6"
                      fontWeight="bold"
                      size="sm"
                      mb={1}
                    >
                      Physical Address
                    </Heading>
                    <Text  textTransform={'capitalize'} fontSize="sm">{plotNo} </Text>
                  </Box>
                </GridItem>
                <GridItem colSpan={{base:12,lg:6}}>
                  <Box>
                    <Heading
                      color="blue.800"
                      as="h6"
                      fontWeight="bold"
                      size="sm"
                      mb={1}
                    >
                      Telephone Number
                    </Heading>
                    <Text  textTransform={'capitalize'} fontSize="sm">{workTel} </Text>
                  </Box>
                </GridItem>
                <GridItem colSpan={{base:12,lg:6}}>
                  <Box>
                    <Heading
                      color="blue.800"
                      as="h6"
                      fontWeight="bold"
                      size="sm"
                      mb={1}
                    >
                      Cellphone Number
                    </Heading>
                    <Text  textTransform={'capitalize'} fontSize="sm">{cell||user?.phone} </Text>
                  </Box>
                </GridItem>
              </Grid>
            </GridItem>
          </GridItem>
          <GridItem colSpan={{base:12,lg:4}} p={"12"} borderRadius="lg" bg={"white"}>
            <HStack alignItems="start" justify={"center"}>
              <Image
                alt={"ResetPassword Image"}
                src={WUCLogo}
                objectFit={"contain"}
                boxSize="100px"
                display={{ base: "none", md: "flex" }}
              />
            </HStack>
            <Flex justify={"center"} alignItems={"center"}>
              <Box
                textAlign={"center"}
                bg={"gray.100"}
                fontWeight={"bold"}
                borderRadius={"lg"}
                my={4}
                p={2}
                w={"100%"}
              >
                <Heading color="blue.800" as="h6" size="sm" mb={1}>
                  Customer Number
                </Heading>
                <Text fontSize="md" color="blue.300">
                  {customerNumber||""}
                </Text>
              </Box>
            </Flex>
            <GridItem colSpan={6} bg={"white"} mt={4}>
              <Stack divider={<StackDivider />} spacing="2">
                {contracts&&false?
                    <Box>
                      <Heading size="xs" textTransform="capitalize">
                        Utility No 123344
                      </Heading>
                      <Text pt="1" fontSize="sm">
                        Plot 142 Gaborone . Self
                      </Text>
                    </Box>:
                    <Flex justify={'center'} align={'center'}>
                      <Heading size="xs" textTransform="capitalize">
                        No Utility Contracts

                      </Heading>

                    </Flex>

                }



              </Stack>
            </GridItem>
          </GridItem>
        </Grid>
        <Box w={"100%"}>
          <VStack
            p={"4"}
            align={"start"}
            bg={"gray.50"}
            borderBottom="1px solid "
            borderColor={"gray.200"}
            borderTopRadius="lg"
          >
            <Box>
              <Heading
                color="blue.800"
                as="h6"
                fontWeight={"medium"}
                size="md"
                mb={1}
              >
                Security
              </Heading>
              <Hide below={'md'}>
              <Text fontSize={"md"} fontWeight={"thin"}>
                Update your password to make your account more secure.
              </Text>
              </Hide>
            </Box>
          </VStack>

          <Grid
            bg={"white"}
            px={{sm:"48px",md:"62px",lg:"12"}}
            py={"8"}
            borderBottomRadius="lg"
            templateColumns="repeat(12, 1fr)"
            gap={6}
            w={"full"}
            alignItems="center"
            justify={"center"}
          >
            <GridItem colSpan={{base:12,md: 11, lg:11}} >
              <HStack alignItems="center"  flexDirection={{ base: "column", md: "row" }}>
                <Icon
                  as={RiLockPasswordFill}
                  boxSize={"64px"}
                  color="white"
                  p={4}
                  bg={"brand.500"}
                  borderRadius={"50%"}
                />
                <Box verticalAlign={"center"}>
                  <Heading
                    color="blue.800"
                    as="h6"
                    fontWeight={"normal"}
                    size="md"
                  >
                    Password
                  </Heading>
                </Box>
              </HStack>
            </GridItem>
            <GridItem colSpan={{base:12,md:1, lg:1}} display="flex" justifyContent="center" alignItems="center">
              <Link to={'change-password'}>
                <Button
                        _hover={{ bg: "blue.400", color: "white" }}
                        colorScheme="black"
                        leftIcon={<MdEdit />}
                        bg={"brand.500"}
                        size={{ base: "sm", md: "md"}}
                        p={{ base: 6 }}



                >
                  Change Password
                </Button>
              </Link>

            </GridItem>
          </Grid>
        </Box>
      </VStack>
    </>
  );
};

export default ViewProfile;
