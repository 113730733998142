import React, { useEffect, useState } from "react";
import { Box, Flex, Grid, GridItem, Heading, Text, Center, Image, useDisclosure, UnorderedList, ListItem } from "@chakra-ui/react";
import { useLocation } from 'react-router-dom';
import {API} from "../../../utils/api";
import ImageCarousel from "./ImageCarousel";
import EmptyProjectList from "./EmptyProjectDocuments";
import pdfFileImage from "../../../../src/assets/images/pdfFile.png";
import PdfViewerModal from "../../../components/PdfViewer/PdfViewerModal";

const ViewProjectPage = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [projectId, setProjectId] = useState('');
    const [project, setProject] = useState({});
    //get the projectId from the navigator state
    const location = useLocation();
    const [pdfFile, setPdfFile] = useState();

    const fetchProjects = async (projectId) => {
        try {
            const response = await API.get(`/projects/${projectId}`);
            console.log("projects fetched: ", response.data);
            setProject(response.data);
        } catch (error) {
            console.error("Error fetching projects: ", error);
        }
    };

    useEffect(() => {
        const { projectId } = location.state || {};
        setProjectId(projectId);
        fetchProjects(projectId);
    }, []);

    const handlePdfClick = (documentKey) => {
        setPdfFile(documentKey);
        onOpen();
    }

    return (
        <>
            <Flex justify={'center'} align={'center'} py={8} w={'full'} bg={'white'}>
                <Box>
                    <Heading size="lg" mb={4} textAlign={'center'} color={'blue.800'}>Project </Heading>
                    <Heading size="lg" mb={2} textAlign={'center'} color={'blue.800'}>{project.title}</Heading>
                    <Text my={2} fontSize="xl" textAlign={'center'} fontWeight="semibold" lineHeight="short" dangerouslySetInnerHTML={{ __html: project.description }}>

                    </Text>
                    <Grid templateColumns='repeat(12, 1fr)' gap={4}>
                        <GridItem colSpan={{ base: 12, md: 12, lg: 12 }}>
                            {project?.imagesDocumentsKeys?.length === 0 && <EmptyProjectList />}
                            {project?.imagesDocumentsKeys?.length > 0 && <ImageCarousel images={project.imagesDocumentsKeys} />}
                        </GridItem>
                    </Grid>
                    {project?.otherDocumentsKeys?.length > 0 && <Heading size="lg" mt={5} mb={4} textAlign={'center'} color={'blue.800'}>Documents </Heading>}

                    <UnorderedList styleType="none">
                        {project?.otherDocumentsKeys?.map((document, index) => (
                            <ListItem key={index} mb={4}>
                                <Box
                                    position="relative"
                                    mr="4"
                                    bg="gray.100"
                                    p={3}
                                    shadow="md"
                                    rounded="md"
                                    cursor="pointer"
                                    width="100%"
                                    height="100px"
                                    mb={4} // Add bottom margin here
                                    onClick={() => handlePdfClick(document.key)}
                                >
                                    {/* <Center height="100%"> */}
                                        <Image height="100px" src={pdfFileImage} mb={4} alt={`pdf-file ${index + 1}`} />
                                    {/* </Center> */}
                                    <Center position="absolute" bottom={10} width="100%">
                                        <Text fontSize="lg" fontWeight="bold" align="center" color="gray.500">
                                            {document.name}
                                        </Text>
                                    </Center>
                                </Box>
                            </ListItem>
                        ))}
                    </UnorderedList>


                </Box>
            </Flex>
            {pdfFile && <PdfViewerModal
                isOpen={isOpen}
                onClose={onClose}
                filePath={pdfFile}
            />}
        </>
    );

}

export default ViewProjectPage;