import React, { useEffect, useState } from "react";
import {
    Box,
    Text,
    Heading,
    Flex,
    Spinner,
    Grid,
    GridItem,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
} from "@chakra-ui/react";
import {AddIcon, MinusIcon} from "@chakra-ui/icons";
import {API} from "../../../../utils/api";
import {CustomGeneralToast} from "../../../../utils/customChakraToasts";

const FAQAccordion = ({ FAQs }) => {
    return (
        <Box w="full">
            <Accordion allowToggle w="full" borderWidth={0} borderRadius="lg">
                {FAQs.map((faq, index) => (
                    <AccordionItem key={index} w="full" borderWidth={0} borderColor="white" borderRadius="lg" mb={4} color="blue.800">
                        {({ isExpanded }) => (
                            <Box boxShadow={isExpanded && 'xl'}>
                                <AccordionButton
                                    w="full"
                                    p={4}
                                    m={0}
                                    color={isExpanded ? '#0093D0' : 'blue.800'}
                                    borderRadius="lg"
                                    fontWeight="semibold"
                                    fontSize="md"
                                    bg={isExpanded ? 'white' : 'gray.100'}
                                    _hover={isExpanded && { bg: 'white' }}
                                >
                                    <Grid templateColumns="repeat(12, 1fr)" w="full">
                                        <GridItem colSpan={{ base: 11, md: 1 }}>
                                            <Flex justifyContent={{ base: 'start', md: 'center' }} align="center" h="full" w="full">
                                                {index + 1 < 10 ? `0${index + 1}` : index + 1}
                                            </Flex>
                                        </GridItem>
                                        <GridItem colSpan={{ base: 12, md: 10 }}>
                                            <Flex h="full" w="full" textAlign="start" justify="start" align="center">
                                                {faq.question}
                                            </Flex>
                                        </GridItem>
                                        <GridItem colSpan={{ base: 1, md: 1 }}>
                                            <Flex h="full" w="full" justify="start" align="center" bg={isExpanded ? 'white' : 'gray.100'} p={4}>
                                                {isExpanded ? <MinusIcon /> : <AddIcon />}
                                            </Flex>
                                        </GridItem>
                                    </Grid>
                                </AccordionButton>
                                <AccordionPanel textAlign="start" borderRadius="lg" px={8} pb={4}>
                                    <Box>
                                        <Text>
                                            <strong>Answer: </strong>
                                            <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                                        </Text>
                                    </Box>
                                </AccordionPanel>
                            </Box>
                        )}
                    </AccordionItem>
                ))}
            </Accordion>
        </Box>
    );
};

const FAQs = () => {
    const [loading, setLoading] = useState(false);
    const [faqs, setFaqs] = useState([]);

    const getFaqs = async () => {
        setLoading(true);
        try {
            const response = await API.get(
                `/faqs`,
            );
            const data = await response.data.docs;
            console.log(data)
            if(response.status===200){
                setLoading(false);
                setFaqs(data)
                return
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log(e)
        }

    };
    useEffect(() => {
        getFaqs();
    }, []);

    if (loading) {
        return (
            <Flex justify="center" align="center" height="100vh">
                <Spinner color="blue.500" emptyColor="gray.200" size="xl" thickness="4px" />
            </Flex>
        );
    }

    return (
        <>
            <Flex justify="center" align="center" bg="white" p={8} w="full">
                <Box w="full">
                    <Heading size="xs" mb={2} textAlign="center" color="blue.800">Customer Service</Heading>
                    <Heading size="lg" mb={4} textAlign="center" color="blue.800">FAQs</Heading>
                    {faqs.length === 0 ? (
                        <Flex justify="center" align="center" mt={{ base: 0, lg: 16 }}>
                            <Heading color="gray.500">No Record Found</Heading>
                        </Flex>
                    ) : (
                        <FAQAccordion FAQs={faqs} />
                    )}
                </Box>
            </Flex>
        </>
    );
};

export default FAQs;
