import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box, Heading, Text, SimpleGrid, Center, Circle
} from "@chakra-ui/react";
import axios from "axios";
import { Card, CardBody, CardFooter, Image, Stack, Button } from '@chakra-ui/react'
import surveyImage from '../../../assets/voice.png'
import EmptySurveyList from "./EmptySurveysList";
import {API} from "../../../utils/api"
const Surveys = () => {
    // list of surveys
    const [surveys, setSurveys] = useState([]); // Add closing parenthesis here
    const [isLoading, setLoading] = useState(false);
    const [questions, setQuestions] = useState([]);
    const navigate = useNavigate();

    const getPolls = useCallback(async () => {
        setLoading(true);
        try {
            const response = await API.get(
                `/common/polls`
            );
            const data = await response.data;
            if (response.status === 200) {
                setQuestions(data);
            } else {
                setQuestions([]);
            }
        } catch (e) {
            setQuestions([]);
            console.log(e);
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchSurveys = async () => {
        try {

            const ip_response = await axios.get('https://api64.ipify.org?format=json');
            const ip = ip_response.data.ip;

            const response = await API.get(`/common/surveys?ip=${ip}`);
            console.log("Surveys fetched: ", response.data);

            //probably should filter out surveys that have ended from the API not here?
            setSurveys(response.data.filter(survey => {
                const now = new Date();
                const endDate = survey.closing_date ? new Date(survey.closing_date) : null;
                return endDate && endDate > now;// excluding the survey with a null value for closing date
            }));
            
        } catch (error) {
            console.error("Error fetching surveys: ", error);
        }
    };

    const attemptSurvey = (survey) => {
        navigate('/surveys/'+survey.title, { state: { survey: survey, polls: survey.polls } });
    }

    const calculateTimeRemaining = (closingDate) => {
        const now = new Date();
        const endDate = new Date(closingDate);
      
        const totalMilliseconds = endDate - now;
        if (totalMilliseconds <= 0) {
          return "Closed";
        }
      
        const totalSeconds = Math.floor(totalMilliseconds / 1000);
        const totalMinutes = Math.floor(totalSeconds / 60);
        const totalHours = Math.floor(totalMinutes / 60);
        const totalDays = Math.floor(totalHours / 24);
        const totalMonths = Math.floor(totalDays / 30);
      
        if (totalMonths > 0) {
          return totalMonths === 1 ? `Closes in ${totalMonths} Month` : `${totalMonths} Months`;
        } else if (totalDays > 0) {
          return totalDays === 1 ? `Closes in ${totalDays} Day` : `${totalDays} Days`;
        } else {
          return totalHours === 1 ? `Closed in ${totalHours} Hour` : `${totalHours} Hours`;
        }
      };
      

    useEffect(() => {
        fetchSurveys();
    }, [getPolls]);


    if(surveys.length === 0){
        return <EmptySurveyList/>
    }

    return (
        <>
            <Box objectFit={'fit'} bg={'white'} px={{ base: 8, lg: 32 }} mb={8} minHeight={400}>
                <Heading size="lg" my={8} textAlign={'center'} color={'blue.800'}>Give Us Feedback</Heading>
                <SimpleGrid columns={{ base: 1, lg: 2 }} gap={8} mb={4} mt={8}>
                     {surveys.map((survey, index) => (
                        <Box width={{ base: "full" }}  key={index}>
                            <Card
                                direction={{ base: 'column', sm: 'row' }}
                                overflow='hidden'
                                variant='outline'
                                boxShadow='lg'
                                height={{ base: 'full', sm: '250px' }}
                            >
                                <Image
                                    objectFit='fit'
                                    maxW={{ base: '100%', sm: '100%' }}
                                    // maxH={{ base: '80%', sm: 'full' }}
                                    ms={4}
                                    src={surveyImage}
                                    alt='Survey Image'
                                />

                                <Stack>
                                    <CardBody>
                                        <Heading size='md'>{survey.title}.</Heading>
                                        <Text py='2'>
                                            {survey.polls.length} questions
                                        </Text>
                                        <Text py='2'>
                                             {calculateTimeRemaining(survey.closing_date)}
                                        </Text>
                                    </CardBody>

                                    <CardFooter>
                                        <Button 
                                            variant='solid' 
                                            colorScheme='blue' 
                                            width={'full'}
                                            onClick={() => {
                                                attemptSurvey(survey);
                                            }}
                                        >
                                            Start
                                        </Button>
                                    </CardFooter>
                                </Stack>
                            </Card>
                        </Box>
                    ))}
                </SimpleGrid>
                
                <Center h='100px' color='white'>
                    <Circle size='40px' bg='#63b3edff' color='white' mx={2}>
                        1
                    </Circle>
                    <Circle size='40px' bg='#dee2e6ff' color='white'>
                        2
                    </Circle>
                </Center>
                
            </Box>
        </>
    );

};
export default Surveys