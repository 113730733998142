import React from "react";
import PortalContainer from "../../components/PortalContainer/PortalContainer";
import QuotationForm from "./Components/QuotationForm";
import utils from "../../utils/commonFunctions";

const CreateQuotation = () => {
  return (
    <>
      <PortalContainer
        Title={"Request Quotation"}
        childComponent={<QuotationForm />}
      />
    </>
  );
};

export default  CreateQuotation;
