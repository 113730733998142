import React from "react";

import PublicViewContainer from "../../components/Containers/PublicViewContainer";
import Publication from "./Components/Publication";

const Publications = ({}) => {
    return (<>


        <PublicViewContainer childComponent={<Publication/>}/>

    </>);
};

export default Publications;
