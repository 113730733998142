import React from "react";
import {Alert, AlertDescription, AlertIcon, Link} from "@chakra-ui/react";

const BillAlert = ({message}) => {
    return (
        <>
            <Alert status="info" borderRadius="2xl" bg={"blue.100"} color="blue.800">
                <AlertIcon boxSize="40px" color={"blue.800"}/>
                <AlertDescription>
                    {!message?
                        <>
                            Click here for{" "}
                            <Link
                                color="blue.800"
                                fontWeight={"semibold"}
                                href="url-to-sample-bill"
                            >
                                sample bill
                            </Link>{" "}
                            layout, or{" "}
                            <Link
                                color="blue.800"
                                fontWeight={"semibold"}
                                href="url-to-contact-page"
                            >
                                here
                            </Link>{" "}
                            to send a message to us, or call 0800 555 555.
                        </>:<>
                            {message}
                        </>}
                </AlertDescription>
            </Alert>


        </>
    );
};

export default BillAlert;
