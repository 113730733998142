import React from "react";
import {
    Flex,
    Text,
    Modal, ModalOverlay, ModalContent, ModalBody, Button, ModalHeader,
} from "@chakra-ui/react";

import {Link} from "react-router-dom";

const TendersModal = ({isOpen, onClose, url, title, action,message}) => {

    return (
        <Modal closeOnOverlayClick={false} isCentered isOpen={isOpen} onClose={onClose} w={'lg'}>
            <ModalOverlay/>
            <ModalContent py={4}>
                <ModalHeader>{title}</ModalHeader>
                <ModalBody textAlign={'justify'}>
                    <Text>
                        {message}
                    </Text>
                </ModalBody>

                <Flex justify={'center'} gap={4} w={'full'} mt={4}>
                    <Button size={'lg'} bg={'white'} color={'blue.800'} boxShadow={'md'}
                            _hover={{bg: 'brand.700', color: 'white'}} onClick={onClose}>
                        Cancel
                    </Button>
                    {url &&
                        <Link to={url}>
                            <Button size={'lg'} boxShadow={'md'} bg={'brand.700'}>
                                Accept
                            </Button>
                        </Link>
                    }
                    {action &&
                        <Button size={'lg'} boxShadow={'md'} bg={'brand.700'} onClick={() => action}>
                            Accept
                        </Button>
                    }

                </Flex>
            </ModalContent>
        </Modal>
    );
};

export default TendersModal;
