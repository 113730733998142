import React from "react";
import {
    Box,
    Text,
} from "@chakra-ui/react";
import FileDisplay from "../../FileDisplay";

// PersonalDetails component
export const PersonalDetails = () => {
    let personalDetails=JSON.parse(localStorage.getItem('customerDetails'))
    return (
        <>
            <Box>
                <Text >First Name</Text>
                <Text  fontWeight='semibold'>{personalDetails?.firstName}</Text>
            </Box>
            <Box>
                <Text >Middle Name</Text>
                <Text  fontWeight='semibold'>{personalDetails?.middleName}</Text>
            </Box>
            <Box>
                <Text >Last Name</Text>
                <Text  fontWeight='semibold'>{personalDetails?.lastName}</Text>
            </Box>
            <Box>
                <Text >Identity Number</Text>
                <Text  fontWeight='semibold'>{personalDetails?.identityNo}</Text>
            </Box>
            <Box>
                <Text >Identity Type</Text>
                <Text  fontWeight='semibold'>{personalDetails?.identityType}</Text>
            </Box>
            <Box>
                <Text >Marital Status</Text>
                <Text  fontWeight='semibold'>{personalDetails?.maritalStatus}</Text>
            </Box>
            <Box>
                <Text >Marital Type</Text>
                <Text  fontWeight='semibold'>{personalDetails?.maritalType}</Text>
            </Box>
            <Box>
                <Text >Sex</Text>
                <Text  fontWeight='semibold'>{personalDetails?.sex}</Text>
            </Box>
            <Box>
                <Text >Date of Birth</Text>
                <Text  fontWeight='semibold'>{personalDetails?.dateOfBirth}</Text>
            </Box>
        </>

    );
};

// ContactDetails component
export const ContactDetails = () => {
    let contactDetails=JSON.parse(localStorage.getItem('address'))
    return (
        <>

            <Box>
                <Text>Cellphone Number</Text>
                <Text fontWeight='semibold'>{contactDetails?.cell}</Text>
            </Box>
            <Box>
                <Text>Home Telephone</Text>
                <Text fontWeight='semibold'>{contactDetails?.homeTel}</Text>
            </Box>
            <Box>
                <Text>Work Telephone</Text>
                <Text fontWeight='semibold'>{contactDetails?.workTel}</Text>
            </Box>
            <Box>
                <Text>Email</Text>
                <Text fontWeight='semibold'>{contactDetails?.email}</Text>
            </Box>


            <Box>
                <Text>Plot Number</Text>
                <Text fontWeight='semibold'>{contactDetails?.plotNo}</Text>
            </Box>
            <Box>
                <Text>Postal Address</Text>
                <Text fontWeight='semibold'>{contactDetails?.postal}</Text>
            </Box>
            <Box>
                <Text>Street</Text>
                <Text fontWeight='semibold'>{contactDetails?.street}</Text>
            </Box>
            <Box>
                <Text>Area/Ward</Text>
                <Text fontWeight='semibold'>{contactDetails?.ward}</Text>
            </Box>
            <Box>
                <Text>City/Village/Town</Text>
                <Text fontWeight='semibold'>{contactDetails?.city}</Text>
            </Box>
            <Box>
                <Text>District</Text>
                <Text fontWeight='semibold'>{contactDetails?.district}</Text>
            </Box>

        </>

    );
};

// ContactDetails component
export const NextOfKin = () => {
    let personalDetails=JSON.parse(localStorage.getItem('nextOfKin'))
    return (
        <>
            <Box>
                <Text >First Name</Text>
                <Text  fontWeight='semibold'>{personalDetails?.firstName}</Text>
            </Box>
            <Box>
                <Text >Middle Name</Text>
                <Text  fontWeight='semibold'>{personalDetails?.middleName}</Text>
            </Box>
            <Box>
                <Text >Last Name</Text>
                <Text  fontWeight='semibold'>{personalDetails?.lastName}</Text>
            </Box>
            <Box>
                <Text >Relationship</Text>
                <Text  fontWeight='semibold'>{personalDetails?.relationship}</Text>
            </Box>
            <Box>
                <Text >Cellphone Number</Text>
                <Text  fontWeight='semibold'>{personalDetails?.cell}</Text>
            </Box>

        </>

    );
};
// ContactDetails component
export const Attachments = () => {
    let files=JSON.parse(localStorage.getItem('files'))
    return (
        <>
            { files?.idKey&&<FileDisplay filePath={files?.idKey} label={"Identity Documents"}/>}
            {files?.maritalProofKey&&<FileDisplay filePath={files?.maritalProofKey} label={"Marital Proof Documents"}/>}
        </>

    );
};