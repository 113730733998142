import {
  Box,
  Button,
  Text,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import {FaCircleXmark} from "react-icons/fa6";
import {Link} from "react-router-dom";


const AlertModal = ({ isOpen, isClosed,link,response}) => {
  let nav=link?link:"dashboard"
  return (
    <>
      <Modal closeOnOverlayClick={false}  isOpen={isOpen} onClose={isClosed} isCentered={true} zIndex={10000}>
        <ModalOverlay />
        <ModalContent p={4} borderRadius={"xl"} zIndex={10000}>
          <ModalBody d={'flex'} justifyContent={'center'} align={'center'}>
            <Box w={'full'} h={'full'}>
              <Box >
                <Icon as={ FaCircleXmark} boxSize={{base:"16",md:"24"}} borderWidth={8} borderRadius={'full'} borderColor={'red.200'} color={"red.500"} />
              </Box>

              <Text my={2} fontSize={"20px"}>
               Failed to save record.
              </Text>
              {response&&
                  <Text textAlign={'center'} my={2} fontSize={"14px"}>
                    {response}
                  </Text>
              }

            </Box>
          </ModalBody>

          <ModalFooter display={"flex"} justifyContent={"center"}>
            <Link to={`/self-service-portal/${nav}`}>
              <Button variant={'solid'} color={'white'} bg='red.500' _hover={{bg:'red.600',color:'white' }}>
                OK
              </Button>
            </Link>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AlertModal;
