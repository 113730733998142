import React from "react";
import PublicViewContainer from "../../../../components/Containers/PublicViewContainer";
import FAQs from './FAQs';


const FAQMainContainer = ({}) => {
    return (<>
      <PublicViewContainer childComponent={<FAQs/>}/>
    </>);
};

export default FAQMainContainer;
