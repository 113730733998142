import React from "react";
import {
    Box,
    Text,
} from "@chakra-ui/react";

// PersonalDetails component
export const ConnectionDetails = () => {
    let personalDetails=JSON.parse(localStorage.getItem('connectionDetails'))
    return (
        <>
            <Box>
                <Text >Service Type</Text>
                <Text  fontWeight='semibold'>{personalDetails?.connectionType}</Text>
            </Box>
            <Box>
                <Text >Connection Type</Text>
                <Text  fontWeight='semibold'>{personalDetails?.isExistingConnection==='true'?'Existing Connection':'New Connection'}</Text>
            </Box>
            <Box>
                <Text >Applicant Type</Text>
                <Text  fontWeight='semibold'>{personalDetails?.applicantType}</Text>
            </Box>
            <Box>
                <Text >Plot Fenced</Text>
                <Text  fontWeight='semibold'>{personalDetails?.isPlotFenced==="true"?'Yes':'No'}</Text>
            </Box>
            <Box>
                <Text >City / Town</Text>
                <Text  fontWeight='semibold'>{personalDetails?.city}</Text>
            </Box>
            <Box>
                <Text >Area / Ward / Village</Text>
                <Text  fontWeight='semibold'>{personalDetails?.ward}</Text>
            </Box>
            <Box>
                <Text >Plot Number</Text>
                <Text  fontWeight='semibold'>{personalDetails?.plotNo}</Text>
            </Box>

        </>

    );
};

// PersonalDetails component
export const PersonalDetails = () => {
    let personalDetails=JSON.parse(localStorage.getItem('customerDetailsDisconnection'))
    return (
        <>
            <Box>
                <Text >First Name</Text>
                <Text  fontWeight='semibold'>{personalDetails?.firstName}</Text>
            </Box>
            <Box>
                <Text >Middle Name</Text>
                <Text  fontWeight='semibold'>{personalDetails?.middleName}</Text>
            </Box>
            <Box>
                <Text >Last Name</Text>
                <Text  fontWeight='semibold'>{personalDetails?.lastName}</Text>
            </Box>

            <Box>
                <Text >Customer Number</Text>
                <Text  fontWeight='semibold'>{personalDetails?.customerNumber}</Text>
            </Box>
            <Box>
                <Text >Contract Number</Text>
                <Text  fontWeight='semibold'>{personalDetails?.contractNumber}</Text>
            </Box>

        </>

    );
};
// ContactDetails component
export const ContactDetails = () => {
    let contactDetails=JSON.parse(localStorage.getItem('addressDisconnection'))
    return (
        <>
            <Box>
                <Text>Plot Number</Text>
                <Text fontWeight='semibold'>{contactDetails?.plotNo}</Text>
            </Box>
            <Box>
                <Text>Postal Address</Text>
                <Text fontWeight='semibold'>{contactDetails?.postal}</Text>
            </Box>
            <Box>
                <Text>Street</Text>
                <Text fontWeight='semibold'>{contactDetails?.street}</Text>
            </Box>
            <Box>
                <Text>Area/Ward</Text>
                <Text fontWeight='semibold'>{contactDetails?.ward}</Text>
            </Box>
            <Box>
                <Text>City/Village/Town</Text>
                <Text fontWeight='semibold'>{contactDetails?.city}</Text>
            </Box>
            <Box>
                <Text>District</Text>
                <Text fontWeight='semibold'>{contactDetails?.district}</Text>
            </Box>

        </>

    );
};
export const Owner = () => {
    let personalDetails=JSON.parse(localStorage.getItem('landlordDetails'))
    return (
        <>
            <Box>
                <Text >Full Name</Text>
                <Text  fontWeight='semibold'>{personalDetails?.name}</Text>
            </Box>
            <Box>
                <Text >Omang/Passport Number</Text>
                <Text  fontWeight='semibold'>{personalDetails?.identityNo}</Text>
            </Box>
            <Box>
                <Text >Identity Document Type</Text>
                <Text  fontWeight='semibold'>{personalDetails?.identityType}</Text>
            </Box>
            <Box>
                <Text >Email</Text>
                <Text  fontWeight='semibold'>{personalDetails?.email}</Text>
            </Box>
            <Box>
                <Text >Cellphone Number</Text>
                <Text  fontWeight='semibold'>{personalDetails?.cell}</Text>
            </Box>
            <Box>
                <Text >Telephone Number</Text>
                <Text  fontWeight='semibold'>{personalDetails?.tel}</Text>
            </Box>
            <Box>
                <Text >Postal Address</Text>
                <Text  fontWeight='semibold'>{personalDetails?.postal}</Text>
            </Box>

        </>

    );
};
export const Banking = () => {
    let personalDetails=JSON.parse(localStorage.getItem('banking'))
    return (
        <>
            <Box>
                <Text >Bank Name</Text>
                <Text  fontWeight='semibold'>{personalDetails?.name}</Text>
            </Box>
            <Box>
                <Text >Branch</Text>
                <Text  fontWeight='semibold'>{personalDetails?.branch}</Text>
            </Box>
            <Box>
                <Text >Branch Code</Text>
                <Text  fontWeight='semibold'>{personalDetails?.branchCode}</Text>
            </Box>
            <Box>
                <Text >Account Number</Text>
                <Text  fontWeight='semibold'>{personalDetails?.accountNumber}</Text>
            </Box>

        </>

    );
};