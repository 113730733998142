import React, { useState, useEffect } from 'react';
import { Box, SimpleGrid, Flex } from '@chakra-ui/react';

import { FaFileInvoiceDollar } from "react-icons/fa6";
import { MdReportProblem } from "react-icons/md";
import { MdWaterDrop } from "react-icons/md";
import { IoSpeedometer } from "react-icons/io5";
import utils from '../../../utils/commonFunctions';
import { MdPayments } from 'react-icons/md';
import { FaFileContract } from 'react-icons/fa6';
import Tables from './../components/Tables/Tables';
import MiniStatistics from './../../../components/MiniStatistics/MiniStatistics';
import {TbAlertTriangleFilled, TbMessageReport} from "react-icons/tb";


const Dashboard = () => {
    const [utilityContracts, setUtilityContracts] = useState(0);
    const [meterReadings, setMeterReadings] = useState(0);
    const [leakReports, setLeakReports] = useState(0);
    const [userPayment, setUserPayment] = useState();
    const [userId, setUserId] = useState();
    const [userLogs, setUserLogs] = useState();


    useEffect(() => {

        setUserId(utils.getUserId())

        async function fetchUtilityContracts() {
            const response = await utils.getUtilityContractsByUserId(userId);
            setUtilityContracts(response?.length);
        }

        async function fetchUserPayments() {
            const payment_response = await utils.getUserPayments(utils.getUserId());
            setUserPayment(payment_response);
        }

        async function fetchMeterReadings() {
            const meter_readings = await utils.getMeterReadings();
            setMeterReadings(meter_readings?.length);
        }

        async function fetchUserLogs() {
            const user_logs = await utils.getUserLogsById(utils.getUserId());
            setUserLogs(user_logs);
        }

        fetchUtilityContracts();
        fetchUserPayments();
        fetchMeterReadings();
        fetchUserLogs();

    }, []);

    return (
        <>
            <Flex alignItems='center' justifyContent='center'  mx={{ base:-12, md: 0 }}>
                <Box
                    maxW={{ base: 'full',sm:'6xl', md: '6xl', xl: '10xl' }}
                    w='full'
                    px={{ base:8}}
                    pt={{ base: 2, sm: 0, md: 10 }}
                >

                    <SimpleGrid
                        columns={{ base: 1,  md: 2, lg:3 }}
                        spacing={{ base: 5, lg: 8 }}
                    >
                        <MiniStatistics
                            title={'Reported Leaks'}
                            stat={userLogs?.leakReportCounts||0}
                            icon={<MdWaterDrop size={'3em'} color='#2BBCF2' />}
                        />
                        <MiniStatistics
                            title={'Reported Shortages'}
                            stat={userLogs?.waterShortageReportCounts||0}
                            icon={<TbAlertTriangleFilled   size={'3em'} color='#2BBCF2' />}
                        />
                        <MiniStatistics
                            title={'Meter Readings'}
                            stat={userLogs?.meterReadingCounts||0}
                            icon={<IoSpeedometer size={'3em'} color='#2BBCF2' />}
                        />
                        <MiniStatistics
                            title={'Bill Requests'}
                            stat={userLogs?.checkBillCounts||0}
                            icon={<FaFileInvoiceDollar size={'3em'} color='#2BBCF2' />}
                        />
                        <MiniStatistics
                            title={'Utility Contracts'}
                            stat={userLogs?.utilityContracts||0}
                            icon={<FaFileContract size={'3em'} color='#2BBCF2' />}
                        />
                        <MiniStatistics
                            title={'Online Payments'}
                            stat={'0'}
                            icon={<MdPayments size={'3em'} color='#2BBCF2' />}
                        />
                    </SimpleGrid>
                    <Tables userPayment={userPayment}/>
                </Box>
            </Flex>
        </>
    );
};

export default Dashboard;
