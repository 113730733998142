import {
    Flex,
    Stack,
    Button,
    Thead,
    Tr,
    Th,
    Table,
    TableContainer, Tbody, Td, Heading, Spacer, IconButton, Tooltip, TagLabel, Tag, useDisclosure,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";

import {Link, useNavigate} from "react-router-dom";

import {API} from "../../../utils/api";
import {FaEye, FaPlus} from "react-icons/fa6";
import utils from "../../../utils/commonFunctions";
import Paginate from "../../../components/Paginate/Paginate";
import {MdPayment} from "react-icons/md";
import moment from "moment/moment";
import PaymentSummaryModal from "../../../components/PayGate/PaymentSummaryModal";
import ResponseModal from "../../../components/PayGate/ResponseModal";
import {useUserProfile} from "../../../providers/UserProfileProvider";
import StatusTag from "./StatusTag";

const LabRequestsTable = () => {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedQuotation, setSelectedQuotation] = useState(null);
    const [quotationNumber, setQuotationNumber] = useState(null);
    const [applications, setApplications] = useState([]);
    // get query params from url
    const [reference, setReference] = useState("");
    const [statusCode, setStatusCode] = useState("");
    const [reason, setReason] = useState("");
    const terminalId=process.env.PAYLAB_TERMINAL_ID
    const [customer,setCustomer]=useState(null)
    let {userProfile}=useUserProfile()
    let {personalDetails,contactDetails,userId,customerNumber}=userProfile||{}
    const [total,setTotal]=useState(0)
    let user=utils.getUser()
    let fullname=null
    if(personalDetails){
        fullname=personalDetails?.firstName+""+personalDetails?.lastName
    }
    const calculateTotal = (items) => {

        let total = 0;
        let tax=0
        if(items&&items?.length){
            items.map((item) => {
                tax=item?.tax?item?.tax:0
                let gross=Number(tax)+ Number(item.net)
                total += Number(gross * item.quantity);
            });
            setTotal(total.toFixed(2));
        }

    };
    useEffect(()=>{
            setCustomer({
                fullName:fullname||user?.name,
                phone:contactDetails?.cell||user?.phone,
                customerNumber:customerNumber,
                customerId:userId,
                email:contactDetails?.email||user?.email
            })
    },[userProfile,user])

    useEffect(() => {
        // get query params from url
        const urlParams = new URLSearchParams(window.location.search);
        //const urlParams = new URLSearchParams( 'reference=%223MtlqLMI%22&code=200');
        setReference(urlParams.get("reference"));
        setStatusCode(urlParams.get("code"));
        setReason(urlParams.get("reason"));

    }, []);
    const getConnections = async () => {
        try {
            const response = await API.get(
                `/labs/`,
                {
                    params: {
                        emailAddress:user.email,
                        pageNumber: currentPage,
                        pageSize: 4
                    }
                }
            );
            const data = await response.data;
            if (response.status === 200) {
                setApplications(data.data)
            }

        } catch (error) {
            await utils.sendMessageToBugNinja('ERROR FETCHING LAB REQUESTS', error.message, error);
            console.log(error)
        }

    };
    useEffect(() => {
        getConnections();
    }, [currentPage]);
    const navigate = useNavigate()
    const handleView = async (id) => {
        navigate(`/self-service-portal/laboratory-services/view-quotation?quote=${id}`)
    };
    const handlePayQuotation=(quote)=>{
        setQuotationNumber(quote.quotationNumber)
        calculateTotal(quote?.gtServiceItems?.item)
        setSelectedQuotation(quote)
    }
    useEffect(() => {
        if(selectedQuotation){
            onOpen()
        }
    }, [selectedQuotation]);
    useEffect(() => {
        if(!isOpen){
            setSelectedQuotation("")
        }
    }, [isOpen]);
    return <>
        <Stack gap={0} justify={'center'} p={8} bg={'white'} boxShadow="md" w="full" minH={{base: '69vh'}}
               borderRadius={'2xl'}>
            <Flex align={'center'} w={"full"} bg={'white'} py={2.5} px={8}>
                <Heading color={'blue.800'} fontSize={'xl'}>Laboratory Quotations List</Heading>
                <Spacer/>
                <Link to={'/self-service-portal/laboratory-services/request-quotation'}>
                    <Button variant={'solid'} leftIcon={<FaPlus fontSize={'lg'} fontWeight={'bold'}/>}>Request
                        Quotation</Button>
                </Link>
            </Flex>
            <TableContainer w="full" h={"full"} bg={'white'} p={8}>
                <Table colorScheme='gray'>
                    <Thead>
                        <Tr>

                            <Th color={'blue.800'} textTransform={'capitalize'} fontSize={'sm'}>Quotation Number</Th>
                            <Th color={'blue.800'} textTransform={'capitalize'} fontSize={'sm'}>Customer Number</Th>
                            <Th color={'blue.800'} textTransform={'capitalize'} fontSize={'sm'}>Customer</Th>
                            <Th color={'blue.800'} textTransform={'capitalize'} fontSize={'sm'}>Status</Th>
                            <Th color={'blue.800'} textTransform={'capitalize'} fontSize={'sm'}>Date Created</Th>
                            <Th color={'blue.800'} textTransform={'capitalize'} fontSize={'sm'}>Action</Th>
                        </Tr>
                    </Thead>

                    <Tbody>
                        {(applications?.docs?.length > 0) &&
                            <>
                                {
                                    applications.docs.map((application) => (
                                        <Tr>
                                            <Td>{application.quotationNumber || application.zQuotationHeader.webDocNumber}</Td>
                                            <Td>{application.gtPartners.item[0].pNumber}</Td>
                                            <Td>{application.gtPartners.item[0].name}</Td>
                                            <Td>
                                                <StatusTag status={application?.status}/>
                                            </Td>
                                            <Td>{moment(application.createdAt).format("DD/MM/YYYY")}</Td>
                                            <Td>
                                                {application.status==="Accepted" &&
                                                    <Tooltip label={'Pay Now'}>
                                                        <IconButton
                                                            ml={2}
                                                            bg={'green.300'}
                                                            _hover={{bg: 'green.600'}}
                                                            aria-label=''
                                                            icon={<MdPayment/>}
                                                            onClick={() => handlePayQuotation(application)}
                                                        />
                                                    </Tooltip>
                                                }

                                                <Tooltip label={'View Quotation'}>
                                                    <IconButton
                                                        ml={2}
                                                        bg={'gray.500'}
                                                        _hover={{bg: 'gray.600'}}
                                                        aria-label=''
                                                        icon={<FaEye/>}
                                                        onClick={() => handleView(application._id)}
                                                    />
                                                </Tooltip>

                                            </Td>
                                        </Tr>
                                    ))
                                }
                            </>
                        }
                    </Tbody>
                </Table>
            </TableContainer>
            <Paginate
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                numberOfPages={applications?.totalPages || 1}
            />
        </Stack>
        <PaymentSummaryModal
            isOpen={isOpen}
            onClose={onClose}
            balance={total}
            quotationNumber={quotationNumber}
            customerNo={customer?.customerNumber}
            paymentType={'LabPayment'}
            terminalId={terminalId}
            customer={customer}
        />

        <ResponseModal
            reference={reference}
            statusCode={statusCode}
            reason={reason}
        />


    </>;
};
export default LabRequestsTable;
