import React from 'react';
import PortalContainer from "../../components/PortalContainer/PortalContainer";
import SelfServiceContainer from "../../components/PortalContainer/SelfServiceContainer";
import LeaksTable from "./Components/LeaksTable";

const ReportLeak = () => {
  return (
    <PortalContainer childComponent={<SelfServiceContainer width={'full'} childComponent={<LeaksTable />} />} Title={'Report Water Leak'} />
  );
};

export default ReportLeak;
