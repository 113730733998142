import React from "react";
import {
    Box,
    Flex,
    Text,
    Modal, ModalOverlay, ModalContent, ModalBody, Button,  SimpleGrid,
} from "@chakra-ui/react";

import moment from 'moment';
import utils from "../../../utils/commonFunctions";
const TendersModal = ({isOpen, onClose,notice}) => {

    return (
        <Modal closeOnOverlayClick={false} isCentered isOpen={isOpen} onClose={onClose}  size={{base:'full',md:'3xl',lg:'6xl'}}>
            <ModalOverlay/>
            <ModalContent color={'gray.600'} px={{base:4,md:8}} w={'full'} py={4}>
                <ModalBody w={'full'} textAlign={'justify'}>
                    <SimpleGrid columns={2} w={'full'}>
                        <Flex  w='full' justify={'start'}  textTransform={"capitalize"}><Box as={'span'} fontWeight={'semibold'} textTransform={"capitalize"} >City:</Box> {notice?.cityName||"N/A"}</Flex>
                        <Flex w={'full'} justify={'end'} fontWeight={'semibold'}><Box as={'span'} >Date:</Box>{moment(notice.dateCreated).format("DD MMMM, YYYY")}</Flex>
                    </SimpleGrid>
                    <Flex mt={4} w='full' justify={'start'}  textTransform={"capitalize"}><Box as={'span'} >Area:</Box> {notice?.areaName||"N/A"}</Flex>
                    <Flex my={4} w='full' justify={'start'} textTransform={'uppercase'}><Box textTransform={'capitalize'} as={'span'} >Notice Details:</Box> {notice.title}</Flex>
                    <Text>
                        <div dangerouslySetInnerHTML={utils.sanitizeHtml(notice?.description)}/>
                    </Text>
                </ModalBody>

                <Flex justify={'center'} gap={4} w={'full'} mt={4}>
                    <Button size={'lg'} bg={'white'} color={'blue.800'} boxShadow={'md'}
                            _hover={{bg: 'brand.700', color: 'white'}} onClick={onClose}>
                        Cancel
                    </Button>


                </Flex>
            </ModalContent>
        </Modal>
    );
};

export default TendersModal;
