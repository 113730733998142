import {
    Flex,
    Image,
    Button,
    Heading,
    HStack,
    Card,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody, Text
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import WUCLogo from "../../assets/BlueLogoNoBG.png";
import PaymentRedirect from "./PaymentRedirect";
import {usePrepaid} from "../../providers/PrepaidProvider";

const PaymentSummaryModal = ({isOpen, balance, customerNo, contractNumber,onClose ,paymentType, customer, quotationNumber, tenderId, isYouth}) => {
    const [loading, setLoading] = useState(false);
    const [redirectAlert, setRedirectAlert] = useState(false);
    const {setTransactionDetails,resetTransactionDetails}=usePrepaid()||{}

    useEffect(() => {
        if(!isOpen){
            setRedirectAlert(false)
        }
    }, [isOpen]);
    const handleRedirect=async ()=>{
        setRedirectAlert(true)
    }
    return (<>
        <Modal minW={'lg'} zIndex={'10000'} closeOnOverlayClick={false} isCentered={true} isOpen={isOpen}
               onClose={onClose}>
            <ModalOverlay/>
            <ModalContent>
                <ModalBody>
                    <Flex flex={1} p={4} textAlign="center" color="blue.800" alignItems="center"
                          justifyContent="center">
                        <Card p={4} w="full" variant={"unstyled"}>
                            <HStack w={"full"} justifyContent={"center"}>
                                <Image
                                    alt={"ResetPassword Image"}
                                    src={WUCLogo}
                                    objectFit={"contain"}
                                    h={120}
                                    w={120}
                                />
                            </HStack>
                            <>
                                {!redirectAlert ?
                                    <>
                                        <Heading
                                            as="h4"
                                            fontSize="24px"
                                            color="blue.800"
                                            fontWeight="semibold"
                                        >
                                            Payment Summary
                                        </Heading>
                                        <Text color={'gray.600'} fontSize={'sm'} mt={2}>Review details of this transaction and click on Confirm to proceed</Text>
                                        <Flex direction={'column'} align={'start'} justifyContent={'start'} bg={'blue.50'} borderRadius={'2xl'} p={4}  mt={4}>
                                            {customerNo&&
                                            <Flex width={'full'} justify={'space-between'} fontSize={'sm'} color={'gray.700'}  textTransform={"capitalize"}>
                                                <Text fontWeight={'medium'}  >Customer Number:</Text>
                                                <Text> {customerNo}</Text>
                                            </Flex>}
                                            {contractNumber&&
                                                <Flex width={'full'} justify={'space-between'} fontSize={'sm'} color={'gray.700'}  textTransform={"capitalize"}>
                                                    <Text fontWeight={'medium'}  >Utility Number:</Text>
                                                    <Text> {contractNumber}</Text>
                                                </Flex>
                                            }
                                            {quotationNumber&&
                                                <Flex width={'full'} justify={'space-between'} fontSize={'sm'} color={'gray.700'}  textTransform={"capitalize"}>
                                                    <Text fontWeight={'medium'}  >Quotation Number:</Text>
                                                    <Text> {quotationNumber}</Text>
                                                </Flex>
                                            }
                                            {customer?.tenderId&&
                                                <Flex width={'full'} justify={'space-between'} fontSize={'sm'} color={'gray.700'}  textTransform={"capitalize"}>
                                                    <Text fontWeight={'medium'}  >Tender Ref:</Text>
                                                    <Text> {customer?.tenderId}</Text>
                                                </Flex>
                                            }
                                            {customer?.meterNo&&
                                                <Flex width={'full'} justify={'space-between'} fontSize={'sm'} color={'gray.700'}  textTransform={"capitalize"}>
                                                    <Text fontWeight={'medium'}  >Meter number:</Text>
                                                    <Text> {customer?.meterNo}</Text>
                                                </Flex>
                                            }
                                            <Flex width={'full'} justify={'space-between'} fontSize={'sm'} color={'gray.700'}  textTransform={"capitalize"}>
                                                <Text fontWeight={'medium'}  >Payment Method:</Text>
                                                <Text> {'Credit/Debit Card'}</Text>
                                            </Flex>
                                            <Flex mt={2} fontWeight={'semibold'}  width={'full'} justify={'space-between'} fontSize={'md'} color={'gray.700'}  textTransform={"capitalize"}>
                                                <Text color={'brand.500'}>Total Amount:</Text>
                                                <Text color={'brand.500'}> {`P ${balance}`}</Text>
                                            </Flex>


                                        </Flex>
                                        <Button
                                            type="submit"
                                            variant={'solid'}
                                            mt={4}
                                            cursor={!loading ? "pointer" : "not-allowed"}
                                            onClick={()=>handleRedirect()}
                                        >
                                            Confirm
                                        </Button>
                                        <Button
                                            variant={'ghost'}
                                            _hover={{bg:'gray.50'}}
                                            mt={4}
                                            cursor={!loading ? "pointer" : "not-allowed"}
                                            onClick={()=>{
                                                resetTransactionDetails()
                                                onClose()
                                            }}
                                        >
                                            Cancel
                                        </Button>

                                    </>:
                                    <PaymentRedirect 
                                        paymentType={paymentType} 
                                        balance={balance} 
                                        customer={customer}  
                                        onClose={onClose}  
                                        quotationNumber={quotationNumber} 
                                        tenderId={tenderId} 
                                        contractNumber={contractNumber}
                                        isYouth={isYouth}
                                    />
                                }
                            </>
                        </Card>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>

    </>);
};

export default PaymentSummaryModal;
