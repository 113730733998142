import React from "react";
import PublicViewContainer from "../../components/Containers/PublicViewContainer";
import ViewProject from "./Components/ViewProjectPage";



const ProjectView= () => {
    return <PublicViewContainer childComponent={<ViewProject/>}/>
}

export default ProjectView