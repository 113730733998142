import React from "react";
import {
    Box, Heading, Text, SimpleGrid, ListItem, UnorderedList, Link
} from "@chakra-ui/react";
const CorprateProfile = () => (
    <>
        <Box objectFit={'fit'} bg={'white'}  px={{base:8,lg:32}} mb={8}>
            <SimpleGrid columns={{base:1,lg:2}} gap={8} mb={4}>
                <Box width={{base:"full"}}>
                    <Heading size="md" mb={4}   color={'blue.800'} textTransform={'capitalize'}>  Infrastructure and<Box as={'span'} color={'brand.700'} >  capacity to supply </Box> </Heading>
                    <Text textAlign={'justify'}>
                        The Corporation has an asset base valued at over P21 billion. The assets include nine (9) dams:
                        Gaborone, Nnywane, Bokaa, Shashe, Letsibogo, Ntimbale, Dikgatlhong, Thune and Lotsane, 900 boreholes,
                        drilling rigs, earthmoving equipment, the North South Carrier Scheme (NSC), with over 500km long pipeline,
                        35,000km network, water or wastewater connections, water treatment plants, associated pump stations, break-pressure
                        tanks, and wastewater treatment facilities across the entire country.
                    </Text>
                </Box>
                <Box width={{base:"full"}}>
                    <Heading size="md" mb={4}   color={'blue.800'} textTransform={'capitalize'}> <Box as={'span'} color={'brand.700'} >Employees</Box> </Heading>
                    <Text textAlign={'justify'}>
                        At the centre of WUC’s operations are the 3,471 WUC employees
                        who are champions of the water and wastewater management processes.
                        Through their collective efforts, water infrastructure is brought to
                        life to ensure inclusive access to and provision of water to people.
                        The leadership is tasked with sound guidance of all staff and delivery
                        of day-to-day business operations, while the Board oversees Management
                        to this end in terms of wider governance and direction.
                    </Text>
                </Box>
            </SimpleGrid>
            <SimpleGrid columns={{base:1,lg:2}} gap={8} mb={4}>
                <Box width={{base:"full"}}>
                    <Heading size="md" mb={4}   color={'blue.800'} textTransform={'capitalize'}> Our<Box as={'span'} color={'brand.700'} > customers</Box> </Heading>
                    <Text textAlign={'justify'}>
                        While everyone is a water user, the actual number of account holders was over 550,500 as the
                        financial year ended. Our customers range from businesses, schools, heath centres, Government,
                        Councils and corporate entities. WUC also works to raise awareness on self-service channels to
                        ensure that home users can access WUC services remotely with convenience, and this effort continued
                        during the period under review.
                    </Text>
                </Box>
                <Box width={{base:"full"}}>
                    <Heading size="md" mb={4}   color={'blue.800'} textTransform={'capitalize'}>Our <Box as={'span'} color={'brand.700'} > Channels</Box> </Heading>
                    <Text textAlign={'justify'}>
                        WUC has a multi-channel approach that helps ensure the utmost level of customer user experience.
                        The Corporation’s self-service channels are a roadway towards self-care, where WUC customers take
                        the proverbial reins of water service and use these following channels to access service without the
                        need for physical presence at WUC offices.  This is an effort towards access and inclusion, as well as
                        user experience. Find  WUC channels at <Link href="/contact-us" color="teal.500">Contact Us</Link>


                    </Text>
                </Box>
            </SimpleGrid>

        </Box>
    </>

);
export default CorprateProfile