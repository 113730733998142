import React, {useEffect, useState} from "react";
import {
  Box,
  Text,
  HStack,
  // Link,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import {GoChevronRight } from "react-icons/go";
import utils from "../../utils/commonFunctions";
import { Link } from 'react-router-dom';

const CustomAccordion = () => {
  const [AccordionData,setAccordionData]=useState([])
  let publications={
    label:'News & Publications',
    children:[
        {href: "/publications",
          label: "Publications"
        }
    ]
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
          const response = await utils.getQuickLinks();
          setAccordionData([...response,publications])
        console.log("Response: ",response)
      } catch (e) {
        console.error(e);  // Changed to console.error for better error logging
      }
    };
    fetchData();
  }, []);
  
  return (
    <Box
      w={"500px"}
      minH="220px"
      p={4}
      bg={"white"}
      borderRadius={"xl"}
      position="absolute"
      zIndex="10000"
      left="20px"
      transform="translateY(-150%)"
      top="112px"
      opacity={1}
      shadow={"xl"}
    >
      <Accordion allowToggle style={{ border: "white" }}>
        {AccordionData?.length > 0 && (
          <>
            {AccordionData.map((e, key) => (
              <AccordionItem key={key}>
                <h2>
                  <AccordionButton
                    _hover={{
                      color: "black",
                      bg: "#f5f5f7",
                      textDecoration: "none",
                      transform: "translateX(5px)",
                      borderLeft: "4px solid #2BBCF2",
                    }}
                    borderRadius={"md"}
                    _expanded={{
                      bg: "#0093D0",
                      color: "white",
                      borderLeft: "none",
                    }}
                  >
                    <Box
                      as="span"
                      fontWeight={"bold"}
                      fontSize={"md"}
                      flex="1"
                      textAlign="left"
                    >
                      {e.label}
                      {e.children?.length < 0 ? (
                        <Link
                          href={e.href}
                          _hover={{ textDecoration: "none" }}
                        >
                          <Text ml={2}>{e.label}</Text>
                        </Link>
                      ) : (
                        ""
                      )}
                    </Box>
                    {e.children?.length > 0 && <AccordionIcon />}
                  </AccordionButton>
                </h2>
                {e.children?.map((item, index) => (
                  <AccordionPanel key={index} pb={0}>
                    <Link 
                      to={item.href} 
                      state={{id: item.id}} 
                      _hover={{ textDecoration: "none" }}
                      >
                      <HStack
                        _hover={{ transform: "translateX(5px)" }}
                        transitionDuration={"0.3s"}
                        cursor="pointer"
                      >
                        <Icon as={GoChevronRight} />
                        <Text ml={2}>{item.label}</Text>
                      </HStack>
                    </Link>
                  </AccordionPanel>
                ))}
              </AccordionItem>
            ))}
          </>
        )}
      </Accordion>
    </Box>
  );
};

const QuickLinksMenu = () => {
  const [show, setShow] = useState(false);
  const handleToggleShow = () => setShow(!show);

  return (
    <Box position={"relative"}>
      <HStack
        onMouseEnter={handleToggleShow}
        h={show ? "125px" : "50px"}
        w="125px"
        transitionDuration={"0.4s"}
        transform={"rotate(90deg)"}
        mt={show ? 0 : "37px"}
        ml={show ? 0 : "-40px"}
      >
        <Box
          bg={"#0093D0"}
          w={"full"}
          h="full"
          py={4}
          fontWeight={"normal"}
          textAlign={"center"}
          borderTopRadius={30}
        >
          <Text
            textDecoration="underline"
            color="white"
            textTransform="uppercase"
            fontSize="14px"
            cursor={'default'}
          >
            Quick Links
          </Text>
        </Box>
      </HStack>
      {show && (<CustomAccordion/>)}
    </Box>
  );
};
export { QuickLinksMenu };
