import PortalContainer from "../../components/PortalContainer/PortalContainer";
import MeterReadingForm from "./Components/SubmitMeterReadingForm";
import SelfServiceContainer from "../../components/PortalContainer/SelfServiceContainer";
import React from "react";
import MeterReadingTable from "./Components/MeterReadingTable";
const SubmitMeterReading = () => {
  return (
    <PortalContainer childComponent={<SelfServiceContainer childComponent={<MeterReadingForm />} table={<MeterReadingTable/>}/>}  Title={'Submit Meter Reading'}/>
  );
};

export default SubmitMeterReading;
