import { extendTheme } from '@chakra-ui/react';

const sharedInputSelectStyles = {
  baseStyle: {
    field: {
      borderRadius: '2xl',
      borderWidth: '2px',
      color: "gray.500",
      _focus: {
        borderColor: '#2BBCF2',
        boxShadow: '0 0 0 1px var(--chakra-colors-brand-500)',
      },
    },
  },
  sizes: {
    md: {
      field: {
        height: '45px',
        borderWidth: '2px',
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
};

const theme = extendTheme({
  fonts: {
    body: 'Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    heading: 'Archivo, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },

  components: {
    Modal: {
      baseStyle: (props) => ({
        dialog: {
          borderRadius: '2xl',
        },
      }),
    },
    FormLabel: {
      baseStyle: {
        color: 'blue.800',
      },
    },
    Input: {...sharedInputSelectStyles},
    Select: {...sharedInputSelectStyles},
    Textarea: {...sharedInputSelectStyles},
    Alert: {
      variants: {
        lightBlue: {
          container: {
            color: 'white',
            bg: 'blue.300',
          },
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: '2xl',
      },
      variants: {
        solid: {
          bg: 'brand.500',
          color: 'white',
          _hover: {
            bg: 'brand.600',
          },
        },
        outline: {
          borderColor: 'brand.500',
          color: 'brand.500',
          _hover: {
            bg: 'brand.500',
            color: 'white',
          },
        },
        ghost: {
          color: 'brand.500',
          _hover: {
            bg: 'brand.100',
          },
        },
      },
      sizes: {
        md: {
          field: {
            height: '45px',
          },
        },
      },
      defaultProps: {
        size: 'md',
      },
    },
  },
  colors: {
    brand: {
      100: '#bee3f8',
      500: '#2BBCF2',
      600: '#2a9fd6',
      700: '#0093D0'
    },
  },
});

export default theme;
