import React, { useState, useEffect } from "react";
import {
    Badge,
    SimpleGrid,
    Circle,
    Box,
    Button,
    ButtonGroup,
    Center,
    Divider,
    Flex,
    Heading,
    Hide,
    Show,
    Stack,
    Text,
    useDisclosure,
    HStack,
    Spacer,
    Grid, GridItem
} from "@chakra-ui/react";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import utils from "../../../utils/commonFunctions";

const NewsItems = ({news}) => {
    const navigate=useNavigate()
    const handleNavigate = (news) => {
        navigate(`/news/view/${news.title.toLowerCase().replace(/\s+/g, '-')}`, { state: {news} });
    };
    return (
        <>
            <Flex key={news?._id} direction={'column'} justify={'center'}  w={'full'} borderBottom={'1px'} borderBottomColor={'gray.300'} my={4}>
                <Heading size={'md'} >{moment(news?.datePublished).format('DD MMMM, YYYY')}</Heading>
                <Text fontWeight={'semibold'} my={2}>{news?.title}</Text>

                <Grid  templateColumns='repeat(12, 1fr)' gap={4} justifyContent={'center'}  mb={4}>
                    <GridItem colSpan={{base:12,md:11}} >
                        <Text noOfLines={2} color={'gray.500'}>
                            <div dangerouslySetInnerHTML={utils.sanitizeHtml(news?.description)}/>
                        </Text>
                    </GridItem>
                    <GridItem colSpan={{base:12,md:1}} alignItems={'end'}>
                        <Button w={'full'} onClick={()=>handleNavigate(news)}>Read More</Button>
                    </GridItem>

                </Grid>

            </Flex>
        </>
    );
};


const News = () => {
    const [news,setNews]=useState([])
    useEffect(() => {
        async function getNews() {
            let fetchedNews= await utils.fetchNews()
            if(fetchedNews?.length) {setNews(fetchedNews)}
        }
        getNews()
    }, []);
    return (
      <Box bg={'white'} w={'full'} p={8} borderRadius={'2xl'} boxShadow={'md'}>
          {!news.length&&
              <Text justifyContent={'center'} align={'center'} fontWeight={'semibold'} color={'gray.500'} fontSize={'2xl'}>
                  No Record Found
              </Text>
          }
          {news&&news.map((news)=>(
              <NewsItems news={news}/>
          ))}



      </Box>
    );
};


export default News;
