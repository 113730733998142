import React, {useEffect, useState} from "react";
import {Heading, Flex, Grid, GridItem, Button, Text, Box, useDisclosure} from "@chakra-ui/react";
import {Link, useLocation} from "react-router-dom";

import NoticeModal from "./NoticeModal";
import utils from "../../../utils/commonFunctions";
import moment from "moment/moment";
const Notice = ({notice}) => {
    const {isOpen, onOpen, onClose} = useDisclosure()
    return(<>
        <Flex  cursor={'pointer'} direction={'column'} justify={'center'} align={'center'} bg={'white'}  pb={4}  boxShadow="md"  borderRadius={'2xl'} >
            <Flex p={8} gap={4}>

                <Box>
                    <Heading size={'sm'} color={'blue.800'} fontWeight={'bold'} noOfLines={1}
                             textTransform={'capitalize'} w={'full'}>{notice.title}</Heading>
                    <Heading size={'sm'} color={'gray.400'} fontWeight={'normal'} noOfLines={2} mt={2}
                             w={'full'}>
                        <div dangerouslySetInnerHTML={utils.sanitizeHtml(notice.description)}/>
                    </Heading>
                    <Text color={'gray.400'} mt={4} textTransform={"capitalize"}><Box as={'span'} fontWeight={'bold'}
                                                                                      textTransform={"capitalize"}>Date:</Box> {moment(notice.dateCreated).format("DD MMMM, YYYY")}
                    </Text>
                    <Text color={'gray.400'} textTransform={"capitalize"}><Box as={'span'} fontWeight={'bold'}
                                                                               textTransform={"capitalize"}>City:</Box> {notice?.cityName || "N/A"}
                    </Text>
                </Box>

            </Flex>
            <Flex direction={'column'} justify={'center'} mt={4}>
                <Link to={`/notices/view?id=${notice._id}`}>
                    <Button w={'200px'} bg={'brand.700'} boxShadow={'md'} size={'lg'}  _hover={{color:'white',bg:'brand.600'}}>Read More</Button>

                </Link>
            </Flex>
        </Flex>
        <NoticeModal isOpen={isOpen} onClose={onClose} notice={notice}/>

    </>)
};

const Notices = () => {
    const location = useLocation();
    const [filter,setFilter]=useState(location?.state?.filter||{})
    const [notices,setNotices]=useState([])
   const getNotices=async ()=>{
       try {
           let notices=await utils.fetchNotices(filter)
           setNotices(notices)
       } catch (e) {
           console.log(e)
       }
   }
    useEffect(() => {
        getNotices()
    }, []);
    return (
        <>
            <Flex justify={'center'} align={'center'} py={8} w={'full'}>
                <Box w={'full'}>
                    <Heading size="lg" mb={4} textAlign={'center'} color={'blue.800'}>Notices </Heading>
                    {!notices?.length>0&&
                        <Flex justifyContent={'center'} alignItems={'center'} colSpan={{base: 12}}>
                            <Heading color={'gray.400'} mt={8}>No Record Found</Heading>
                        </Flex>
                    }
                    <Grid templateColumns='repeat(12, 1fr)' gap={4}>
                        {notices?.length>0&&notices.map((notice, index) => (
                            <GridItem key={index} colSpan={{base: 12, md: 4, lg:3}}>
                                <Notice notice={notice} />
                            </GridItem>
                        ))}

                    </Grid>
                </Box>
            </Flex>
        </>
    )
};
export default Notices