import React from "react";
import AboutUs from "./Components/AboutUs";
import FullContainer from "../../components/Containers/FullContainer";



const About = () => {
    return <FullContainer childComponent={<AboutUs/>}/>

}

export default About
