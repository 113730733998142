import React from "react";
import {

    Heading,
    Flex, Image, Button,

} from "@chakra-ui/react";
import {Box} from "@chakra-ui/layout";
import utils from "../../../../utils/commonFunctions";


const Event = ({title,description,startDate,image}) => {
    const date = new Date(startDate);
    const day = date.getDate();
    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const month = months[date.getMonth()]; // 'May'
    const year = date.getFullYear();
    return(<>
        <Flex  cursor={'pointer'} direction={'column'} justify={'center'}  bg={'white'}  height="320px"  boxShadow="md"  borderRadius={'2xl'} >
            <Flex
                as={'button'}
                width="full"
                justifyContent="start"
                alignItems="end"
                borderTopRadius="2xl"

            >
                <Image h={'200px'} w={'full'} src={image}
                       borderTopRadius="xl"
                       p={{ base: 8, md: 0 }}
                       transition="transform 0.5s, filter 0.5s ease-in-out"
                       _hover={{
                           transform: `scale(100%)`,
                           filter: "brightness(75%)",
                       }}/>
            </Flex>
            <Flex p={4} gap={4} height="120px" justify={'start'}>
                <Flex direction={'column'} textAlign={'left'} >
                    <Flex direction={'column'} textAlign={'left'}>
                        <Heading size={'sm'} color={'purple.600'} fontWeight={'medium'} w={'full'}>{day}</Heading>
                        <Heading size={'sm'} color={'blue.800'} fontWeight={'bold'} w={'full'}>{month}</Heading>
                        <Heading size={'sm'} color={'blue.800'} fontWeight={'bold'} w={'full'}>{year}</Heading>
                    </Flex>
                </Flex>
                <Box  >
                    <Heading size={'sm'} color={'blue.800'} fontWeight={'bold'} noOfLines={2}  w={'full'}>{title}</Heading>
                    <Heading size={'sm'} color={'gray.400'} fontWeight={'normal'} noOfLines={2} mt={2} w={'full'}>
                        <div dangerouslySetInnerHTML={utils.sanitizeHtml(description)}/>
                    </Heading>
                </Box>
            </Flex>
            <Flex   px={4} mb={8} direction={'column'} justify={'center'} alignSelf={"center"}  w={'full'}>
                <Button  w={'full'} bg={'brand.700'} boxShadow={'md'} size={'lg'}  _hover={{color:'white',bg:'brand.600'}}>View</Button>
            </Flex>

        </Flex>


    </>)
};

export default Event