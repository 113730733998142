import React from "react";

import PublicViewContainer from "../../components/Containers/PublicViewContainer";
import NewsViewComponent from "./Components/NewsView";


const NewsView = () => {

    return <PublicViewContainer childComponent={<NewsViewComponent/>}/>

};


export default NewsView;
