import React from "react";
import FullContainer from "../../components/Containers/FullContainer";
import { useLocation } from 'react-router-dom';
import { useState, useEffect, useCallback } from "react";
import { Box, Center } from "@chakra-ui/react";
import axios from "axios";
import {API} from "../../utils/api"
import PollQuestions from "../../components/Polls/PollQuestions";
import EmptySurveyQuestions from "./Components/EmptySurveyQuestions";

const SurveyAttempt = () => {
    const location = useLocation();
    const { survey, polls } = location.state || {};

    return <FullContainer childComponent={<SurveyQuestion survey={survey} />} />;
}

const SurveyQuestion = ({ survey }) => {
    const [ipAddress, setIpAddress] = useState('');
    const [questionIndex, setQuestionIndex] = useState(0);
    const [polls, setPolls] = useState([]);
    const [filteredQuestions, setFilteredQuestions] = useState([]);

    const fetchIPAddress = async () => {
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            setIpAddress(response.data.ip);
        } catch (error) {
            console.error('Error fetching IP address: ', error);
            setIpAddress('');
        }
    };

    const fetchPolls = useCallback(async () => {
        try {
            const response = await API.get(`/common/surveys/${survey?._id}`);
            if (response.status === 200) {
                const data = response.data;
                console.log("Fetched Polls: ", data.polls);
                setPolls(data.polls);
            } else {
                console.error('Error fetching polls:', response.status);
                setPolls([]);
            }
        } catch (error) {
            console.error('Error fetching polls: ', error);
            setPolls([]);
        }
    }, [survey?._id]);

    useEffect(() => {
        fetchIPAddress();
        fetchPolls();
    }, [fetchPolls]);

    useEffect(() => {
        if (ipAddress && polls.length > 0) {
            const newFilteredQuestions = polls.filter(question =>
                !question.ipAddresses.includes(ipAddress)
            );
            console.log("Filtered Questions: ", newFilteredQuestions);
            setFilteredQuestions(newFilteredQuestions);
        }
    }, [ipAddress, polls]);

    const refreshSurvey = useCallback(() => {
        fetchPolls();
    }, [fetchPolls]);

    useEffect(() => {
        if (filteredQuestions.length > 0) {
            setQuestionIndex(0); // Reset question index when filtered questions are updated
        }
    }, [filteredQuestions]);

    useEffect(() => {
        console.log("Filtered Questions Length:", filteredQuestions.length);
        console.log("Question Index:", questionIndex);
    }, [filteredQuestions, questionIndex]);

    if (filteredQuestions.length > 0) {
        console.log("Filtered Question: ", filteredQuestions[questionIndex])
        return (
            <Box bg={'white'} px={{ base: 8, lg: 32 }} mb={8} minHeight={400} boxShadow='lg'>
                <Center p='4'>
                    <Box width={{ base: "50%" }}>
                        <PollQuestions
                            questions={filteredQuestions[questionIndex]}
                            ipAddress={ipAddress}
                            refreshSurvey={refreshSurvey}
                            title={'Survey Question'}
                            optionsHeight="auto"
                        />
                    </Box>
                </Center>
            </Box>
        );
    } else {
        return <EmptySurveyQuestions />;
    }
};

export default SurveyAttempt;


// const SurveyQuestion = ({ survey, polls }) => {
//     const [ipAddress, setIpAddress] = useState('');
//     const [questionIndex, setQuestionIndex] = useState(0);
//     const [filteredQuestions, setFilteredQuestions] = useState([]);

//     const fetchIPAddress = async () => {
//         try {
//             const response = await API.get('https://api.ipify.org?format=json');
//             setIpAddress(response.data.ip);
//         } catch (error) {
//             console.error('Error fetching IP address: ', error);
//             setIpAddress('');
//         }
//     };

//     useEffect(() => {
//         fetchIPAddress();
//     }, []);

//     useEffect(() => {
//         if (ipAddress && polls) {
//             const newFilteredQuestions = polls.filter(question =>
//                 !question.ipAddresses.includes(ipAddress)
//             );
//             setFilteredQuestions(newFilteredQuestions);
//         }
//     }, [ipAddress, polls]);

//     const refreshSurvey = () => {
//         console.log("Refreshing survey");
//         console.log("My IP address is ", ipAddress);
//         if (ipAddress && polls) {
//             const newFilteredQuestions = polls.filter(question =>
//                 !question.ipAddresses.includes(ipAddress)
//             );
//             setFilteredQuestions(newFilteredQuestions);
//         }
//     }

//     if (!filteredQuestions.length) {
//         return <EmptySurveyQuestions />
//     }

//     return (
//         <Box bg={'white'} px={{ base: 8, lg: 32 }} mb={8} minHeight={400} boxShadow='lg'>
//             <Center p='4'>
//                 <Box width={{ base: "50%" }}>
//                     <PollQuestions
//                         questions={filteredQuestions[questionIndex]}
//                         ipAddress={ipAddress}
//                         refreshSurvey={refreshSurvey}
//                         title={'Survey Question'}
//                         optionsHeight="auto"
//                     />
//                 </Box>
//             </Center>
//         </Box>
//     );
// };

// export default SurveyAttempt;
