import {
    Input,
    Stack,
    Button,
    FormLabel,
    FormControl,
    HStack,
    InputGroup,
    useToast,
    Select,
    Text,
    Switch,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {PropagateLoader} from "react-spinners";
import {CustomGeneralToast} from "../../../utils/customChakraToasts";
import utils from "../../../utils/commonFunctions";
import {API} from "../../../utils/api";
import ViewBalanceModal from "./ViewBalanceModal";
import PaymentSummaryModal from "../../../components/PayGate/PaymentSummaryModal";
import BillAlert from "../../../components/PortalContainer/BillAlert";
import {useUserProfile} from "../../../providers/UserProfileProvider";
import ResponseModal from "../../../components/PayGate/ResponseModal";
import {useQuoteCart} from "../../../providers/QuoteCartProvider";
import {ContractNumberInput, CustomerNumberInput} from "../../../components/Applications/Forms/Inputs";

const CheckBill = () => {
    const userId = utils.getUserId();
    const [customer, setCustomer] = useState(null)
    let {name,phone,email} = utils.getUser()||{}
    const {billBalance, setBillBalance} = useQuoteCart()
    const [number, setNumber] = useState( "");
    const [utility, setUtility] = useState("");
    const [loading, setLoading] = useState(false);
    const [isViewBalanceModalOpen, setIsViewBalanceModalOpen] = useState(false);
    const [viewResponseModalOpen, setViewResponseModalOpen] = useState(false);
    const [isPaymentSummaryModalOpen, setIsPaymentSummaryModalOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [checkForOthers, setCheckForOthers] = useState(false);
    const [balance, setBalance] = useState(0);
    const {refreshFlag, setRefreshFlag} = useQuoteCart()
    const {addGeneralToast} = CustomGeneralToast();
    const toast = useToast();

    // get query params from url
    const [reference, setReference] = useState("");
    const [statusCode, setStatusCode] = useState();
    const [reason, setReason] = useState();
    const [numberOptions, setNumberOptions] = useState();

    useEffect(() => {

        // get query params from url
        const urlParams = new URLSearchParams(window.location.search);
        // http://localhost:3000/self-service-portal/pay-bill-online?reference=%223MtlqLMI%22&code=200

        setStatusCode(urlParams.get("code"));
        setReference(urlParams.get("reference"));
        setReason(urlParams.get("reason"));

        if (urlParams.has("reference") && urlParams.has("code")) {
            setViewResponseModalOpen(true);
        }
    }, []);
    useEffect(() => {
            setCustomer({
                fullName: name,
                phone: phone,
                email: email,
                customerId: userId,
                customerNumber: number,
                contractNumber: utility
            })
    }, [name, phone, email, number, utility])

    useEffect(() => {
            setCustomer({
                fullName: name,
                customerId: userId,
                email: email,
                phone:phone,
                customerNumber: "",
                contractNumber: "",

            })
    }, [checkForOthers]);
    const customerMap = {
        fullName: "Customer Name",
        phone: "Customer Cell Number",
        email: "Customer Email",
        customerNumber: "Customer Number",
        contractNumber: "Utility Number",

    }

    function checkCustomerIsValid(customer) {
        for (let key in customer) {
            if (customer[key] === null || customer[key] === undefined || customer[key] === "") {
                toast.closeAll();
                addGeneralToast({
                    message: `${customerMap[key]} is a required field`,
                    type: "warning",
                });
                setLoading(false);
                return false;
            }
        }
        return true
    }

    const getBillAmount = async () => {

        if (!checkCustomerIsValid(customer)) {
            return
        }
        setShow(false);
        setLoading(true);
        try {
            const response = await API.post(
                `/meter/balance-check`,
                {
                    customerNumber: number,
                    contractNumber: utility,
                    createdBy: userId || email,
                }
            );

            const data = response.data;
            if (response.status === 200) {
                setBalance(Number(data.balance));
                setShow(true);
                setLoading(false);
                setIsViewBalanceModalOpen(true);
                setRefreshFlag(true)

            } else {
                handleErrorResponse(data.message);
            }
        } catch (e) {
            await utils.sendMessageToBugNinja('ERROR FETCHING ACCOUNT BALANCE', e.message, e);
            handleErrorResponse(e?.message ? e.message : "Could not fetch customer account balance.Please contact administrator");
        }
    };

    const handleErrorResponse = (message) => {
        setLoading(false);
        setBalance(0);
        toast.closeAll();
        addGeneralToast({
            message,
            type: "error",
        });
        setShow(false);
    };

    const closeViewBalanceModal = () => {
        setIsViewBalanceModalOpen(false);
    };
    const closePaymentSummaryModal = () => {
        setIsPaymentSummaryModalOpen(false);
    };
    const updatePaymentSummaryModal = (newState) => {
        setIsPaymentSummaryModalOpen(newState);
    };

    const handleSetUtility = (e) => {
        const {value} = e.target;
        setUtility(value)
    };
    const handleSetNumber = (e) => {
        const {value,name} = e.target;
        setNumber(value)
    };

    return (
        <>
            <Stack spacing={1} justify="center" px={4} w="full" h="full">
                <BillAlert/>
                {checkForOthers ? (
                    <FormControl isRequired id="customerNumber">
                        <FormLabel>Customer Number</FormLabel>
                        <Input
                            type="number"
                            placeholder={"Customer Number"}
                            onChange={(e) => setNumber(e.target.value)}
                        />
                    </FormControl>
                ) : (
                    <CustomerNumberInput
                        value={number}
                        callback={handleSetNumber}
                    />
                )}
                {checkForOthers ? (
                    <FormControl isRequired id="contractNumber">
                        <FormLabel>Utility Number</FormLabel>
                        <Input
                            type="number"
                            placeholder={"Utility Number"}
                            onChange={(e) => setUtility(e.target.value)}
                        />
                    </FormControl>
                ) : (
                    <ContractNumberInput
                        value={ utility}
                        customerNumber={number}
                        callback={handleSetUtility}
                    />
                )}


                <Stack spacing={6} mt={2}>
                    {loading ? (
                        <Button variant="solid">
                            <PropagateLoader size={8} color="white"/>
                        </Button>
                    ) : (
                        <Button
                            type="submit"
                            variant="solid"
                            onClick={getBillAmount}
                        >
                            Get Bill Amount
                        </Button>
                    )}
                </Stack>
                <HStack mt={2} justifyContent="center" alignItems="center">
                    <Text color="blue.800">Utility Contract not listed?</Text>
                    <Switch id='bill-for-others' onChange={(e) => setCheckForOthers(e.target.checked)}/>
                </HStack>

            </Stack>

            {isViewBalanceModalOpen && (
                <ViewBalanceModal
                    isOpen={isViewBalanceModalOpen}
                    onClose={closeViewBalanceModal}
                    balance={balance}
                    onUpdatePaymentSummaryModal={updatePaymentSummaryModal}
                />
            )}

            {isPaymentSummaryModalOpen && (
                <PaymentSummaryModal
                    isOpen={isPaymentSummaryModalOpen}
                    onClose={closePaymentSummaryModal}
                    balance={billBalance}
                    customerNo={customer?.customerNumber}
                    contractNumber={customer?.contractNumber}
                    paymentType={'BillPayment'}
                    customer={customer}
                />
            )}

            {viewResponseModalOpen && (
                <ResponseModal
                    reference={reference}
                    statusCode={statusCode}
                    reason={reason}
                />
            )}
        </>
    );
};

export default CheckBill;
