
import React from "react";
import { Box, Heading, Center, Image, Button } from "@chakra-ui/react";
import { VStack } from '@chakra-ui/react'
import thumbUpImage from "../../../assets/thumb-up.png";
import { useNavigate } from "react-router-dom";

const EmptySurveyQuestions = () => {
    const navigate = useNavigate();

    return (
        <Box objectFit={'fit'} px={{ base: 8, lg: 32 }} mb={8} minHeight={400}>
            <Heading size="lg" my={8} textAlign={'center'} color={'brand.700'}>No questions available!</Heading>
            <Center p='4' color='white' axis='both'>
                <VStack
                    spacing={4}
                    align='stretch'
                    bg={'white'}
                    shadow={'lg'}
                    px={{ base: 8, lg: 32 }} mb={8}
                    py={8}
                    borderRadius='lg'
                >
                    <Image
                        objectFit='fit'
                        maxW={{ base: '100%', sm: '200px' }}
                        maxH={{ base: '80%', sm: 'full' }}
                        ms={4}
                        src={thumbUpImage}
                        alt='No Questions Image'
                    />
                    <Button
                        mt={8}
                        variant='solid'
                        colorScheme='blue'
                        width={'full'}
                        onClick={() => {
                            navigate('/survey');
                        }}
                    >
                        Back to Surveys
                    </Button>
                </VStack>
            </Center>
        </Box>
    );

};

export default EmptySurveyQuestions
