import React, {useState} from "react";
import {
    Button,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    Radio,
    RadioGroup,
    SimpleGrid,
    Stack,
    Textarea,
    useToast,
} from "@chakra-ui/react";
import {API} from "../../../utils/api";
import {CustomGeneralToast} from "../../../utils/customChakraToasts";
import {PropagateLoader} from "react-spinners";
import BillAlert from "../../../components/PortalContainer/BillAlert";

const LeakReportGuest = () => {


    const [name, setName] = useState("");
    const [cell, setCell] = useState("");
    const [number, setNumber] = useState("");
    const [leakLocation, setLeakLocation] = useState("Outside Plot");
    const [villageOrTown, setVillageOrTown] = useState("");
    const [physicalAddress, setPhysicalAddress] = useState("");
    const [email, setEmail] = useState("");
    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const {addGeneralToast} = CustomGeneralToast();
    const [checkForOthers, setCheckForOthers] = useState(false);
    const [utility, setUtility] = useState("");

const checkLocation=()=>{
    if(!leakLocation){
        return false
    }
    if(leakLocation==='Inside Plot'){
       if(!utility && !number){
           return false
       }
       return true
    }
    return true

}
    const reportLeak = async () => {
    const check= checkLocation()
        if (!name || !cell ||!check|| !villageOrTown || !physicalAddress || !email || !comment) {
            toast.closeAll();
            addGeneralToast({
                message: "All fields must be filled in",
                type: "warning",
            });
            setLoading(false);
            return;
        }
        setLoading(true);
        try {
            let dataWithContract=  {
                name: name,
                customerNumber: number,
                contractNumber: utility,
                contactDetails: {
                    email: email,
                    phone: cell
                },
                where: leakLocation,
                village: villageOrTown,
                address: physicalAddress,
                createdBy: name,
                comment: comment
            };
            let data={
                name: name,
                contactDetails: {
                    email: email,
                    phone: cell
                },
                where: leakLocation,
                village: villageOrTown,
                address: physicalAddress,
                createdBy: name,
                comment: comment
            }
            if(utility&&number){
                data=dataWithContract
            }
            const response = await API.post(`/leaks/`,data)
            if (response.status === 201) {
                toast.closeAll();
                addGeneralToast({
                    message: "Leak Report Submitted Successfully",
                    type: "success",
                });
                setEmail("")
                setName("")
                setUtility("")
                setCell("")
                setNumber("")
                setComment("")
                setLeakLocation("")
                setPhysicalAddress("")
                setVillageOrTown("")

            } else {
                toast.closeAll();
                addGeneralToast({
                    message: "Leak Report Unsuccessful",
                    type: "error",
                });
            }
        } catch (e) {
            console.log(e)
            toast.closeAll();
            addGeneralToast({
                message: e?.message? e?.message : e.statusText,
                type: "error",
            });
        } finally {
            setLoading(false);
        }
    };
    return <>
        <Stack spacing={1} justify="center" w="full" h="full">


            <SimpleGrid columns={{base: 1, lg: 2}} gap={2} mt={2}>


                <FormControl isRequired id="customerName">
                    <FormLabel>Customer Name</FormLabel>
                    <Input type="text" value={name} onChange={(e) => setName(e.target.value)}/>
                </FormControl>
                <FormControl isRequired id="customerCell">
                    <FormLabel>Customer Cell No</FormLabel>
                    <Input type="number" value={cell} onChange={(e) => setCell(e.target.value)}/>
                </FormControl>
                <FormControl isRequired id="email">
                    <FormLabel>Email</FormLabel>
                    <InputGroup>
                        <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                    </InputGroup>
                </FormControl>
                <FormControl isRequired id="villageOrTown">
                    <FormLabel>Village/Town</FormLabel>
                    <Input type="text" value={villageOrTown}
                           onChange={(e) => setVillageOrTown(e.target.value)}/>
                </FormControl>
            </SimpleGrid>
            <FormControl isRequired id="comment">
                <FormLabel>Message</FormLabel>
                <Textarea value={comment} onChange={(e) => setComment(e.target.value)}/>
            </FormControl>
            <FormControl isRequired id="physicalAddress">
                <FormLabel>Physical Address</FormLabel>
                <Textarea value={physicalAddress} onChange={(e) => setPhysicalAddress(e.target.value)}/>
            </FormControl>
            {leakLocation === "Inside Plot" &&
                <>
                    <FormControl isRequired id="customerNumber">
                        <FormLabel>Customer Number</FormLabel>
                        <Input
                            value={number}
                            type="number"
                            onChange={(e) => setNumber(e.target.value)}
                        />
                    </FormControl>
                    <FormControl isRequired id="contractNumber">
                        <FormLabel>Utility Number</FormLabel>
                        <>
                            <Input
                                isRequired
                                type="number"
                                value={utility}
                                onChange={(e) => setUtility(e.target.value)}
                            />
                        </>
                    </FormControl>
                </>
            }
            <FormControl isRequired id="leakLocation">
                <FormLabel>Leak Location</FormLabel>
                <RadioGroup value={leakLocation} onChange={setLeakLocation}>
                    <Stack direction="row">
                        <Radio value="Inside Plot">Inside Plot</Radio>
                        <Radio value="Outside Plot">Outside Plot</Radio>
                    </Stack>
                </RadioGroup>
            </FormControl>
            <Stack spacing={6} mt={2}>
                <Button
                    onClick={!loading ? reportLeak : undefined}
                >
                    {!loading ? "Submit Report" : <PropagateLoader size={8} color="white"/>}
                </Button>
            </Stack>
        </Stack>

    </>;
};

export default LeakReportGuest;
