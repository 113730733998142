import React from 'react';
import RequestMonthlyBill from './Components/RequestBillGuest';
import SelfServiceContainer from "../../components/Containers/SelfServiceContainer";

const RequestMonthlyBillGuest = () => {
  return (
      <SelfServiceContainer childComponent={<RequestMonthlyBill/>}/>
  );
};

export default RequestMonthlyBillGuest;
