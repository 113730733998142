import {
    Flex,
    Stack,
    Button,
    useToast,
    Thead,
    Tr,
    Th,
    Table,
    TableContainer, Tbody, Td, Heading, Spacer, IconButton, Tooltip, TagLabel, Tag, useDisclosure, Show, Hide,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";

import {Link, useNavigate} from "react-router-dom";
import {CustomGeneralToast} from "../../../utils/customChakraToasts";

import {API} from "../../../utils/api";
import {FaPlus} from "react-icons/fa6";
import utils from "../../../utils/commonFunctions";
import Paginate from "../../../components/Paginate/Paginate";
import {MdEdit, MdFileOpen} from "react-icons/md";
import PdfModal from "../../../components/PdfViewer/PdfModal";
import enums from "wuc-library/enums";
import {ConnectionRequestStatus} from "wuc-library/enums";
import ApplicationRequestPdf from "../../../components/PdfViewer/AppliactionRequestPdf";

const ConnectionTable = () => {
    const {isOpen,onOpen,onClose} = useDisclosure();
    const navigate=useNavigate()
    const [number, setNumber] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [utility, setUtility] = useState("");
    const userId = utils.getUserId();
    // Date filter for the last 3 months
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
    threeMonthsAgo.setHours(0, 0, 0, 0);
    const query = {
        id: null,
        customerNumber:null,
        profileId:null,
        userId:userId,
        contractNumber:null,
        pageNumber:currentPage,
        pageSize:4
    };


    const [applications, setApplications] = useState([]);
    const [pdfData, setPdfData] = useState([]);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const {addGeneralToast} = CustomGeneralToast();
    const toast = useToast();

    const getConnections= async () => {
        setLoading(true);
        try {

            const response = await API.get(
                `/connection/find/individual-connection-requests`,
                {params:query}
            );
            const data = await response.data;
            if (response.status === 200) {
                setApplications(data.data)
                setShow(true);
                setLoading(false);
                //setIsModalOpen(true)
            }
            setShow(false);
            setLoading(false);

        } catch (e) {
            setLoading(false);
            console.log(e)
        }

    };
    useEffect(() => {
        getConnections();
    }, [currentPage]);
    const handleUpdate= async (id) => {
        try {
            const response = await API.get(
                `/connection/find/individual-connection-requests`,
                {params:{id}}
            );
            const data = await response.data;
            if (response.status === 200) {
             navigate('/self-service-portal/update-connection',{ state: { application:data.data.docs[0] } })
            }
        } catch (e) {
            console.log(e)
        }

    };
    const handleView= async (application) => {
        try {
          setPdfData(application)
            onOpen()
        } catch (e) {
            console.log(e)
        }

    };
    const statusColorScheme = {
        [ConnectionRequestStatus.SUBMITTED]: 'orange',
        [ConnectionRequestStatus.REVIEW]: 'purple',
        //[ConnectionRequestStatus.RETURNED]: 'orange',
        [ConnectionRequestStatus.APPROVED]: 'green',
        [ConnectionRequestStatus.DECLINED]: 'red',
        [ConnectionRequestStatus.COMPLETE]: 'blue'

    };
    return <>
        <Stack  gap={0} justify={'center'} p={{base:4,md:8}} bg={'white'} boxShadow="md"  w="full" minH={{base:'6vh', md:'80vh'}} borderRadius={'2xl'} >
            <Flex  justify={{base: 'start'}} align={'center'} w="full" bg={'white'} >


                <Heading color={'blue.800'} fontSize={{base:'md',md:'xl'}}>Connections List</Heading>
                <Spacer/>
                <Link to={'/self-service-portal/new-connection'}>
                    <Hide below={'md'}>
                        <Button  variant={'solid'} leftIcon={<FaPlus fontSize={'lg'} fontWeight={'bold'}/>}>New Application</Button>
                    </Hide>
                    <Show below={'md'}>
                        <IconButton   variant={'solid'} size={'sm'} icon={<FaPlus fontSize={'sm'} fontWeight={'bold'}/>}/>
                    </Show>
                </Link>
            </Flex>
            <TableContainer w="full" h={"full"} bg={'white'}  p={10}>
                <Table  colorScheme='gray'>
                    <Thead>
                        <Tr  >
                            <Th color={'blue.800'} textTransform={'capitalize'} fontSize={'sm'}>Name</Th>
                            <Th color={'blue.800'} textTransform={'capitalize'} fontSize={'sm'}>Connection Type</Th>
                            <Th color={'blue.800'} textTransform={'capitalize'} fontSize={'sm'}>Plot No</Th>
                            <Th color={'blue.800'} textTransform={'capitalize'} fontSize={'sm'}>Service Area/Ward</Th>
                            <Th color={'blue.800'} textTransform={'capitalize'} fontSize={'sm'}>Status</Th>
                            <Th color={'blue.800'} textTransform={'capitalize'} fontSize={'sm'}>Action</Th>
                        </Tr>
                    </Thead>

                    <Tbody>
                        {(applications?.docs?.length>0)&&
                            <>
                            {
                                applications.docs.map((application)=>(
                                    <Tr>

                                        <Td>{application.personalDetails.firstName+" "+application.personalDetails.lastName}</Td>
                                        <Td>{application.connectionType.join(', ')}</Td>

                                        <Td >{application.address.plotNo}</Td>
                                        <Td >{application.address.city}</Td>
                                        <Td >
                                            <Tag size='lg' colorScheme={statusColorScheme[application.status]} borderRadius='full'>
                                                <TagLabel>{application.status}</TagLabel>
                                            </Tag>
                                        </Td>
                                        <Td  >
                                            <Tooltip label={'Edit Application'}>
                                                <IconButton
                                                    isDisabled={application?.status!==enums.ConnectionRequestStatus.SUBMITTED}
                                                    onClick={()=>handleUpdate(application?._id)}
                                                    bg={'orange.500'}
                                                    _hover={{bg:'orange.600'}}
                                                    aria-label=''
                                                    icon={<MdEdit />}
                                                />

                                            </Tooltip>
                                            <Tooltip label={'View Application'}>
                                                <IconButton
                                                    onClick={()=>handleView(application)}
                                                    ml={2}
                                                    bg={'gray.500'}
                                                    _hover={{bg:'gray.600'}}
                                                    aria-label=''
                                                    icon={<MdFileOpen />}
                                                />
                                            </Tooltip>

                                        </Td>
                                    </Tr>
                                ))
                            }
                            </>
                        }
                    </Tbody>
                </Table>
            </TableContainer>
            <PdfModal PdfTemplate={  <ApplicationRequestPdf pdfData={pdfData}/>} isOpen={isOpen} onClose={onClose} />
            { (!applications||applications.docs?.length <=0) &&
                <Flex justifyContent="center" align="center" w="full" h="full" py={16}>
                    <Heading size="lg" color="gray.500">No Record Found</Heading>
                </Flex>
            }
            <Paginate
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                numberOfPages={applications?.totalPages||1}
            />
        </Stack>

    </>;
};

export default ConnectionTable;
