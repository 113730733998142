import {
  Box,
  Button,
  Text,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import {FaCircleCheck} from "react-icons/fa6";
import {Link} from "react-router-dom";


const AlertModal = ({ isOpen, isClosed,link,responseTime}) => {
  let nav=link?link:"dashboard"
  return (
    <>
      <Modal closeOnOverlayClick={false}  isOpen={isOpen} onClose={isClosed} isCentered={true} zIndex={10000}>
        <ModalOverlay />
        <ModalContent p={4} borderRadius={"xl"} zIndex={10000}>
          <ModalBody d={'flex'} justifyContent={'center'} align={'center'}>
            <Box w={'full'} h={'full'}>
              <Box >
                <Icon as={ FaCircleCheck} boxSize={{base:"16",md:"24"}} borderWidth={8} borderRadius={'full'} borderColor={'green.200'} color={"green.500"} />
              </Box>

              <Text my={2} fontSize={"20px"}>
                {" "}
                Record has been successfully saved.
              </Text>
              {responseTime?
                <Text>{responseTime}</Text>:
                  <Text>Please allow up to 24 working hours for us to process your application.</Text>
              }
            </Box>
          </ModalBody>

          <ModalFooter display={"flex"} justifyContent={"center"}>
            <Link to={`/self-service-portal/${nav}`}>
              <Button variant={'solid'} color={'white'} bg='green.500' _hover={{bg:'green.500',color:'white' }}>
                OK
              </Button>
            </Link>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AlertModal;
