import React, { useEffect, useState } from "react";
import {
    Flex, Stack, Heading, Table, TableContainer, Thead, Tr, Th, Tbody, Td, Tag, TagLabel, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, Text, useBreakpointValue
} from "@chakra-ui/react";
import moment from "moment";
import Paginate from "../../../components/Paginate/Paginate";
import utils from "../../../utils/commonFunctions";
import { useQuoteCart } from "../../../providers/QuoteCartProvider";
import { HashLoader } from "react-spinners";


const CheckBillTable = () => {
    const [checkedBills, setCheckedBills] = useState({ docs: [], totalPages: 1 });
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const { refreshFlag, setRefreshFlag } = useQuoteCart();

    const displayAsAccordion = useBreakpointValue({ base: true, md: false });

    const fetchData = async () => {
        const userId = utils.getUserId();
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
        threeMonthsAgo.setHours(0, 0, 0, 0);
        const query = {
            id: userId,
            result: null,
            createdDateGte: threeMonthsAgo,
            pageNumber: currentPage,
            pageSize: 6
        };

        setLoading(true);

        const fetchedBills = await utils.getMeterReadings(query);
        setCheckedBills(fetchedBills);
        setLoading(false);
        setRefreshFlag(false);
    };

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    useEffect(() => {
        if (refreshFlag) {
            fetchData();
        }
    }, [refreshFlag]);

    return (
        <Stack p={8} bg="white" w="full" h="full" borderRadius={'2xl'}>
            <Flex align="center" py={2.5} px={8} w="full">
                <Heading color="blue.800" fontSize="xl">Meter Readings</Heading>
            </Flex>

            {displayAsAccordion ? (
                <Accordion allowMultiple>
                    {!loading ? (
                        checkedBills?.docs?.length > 0 ? (
                            checkedBills.docs.map((bill, index) => (
                                <AccordionItem key={index}>
                                    <AccordionButton>
                                        <Box flex="1" textAlign="left">
                                            {bill.customerNumber}
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel pb={4}>
                                        <Text><strong>Contract Number:</strong> {bill.contractNumber}</Text>
                                        <Text><strong>Reading:</strong> {bill.meterReading}</Text>
                                        <Text><strong>Status:</strong> 
                                            <Tag size='lg' colorScheme={(bill.result === 'true') ? 'green' : 'red'} borderRadius='full'>
                                                <TagLabel>{(bill.result === 'true') ? 'Success' : 'Failed'}</TagLabel>
                                            </Tag>
                                        </Text>
                                        <Text><strong>Submitted Date:</strong> {moment(bill.dateCreated).format("DD-MM-YYYY")}</Text>
                                    </AccordionPanel>
                                </AccordionItem>
                            ))
                        ) : (
                            <Flex justifyContent="center" align="center" w="full" h="full" py={16}>
                                <Heading size="lg" color="gray.500">No Record Found</Heading>
                            </Flex>
                        )
                    ) : (
                        <Flex justifyContent="center" align="center" w="full" h="full">
                            <HashLoader color="#2BBCF2" />
                        </Flex>
                    )}
                </Accordion>
            ) : (
                <TableContainer p={8} w="full" h="full">
                    <Table colorScheme="gray">
                        <Thead>
                            <Tr>
                                <Th color="blue.800" fontSize={'sm'} textTransform="capitalize">Customer Number</Th>
                                <Th color="blue.800" fontSize={'sm'} textTransform="capitalize">Contract Number</Th>
                                <Th color="blue.800" fontSize={'sm'} textTransform="capitalize">Reading</Th>
                                <Th color="blue.800" fontSize={'sm'} textTransform="capitalize">Status</Th>
                                <Th color="blue.800" fontSize={'sm'} textTransform="capitalize">Submitted Date</Th>
                            </Tr>
                        </Thead>
                        {!loading ? (
                            checkedBills?.docs?.length > 0 && (
                                <Tbody>
                                    {checkedBills.docs.map((bill, index) => (
                                        <Tr key={index}>
                                            <Td>{bill.customerNumber}</Td>
                                            <Td>{bill.contractNumber}</Td>
                                            <Td>{bill?.meterReading}</Td>
                                            <Td>
                                                <Tag size='lg' colorScheme={(bill.result === 'true') ? 'green' : 'red'} borderRadius='full'>
                                                    <TagLabel>{(bill.result === 'true') ? 'Success' : 'Failed'}</TagLabel>
                                                </Tag>
                                            </Td>
                                            <Td>{moment(bill.dateCreated).format("DD-MM-YYYY")}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            )
                        ) : (
                            <Flex justifyContent="center" align="center" w="full" h="full">
                                <HashLoader color="#2BBCF2" />
                            </Flex>
                        )}
                    </Table>
                </TableContainer>
            )}

            <Paginate
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                numberOfPages={checkedBills?.totalPages || 1}
            />
        </Stack>
    );
};

export default CheckBillTable;
