import {
    Input,
    Stack,
    Button,
    FormLabel,
    FormControl,
    useToast,
    Select,
    HStack, Text, Switch,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";

import {PropagateLoader} from "react-spinners";
import {CustomGeneralToast} from "../../../utils/customChakraToasts";
import utils from "../../../utils/commonFunctions";
import {API} from "../../../utils/api";
import BillAlert from "../../../components/PortalContainer/BillAlert";
import {useUserProfile} from "../../../providers/UserProfileProvider";
import {useQuoteCart} from "../../../providers/QuoteCartProvider";
import {ContractNumberInput, CustomerNumberInput, SelectInput} from "../../../components/Applications/Forms/Inputs";

const SubmitMeterReadingForm = () => {
    const userId=utils.getUserId()
    const [reading, setReading] = useState("");
    const [number, setNumber] = useState("");
    const [utility, setUtility] = useState("");
    const [loading, setLoading] = useState(false);
    const [checkForOthers, setCheckForOthers] = useState(false);
    const {addGeneralToast} = CustomGeneralToast();
    const {refreshFlag,setRefreshFlag}=useQuoteCart()

    const toast = useToast();

    const submitMeterReading = async () => {
        if (number === "" || utility === ""||reading==="") {
            toast.closeAll();
            addGeneralToast({
                message: "All fields must be filled in",
                type: "warning",
            });
            setLoading(false);
            return;
        }
        setLoading(true);
        try {
            const response = await API.post(
                `/meter/reading`,
                {
                    customerNumber: number,
                    contractNumber: utility,
                    meterReading:reading,
                    createdBy:userId
                }
            );
            const data = await response.data;
            if (response.status === 200) {
                setLoading(false);
            } else {
                toast.closeAll();
                addGeneralToast({
                    message: data.message,
                    type: "error",
                });
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
            toast.closeAll();
            console.log(e)
            addGeneralToast({
                message: e?.message? e?.message : e.statusText,
                type: "error",
            });
        }finally {
             setRefreshFlag(true)
        }

    };

    const handleSetUtility = (e) => {
        const {value} = e.target;
        setUtility(value)
    };
    const handleSetNumber = (e) => {
        const {value,name} = e.target;
        setNumber(value)
    };

    return (
        <>
            <Stack spacing={1} w="full" h={'full'} px={4}>
                <BillAlert/>
                {/* Similar FormControl components for 'Customer Cell No' and 'Customer Number' */}
                {checkForOthers ? (
                    <FormControl isRequired id="customerNumber">
                        <FormLabel>Customer Number</FormLabel>
                        <Input
                            type="number"
                            placeholder={"Customer Number"}
                            onChange={(e) => setNumber(e.target.value)}
                        />
                    </FormControl>
                ) : (
                    <CustomerNumberInput
                        value={number}
                        callback={handleSetNumber}
                    />
                )}

                {checkForOthers ? (
                <FormControl id="contractNumber">
                    <FormLabel>Utility Number</FormLabel>
                    <>
                       
                            <Input
                                isRequired
                                type="number"
                                onChange={(e) => setReading(e.target.value)} />
                    </>
                    </FormControl>   
               
                        ) : (
                    <ContractNumberInput
                        value={ utility}
                        customerNumber={number}
                        callback={handleSetUtility}
                    />
                        )}
               
                <FormControl id="customerNumber">
                    <FormLabel color="blue.800">Meter Reading</FormLabel>
                        <Input
                            type="text"
                            onChange={(e) => setReading(e.target.value)}
                        />
                </FormControl>


                <Stack spacing={6} mt={2}>
                    {loading ? (
                        <Button variant="solid">
                            <PropagateLoader size={8} color="white"/>
                        </Button>
                    ) : (
                        <Button
                            type="submit"
                            variant="solid"
                            onClick={submitMeterReading}
                        >
                            Submit Meter Reading
                        </Button>
                    )}
                </Stack>
                {utils.isAuthenticated()&&
                    <HStack mt={2} justifyContent="center" alignItems="center">
                        <Text color={"blue.800"}>Utility Contract not listed?</Text>
                        <Switch id='bill-for-others' onChange={(e) => setCheckForOthers(e.target.checked)}/>
                    </HStack>
                }
            </Stack>
        </>
    );
};

export default SubmitMeterReadingForm;
