import React, {useEffect, useState} from "react";
import {
    Box,
    Flex, Heading, Text
} from "@chakra-ui/react";
import utils from "../../../utils/commonFunctions";
import {useLocation} from "react-router-dom";


const ContentPages = () => {
    const { state } = useLocation();
    const id = state ? state.id : null;
    console.log(state);
    const [page, setPage] = useState({});

    const handleViewPage = async () => {
        if (id) {
            try {
                const fetchedPage = await utils.fetchPageById(id);
                console.log(fetchedPage,'page')
                setPage(fetchedPage);
            } catch (error) {
                console.error('Failed to fetch page:', error);
                setPage({});
            }
        } else {
            setPage({});
        }
    };

    useEffect(() => {
        handleViewPage();
    }, [id]);
    return (<>
        <Flex
            direction={'column'}
            w={"100%"}
            justify={"center"}
            mt={4}
        >
            {page&&
                <>
                    <Heading size={{base: 'md', md: 'lg'}}
                             mb={6} color={'brand.700'}>{page.pageName}</Heading>
                    <Box 
                        sx={{
                            h2: {
                                padding: "16px 0", // Add padding to h2
                            },
                            p: {
                                margin: "12px 0", // Custom margin for paragraphs
                            },
                            ul: {
                                paddingLeft: "20px", // Add padding to indent bullets
                            },
                            li: {
                                marginBottom: "8px", // Space between list items
                            },
                            img: {
                                // Center images
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginBottom: "16px",
                            },
                          }}
                          lineHeight="1.6" // Global line height
                    >
                        {page.revisions &&
                            page.revisions.map((rev) => {
                                if (rev && rev.active) {
                                    return <div  key={rev._id} dangerouslySetInnerHTML={utils.sanitizeHtml(rev?.description)}/>
                                }
                                return null; // Return null if not active
                            })
                        }
                        {(page?.description)&&page.description}
                    </Box>
                </>

            }

        </Flex>

    </>);
};
export default ContentPages;
