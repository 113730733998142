import React from "react";
import PortalContainer from "../../components/PortalContainer/PortalContainer";
import ViewProfile from "./Components/ViewProfile";
import KycAlertModal from "../KnowYourCustomer/Components/Modals/KycAlertModal";

const UpdateProfile = () => {
  return <>
    <PortalContainer Title={"View Profile"} childComponent={<ViewProfile/>}/>
  </>;
};

export default UpdateProfile;
