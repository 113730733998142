import {
    Flex,
    Input,
    Image,
    Button,
    Box,
    Heading,
    FormLabel,
    FormControl,
    HStack,
    InputGroup,
    Card,
    CardBody,
    Tooltip,
    Spacer,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { PropagateLoader } from "react-spinners";
import { InfoIcon } from "@chakra-ui/icons";
import WUCLogo from "../../../assets/BlueLogoNoBG.png";
import { FormatMoney } from "format-money-js";
import {useQuoteCart} from "../../../providers/QuoteCartProvider";

const ViewBalanceModal = ({ isOpen, balance, onClose, onUpdatePaymentSummaryModal }) => {
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(true);
    const [inputValue, setInputValue] = useState("");
    const {billBalance,setBillBalance}=useQuoteCart()
    const fm = new FormatMoney({ decimals: 2 });

    useEffect(() => {
        setShow(false);
        setLoading(false);
        setBillBalance(balance)
    }, []);
    useEffect(() => {
        setBillBalance(inputValue)
    }, [inputValue]);
    const handlePayFullAmount = () => {
        setInputValue(balance);
    };

    const handleChange = (event) => {
        setInputValue(event.target.value);
    };

    const handlePayment = () => {
        onClose();
        onUpdatePaymentSummaryModal(true);
    };

    return (
        <Modal minW="lg" zIndex="10000" closeOnOverlayClick={false} isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody>
                    <Flex p={4} textAlign="center" color="blue.800" alignItems="center" justifyContent="center">
                        <Card p={8} w="full" variant="unstyled">
                            <HStack w="full" justifyContent="center">
                                <Image
                                    alt="ResetPassword Image"
                                    src={WUCLogo}
                                    objectFit="contain"
                                    h={120}
                                    w={120}
                                />
                            </HStack>
                            <Heading as="h2" size="lg" color="blue.800" fontWeight="semibold">
                                Your Bill Amount
                            </Heading>
                            <CardBody>
                                {!loading ? (
                                    <Heading as="h3" size="2xl" color="brand.500">
                                        {fm.from(Number(balance), { symbol: "P " })}
                                    </Heading>
                                ) : (
                                    <Box py={8}>
                                        <PropagateLoader size={8} color="#0093D0" />
                                    </Box>
                                )}
                                <Tooltip label="Bill amount due for payment" fontSize="sm">

                                    <InfoIcon mt={4} />
                                </Tooltip>
                            </CardBody>

                            {!loading && (
                                <>
                                    <FormControl id="amountPaid">
                                        <HStack>
                                            <FormLabel color="blue.800">Amount to pay</FormLabel>
                                            <Spacer />
                                            <Tooltip label="Pay full bill amount" placement="top" bg="gray.100" color="blue.800">
                                                <FormLabel
                                                    color="blue.300"
                                                    cursor="pointer"
                                                    _hover={{ textDecoration: "underline" }}
                                                    onClick={handlePayFullAmount}
                                                >
                                                    Pay full amount
                                                </FormLabel>
                                            </Tooltip>
                                        </HStack>
                                        <InputGroup>
                                            <Input
                                                type="number"
                                                value={inputValue}
                                                onChange={handleChange}
                                                isDisabled={!!loading}
                                            />
                                        </InputGroup>
                                    </FormControl>
                                </>
                            )}
                            <Button
                                type="submit"
                                variant="solid"
                                mt={4}
                                cursor={(inputValue===""||inputValue<1)? "not-allowed" : "pointer"}
                                isDisabled={inputValue===""||inputValue<1}
                                onClick={handlePayment}
                            >
                                Pay Bill
                            </Button>
                        </Card>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ViewBalanceModal;
