import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

const ImageCarousel = ({images}) => {
  // const images = [
  //   'https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80',
  //   'https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80',
  //   'https://images.unsplash.com/photo-1507477338202-487b170b7fa6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80',
  // ];

  const splideOptions = {
    type: 'loop',
    perPage: 1,
    // autoplay: true,
    pauseOnHover: true,
    pagination: false,
    arrows: true,
  };

  return (
    <Box
      flex="1"
      bg="gray.200"
      p={4}
      // m={2}
      // borderRadius="md"
      // boxShadow="md"
      width={{ base: '100%', md: '100%' }}
      height={{ base: '200px', md: '300px' }}
      overflow="hidden"
    >
      <Splide options={splideOptions}>
        {images.map((src, index) => (
          <SplideSlide key={index}>
            <Image
              objectFit="fit"
              src={src}
              alt={`Slide ${index}`}
              borderRadius="md"
              width="100%"
              height={{ base: '200px', md: '300px' }}
            />
          </SplideSlide>
        ))}
      </Splide>
    </Box>
  );
};

export default ImageCarousel;
