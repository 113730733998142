import {
    Input,
    Stack,
    Button,
    FormLabel,
    FormControl,
    useToast,
    Select,

} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { PropagateLoader } from "react-spinners";
import { CustomGeneralToast } from "../../../utils/customChakraToasts";
import utils from "../../../utils/commonFunctions";
import {API} from "../../../utils/api";
import ViewBalanceModal from "./ViewBalanceModal";
import BillAlert from "../../../components/PortalContainer/BillAlert";

const BillCalculator = () => {


    const [requestData,setRequestData]=useState({
        type:"",
        firstReading:"",
        secondReading:""
    })
    const [loading, setLoading] = useState(false);
    const [isViewBalanceModalOpen, setIsViewBalanceModalOpen] = useState(false);
    const [balance, setBalance] = useState(0);
    const { addGeneralToast } = CustomGeneralToast();
    const toast = useToast();

    const customerMap={
        type:"Service type",
        firstReading: "Previous reading",
        secondReading:"Current Reading"
    }
    function checkCustomerIsValid(customer) {
        for (let key in customer) {
            if (customer[key] === null || customer[key] === undefined || customer[key] === "") {
                toast.closeAll();
                addGeneralToast({
                    message: `${customerMap[key]} is a required field`,
                    type: "warning",
                });
                setLoading(false);
                return false;
            }
        }
        return true
    }

    const getBillAmount = async () => {

        if(! checkCustomerIsValid(requestData)){
            return
        }
        setLoading(true);
        try {
            console.log("Data ",requestData)
            const response = await API.post(`/bill-calculator/`,requestData);

            const data = response.data;
            if (response.status === 200) {
                setBalance(data);
                setLoading(false);
                setIsViewBalanceModalOpen(true);

            } else {
                handleErrorResponse(data.message);
            }
        } catch (e) {
            console.log(e)
            await utils.sendMessageToBugNinja('ERROR FETCHING ACCOUNT BALANCE', e.message, e);
            handleErrorResponse(e.response ? e.response.data.message : "Could not fetch customer account balance.Please contact administrator");
        }
    };

    const handleErrorResponse = (message) => {
        setLoading(false);
        setBalance(0);
        toast.closeAll();
        addGeneralToast({
            message,
            type: "error",
        });
    };

    const handleChange = (event) => {
        const { value, name } = event.target;
        setRequestData((prev) => ({
            ...prev,
            [name]: value
        }));
    }
    const closeViewBalanceModal = () => {
        setRequestData({
            type:"",
            firstReading:"",
            secondReading:""
        })
        setIsViewBalanceModalOpen(false);
    };
    return (
        <>
            <Stack spacing={1} justify="center" px={4} w="full" h="full">
                <BillAlert message={'For a more accurate estimate, provide readings not more than one month apart.'} />
                <FormControl isRequired >
                    <FormLabel>Service Type</FormLabel>
                    <Select
                        isRequired
                        placeholder="Select Type"
                        name={'type'}
                        value={requestData.type}
                        onChange={(e) => handleChange(e)}
                    >
                        <option value={'domestic'}>Domestic</option>
                        <option value={'domesticNoWasteWater'}>Domestic (no waste water)</option>
                        <option value={'government'}>Government</option>
                        <option value={'businessCommercialIndustrial'}>Business/Commercial/Industrial</option>
                    </Select>

                </FormControl>
                <FormControl isRequired id="customerNumber">
                    <FormLabel>Previous Reading</FormLabel>
                    <Input
                        isRequired
                        type="number"
                        name={'firstReading'}
                        value={requestData.firstReading}
                        onChange={(e) => handleChange(e)}
                    />
                </FormControl>
                <FormControl isRequired id="customerNumber">
                    <FormLabel>Current Reading</FormLabel>
                    <Input
                        isRequired
                        type="number"
                        name={'secondReading'}
                        value={requestData.secondReading}
                        onChange={(e) => handleChange(e)}
                    />
                </FormControl>


                <Stack spacing={6} mt={2}>
                {loading ? (
                        <Button variant="solid">
                            <PropagateLoader size={8} color="white" />
                        </Button>
                    ) : (
                        <Button
                            type="submit"
                            variant="solid"
                            onClick={getBillAmount}
                        >
                            Get Bill Estimate
                        </Button>
                    )}
                </Stack>


            </Stack>

            {isViewBalanceModalOpen && (
                <ViewBalanceModal
                    isOpen={isViewBalanceModalOpen}
                    onClose={closeViewBalanceModal}
                    vat={balance?.excludeVat||0}
                    noVat={balance?.includingVat||0}
                    //onUpdatePaymentSummaryModal={updatePaymentSummaryModal}
                />
            )}




        </>
    );
};

export default BillCalculator;
