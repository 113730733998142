import React from "react";
import {Box, Flex, Heading, Grid, GridItem,SimpleGrid} from "@chakra-ui/react";
import InstructionsModal from "../../../../components/CustomModals/InstructionsModal";
import {Attachments, ConnectionDetails, ContactDetails, NextOfKin, Owner, PersonalDetails} from "./Details";

const Category = ({title, details}) => {
    return (
        <>
            <Box borderWidth={'1px'}   borderColor={'gray.50'} borderRadius="2xl">
                <Flex alignItems={'center'} bg={'gray.50'} p={4} borderTopRadius={'2xl'} mb={2}>
                    <Heading
                        color="brand.500"
                        as="h6"
                        fontWeight={"bold"}
                        size="sm"
                    >
                        {title}
                    </Heading>

                </Flex>


                <Grid
                    bg={"white"}
                    p={"6"}
                    borderBottomRadius="2xl"
                    templateColumns="repeat(12, 1fr)"
                    gap={6}
                    w={"100%"}

                >
                    <GridItem colSpan={{base: 12}}>
                        <SimpleGrid  gap={2} color={'gray.400'} columns={{base:1,md:2}}>
                            {details}
                        </SimpleGrid>
                    </GridItem>

                </Grid>
            </Box>
        </>
    );
};

const Preview = () => {
    return (
        <>
            <Grid
                bg={"white"}
                borderBottomRadius="2xl"
                templateColumns="repeat(12, 1fr)"
                gap={2}
                w={"100%"}
            >
                <GridItem colSpan={{base: 12}}>
                    <Category title={'Service Connection Details'} details={<ConnectionDetails/>}/>
                </GridItem> <GridItem colSpan={{base: 12}}>
                <Category title={'Personal Details'} details={<PersonalDetails/>}/>
            </GridItem>
                <GridItem colSpan={{base: 12}}>
                    <Category title={'Contact Details'} details={<ContactDetails/>}/>
                </GridItem>
                <GridItem colSpan={{base: 12}}>
                    <Category title={'Next Of Kin Details'} details={<NextOfKin/>}/>
                </GridItem>
                <GridItem colSpan={{base: 12}}>
                    <Category title={'Owner or Landlord Details'} details={<Owner/>}/>
                </GridItem>
                <GridItem colSpan={{base: 12}}>
                    <Category title={'Attachments'} details={<Attachments/>}/>
                </GridItem>

            </Grid>

        </>
    );
};

const PreviewModal = ({callback,isOpen,onClose}) => {
    return (
        <InstructionsModal title={'Connection Request Preview'} Instructions={<Preview/>} onClose={onClose} isOpen={isOpen} callback={callback}  />
    );
};

export default PreviewModal;
