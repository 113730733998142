import React from "react";
import LoginComponent from "./components/Login";
import AuthenticationContainer from "../../components/Authentication/AuthenticationContainer";

const Login = () => {
  return (
    <>
      <AuthenticationContainer Children={<LoginComponent />} />
    </>
  );
};

export default Login;
