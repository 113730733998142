import React from 'react';
import {
    Flex,
    Button,
    Heading, Modal, ModalOverlay, ModalContent, ModalBody, ButtonGroup, useColorModeValue,
} from '@chakra-ui/react';

import {ArrowForwardIcon} from "@chakra-ui/icons";
import {MdCancel} from "react-icons/md";
import { useNavigate} from "react-router-dom";

const InstructionsModal = ({onClose,isOpen, Instructions, title, callback, link}) => {
    const navigate = useNavigate()
    const trackColor = useColorModeValue("gray.200", "gray.700");

    const handleSubmit = () => {
       onClose()
        if (callback) {
            callback()
        }
    }
    const handleCancel = () => {
        onClose()
        if(link){
            navigate(`/self-service-portal/${link}`)
        }

    }
    //const toggl
    return (
        <Modal closeOnOverlayClick={false} isCentered isOpen={isOpen} onClose={onClose}
               size={{base: "full", md: '2xl'}} >
            <ModalOverlay/>

            <ModalContent p={4} maxH={'98vh'}>
                <Heading textTransform={'capitalize'} color={'brand.500'} px={5} size={'lg'}>{title}</Heading>
                <ModalBody overflowY={'auto'} sx={{
                    "&::-webkit-scrollbar": {
                        width: "4px", // Thinner scrollbar
                    },
                    "&::-webkit-scrollbar-track": {
                        background: trackColor,
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "brand.500", // Using brand color
                        borderRadius: "8px",
                        border: `2px solid ${trackColor}`,
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        background: "brand.600", // Slightly darker brand color on hover
                    },
                }}>
                    {Instructions}
                </ModalBody>

                <Flex justify={'end'} w={'full'} py={2}>
                    <ButtonGroup gap={2}>
                        <Button rightIcon={<MdCancel/>} onClick={handleCancel} bg={'red.500'} _hover={{bg: 'red.600'}}>
                            Cancel
                        </Button>
                        <Button rightIcon={<ArrowForwardIcon/>} onClick={handleSubmit}>
                            Proceed
                        </Button>
                    </ButtonGroup>
                </Flex>
            </ModalContent>
        </Modal>
    );
};


export default InstructionsModal;
