import React, { useEffect, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
//import navigator to route to the project page
import { useNavigate } from 'react-router-dom';
import { Image } from '@chakra-ui/react';
import {API} from "../../utils/api";
import image1 from './../../assets/Gaborone Dam.jpg';
import image2 from './../../assets/Kazungula Bridge.jpg';
import image3 from './../../assets/truckAndPipes.jpg';

const ProjectsCarousel = () => {
  const slides = [image1, image2, image3, image2, image1];
  const navigator = useNavigate();
  const [slidesCount, setSlidesCount] = useState(4);

  const [projectsArr, setProjectsArr] = useState([]);

  const fetchProjects = async () => {
    try {
      const response = await API.get(`/projects`);
      console.log("projects fetched: ", response.data);
      setProjectsArr(response.data);

      if(projectsArr.length < 4 && projectsArr.length > 0){
        setSlidesCount(projectsArr.length);
      }

    } catch (error) {
      console.error("Error fetching projects: ", error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  console.log(projectsArr);

  const handleClick = (projectId) => {
    if (projectId)
      navigator('/projects/view', { state: { projectId: projectId } });
  }

  const truncateText = (text, length) => {
    return text.length > length ? text.substring(0, length) + '...' : text;
  }

  return (
    <Splide
      options={{
        type: 'loop',
        gap: '1rem',
        autoplay: true,
        pauseOnHover: false,
        resetProgress: false,
        perPage: slidesCount,
        breakpoints: {
          768: {
            perPage: 1,
          },
          1024: {
            perPage: 2,
          },
        },
      }}
    >
      {projectsArr.map((project, index) => (
        <SplideSlide key={`slide-${index}`}>
          <div className="slide-container" onClick={() => handleClick(project._id)}>
            <Image
              src={project.imagesDocumentsKeys[0].key}
              my={'auto'}
              alt={`Project ${index}`}
              className="image"
              fallbackSrc='https://via.placeholder.com/1200'
            />
            <div className="overlay">
              <div className="overlay-title">{truncateText(project.title, 50)}</div>
              <button className="overlay-button">Read More</button>
            </div>
          </div>
        </SplideSlide>
      ))}
    </Splide>
  );
};

export default ProjectsCarousel;
