import React, { useEffect, useState } from "react";
import {
    Box,
    Heading,
    Flex,
    GridItem,
    Grid,
} from "@chakra-ui/react";

import EventViewer from "./EventViewer";
import { usePhoto } from "../../../../providers/PhotosProvider";
import Event from "./Event";
import utils from "../../../../utils/commonFunctions";

const Events = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [event, setEvent] = useState(null);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const fetchEventsData = async () => {
            const data = await utils.fetchEvents();
            if (data) {
                console.log(data);
                setEvents(data);
            }
        };
        fetchEventsData();
    }, []);

    const handleView = (event) => {
        setEvent(event);
        openModal();
    };

    return (
        <>
            <Flex justify={'center'} align={'center'} bg={'white'} px={{ base: 4, md: 8 }} py={4} w={'full'}>
                <Box p={4}>
                    <Heading size="xs" mb={2} textAlign={'center'} color={'blue.800'}>Media</Heading>
                    <Heading size="lg" mb={4} textAlign={'center'} color={'blue.800'}>Events</Heading>
                    <Grid templateColumns='repeat(12, 1fr)' gap={4} p={6}>
                        {events.map((event, index) => (
                            <GridItem
                                key={index}
                                colSpan={{ base: 12, md: 6, lg: 4 }}
                                onClick={() => handleView(event)}  // Pass a function reference
                            >
                                <Event
                                    title={event.title}
                                    image={event.imagesDocumentsKeys[0]}
                                    startDate={event.startDate}
                                    description={event.description}
                                />
                            </GridItem>
                        ))}
                    </Grid>
                </Box>
            </Flex>
            <EventViewer event={event} isModalOpen={isModalOpen} onClose={closeModal} />
        </>
    );
};

export default Events;
