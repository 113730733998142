import {
    Modal, ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
} from "@chakra-ui/react";


import React, {useEffect, useState} from "react";
import {
    Box, Heading, Flex, Image, GridItem, Grid, useColorModeValue
} from "@chakra-ui/react";
import Picture from "./Event";
import {usePhoto} from "../../../../providers/PhotosProvider";
import EventView from "./EventView";

const PicturesGrid = ({Pictures}) => {
    const {setPhoto}=usePhoto()
    const trackPhotoId = (id) => {
       setPhoto(id)
    }
    return (
        <>
            <Flex justify={'center'} align={'center'} bg={'white'} w={'full'}>
                <Grid templateColumns='repeat(12, 1fr)' gap={4}>
                    {Pictures.map((picture, index) => (
                        <GridItem key={index} colSpan={{base: 12, md: 3}} onClick={() => trackPhotoId(index)}>
                            <Picture name={picture.title} image={picture.url}/>
                        </GridItem>
                    ))}
                </Grid>
            </Flex>
        </>
    )
};

const PictureList = ({PicturesArr}) => {
    const trackColor = useColorModeValue("gray.200", "gray.700");
    const {photo}= usePhoto();
    console.log(PicturesArr)
    const [picture, setPicture] = useState(PicturesArr[photo].url);

    useEffect(() => {
        console.log(PicturesArr[photo],photo)
        setPicture(PicturesArr[photo].url)
    }, [photo]);

    return (
        <>
            <Flex justify={'center'} align={'center'} bg={'white'} p={8} w={'full'}>
                <Box w={'full'}>
                    <Grid templateColumns='repeat(12, 1fr)' gap={4} w={'full'}>
                        <GridItem bg={'gray.50'} colSpan={{base: 12}} justify={'center'} align={'center'}
                                  boxShadow={'md'} borderRadius={'2xl'}>
                            <Image borderRadius={'2xl'} w={'full'} h='450px' objectFit='cover' src={picture}/>
                        </GridItem>
                        <GridItem colSpan={{base: 12}}>
                            <Grid templateColumns='repeat(12, 1fr)' gap={4} h={{base: '165px'}} overflowY={'auto'}
                                  overflowX={'hidden'} sx={{
                                "&::-webkit-scrollbar": {
                                    width: "4px", // Thinner scrollbar
                                },
                                "&::-webkit-scrollbar-track": {
                                    background: trackColor,
                                },
                                "&::-webkit-scrollbar-thumb": {
                                    backgroundColor: "brand.500", // Using brand color
                                    borderRadius: "8px",
                                    border: `2px solid ${trackColor}`,
                                },
                                "&::-webkit-scrollbar-thumb:hover": {
                                    background: "brand.600", // Slightly darker brand color on hover
                                },
                            }}>
                                <GridItem colSpan={{base: 12}}>
                                    <PicturesGrid Pictures={PicturesArr}/>
                                </GridItem>
                            </Grid>
                        </GridItem>
                    </Grid>
                </Box>
            </Flex>
        </>
    )
};


function EventViewer({event,isModalOpen,onClose}) {
    return (
        <>
            <Modal closeOnOverlayClick={false} size={'6xl'} my={4} isCentered isOpen={isModalOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalCloseButton onClick={onClose} />
                    <ModalBody pb={6}>
                       <EventView  event={event}/>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default EventViewer