import {
    Flex,
    Image,
    Button,
    Heading,
    HStack,
    Card,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody, Text, Stack, useDisclosure, Box,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import WUCLogo from "../../../assets/BlueLogoNoBG.png";
import {FormatMoney} from "format-money-js";
import Info from "./Info";
import utils from "../../../utils/commonFunctions";
import moment from "moment";
import PrepaidVocherPdf from "../../../components/PdfViewer/PrepaidVoucherPdf";
import {PropagateLoader} from "react-spinners";

const ViewBalanceModal = ({statusCode, reference}) => {
    const [loading, setLoading] = useState(true);
    const {isOpen, onOpen, onClose} = useDisclosure();
    const fm = new FormatMoney({decimals: 2});
    const [prepaid, setPrepaid] = useState(null);


    async function getPrepaid(id) {
        const fetchedData = await utils.getPrepaidByPaymentId(id)
        if (fetchedData) {
            setPrepaid(fetchedData)
        }
    }

    useEffect(() => {
        if (statusCode && reference) {
            if (statusCode === "200") {
                onOpen()
                if (reference && reference.startsWith('"') && reference.endsWith('"')) {
                    reference = reference.slice(1, -1); // Remove first and last character (quotes)
                }
                getPrepaid(reference)
            }
            //clear query params
            window.history.pushState({}, document.title, window.location.pathname);
        }
    }, [statusCode, reference, onOpen]);


    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 40000); // 40000 milliseconds = 40 seconds

        return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
    }, []);

    const handleClose = () => {
        onClose();
    }
    const mergeName = (firstName, lastName) => {
        return [firstName, lastName].join(" ")
    }
    return (
        <Modal minW="lg" zIndex="10000" closeOnOverlayClick={false} isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent>
                <ModalBody>
                    <Flex p={4} textAlign="center" color="blue.800" alignItems="center" justifyContent="center">
                        <Card w="full" variant="unstyled">
                            <HStack w="full" justifyContent="center">
                                <Image
                                    alt="ResetPassword Image"
                                    src={WUCLogo}
                                    objectFit="contain"
                                    h={120}
                                    w={120}
                                />
                            </HStack>
                            {loading && (
                                <>
                                    <Flex mb={4} direction={'column'} align={'center'} justifyContent={'center'}
                                          bg={'blue.50'} borderRadius={'2xl'} p={4} mt={4}>
                                            <PropagateLoader mt={4} color={'#2BBCF2'}/>
                                        <Box mt={8}>
                                            <Info text={'Please wait patiently while we process your prepaid recharge request.'}/>
                                        </Box>
                                    </Flex>
                                </>
                            )}
                            {!loading && (
                                <>
                                    <Flex mb={4} direction={'column'} align={'start'} justifyContent={'start'}
                                          bg={prepaid?.token?'blue.50':'red.100'} borderRadius={'2xl'} p={4} mt={4}>
                                        {prepaid && prepaid?.token && (
                                            <>
                                                <Flex width={'full'} justify={'space-between'} fontSize={'sm'}
                                                      color={'gray.700'} textTransform={"capitalize"}>
                                                    <Text fontWeight={'medium'}>Customer Name:</Text>
                                                    <Text
                                                        fontWeight={'semibold'}>{mergeName(prepaid?.firstName, prepaid?.lastName)}</Text>
                                                </Flex>
                                                <Flex width={'full'} justify={'space-between'} fontSize={'sm'}
                                                      color={'gray.700'} textTransform={"capitalize"}>
                                                    <Text fontWeight={'medium'}>Meter No:</Text>
                                                    <Text> {prepaid?.meterNo}</Text>
                                                </Flex>
                                                <Flex width={'full'} justify={'space-between'} fontSize={'sm'}
                                                      color={'gray.700'} textTransform={"capitalize"}>
                                                    <Text fontWeight={'medium'}>Cost (BWP):</Text>
                                                    <Text> {fm.from(Number(prepaid?.amount))}</Text>
                                                </Flex>
                                                <Flex width={'full'} justify={'space-between'} fontSize={'sm'}
                                                      color={'gray.700'} textTransform={"capitalize"}>
                                                    <Text fontWeight={'medium'}>Units (kl):</Text>
                                                    <Text> {prepaid?.units}</Text>
                                                </Flex>
                                                <Flex width={'full'} justify={'space-between'} fontSize={'sm'}
                                                      color={'gray.700'} textTransform={"capitalize"}>
                                                    <Text fontWeight={'medium'}>Reference:</Text>
                                                    <Text> {prepaid?.reference}</Text>
                                                </Flex>
                                                <Flex width={'full'} justify={'space-between'} fontSize={'sm'}
                                                      color={'gray.700'} textTransform={"capitalize"}>
                                                    <Text fontWeight={'medium'}>Date & Time:</Text>
                                                    <Text> {moment(prepaid?.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</Text>
                                                </Flex>
                                                <Stack width={'full'} mt={8} justify={'space-between'} fontSize={'sm'}
                                                       color={'gray.700'} textTransform={"capitalize"}>
                                                    <Text fontSize={'xl'}
                                                          fontWeight={'semibold'}>{prepaid?.token}</Text>
                                                </Stack>
                                                <Flex mt={2} justify={'center'} w={'full'}>
                                                    <Info
                                                        text={'Please enter voucher token in your meter to receive the energy units.'}/>
                                                </Flex>
                                            </>
                                        )}
                                        {!prepaid?.token && (
                                            <>
                                                <Stack width={'full'} mt={8} justify={'space-between'} fontSize={'sm'}
                                                       color={'red.500'} >
                                                    <Text fontSize={'xl'} fontWeight={'normal'}>
                                                        Dear valued customer your request to purchase
                                                        smart meter token was not successful.
                                                        Please try again or contact system
                                                        administrator for further assistance
                                                    </Text>
                                                </Stack>
                                            </>
                                        )}
                                    </Flex>
                                    {prepaid?.token &&
                                        <PrepaidVocherPdf pdfData={{fullName: mergeName(prepaid?.firstName, prepaid?.lastName), ...prepaid}}/>
                                    }
                                </>
                            )}
                            <Button
                                type="submit"
                                variant="ghost"
                                mt={4}
                                cursor={!loading ? "pointer" : "not-allowed"}
                                isDisabled={loading}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Card>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ViewBalanceModal;
