import { Button, Flex, IconButton } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

// Button for individual page numbers
const PageButton = ({ pageNumber, currentPage, setCurrentPage, color }) => {
    const isActive = pageNumber === currentPage;

    return (
        <Button
            onClick={() => setCurrentPage(pageNumber)}
            p={{ base: 1, md: 2 }}
            fontSize={{ base: 'sm', md: 'md' }}
            bg={isActive ? 'brand.500' : "transparent"}
            color={isActive ? "white" : "gray.400"}
            borderRadius="2xl"
            _hover={{ bg: 'brand.500', color: 'white' }}
        >
            {pageNumber}
        </Button>
    );
};

// Main pagination component
const Pagination = ({ currentPage, numberOfPages, setCurrentPage, color }) => {
    const handlePrev = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < numberOfPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <Flex 
            bg="white" 
            justify="center" 
            p={2} 
            gap={{ base: 1, md: 2 }} 
            wrap="wrap"
        >
            <IconButton
                icon={<ChevronLeftIcon />}
                onClick={handlePrev}
                isDisabled={currentPage <= 1}
                p={{ base: 1, md: 2 }}
                fontSize={{ base: 'sm', md: 'md' }}
                bg="transparent"
                color="gray.400"
                _hover={{ bg: 'brand.500', color: 'white' }}
            />
            {[...Array(numberOfPages).keys()].map(page => (
                <PageButton
                    key={page + 1}
                    pageNumber={page + 1}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    color={'brand.500'}
                />
            ))}
            <IconButton
                icon={<ChevronRightIcon />}
                onClick={handleNext}
                isDisabled={currentPage >= numberOfPages}
                p={{ base: 1, md: 2 }}
                fontSize={{ base: 'sm', md: 'md' }}
                bg="transparent"
                color="gray.400"
                _hover={{ bg: 'brand.500', color: 'white' }}
            />
        </Flex>
    );
};

export default Pagination;
