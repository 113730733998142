import React, { useEffect, useState } from "react";
import {Box, Checkbox, Flex, Text} from "@chakra-ui/react";
import PdfViewer from "../../../components/PdfViewer/PdfViewer";

const TermsAndConditions = () => {
    const form = JSON.parse(localStorage.getItem('consent'));
    const [consent, setConsent] = useState(form || false);
    const [checkboxOne, setCheckboxOne] = useState(form || false);
    const [checkboxTwo, setCheckboxTwo] = useState(form || false);
    const pdfUrl = "https://wucrprod.blob.core.windows.net/wucfiles/terms/terms-and-conditions.pdf";
    useEffect(() => {
        const newConsent = checkboxOne && checkboxTwo;
        setConsent(newConsent);
    }, [checkboxOne, checkboxTwo]);

    useEffect(() => {
        localStorage.setItem('consent', JSON.stringify(consent));
        localStorage.setItem('isValidForm', consent);
    }, [consent]);

    return (
        <>
            <Box w={'100%'} h={'100vh'} mb={8}>
                <PdfViewer PdfData={pdfUrl} />
            </Box>
            <Checkbox
                colorScheme='brand'
                isChecked={checkboxOne}
                onChange={e => setCheckboxOne(e.target.checked)}
            >
                I declare that to the best of my knowledge and belief the information in this application and the
                accompanying documents is true and correct and have read and understood the implications of the
                Waterworks Act and all the relevant Legislation and Regulations
            </Checkbox>
            <Checkbox
                colorScheme='brand'
                isChecked={checkboxTwo}
                onChange={e => setCheckboxTwo(e.target.checked)}
            >
                <Text fontWeight={'bold'}>
                    I warrant and agree to be bound by these Terms & Conditions.
                </Text>
            </Checkbox>
        </>
    );
};

export default TermsAndConditions;
