import React from 'react';
import PortalContainer from "../../components/PortalContainer/PortalContainer";
import SelfServiceContainer from "../../components/PortalContainer/SelfServiceContainer";
import WaterShortageTable from "./Components/WaterShortageTable";

const ReportShortage = () => {
  return (
  <PortalContainer childComponent={<SelfServiceContainer width={'full'} childComponent={<WaterShortageTable />}/>}  Title={'Report Water Leak'}/>

);
};

export default ReportShortage;
