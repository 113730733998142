import enums from "wuc-library/enums";
import DOMPurify from "dompurify";
import {API} from "./api";
import axios from "axios";
import contact from "../components/PortalContainer/Contact";

const CryptoJS = require('crypto-js');
const decodeJWT = (authToken) => {

    if (authToken) {
        // Decode the JWT token
        const tokenParts = authToken.split(".");
        const encodedPayload = tokenParts[1];
        const decodedPayload = JSON.parse(atob(encodedPayload));
        return decodedPayload;
    }
    return null;
};

const utils = {

    decodeToken: (authToken) => {

        if (authToken) {
            // Decode the JWT token
            const tokenParts = authToken.split(".");
            const encodedPayload = tokenParts[1];
            const decodedPayload = JSON.parse(atob(encodedPayload));
            return decodedPayload;
        }
        return null;
    },
    isAuthenticated: () => {


        const jwt = decodeJWT(localStorage.getItem("authToken"));
        if (jwt) {
            // Check if the token has expired
            const tokenExpiration = jwt.exp * 1000; // Convert expiration time to milliseconds
            const currentTimestamp = Date.now();
            if (currentTimestamp < tokenExpiration) {
                // Token valid
                return true;
            }
        }
        return false;
    },
    getIsVerified: (authToken) => {
        if (authToken) {
            // Decode the JWT token
            const tokenParts = authToken.split(".");
            const encodedPayload = tokenParts[1];
            const jwt = JSON.parse(atob(encodedPayload));

            // Check if the token has expired
            if (jwt) {
                return jwt.isVerified;
            }
        }
        return false;
    },
    getIsFirstTimeLogin: (authToken) => {
        if (authToken) {
            // Decode the JWT token
            const tokenParts = authToken.split(".");
            const encodedPayload = tokenParts[1];
            const jwt = JSON.parse(atob(encodedPayload));

            // Check if the token has expired
            if (jwt) {
                return jwt.isFirstTimeLogin;
            }
        }
        return false;
    },
    getRequirePasswordChange: (authToken) => {
        if (authToken) {
            // Decode the JWT token
            const tokenParts = authToken.split(".");
            const encodedPayload = tokenParts[1];
            const jwt = JSON.parse(atob(encodedPayload));

            // Check if the token has expired
            if (jwt) {
                return jwt.requirePasswordChange;
            }
        }
        return false;
    },
    setToken: (name, token) => {
        localStorage.setItem(name, token);
    },
    getUserEmail: (token) => {
        let jwt = decodeJWT(token)
        if (jwt) {
            return jwt.email
        }
        return null;
    },
    getUserRole: (token) => {
        let jwt = decodeJWT(token)
        if (jwt) {
            return jwt.role
        }
        return null;
    },
    getUserId: () => {
        let jwt = decodeJWT(localStorage.getItem("authToken"))
        if (jwt) {
            return jwt.id
        }
        return null;
    },
    getUser: () => {
        let jwt = decodeJWT(localStorage.getItem("authToken"))
        if (jwt) {
            return {name: jwt.name, email: jwt.email, phone: jwt.phone}
        }
        return null;
    },
    fetchUserProfile: async () => {
        try {

            if (utils.isAuthenticated()) {
                let userId = await utils.getUserId()
                const response = await API.get(`/kyc/get`, {params: {userId: userId}})
                if (response.status === 200) {
                    return response.data.data.docs[0];
                } else {
                    return null
                }
            }
        } catch (error) {
            console.error('Error fetching user profile', error);
            utils.sendMessageToBugNinja('ERROR FETCHING USER PROFILE', error);
        }
    },
    fetchUser: async () => {
        try {

            if (utils.isAuthenticated()) {
                const data = {
                    filterOptions: {
                        id: await utils.getUserId()
                    },
                    pageNumber: 1,
                    pageSize: 100
                };
                const response = await API.post(`/auth/users`, data)
                if (response.status === 200) {
                    return await response.data.data.docs[0];
                } else {
                    return null
                }
            }
        } catch (error) {
            console.error('Error fetching user profile', error);
            utils.sendMessageToBugNinja('ERROR FETCHING USER PROFILE', error);
        }
    },
    login: async (data) => {
        try {
            return await API.post(
                `/auth/signin`,
                data, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
        } catch (error) {
            console.error('Error signing in', error);
            utils.sendMessageToBugNinja('ERROR SIGNING IN', error);
            throw error;
        }
    },
    signup: async (data) => {
        try {
            return await API.post(
                `/auth/signup`,
                data, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
        } catch (error) {
            console.error('Error signing up', error);
            utils.sendMessageToBugNinja('ERROR SIGNING UP', error);
            throw error;
        }
    },
    setNewPassword: async (resetToken, newPassword) => {
        try {
            const data = {
                filterOptions: {
                    resetPasswordToken: resetToken
                },
                pageNumber: 1,
                pageSize: 100
            };

            const response = await API.post(`/auth/users`, data);
            if (response.data.status === 'Success') {

                const res = await API.put(`/auth/password/set`,
                    {userId: response.data.data?.docs[0]?._id, newPassword})

                return res
            } else {
                return response
            }
        } catch (error) {
            console.error('Error setting new password', error);
            utils.sendMessageToBugNinja('ERROR SETTING NEW PASSWORD', error);
            throw error
        }
    },
    forgotPassword: async (email) => {
        try {
            const response = await API.put(
                `/auth/password/reset`,
                {userRole: enums.UserRole.USER, email}
            );
            return response
        } catch (error) {
            console.error('Error resetting  password', error);
            utils.sendMessageToBugNinja('ERROR SETTING NEW PASSWORD', error);
            throw error
        }
    },

    requestNewOTP: async (email, usage = enums.OTPUsage.REGISTRATION) => {
        try {
            return await API.post(`/auth/otp/change`, {email, usage});
        } catch (error) {
            console.error('Error requesting new CreatePassword:', error.response ? error.response.data : error);
            utils.sendMessageToBugNinja('ERROR REQUESTING NEW OTP', error.response.data, error);
            throw error;
        }
    },
    logout: (navigate) => {
        localStorage.clear();
        sessionStorage.clear();
        //window.location.href = '/login'
        //navigate('/login',{replace:true})
    },
    validatePassword: (password) => {
        const result = {msg: "", digit: true, uppercase: true, lowercase: true, isLength: true, isValid: true};
        if (password.length < 8) {
            result.isLength = false;
            result.isValid = false;
            result.msg = "Password does not meet minimum requirements"
        }

        if (!/[A-Z]/.test(password)) {
            result.uppercase = false;
            result.isValid = false;
            result.msg = "Password does not meet minimum requirements"
        }

        if (!/[a-z]/.test(password)) {
            result.lowercase = false;
            result.isValid = false;
            result.msg = "Password does not meet minimum requirements"
        }
        if (!/\d/.test(password)) {
            result.digit = false;
            result.isValid = false;
            result.msg = "Password does not meet minimum requirements"
        }

        return result;
    },

    sanitizeHtml: (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        };
    },

    handleFileUpload: async (file, folderPath) => {
        let formData = new FormData()
        formData.append('folderPath', folderPath)
        formData.append('file', file)
        try {
            const response = await API.post(`/common/file/upload`, formData);
            return response.data;
        } catch (e) {
            console.log(e)
            utils.sendMessageToBugNinja('ERROR UPLOADING FILE', e.message, e);
        }
    },
    handleFileRetrival: async (key) => {
        try {
            const response = await API.get(
                `/common/file/get`, {params: {key: key}});
            return response.data;
        } catch (e) {
            console.error(e);
            utils.sendMessageToBugNinja('ERROR RETRIEVING FILE', e.message, e);
            throw e;
        }
    },
    getOriginalFileName: (path) => {
        try {
            const parts = path.split('/');
            const fileNameWithParams = parts[parts.length - 1];
            return fileNameWithParams.split('?')[0];
        } catch (e) {
            console.log(e);
            utils.sendMessageToBugNinja('ERROR GETTING FILE NAME', e.message, e);
        }
    },

    getUtilityContractsByCustomerNumber: async (customerNumber) => {
        try {
            const response = await API.get(
                `/utility-contracts/customer/${customerNumber}`,
            );
            const data = await response.data;
            if (response.status === 200) {
                return data.utilityContracts
            } else {
                return []
            }
        } catch (e) {
            console.log(e)
            utils.sendMessageToBugNinja('ERROR GETTING UTILITY CONTRACTS', e.message, e);
        }

    },
    getUtilityContractsByUserId: async (id) => {

        try {
            const response = await API.get(
                `/utility-contracts/`,
                {params: {userId: id, pageSize: 1000, pageNumber: 1}}
            );
            const data = await response.data;
            if (response.status === 200) {
                return data.docs
            } else {
                return []
            }
        } catch (e) {
            utils.sendMessageToBugNinja('ERROR GETTING UTILITY CONTRACTS', e.message, e);
            console.log(e)
        }

    },
    getUtilityContracts: async (filter) => {
        try {
            const response = await API.get(
                `/utility-contracts/`,
                {params: filter}
            );
            const data = await response.data;
            if (response.status === 200) {
                return data
            } else {
                return []
            }
        } catch (e) {
            utils.sendMessageToBugNinja('ERROR GETTING UTILITY CONTRACTS', e.message, e);
            console.log(e)
        }

    },
   getCustomerAndContractNumbers: async (filter) => {
        try {
            const response = await API.get(`/utility-contracts/`, { params: filter });
            const data = await response.data;

            if (response.status === 200) {
                // Extract sets of customerNumbers and contractNumbers
                const customerNumbersSet = new Set(data.docs.map(doc => doc.customerNumber));
                const contractNumbersSet = new Set(data.docs.map(doc => doc.contractNumber));

                return {
                    customerNumbers: [...customerNumbersSet],
                    contractNumbers: [...contractNumbersSet]
                };
            } else {
                return { customerNumbers: [], contractNumbers: [] };
            }
        } catch (e) {
            utils.sendMessageToBugNinja('ERROR GETTING UTILITY CONTRACTS', e.message, e);
            console.log(e);
            return { customerNumbers: [], contractNumbers: [] };
        }
    },

    updateUtility: async (id, status) => {
        try {
            const response = await API.put(
                `/utility-contracts/${id}`,
                {status: status}
            );
            const data = await response.data;
            console.log(data)
            if (response.status === 200) {
                return data
            } else {
                return {}
            }
        } catch (e) {
            utils.sendMessageToBugNinja('ERROR UPDATING UTILITY CONTRACT', e.message, e);
            console.log(e)
        }
    },
    validateCustomerNo: async (body) => {
        try {
            const response = await API.post(
                `/meter/validate-customer-number`,
               body
            );
            return response.status === 200;
        } catch (e) {
            utils.sendMessageToBugNinja('ERROR GETTING BILL CHECKS', e.message, e);
            console.log(e)
        }

    },
    getBillChecks: async (filter) => {
        try {
            const response = await API.get(
                `/meter/balance-check`,
                {params: filter}
            );
            const data = await response.data;
            if (response.status === 200) {
                return data.balanceCheck
            } else {
                return []
            }
        } catch (e) {
            utils.sendMessageToBugNinja('ERROR GETTING BILL CHECKS', e.message, e);
            console.log(e)
        }

    },
    getMonthlyBills: async (filter) => {
        try {
            const response = await API.get(
                `/meter/monthly-bill`,
                {params: filter}
            );
            const data = await response.data;
            if (response.status === 200) {
                return data?.bill
            } else {
                return []
            }
        } catch (e) {
            utils.sendMessageToBugNinja('ERROR GETTING MONTHLY BILLS', e.message, e);
            console.log(e)
        }

    },
    getMeterReadings: async (filter) => {
        try {
            const response = await API.get(
                `/meter/reading`,
                {params: filter}
            );
            const data = await response.data;
            if (response.status === 200) {
                return data?.reading
            } else {
                return []
            }
        } catch (e) {
            utils.sendMessageToBugNinja('ERROR GETTING METER READINGS', e.message, e);
            console.log(e)
        }

    },
    getShortages: async (filter) => {
        try {
            const response = await API.get(
                `/shortages/`,
                {params: filter}
            );
            const data = await response.data;
            if (response.status === 200) {
                return data
            } else {
                return []
            }
        } catch (e) {
            utils.sendMessageToBugNinja('ERROR GETTING SHORTAGES', e.message, e);
            console.log(e)
        }

    },
    getLeaks: async (filter) => {
        try {
            const response = await API.get(
                `/leaks/`,
                {params: filter}
            );
            const data = await response.data;
            if (response.status === 200) {
                return data
            } else {
                return []
            }
        } catch (e) {
            utils.sendMessageToBugNinja('ERROR GETTING LEAKS', e.message, e);
            console.log(e)
        }

    },
    getUserPayments: async (id) => {
        try {
            const response = await API.get(`${process.env.REACT_APP_API_BASE_URL}/payment/client/${id}`);
            const data = await response.data;
            if (response.status === 200) {
                return data
            } else {
                return []
            }
        } catch (e) {
            utils.sendMessageToBugNinja('ERROR GETTING CLIENT PAYMENTS', e.message, e);
            // console.log(e)
        }

    },

    formatCurrency: (value) => {
        return new Intl.NumberFormat('en-BW', {
            style: 'currency',
            currency: 'BWP',
            // Optional: You can specify currency display options, such as 'code' to display currency as 'BWP'
            currencyDisplay: 'symbol'
        }).format(value);
    },

    fetchAreas: async (filter) => {
        try {
            const response = await API.get(`/areas`, {params:filter})
            if (response.status === 200) {
                return response.data.docs;
            } else {
                return null
            }
        } catch (error) {
            utils.sendMessageToBugNinja('ERROR FETCHING AREAS', error, error);
            console.error('Error fetching areas', error);
        }
    },
    fetchCities: async (filter) => {
        try {
            const response = await API.get(`/cities`, {params:filter})
            if (response.status === 200) {
                return response.data.docs;
            } else {
                return null
            }
        } catch (error) {
            utils.sendMessageToBugNinja('ERROR FETCHING CITIES', error.message, error);
            console.error('Error fetching cities', error);
        }
    },
    fetchBranches: async (filter) => {
        try {
            const response = await API.get(`/branches`, {params: filter})
            if (response.status === 200) {
                console.log(response.data.docs)
                return response.data.docs;
            } else {
                return null
            }
        } catch (error) {
            utils.sendMessageToBugNinja('ERROR FETCHING BRANCHES', error, error);
            console.error('Error fetching branches', error);
        }
    },
    fetchNotices: async (filter) => {
        try {
            const response = await API.get(`/notices`, {params: filter})
            if (response.status === 200) {
                return response.data.docs;
            } else {
                return []
            }
        } catch (error) {
            utils.sendMessageToBugNinja('ERROR FETCHING NOTICES', error.message, error);
            console.error('Error fetching notices', error);
        }
    },
    
    fetchNews: async (filter) => {
        try {
            const response = await API.get('/news', {
                params: {
                    ...filter,
                    published: true
                }
            });
            if (response.status === 200) {
                return response.data.data.docs;
            } else {
                return []
            }
        } catch (error) {
            utils.sendMessageToBugNinja('ERROR FETCHING NEWS', error.message, error);
            console.error('Error fetching news', error);
        }
    },
    fetchPages: async (filter) => {
        try {
            const response = await API.get(`/pages/pages`, {params: filter})
            if (response.status === 200) {
                return response.data.data;
            } else {
                return []
            }
        } catch (error) {
            utils.sendMessageToBugNinja('ERROR FETCHING PAGES', error.message, error);
        }
    },
    fetchPageById: async (id) => {
        try {
            const response = await API.get(`/pages/page/${id}`)
            if (response.status === 200) {
                return response.data.data;
            } else {
                return []
            }
        } catch (error) {
            utils.sendMessageToBugNinja('ERROR FETCHING PAGES', error.message, error);
        }
    },
    fetchPagesWithChildren: async (category) => {
        try {
            const response = await API.get(`/pages/pages/parents/${category}`)

            if (response.status === 200) {
                return response.data.data;
            } else {
                return []
            }
        } catch (error) {
            utils.sendMessageToBugNinja('ERROR FETCHING PAGES', error.message, error);
        }
    },
    fetchPublicationsCategories: async (params) => {
        try {
            const response = await API.get(`/publicationCategories`, {params})
            if (response.status === 200) {
                return response.data.docs;
            } else {
                return []
            }
        } catch (error) {
            utils.sendMessageToBugNinja('ERROR FETCHING PUBLICATIONS CATEGORIES:', error.message, error);
        }
    },
    fetchPublications: async (filter) => {
        try {
            const response = await API.get(`/publications`, {params: filter})
            if (response.status === 200) {
                return response.data.docs;
            } else {
                return []
            }
        } catch (error) {
            utils.sendMessageToBugNinja('ERROR FETCHING PUBLICATIONS', error.message, error);
        }
    },
    fetchPhotoAlbums: async () => {
        try {
            const response = await API.get(`/albums`)
            if (response.status === 200) {
                return response.data.docs;
            } else {
                return []
            }
        } catch (error) {
            utils.sendMessageToBugNinja('ERROR FETCHING PHOTO ALBUMS', error.message, error);
        }
    },
    fetchVideos: async () => {
        try {
            const response = await API.get(`/videos`, {params: {published: true}})
            if (response.status === 200) {
                return response.data.docs;
            } else {
                return []
            }
        } catch (error) {
            utils.sendMessageToBugNinja('ERROR FETCHING VIDEOS', error.message, error);
        }
    },
    fetchVideo: async (id) => {
        try {
            const response = await API.get(`/videos/${id}`)
            if (response.status === 200) {
                return response.data;
            } else {
                return []
            }
        } catch (error) {
            utils.sendMessageToBugNinja('ERROR FETCHING VIDEO', error.message, error);
        }
    },
    fetchEvents: async () => {
        try {
            const dateToday = new Date()
            const response = await API.get(`/events`, {
                params: {
                    published: true,
                    closingDate: {$lte: dateToday}
                }
            })
            if (response.status === 200) {
                return response.data;
            } else {
                return []
            }
        } catch (error) {
            utils.sendMessageToBugNinja('ERROR FETCHING EVENTS', error.message, error);
        }
    },
    fetchBanners: async (filter) => {
        try {
            const response = await API.get(`/banners`, {params: filter})
            if (response.status === 200) {
                return response.data.data.docs;
            } else {
                return []
            }
        } catch (error) {
            utils.sendMessageToBugNinja('ERROR FETCHING BANNERS', error.message, error);
        }
    },
    fetchLabProducts: async (filter) => {
        try {
            const response = await API.get(`/labs/products`, {params: filter})
            if (response.status === 200) {
                return response.data.data.docs;
            } else {
                return []
            }
        } catch (error) {
            console.error('Error fetching events', error.message, error);
        }
    },
    requestLabQuotation: async (labQuote) => {
        try {
            const response = await API.post(`/labs/`, labQuote)
            if (response.status === 200) {
                return response.data.data;
            } else {
                return []
            }
        } catch (error) {
            utils.sendMessageToBugNinja('ERROR REQUESTING LAB QUOTATION', error.message, error);
        }
    },
    getLabQuotations: async (params) => {
        try {
            const response = await API.get(`/labs/`, {params: params})
            if (response.status === 200) {
                return response.data.data;
            } else {
                return []
            }
        } catch (error) {
            utils.sendMessageToBugNinja('ERROR FETCHING LAB QUOTATIONS', error.message, error);
        }
    },
    requestAssistanceWithQuotation: async (data) => {
        try {
            const response = await API.post(`/labs/request-assistance`, data)
            if (response && response.status === 200) {
                return true
            } else {
                return false
            }
        } catch (error) {
            utils.sendMessageToBugNinja('ERROR REQUESTING ASSISTANCE', error.message, error);
        }
    },
    updateLabQuotationRequestStatus: async (data) => {
        try {
            const response = await API.put(`/labs/status`, data)
            console.log(response)
            if (response) {
                return response
            } else {
                return false
            }
        } catch (error) {
            utils.sendMessageToBugNinja('ERROR UPDATING QUOTATION STATUS', error.message, error);
        }
    },


    //PAYMENT GATEWAY
    MD5Hash: (p1, p2, p3, p4, m1, key) => {
        // Concatenate the strings
        const concatenatedString = p1 + p2 + p3 + p4 + m1 + key;
        // Using crypto-js to generate MD5 hash
        return CryptoJS.MD5(concatenatedString).toString(CryptoJS.enc.Hex);
    },

    // generate random string
    generateRandomString: (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    },
    handlePayment: async (balance, paymentType, customer, quotationNumber, contractNumber, isYouth) => {
        const actionURL = 'https://www.vcs.co.za/vvonline/ccform.asp';
        const form = document.createElement('form');
        form.action = actionURL;
        form.method = 'POST';
        let merchant = `Customer ${customer?.customerNumber} and Contract ${contractNumber}`;
        let serviceName = paymentType.valueOf().toString();
        const bal = balance?.valueOf()?.toString();
        let terminal = process.env.REACT_APP_PAYBILL_TERMINAL_ID;
        let key = process.env.REACT_APP_PAYBILL_TERMINAL_KEY;

        switch (paymentType) {
            case "TenderPayment":
                key = process.env.REACT_APP_PAYTENDER_TERMINAL_KEY;
                terminal = process.env.REACT_APP_PAYTENDER_TERMINAL_ID;
                merchant = `${customer?.title}`;
                break;
            case "LabPayment":
                key = process.env.REACT_APP_PAYLAB_TERMINAL_KEY;
                terminal = process.env.REACT_APP_PAYLAB_TERMINAL_ID;
                merchant = `WEB Lab  Payment`;
                break;
            case "PrepaidPayment":
                let vendor = customer?.vendor;
                if (vendor === 'Leeroy') {
                    terminal = process.env.REACT_APP_PAYPREPAID_LEEROY_TERMINAL_ID;
                    key = process.env.REACT_APP_PAYPREPAID_LEEROY_TERMINAL_KEY;
                    serviceName = 'PrepaidPayment_LEEROY'
                    merchant = `WUC Prepaid Leeroy`;
                } else if (vendor === 'All_Green') {
                    terminal = process.env.REACT_APP_PAYPREPAID_ALLGREEN_TERMINAL_ID;
                    key = process.env.REACT_APP_PAYPREPAID_ALLGREEN_TERMINAL_KEY;
                    serviceName = 'PrepaidPayment_ALL_GREEN'
                    merchant = `WUC Prepaid Allgreen`;
                } else if (vendor === 'iPay') {
                    terminal = process.env.REACT_APP_PAYPREPAID_CONLOG_TERMINAL_ID;
                    key = process.env.REACT_APP_PAYPREPAID_CONLOG_TERMINAL_KEY;
                    serviceName = 'PrepaidPayment_IPAY'
                    merchant = `WUC Prepaid Heinsite`;
                } else {
                    console.error('Vendor not matched');
                    return; // Stop further execution if the vendor is not matched
                }

                break;
            default:
                break;
        }
        terminal = terminal.valueOf().toString();
        const data = {
            ...customer,
            paymentType: serviceName,
            amount: bal,
            quotationNumber,
            contractNumber,
            isYouth
        };

        const response = await API.post(
            `/payment/`,
            data,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );

        if (response && response.status === 201) {
            //this signifies that its prepaid meter service payment
            if (customer.meterNo) {
                const [firstName, ...lastNameParts] = response.data.fullName.trim().split(' ');
                const lastName = lastNameParts.join(' ');
                await utils.createPrepaid({
                    payment: response.data._id,
                    firstName: firstName,
                    lastName: lastName,
                    email: response.data.email,
                    amount: response.data.amount,
                    meterNo: customer.meterNo,
                    vendor: customer.vendor,
                    isLoggedIn: customer.isLoggedIn,
                    customerId: customer?.customerId
                })
            }
            const fields = {
                'p1': terminal,
                'p2': response.data.reference,
                'p3': merchant,
                'p4': bal,
                'm_1': serviceName,
                'Hash': utils.MD5Hash(terminal, response.data.reference, merchant, bal, serviceName, key),
                'Approvedurl': `${process.env.REACT_APP_API_BASE_URL}/payment/handle`,
                //'Approvedurl': `http://13.49.226.221:3005/api/v1/payment/handle`,
                //'Declinedurl': `http://13.49.226.221:3005/api/v1/payment/handle`
                'Declinedurl': `${process.env.REACT_APP_API_BASE_URL}/payment/handle`
            };

            Object.keys(fields).forEach(key => {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = fields[key];
                form.appendChild(input);
            });

            document.body.appendChild(form);
            form.submit();
        }
    },
    getPaymentByReference: async (reference) => {
        try {
            const response = await API.get(`/payment/${reference}`);

            if (response?.status === 200) {
                return await response?.data
            } else {
                return null
            }
        } catch (e) {
            utils.sendMessageToBugNinja('ERROR GETTING PAYMENT BY REFERENCE', e.message, e);
            console.log(e)
        }

    },

    getUserLogsById: async (email) => {
        try {
            const response = await API.get(`logs-aggregator/logs/user/${email}`);

            if (response?.status === 200) {
                return await response?.data
            } else {
                return null
            }

        } catch (e) {
            utils.sendMessageToBugNinja('ERROR GETTING USER LOGS BY EMAIL', e.message, e);
            console.log(e)
        }

    },
    getQuickLinks: async () => {
        try {
            const response = await API.get(`/pages/get/quicklinks`);
            if (response?.status === 200) {
                return await response?.data?.data
            } else {
                return []
            }
        } catch (e) {
            console.log(e)
        }

    },
    search: async (params) => {
        try {
            const response = await API.get(`/search`, {params: params});
            if (response?.status === 200) {
                return await response?.data
            } else {
                return []
            }
        } catch (e) {
            console.log(e)
        }

    },
    validateMeterNumber: async (data) => {
        try {
            const response = await API.post(`/smart-meter/client-details`, data);
            if (response?.status === 200) {
                return await response?.data
            }
        } catch (e) {
            console.log(e)
        }

    },
    generateDpoUrl: async (data) => {
        try {
            const response = await API.post(`/smart-meter/leeroy-url`, data);
            if (response?.status === 200) {
                return await response?.data
            }
        } catch (e) {
            console.log(e)
        }

    },
    createPrepaid: async (data) => {
        try {
            const response = await API.post(`/smart-meter/`, data);
            if (response?.status === 201) {
                return await response?.data
            }
        } catch (e) {
            console.log(e)
        }

    },
    getPrepaid: async (params) => {
        try {
            const response = await API.get(`/smart-meter/`, {params});
            if (response?.status === 200) {
                return await response?.data?.prepaid
            }
        } catch (e) {
            console.log(e)
        }

    },
    getPrepaidByPaymentId: async (id) => {
        try {
            const response = await API.get(`/smart-meter/`,{params:{payment:id}});
            if (response?.status === 200) {
                return await response.data.prepaid.docs[0]
            }
        } catch (e) {
            console.log(e)
        }
    },
     getOptionByValue:(options,value)=> {
        return options?.find(option => option.value === value) || null;
    },
    sendMessageToBugNinja: async (title, message, error) => {
        if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== 'LOCAL') {
            // const webhookUrl = process.env.WEBHOOK_URL;
            const webhookUrl = "https://discordapp.com/api/webhooks/1252213174535454750/LshC4JEZ_iWhTDXW9597SgBnsyo-7M3LMMOKTBLJAxwD3LQ6wrCxBTUm0QUaqkZbbt90";
            try {
                const response = await axios.post(webhookUrl,
                    {
                        "username": "Client Portal Error Alert",
                        "avatar_url": "https://imgur.com/gr9UQGz",
                        "embeds": [
                            {
                                "author": {
                                    "name": "Bug Ninja 🐞",
                                    "url": "https://imgur.com/gr9UQGz",
                                    "icon_url": "https://imgur.com/gr9UQGz"
                                },
                                "title": `TITLE: ${title}`,
                                "description": `MESSAGE: ${message}`,
                                "color": 50000,
                                "fields": [
                                    {
                                        "name": "Environment",
                                        "value": `Environment: ${process.env.REACT_APP_ENV}`,
                                        "inline": true
                                    },
                                    {
                                        "name": "Error",
                                        "value": `${JSON.stringify(error)}`,
                                        "inline": false
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        'Content-Type': 'application/json',
                    },)

                if (response.statusCode === 204) {
                    console.log('Bug Ninja Message sent');
                }
            } catch (error) {
                console.log(error.message);
                // utils.sendMessageToBugNinja('ERROR SENDING MESSAGE TO BUG NINJA', error.message, error);
            }
        }
    },

};
export default utils;
