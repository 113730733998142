import React, { useEffect, useState, createContext, useContext } from 'react';
import utils from "../utils/commonFunctions";

const UserProfileContext = createContext(null);

export const UserProfileProvider = ({ children }) => {
    const [userProfile, setUserProfile] = useState(null);
    useEffect(() => {

        const fetchUserProfile = async () => {
            try {
                const data = await utils.fetchUserProfile();
                setUserProfile(data);
            } catch (error) {
                console.error("Error fetching user profile:", error);
            }
        };
        fetchUserProfile();
    }, []);
    return (
        <UserProfileContext.Provider value={{userProfile}}>
            {children}
        </UserProfileContext.Provider>
    );
};

export const useUserProfile = () => useContext(UserProfileContext);