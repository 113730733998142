import React from "react";
import {Flex, Grid, GridItem} from "@chakra-ui/react";
import Contact from "./Contact";

const SelfServiceContainer = ({childComponent,width='md',table}) => {
    return (
        <>
            <Grid templateColumns='repeat(12, 1fr)' gap={4}>
                <GridItem colSpan={{base:12,lg:4}} order={{base:3,lg:2}} >
                    <Contact/>
                </GridItem>
                <GridItem colSpan={{base:12,lg:8}} order={{base:1}} boxShadow={'md'} borderRadius={'2xl'} align="center" justify="center" bg={'white'} py={8}>
                    <Flex maxW={width} h={'full'}  align="center" justify="center">
                        {childComponent}
                    </Flex>
                </GridItem>
                <GridItem colSpan={{base:12}} order={{base:2,lg:3}} >
                    {table}
                </GridItem>
            </Grid>
        </>
    );
};

export default SelfServiceContainer;
