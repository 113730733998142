
import React from "react";
import { Box, Heading, Center, Image, Button } from "@chakra-ui/react";
import { VStack } from '@chakra-ui/react'
import emptySurveyBox from "../../../assets/empty-box.png";

const EmptyProjectList = () => {

    return (
        <Box objectFit={'fit'} px={{ base: 8, lg: 32 }} mb={8} minHeight={400} bg={'white'} shadow={'lg'}>
            <Heading size="lg" my={8} textAlign={'center'} color={'brand.700'}>Oops, there are no project images!</Heading>
            <Center p='4' color='white' axis='both'>
                <VStack
                    spacing={4}
                    align='stretch'                    
                    px={{ base: 8, lg: 32 }} mb={8}
                    py={8}
                    borderRadius='lg'
                >
                    <Image
                        objectFit='fit'
                        maxW={{ base: '100%', sm: '200px' }}
                        maxH={{ base: '80%', sm: 'full' }}
                        ms={4}
                        src={emptySurveyBox}
                        alt='No project images'
                    />
                    {/* <Button
                        mt={8}
                        variant='solid'
                        colorScheme='blue'
                        width={'full'}
                        onClick={() => {
                            navigate('/survey');
                        }}
                    >
                        Back to Surveys
                    </Button> */}
                </VStack>
            </Center>
        </Box>
    );

};

export default EmptyProjectList
