import React, { useState, useEffect } from "react";
import {
    Box,
    Flex,
    Icon,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Button, useDisclosure,
} from "@chakra-ui/react";
import { FaCircleExclamation } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useUserProfile } from "../../../../providers/UserProfileProvider";
import enums from "wuc-library/enums";


const KycAlertModal = () => {
    const {isOpen,onOpen,onClose}=useDisclosure()
    const { userProfile } = useUserProfile();
    const [message, setMessage] = useState("");
    const [link, setLink] = useState("");

    useEffect(() => {
        const { status } = userProfile || {};
        if (userProfile && status) {
            switch (status) {
                case enums.ConnectionRequestStatus.DECLINED:
                    setMessage("Dear valued customer, you need to be KYC compliant to perform this action. Please update your KYC Profile or call our customer service center for further assistance.");
                    setLink('know-your-customer');
                    onOpen()
                    break;
                case enums.ConnectionRequestStatus.SUBMITTED:
                    setMessage("Dear valued customer, your KYC profile is still under review. In order to continue with this action, please be patient or call our customer service center for further assistance.");
                    setLink('dashboard');
                    onOpen()
                    break;
                default:
                    setMessage("Dear valued customer, call our customer service center for further assistance.");
                    setLink('dashboard');
                    break;
            }
        } else {
            setMessage("Dear valued customer, you need to be KYC compliant in order to continue with this action, please update your KYC profile or call our customer service center for further assistance.");
            setLink('know-your-customer');
            onOpen()
        }

    }, [userProfile]);

    return (
        <Modal closeOnOverlayClick={false} isCentered isOpen={isOpen} onClose={onClose} w={{base:'full',md:'lg'}}>
            <ModalOverlay />
            <ModalContent py={4}>
                <ModalBody textAlign={"center"}>
                    <Box>
                        <Icon as={FaCircleExclamation} boxSize={{ base: "16", md: "24" }} borderWidth={8}
                              borderRadius={'full'} borderColor={'red.200'} color={"red.500"} />
                        <Text my={2} fontSize={"20px"}>
                            {message}
                        </Text>
                    </Box>
                </ModalBody>
                <Flex justify={'center'} w={'full'}>
                    <Link to={`/self-service-portal/${link}`}>
                        <Button onClick={onClose} bg={'red.500'} _hover={{ bg: 'red.600' }}>
                            OK
                        </Button>
                    </Link>
                </Flex>
            </ModalContent>
        </Modal>
    );
};

export default KycAlertModal;
