import React from 'react';
import PortalContainer from '../../components/PortalContainer/PortalContainer';
import DisconnectionsTable from "./Components/DisconnectionsTable";

const MyConnections= () => {

    return <>
        <PortalContainer Title={"Disconnection Applications"} childComponent={<DisconnectionsTable/>}/>
    </>;
};

export default MyConnections;
