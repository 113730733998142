import React from "react";

import PublicViewContainer from "../../components/Containers/PublicViewContainer";
import BuyTenderComponent from "./Components/ContactUsForm";


const ContactUs = ({tenderFee}) => {
    return <PublicViewContainer childComponent={<BuyTenderComponent/>}/>

};


export default ContactUs;
