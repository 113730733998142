import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {ChakraProvider} from "@chakra-ui/react";
import customTheme from "./theme/customTheme";
import {QuoteCartProvider} from "./providers/QuoteCartProvider";
import {NavBarProvider} from './providers/NavBarSliderProvider';
import {UserProfileProvider} from "./providers/UserProfileProvider";
import {PhotoProvider} from "./providers/PhotosProvider";
import {AuthProvider} from "./providers/AuthProvider";
import {SearchProvider} from "./providers/searchProvider";
import {PrepaidProvider} from "./providers/PrepaidProvider";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
            <ChakraProvider theme={customTheme}>
                <AuthProvider>
                <UserProfileProvider>
                    <NavBarProvider>
                        <QuoteCartProvider>
                            <PhotoProvider>
                                <SearchProvider>
                                    <PrepaidProvider>
                                        <App/>
                                    </PrepaidProvider>
                                </SearchProvider>
                            </PhotoProvider>
                        </QuoteCartProvider>
                    </NavBarProvider>
                </UserProfileProvider>
                </AuthProvider>
            </ChakraProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
