import React from "react";
import LoginComponent from "../ForgotPassword/components/ForgotPassword";
import AuthenticationContainer from "../../components/Authentication/AuthenticationContainer";

const ForgotPassword = () => {
  return (
    <>
      <AuthenticationContainer Children={<LoginComponent />} />
    </>
  );
};

export default ForgotPassword;
