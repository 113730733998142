import {
    Avatar,
    AvatarBadge,
    Box, Flex,
    Grid,
    GridItem,
    Heading,
    Text, useDisclosure,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useUserProfile} from "../../../providers/UserProfileProvider";
import {useQuoteCart} from "../../../providers/QuoteCartProvider";
import utils from "../../../utils/commonFunctions";
import {MdShoppingCart} from "react-icons/md";
import CartModal from "./CustomModal/CartModal";


const DetailsHeader = () => {
    const {onOpen,isOpen,onClose}=useDisclosure()
  const {customer, setCustomer,cartSize} = useQuoteCart();
  let {userProfile}=useUserProfile()
  let {personalDetails,address,contactDetails,customerNumber}=userProfile||{}
  let user=utils.getUser()
  let fullname=null
  if(personalDetails){
    fullname=personalDetails?.firstName+""+personalDetails?.lastName
  }
  useEffect(()=>{
      setCustomer({
        pNumber:'OTC-QUOTE',
        name:fullname||user?.name,
        street:address?.street,
        city:address?.city,
        postalCode:'0000',
        emailAddress:user?.email,
      })
  },[userProfile,user])

  return (

      <Grid templateColumns="repeat(12, 1fr)" gap={4} w={"100%"} mb={16}>
        <GridItem colSpan={{base:10,md:5}} order={{base:1}}>
          <Heading fontSize={"md"} color={"blue.800"} mb={2}>
            Customer Information
          </Heading>
          <Box fontSize="sm">
            <Text>{customer.name}</Text>
            <Text>{customer.emailAddress}</Text>
            <Text>{customer.pNumber}</Text>
          </Box>
        </GridItem>

        <GridItem colSpan={{base:12,md:5}} order={{base:3, md:2}} borderLeft={{base:0,md:'1px'}} borderColor={'blue.300'} px={{base:0,md:4}}>
          <Heading fontSize={"md"} color={"blue.800"} mb={2}>
            Address Information
          </Heading>
            <Box fontSize="sm">
              <Text>{'0000'}</Text>
              <Text>{customer.street}</Text>
              <Text>{customer.city}</Text>
            </Box>
        </GridItem>
        <GridItem colSpan={{base:2,md:2}} order={{base:2, md:3}} px={4}  justifyContent={'end'} >
            <Flex  w={'full'} justifyContent={'end'}>
                <Avatar as={'button'} onClick={()=>onOpen()} icon={<MdShoppingCart fontSize="1.5rem" />}>
                    <AvatarBadge boxSize="1.8em" fontSize="14px" bg="red.500" border>
                        {cartSize}
                    </AvatarBadge>
                </Avatar>
            </Flex>

        </GridItem>
          <CartModal onClose={onClose} isOpen={isOpen}/>
      </Grid>

  );
};

export default DetailsHeader;
