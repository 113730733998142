import React, { useState, useEffect } from "react";
import {
    Box,
    Flex,
    Heading,
    Text,
    Grid, GridItem, Image
} from "@chakra-ui/react";
import moment from "moment";
import {useLocation, useNavigate} from "react-router-dom";
import utils from "../../../utils/commonFunctions";

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import image1 from '../../../assets/latest-news.jpg';
const ImageSlider = ({ images }) => {
    const splideOptions = {
        type: 'fade',
        perPage: 1,
        pagination: true,
        rewind: true,
        focus: 'center',
        gap: '1rem',
    };

    return (
        <Splide options={splideOptions}>
            {(!images?.length) ? (
                <SplideSlide>
                    <img src={image1} alt="Default Image" />
                </SplideSlide>
            ) : (
                images?.map((image, index) => (
                    <SplideSlide key={index}>
                        <img src={image} alt={`Image ${index + 1}`} />
                    </SplideSlide>
                ))
            )}
        </Splide>
    );
};



const VideoEmbed = ({ iframeCode }) => {
    const [src, setSrc] = useState('');

    useEffect(() => {
        // Extract src from iframeCode
        const parser = new DOMParser();
        const doc = parser.parseFromString(iframeCode, 'text/html');
        const iframeSrc = doc.querySelector('iframe').getAttribute('src');

        setSrc(iframeSrc);
    }, [iframeCode]);

    if (!src) {
        return null; // or handle loading state
    }

    return (
        <iframe
            width="100%"
            height="226"
            src={src}
            title="Embedded Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
        ></iframe>
    );
};




const NewsItems = ({news}) => {
    return (
        <>
            <Grid templateColumns='repeat(12,1fr)' gap={4}>
                <GridItem colSpan={{base:12,md:4}} order={{base:2,md:1}} bg={'gray.50'} borderRadius={'2xl'}>
                    <Box mb={4}>
                        <ImageSlider images={news?.images}/>
                    </Box>
                    {news?.videoEmbed&&
                        <Box>
                           <VideoEmbed iframeCode={news?.videoEmbed}/>
                        </Box>
                    }

                </GridItem>
                <GridItem colSpan={{base:12,md:8}} order={{base:1,md:2}}>
                    <Flex key={news?._id} direction={'column'} justify={'center'}  w={'full'} >
                        <Heading size={'md'} noOfLines={2}>{news?.title} </Heading>
                        <Text fontWeight={'medium'} color={'gray.500'} fontSize={'sm'} my={2} textTransform={'uppercase'}>{moment(news?.datePublished).format('DD MMMM, YYYY')}</Text>

                        <Grid  templateColumns='repeat(12, 1fr)' gap={4} justifyContent={'center'}  mb={4}>
                            <GridItem colSpan={{base:12,md:11}} >
                                <Text  color={'gray.500'} textAlign={'justify'}>
                                    <div dangerouslySetInnerHTML={utils.sanitizeHtml(news?.description)}/>
                                </Text>
                            </GridItem>
                        </Grid>

                    </Flex>
                </GridItem>

            </Grid>
        </>
    );
};


const NewsView = () => {
    let {news}=useLocation()?.state||{}

    return (
        <Box bg={'white'} w={'full'} p={8} borderRadius={'2xl'} boxShadow={'md'}>
            {news&& <NewsItems news={news}/>}
        </Box>
    );
};
export default NewsView;
