import React from 'react';
import ProjectsCarousel from './ProjectsCarousel';
import {Box, Flex, Heading, Text, VStack} from '@chakra-ui/react';

const Projects = () => {

  return (
    <Flex align='center' justify='center' h='auto' bg='#3c4043' p={10} mb={-24}>
      <VStack spacing={10}>
        <VStack pb={8}>
          <Heading size="xl"  textAlign={'center'} color={'white'}>Our <Box as={'span'} color={'white'} >Projects</Box> </Heading>
        </VStack>

        <ProjectsCarousel />
      </VStack>
    </Flex>
  );
};

export default Projects;
