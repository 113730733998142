import React from 'react';
import PortalContainer from '../../components/PortalContainer/PortalContainer';
import ConnectionTable from "./Components/ConnectionsTable";

const MyConnections= () => {

    return <>
        <PortalContainer Title={"Connection Applications"} childComponent={<ConnectionTable/>}/>
    </>;
};

export default MyConnections;
