import React, { useState } from "react";
import {
    Box, Center,
    HStack,
    Stack,
    useRadio,
    useRadioGroup,
} from "@chakra-ui/react";
import LeakReportGuest from "../../ReportLeak/Components/LeakReportGuest";
import ShortageReportGuest from "../../ReportShortage/Components/WaterShortageGuest";

function RadioCard(props) {
    const { getInputProps, getRadioProps } = useRadio(props);

    const input = getInputProps();
    const checkbox = getRadioProps();

    return (
        <Box as='label'>
            <input {...input} />
            <Box
                {...checkbox}
                cursor='pointer'
                borderWidth='1px'
                borderRadius='md'
                boxShadow='sm'
                _checked={{
                    bg: 'brand.500',
                    color: 'white',
                    borderColor: 'brand.500',
                }}
                _focus={{
                    boxShadow: 'outline',
                }}
                px={5}
                py={3}
            >
                {props.children}
            </Box>
        </Box>
    );
}

const ReportsRadio = ({ setService }) => {
    const options = ['Report Water Shortage','Report Water Leakage'];

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'service',
        defaultValue: 'Report Water Shortage',
        onChange: setService,
    });

    const group = getRootProps();

    return (
        <HStack {...group}>
            {options.map((value) => {
                const radio = getRadioProps({ value });
                return (
                    <RadioCard key={value} {...radio}>
                        {value}
                    </RadioCard>
                );
            })}
        </HStack>
    );
};

const ReportsGuest = () => {
    const [service, setService] = useState("Report Water Shortage");

    return (
        <Stack spacing={4} justify="center" w="full" h="full">
            <Center>
                <ReportsRadio setService={setService} />
            </Center>

            {service === "Report Water Leakage" && <LeakReportGuest />}
            {service === "Report Water Shortage" && <ShortageReportGuest />}
        </Stack>
    );
};

export default ReportsGuest;
