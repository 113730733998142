import {
    Flex,
    Button,
    Heading,
    Text, useDisclosure,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import utils from "../../utils/commonFunctions";
import {PropagateLoader} from "react-spinners";
import DpoModal from "./DpoModal";
import {usePrepaid} from "../../providers/PrepaidProvider";

const PaymentRedirect = ({balance, onClose, paymentType, customer,quotationNumber, contractNumber, isYouth,vendor}) => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        function handleWindowLoad() {
            setLoading(false);
        }

        // If the document is already fully loaded
        if (document.readyState === "complete") {
            setLoading(false);
        } else {
            // Add event listener if the document is not yet fully loaded
            window.addEventListener('load', handleWindowLoad);

            return () => {
                window.removeEventListener('load', handleWindowLoad);
            };
        }

        // Add beforeunload event listener to handle the loading state
        window.addEventListener('beforeunload', () => setLoading(true));

        return () => {
            window.removeEventListener('beforeunload', () => setLoading(true));
        };
    }, []);
    const {setTransactionDetails,resetTransactionDetails}=usePrepaid()||{}
    async function handlePayment() {
        try {
            setLoading(true);
            console.log("Payment",{balance,paymentType, customer})
            await utils.handlePayment(balance,paymentType, customer, quotationNumber, contractNumber, isYouth);
            setLoading(false);
        } catch (e) {
            console.error("Error handling payment", e);
            setLoading(false);
        }
    }

    return (
        <>
            <Heading
                as="h4"
                fontSize="24px"
                color="blue.800"
                fontWeight="semibold"
            >
                Confirmation
            </Heading>
            <Text color={'gray.600'} fontSize={'md'} mt={2}>
                You are about to be redirected to third party payment gateway.
                Click on Pay Now to proceed with this transaction.
            </Text>
            <Button
                type="submit"
                variant={'solid'}
                mt={4}
                isDisabled={loading}
                as={"Flex"}
                align={'center'}
                cursor={!loading ? "pointer" : "not-allowed"}
                onClick={handlePayment}
            >
                {loading ? <PropagateLoader color="white" /> : 'Pay Now'}
            </Button>
            <Button
                variant={'ghost'}
                _hover={{bg: 'gray.50'}}
                mt={4}
                cursor={!loading ? "pointer" : "not-allowed"}
                onClick={() => {
                    resetTransactionDetails()
                    onClose()
                }}
                isDisabled={loading}
            >
                Cancel
            </Button>
        </>
    );
};

export default PaymentRedirect;
