import React from "react";
import {Box, Button, Flex, Icon, Text} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import {TbError404} from "react-icons/tb";


const Approved= () => {

  return (
      <>
        <Flex px={2} flexDirection={'column'} justify={'center'} align={'center'} w={'full'} h={'100vh'} >

          <Box >
            <Icon as={ TbError404} boxSize={{base:"16",md:"24"}} borderWidth={8} borderRadius={'full'} borderColor={'red.200'} color={"red.500"} />
          </Box>

          <Text textAlign={'center'} fontWeight={'bold'} my={2} fontSize={{base:"30px"}}>
           PAGE NOT FOUND
          </Text>
          <Text textAlign={'center'} my={2} fontSize={{base:"20px"}}>
            Sorry the resource you are try to access it might temporarily unavailable or removed.
          </Text>

          <Text textAlign={'center'} my={2} fontSize={{base:"14px"}}>
            {` ${new Date()}`}
          </Text>
          <Link to={'/'}>
            <Button  my={2}  bg={'red.500'} _hover={{bg:'red.400'}} p={4}>
              Back to Homepage
            </Button>
          </Link>


        </Flex>
      </>
  )

};


export default Approved;
