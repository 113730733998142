import utils from "../../utils/commonFunctions";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {useRedirectUrl} from "../../providers/AuthProvider";
function PrivateRoute() {
    const { setRedirectUrl } = useRedirectUrl()||{};
    const navigate=useNavigate()
    const location = useLocation();
    if (utils.isAuthenticated()) {
      return <Outlet />
    }
    setRedirectUrl(location.pathname)
    utils.logout();
    navigate('/login')
}


export default PrivateRoute;
