import {
    Input,
    Stack,
    Button,
    FormLabel,
    FormControl,
    useToast,
    Select,
    HStack, Text, Switch,
} from "@chakra-ui/react";
import {useEffect, useState} from "react";

import {PropagateLoader} from "react-spinners";
import {CustomGeneralToast} from "../../../utils/customChakraToasts";
import utils from "../../../utils/commonFunctions";
import {API} from "../../../utils/api";
import BillAlert from "../../../components/PortalContainer/BillAlert";
import {useUserProfile} from "../../../providers/UserProfileProvider";
import {useQuoteCart} from "../../../providers/QuoteCartProvider";

const SubmitMeterReadingForm = () => {
    const userId=utils.getUserId()
    const [reading, setReading] = useState("");
    const {userProfile} = useUserProfile() || {}
    const {customerNumber} = userProfile || ""
    const [number, setNumber] = useState(customerNumber);
    const [utility, setUtility] = useState("");
    const [utilityContracts, setUtilityContracts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [checkForOthers, setCheckForOthers] = useState(false);
    const {addGeneralToast} = CustomGeneralToast();
    const {refreshFlag,setRefreshFlag}=useQuoteCart()

    const toast = useToast();
    useEffect(() => {
        const getUtilityContracts = async () => {
            setUtilityContracts(await utils.getUtilityContractsByCustomerNumber(customerNumber))
        };
        if(!checkForOthers&&customerNumber!==""){ getUtilityContracts()}
    }, [checkForOthers,customerNumber]);
    const submitMeterReading = async () => {
        if (number === "" || utility === ""||reading==="") {
            toast.closeAll();
            addGeneralToast({
                message: "All fields must be filled in",
                type: "warning",
            });
            setLoading(false);
            return;
        }
        setLoading(true);
        try {
            const response = await API.post(
                `/meter/reading`,
                {
                    customerNumber: number,
                    contractNumber: utility,
                    meterReading:reading,
                    createdBy:userId
                }
            );
            const data = await response.data;
            if (response.status === 200) {
                setLoading(false);
            } else {
                toast.closeAll();
                addGeneralToast({
                    message: data.message,
                    type: "error",
                });
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
            toast.closeAll();
            console.log(e)
            addGeneralToast({
                message: e?.message? e?.message : e.statusText,
                type: "error",
            });
        }finally {
             setRefreshFlag(true)
        }

    };

    return (
        <>
            <Stack spacing={1} w="full" h={'full'} px={4}>
                <BillAlert/>
                {/* Similar FormControl components for 'Customer Cell No' and 'Customer Number' */}
                <FormControl id="customerNumber">
                    <FormLabel>Customer Number</FormLabel>
                    {!utils.isAuthenticated()||checkForOthers ? (
                        <Input
                            isRequired
                            type="number"
                            onChange={(e) => setNumber(e.target.value)}
                        />): <Input
                        isRequired
                        isDisabled
                        type="number"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                    />}
                </FormControl>
                <FormControl id="contractNumber">
                    <FormLabel>Utility Number</FormLabel>
                    <>
                        {!utils.isAuthenticated()|| checkForOthers ? (
                            <Input
                                isRequired
                                type="number"
                                onChange={(e) => setUtility(e.target.value)}
                            />
                        ) : (
                            <Select
                                isRequired
                                placeholder="Select Utility"
                                onChange={(e) => setUtility(e.target.value)}
                            >

                                {utilityContracts?.length > 0 && utilityContracts.map((contract) => (
                                    <option key={contract?.contractNumber} value={contract?.contractNumber}>
                                        {contract?.contractNumber}
                                    </option>
                                ))}

                            </Select>
                        )}

                    </>
                </FormControl>
                <FormControl id="customerNumber">
                    <FormLabel color="blue.800">Meter Reading</FormLabel>
                        <Input
                            type="text"
                            onChange={(e) => setReading(e.target.value)}
                        />
                </FormControl>


                <Stack spacing={6} mt={2}>
                    {loading ? (
                        <Button variant="solid">
                            <PropagateLoader size={8} color="white"/>
                        </Button>
                    ) : (
                        <Button
                            type="submit"
                            variant="solid"
                            onClick={submitMeterReading}
                        >
                            Submit Meter Reading
                        </Button>
                    )}
                </Stack>
                {utils.isAuthenticated()&&
                    <HStack mt={2} justifyContent="center" alignItems="center">
                        <Text color={"blue.800"}>Submit Meter Readings For Others</Text>
                        <Switch id='bill-for-others' onChange={(e) => setCheckForOthers(e.target.checked)}/>
                    </HStack>
                }
            </Stack>
        </>
    );
};

export default SubmitMeterReadingForm;
