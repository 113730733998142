import React from "react";


import {Box} from "@chakra-ui/react";

import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import TopBanner from "../BottomBanner/TopBanner";
import image1 from "../../assets/bgn-industries.png";

const FullConatainer = ({ childComponent}) => {
    return (
        <>
            <Header/>
            <TopBanner/>
            <Box objectFit={'fit'}
                // bgImage={`url(${image1})`}
                 bgPosition="center"
                 bgRepeat="no-repeat"
                 bgSize="cover" >
                {childComponent}


            </Box>
            <Footer/>

        </>
    )
}
    export default FullConatainer
