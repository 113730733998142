import React from "react";
import CustomerServicesComponent from "./Components/ContentPages";
import PublicViewContainer from "../../components/Containers/PublicViewContainer";
import './Pages.css'

const ContentPages = ({}) => {
    return (<>
      <PublicViewContainer childComponent={<CustomerServicesComponent/>}/>
    </>);
};

export default ContentPages;
