import React from "react";

import {
    Box,
    Flex,
    Icon,
    Input,
    InputGroup,
    IconButton,
    Button,
    useDisclosure,
    Link,
    Collapse,
    Stack,
    HStack,
    Image,
    Text,
    Grid,
    GridItem,
    Spacer,
    VStack, Hide, Show, useMediaQuery
} from "@chakra-ui/react";
import {
    FaFacebook,
    FaTwitter,
    FaInstagram,
    FaWhatsapp,
    FaSearch,
} from "react-icons/fa";
import { MdDashboard, MdMessage } from "react-icons/md";
import { GiPhone } from "react-icons/gi";
import { GoChevronDown } from "react-icons/go";
import { FaUser } from "react-icons/fa";
import Logo from "./../../assets/LogoWithWhiteBG.png";
import { Login } from "../../pages/Login/components/Login";
import utils from "../../utils/commonFunctions.js";
import ProfileButton from "./ProfileButton.js";
import { CgMenuLeftAlt } from "react-icons/cg";
import { CustomMenuButton } from "./Portal/CustomMenu";
import { LuLogIn } from "react-icons/lu";
import { useDrawer } from "../../providers/NavBarSliderProvider";
import SideBar from "../HomeSideBar/SideBar";
import { FaLinkedinIn, FaXTwitter } from "react-icons/fa6";
import SearchBox from "../SearchBox/SearchBox";

const MenuLink = ({ label, url, children = [] }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <Box position="relative" onMouseEnter={onOpen} onMouseLeave={onClose} zIndex={1000}>
            <Link
                href={url}
                fontWeight="bold"
                _hover={{
                    textDecoration: "none",
                    bg: "#f5f5f7",
                    borderBottomColor: "blue.300",
                    borderBottomWidth: '2px',
                    position: "relative",
                    _after: {
                        content: '""',
                        position: "absolute",
                        width: "100%",
                        height: "2px",
                        bottom: 0,
                        left: 0,
                        bg: "#f5f5f7",
                        transition: "width 0.3s ease-in-out",
                    },
                }}
                _focus={{ boxShadow: "none" }}
                color="#3C4043"
                opacity={1}
                display="flex"
                alignItems="center"
                h="50px"
            >
                {label}
                {children.length > 0 && <Icon as={GoChevronDown} ml="2" />}
            </Link>
            {children.length > 0 && (
                <Collapse in={isOpen}>
                    <Box mt="0" bg="white" position="absolute" zIndex={10000} w="60" borderBottomRadius={'xl'}>
                        <Stack bg={'white'} borderBottomRadius={'xl'}>
                            {children.map((child, index) => (
                                <Link
                                    key={index}
                                    href={child.href}
                                    color="#3C4043"
                                    _hover={{
                                        textDecoration: "none",
                                        bg: "brand.700",
                                        color: "white",
                                        borderBottomColor: "brand.700",
                                    }}
                                    align="center"
                                    justify="center"
                                    borderBottomColor="transparent"
                                    bg="transparent"
                                    w="full"
                                    h="40px"
                                    pt={2}
                                    mb={-1}
                                    borderRadius={'sm'}
                                    fontSize="15px"
                                >
                                    {child.label}
                                </Link>
                            ))}
                        </Stack>
                    </Box>
                </Collapse>
            )}
        </Box>
    );
};

function LogoDropDown() {
    return (
    <Link href={"/"}>
        <Flex
            align="center"
            justify="center"
            bg="white"
            w={{ base: "80px", md: "120px", lg: "120px" }}
            h={{ base: "130px", md: "130px", lg: "130px" }}
            borderBottomRadius="30"
            pos="relative"
            top={{ base: '30px', md: "30px", lg: '30px' }}
            left={{ base: '0px', md: "0px", lg: '0px' }}
            zIndex={100}
            boxShadow="2xl"
        >
            <Image src={Logo} alt="Logo" w={{ base: "50px", md: "100px", lg: "90px" }} />
        </Flex>
        </Link>
    );
}

function OriginalHeader() {
    const { isOpen, openDrawer } = useDrawer();
    const [isLargerThanTablet] = useMediaQuery("(min-width: 993px) and (max-width: 1024px)");
    console.log("isLargerThanTablet", isLargerThanTablet);
    return (
        <>
            {/**Contacts Header */}
            <Hide below={'md'}>
                <Box bg="#A7A9AB" w="100%" h="50px">
                    <Flex align="center" h="100%" px="4">

                        <Hide below={'lg'}>
                            <Box pl={isLargerThanTablet ? "0" : { base: "20px" }}>
                                <LogoDropDown />
                            </Box>

                            <Box color="white" pl={{ base: "20" }}>
                                <HStack>

                                    <Icon as={GiPhone} boxSize={5} />
                                    <Text>0800 555 555 /</Text>
                                    <Icon as={MdMessage} />
                                    <Text>SMS16299</Text>
                                </HStack>
                            </Box>
                        </Hide>


                        <Flex justifyContent="center" flexGrow={1} pr="16">
                            {[{ icon: FaFacebook, link: 'https://www.facebook.com/waterutilities' },
                            { icon: FaXTwitter, link: 'https://x.com/_wuc?lang=en' },
                            { icon: FaWhatsapp, link: 'https://wa.me/26773042819' },
                            { icon: FaLinkedinIn, link: 'https://www.linkedin.com/company/water-utilities-corporation/' }
                            ].map(
                                (SocialIcon, index) => (
                                    <Button
                                        key={index}
                                        rounded="full"
                                        w={{ base: "20px", md: "40px", lg: "40px" }}
                                        h={{ base: "20px", md: "40px", lg: "40px" }}
                                        bg="brand.700"
                                        ml={index ? 2 : 0}
                                        _hover={{ bg: "brand.500" }}
                                    >
                                        <Link href={SocialIcon.link}>
                                            <Icon as={SocialIcon.icon} color="white" />
                                        </Link>

                                    </Button>
                                )
                            )}
                        </Flex>
                        <SearchBox />
                    </Flex>
                </Box>

            </Hide>
            {/**Navigation bar */}
            <Grid
                w="100%"
                align="center"
                alignItems="center"
                justify="start"
                pl={isLargerThanTablet ? {lg:"150px"} : { lg: "300px"}}
                py={{base: 4, lg: 0}}
                px={{base: 4}}
                templateColumns="repeat(12, 1fr)"
                bg={'white'}
            >
                <Hide below={'lg'}>
                    <GridItem colSpan={11}>
                        <HStack spacing={8} align="center">
                            <MenuLink label="Home" url='/' />
                            <MenuLink label="About WUC" url='/about' />
                            <MenuLink label="Customer Service" url='/customer-services' />
                            <MenuLink label="Careers" url='https://careers.wuc.bw/' />
                            <MenuLink label="Tenders" url='/tenders' />
                            <MenuLink
                                label="Media"
                                children={[
                                    {label: "Events", href: "/media/events"},
                                    {label: "Pictures", href: "/media/pictures"},
                                    {label: "Videos", href: "/media/videos"},

                                ]}
                            />
                            <MenuLink label="Projects" url='/projects' />
                            <MenuLink label="Contact Us" url='/contact-us' />
                        </HStack>
                    </GridItem>
                </Hide>


                <GridItem colSpan={{ base: 12, lg: 1 }} py={{ base: 2, lg: 0 }}  >
                    <Show below={'lg'}>
                        <Flex w={"full"} h="full" align="center">
                            <Flex w={"full"} h="full" display={{ base: 'flex' }} align="center">
                                <IconButton
                                    bg={'transparent'}
                                    aria-label='Open sidebar'
                                    icon={<CgMenuLeftAlt />}
                                    color={'blue.800'}
                                    fontSize={'24px'}
                                    alignSelf="center"
                                    alignItems="center"
                                    justifyContent="center"
                                    mx={'4'}
                                    onClick={openDrawer}
                                />
                                <Link href={'/'} textDecoration={'none'}>
                                <Flex alignItems="center" flexDirection="row">
                                <Image src={Logo} alt="Logo" w={{ base: "50px", md: "60px"}} />
                                <Text color={'blue.800'} fontWeight={'bold'} display={{ base: 'none', md: 'flex' }}  fontSize={'24px'} ml={8}> WUC</Text>
                                </Flex>
                                </Link>

                            </Flex>

                            <Spacer />
                            <Flex gap={2} align="center" justifyContent="end">

                                {utils.isAuthenticated() ? (
                                    <>

                                        <CustomMenuButton icon={MdDashboard} color={'brand.700'} bg={'white'} tip={'Self Service Portal'}
                                            url={'/self-service-portal/dashboard'} />

                                        <Flex display={{ base: 'flex' }} >
                                            <ProfileButton utils={utils} />
                                        </Flex>
                                    </>
                                ) : (
                                    <Flex display={{ base: 'flex' }}>
                                        <CustomMenuButton icon={LuLogIn} color={'brand.700'} bg={'white'}
                                            tip={'Login'} url={'/login'} />

                                    </Flex>
                                )}
                            </Flex>

                        </Flex>
                    </Show>
                    <Hide below={'lg'}>
                        {!utils.isAuthenticated() ? (
                            <Flex w={"full"} h="full" justifyContent="end">
                                <Hide below={'md'}>
                                    <Link href="/login">
                                        <Button
                                            bg="brand.700"
                                            color="white"
                                            _hover={{
                                                bg: "brand.500",
                                                color: "white",
                                            }}
                                            borderRadius="full"
                                            ml={{ base: 0, lg: 20 }}
                                            leftIcon={<FaUser />}
                                        >
                                            Login
                                        </Button>
                                    </Link>
                                </Hide>


                            </Flex>
                        ) : (
                            <Flex w={"full"} h="full" align="center" justifyContent="end">
                                <VStack color="brand.700" mr={8}>
                                    <Link href={"/self-service-portal"} textDecoration={"none"}>
                                        <Text fontWeight={"semibold"}>Dashboard</Text>
                                    </Link>
                                </VStack>

                                <Spacer />
                                <ProfileButton utils={utils} />
                            </Flex>
                        )}
                    </Hide>
                </GridItem>
            </Grid>
            {isOpen && <SideBar />}

        </>
    );
}

export default OriginalHeader;
