import React from "react";
import {
    Flex,
    useToast,
    Grid,
    GridItem,
    Spacer,
    IconButton,
    Text,
} from "@chakra-ui/react";
import utils from "../../../utils/commonFunctions.js";
import ProfileButton from "../ProfileButton.js";
import CustomBreadcrumb from "../../CustomBreadCrumb/CustomBreadCrumb";
import { AiFillHome } from "react-icons/ai";
import { MdNotifications } from "react-icons/md";
import { LuLogOut } from "react-icons/lu";
import { CustomMenu, CustomMenuButton } from "./CustomMenu";
import { CustomThemedToast } from "../../../utils/customChakraToasts";
import { CgMenuLeftAlt } from "react-icons/cg";
import { useDrawer } from '../../../providers/NavBarSliderProvider';
import { useNavigate } from "react-router-dom";

function Header({ onOpen }) {
    const { openDrawer } = useDrawer();
    const navigate = useNavigate();
    const toast = useToast();
    const { addThemedToast } = CustomThemedToast();

    const handleLogout = () => {
        toast.closeAll();
        addThemedToast({
            message: "Logging you out...",
            variant: "lightBlue",
        });
        setTimeout(() => {
            utils.logout();
            navigate('/login');
            toast.closeAll();
        }, 1500);
    };

    return (
        <>
            {/**Navigation bar */}
            <Grid
                w="100%"
                alignItems="center"
                justify="start"
                templateColumns="repeat(12, 1fr)"
                bg={"transparent"}
                borderRadius={'2xl'}
                gap={{ base: 4, md:4, lg: 0 }}
            >
                <GridItem colSpan={[12, 12, 12, 11]} order={{ base: 2, sm: 2, md: 2, lg: 1 }}>
                    <CustomBreadcrumb base={"Self Service Portal"} />
                </GridItem>

                <GridItem colSpan={[12, 12, 12, 1]} order={{ base: 1, sm: 1, md: 1, lg: 2 }} bg={'white'} p="10px" borderRadius="30px" boxShadow={'lg'} h={'full'}>
                    <Flex w={"full"} h="full" align="center">
                        <Flex w={"full"} h="full" display={{ base: 'flex', md: 'flex', lg: 'none' }} align="center">
                            <IconButton
                                bg={'transparent'}
                                aria-label='Open sidebar'
                                icon={<CgMenuLeftAlt />}
                                color={'blue.800'}
                                fontSize={'24px'}
                                alignSelf="center"
                                alignItems="center"
                                justifyContent="center"
                                mx={'4'}
                                onClick={openDrawer}
                            />
                            <Text color={'blue.800'} fontWeight={'bold'} display={{ base: 'flex', md: 'flex', lg: 'none' }} fontSize={'24px'}>WUC</Text>
                        </Flex>

                        <Spacer />
                        <Flex gap={2} align="center" justifyContent="end">
                            <CustomMenuButton icon={AiFillHome} color={'brand.500'} bg={'white'} tip={'Home'} url={'/'} />
                            <CustomMenu icon={MdNotifications} color={'brand.500'} bg={'white'} tip={'Notifications'} />
                            <Flex display={{ base: 'none', md:'none', lg: 'flex' }}>
                                <CustomMenuButton onClick={handleLogout} icon={LuLogOut} color={'red.400'} bg={'white'} tip={'Logout'} />
                            </Flex>
                            <ProfileButton utils={utils} />
                        </Flex>
                    </Flex>
                </GridItem>
            </Grid>
        </>
    );
}

export default Header;
