import React from "react";


import {
  Box,
  Flex,
  Icon,

  Button,
  useDisclosure,
  Link,
  Collapse,
  Stack,
  HStack,
  Image,
  Text,
  Grid,
  GridItem,
  Spacer,

  VStack,
} from "@chakra-ui/react";


import { GoChevronDown } from "react-icons/go";
import { FaUser } from "react-icons/fa";
import Logo from "./../../assets/LogoWithWhiteBG.png";
import { Login } from "../../pages/Login/components/Login";
import utils from "../../utils/commonFunctions.js";
import ProfileButton from "./ProfileButton.js";

const MenuLink = ({ label,url, children = [] }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box position="relative" onMouseEnter={onOpen} onMouseLeave={onClose}>
      <Link
        href={url}
        fontWeight="bold"
        _hover={{
          textDecoration: "none",
          bg: "#f5f5f7",
          borderBottomColor:"blue.300",
          borderBottomWidth:'2px',
          position: "relative",
          _after: {
            content: '""',
            position: "absolute",
            width: "100%",
            height: "2px",
            bottom: 0,
            left: 0,
            bg: "#f5f5f7",
            transition: "width 0.3s ease-in-out",
          },
        }}
        _focus={{ boxShadow: "none" }}
        color="#3C4043"
        opacity={1}
        display="flex"
        alignItems="center"
        h="50px"
      >
        {label}
        {children.length > 0 && <Icon as={GoChevronDown} ml="2" />}
      </Link>
      {children.length > 0 && (
        <Collapse in={isOpen}>
          <Box mt="0" bg="white" position="absolute" zIndex={10000} w="60">
            <Stack bg={'white'}>
              {children.map((child, index) => (
                <Link
                  key={index}
                  href={child.href}
                  color="#3C4043"
                  _hover={{
                    textDecoration: "none",
                    bg: "brand.700",
                    color: "white",
                    borderBottomColor: "brand.700",
                  }}
                  align="center"
                  justify="center"
                  borderBottomColor="transparent"
                  bg="gray.100"
                  w="full"
                  h="40px"
                  pt={2}
                  mb={-1}
                  borderRadius={'sm'}
                  fontSize="15px"
                >
                  {child.label}
                </Link>
              ))}
            </Stack>
          </Box>
        </Collapse>
      )}
    </Box>
  );
};

function LogoDropDown() {
  return (
  <Link href={"/"}>
    <Flex
      align="center"
      justify="center"
      bg="white"
      w="80px"
      h="80px"
      borderRadius="30"
      pos="relative"

      zIndex={100}

    >
      <Image src={Logo} alt="Logo" w="120px" />
    </Flex>
    </Link>
  );
}

function Header() {

  return (
    <>
      {/**Navigation bar */}
      <Grid
        w="100%"
        align="center"
        alignItems="center"
        justify="start"
py={4}
        px="20"
        bg={'white'}
        templateColumns="repeat(12, 1fr)"
      >
          <GridItem colSpan={2}>
             <LogoDropDown/>
          </GridItem>
        <GridItem colSpan={9}>
          <HStack spacing={8} align="center">
            <MenuLink label="Home" url='/'/>
            <MenuLink label="About WUC" url='/about'/>
            <MenuLink label="Customer Service" url='/customer-services'/>
            <MenuLink label="Careers" url='https://careers.wuc.bw/'/>
            <MenuLink label="Tenders" url='/tenders'/>
            <MenuLink
              label="Media"
              children={[
                { label: "Events", href: "/media/events" },
                { label: "Pictures", href: "/media/pictures" },
                { label: "Videos", href: "/media/videos" }
              ]}
            />
            <MenuLink label="Projects"  url='/projects'/>
            <MenuLink label="Contact Us" url='/contact-us'/>
          </HStack>
        </GridItem>

        <GridItem colSpan={1}>
          {!utils.isAuthenticated() ? (
            <Flex w={"full"} h="full" justifyContent="end">
              <Link href="/login">
                <Button
                  bg="brand.700"
                  color="white"
                  _hover={{
                    bg: "brand.500",
                    color: "white",
                  }}
                  borderRadius="full"
                  ml={20}
                  leftIcon={<FaUser />}
                >
                  Login
                </Button>
              </Link>
            </Flex>
          ) : (
            <Flex w={"full"} h="full" align="center" justifyContent="end">
              <VStack color="brand.700" mr={8}>
                <Link href={"/self-service-portal"} textDecoration={"none"}>
                  <Text fontWeight={"semibold"}>Dashboard</Text>
                </Link>
              </VStack>
              <Spacer />
              <ProfileButton utils={utils} />
            </Flex>
          )}
        </GridItem>
      </Grid>
    </>
  );
}

export default Header;
