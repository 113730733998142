import React, { useState, useEffect, useRef } from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import IntersectionObserverHeader from "./IntersectionObserverHeader";
import OriginalHeader from "./OriginalHeader";

const Header = () => {
  const [showAlternateHeader, setShowAlternateHeader] = useState(false);
  const headerRef = useRef(null);
  const isLargeScreen = useBreakpointValue({ base: false, md: false, lg: true });
  const isSmallScreen = useBreakpointValue({ base: true,md:true, lg: false });

  useEffect(() => {
    if (!isLargeScreen) {
      // On smaller devices, always show the original header
      setShowAlternateHeader(false);
      return;
    }

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setShowAlternateHeader(!entry.isIntersecting);
      });
    }, options);

    if (headerRef.current) {
      observer.observe(headerRef.current);
    }

    return () => {
      if (headerRef.current) {
        observer.unobserve(headerRef.current);
      }
    };
  }, [isLargeScreen]);

  return (
      <Box position="relative" bg={'red.600'}>
        <motion.div
            className="original-header"
            ref={headerRef}
            initial={{ opacity: 1 }}
            animate={{ opacity: showAlternateHeader ? 0 : 1 }}
            transition={{ duration: 0.3 }}
            style={!isLargeScreen ? { position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 1000 } : { zIndex: 1000 }}
        >
          <OriginalHeader />
        </motion.div>
        {isLargeScreen && showAlternateHeader&& (
            <motion.div
                className="alternate-header"
                initial={{ opacity: 0 }}
                animate={{ opacity: showAlternateHeader ? 1 : 0 }}
                transition={{ duration: 0.3 }}
                style={{ position: 'fixed', top:0, left: 0, width: '100%', zIndex: 1000 }}
            >
              <IntersectionObserverHeader />
            </motion.div>
        )}
      </Box>
  );
};

export default Header;
