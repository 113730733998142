import {
    Text,
    Modal, ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
} from "@chakra-ui/react";

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/skyblue';
import React, { useEffect, useState } from "react";
import {
    Box, Flex, Image, useColorModeValue
} from "@chakra-ui/react";
import Picture from "./Picture";
import { usePhoto } from "../../../../providers/PhotosProvider";
import '@splidejs/react-splide/css';

const PictureList = ({ PicturesArr }) => {
    const trackColor = useColorModeValue("gray.200", "gray.700");
    const { photo, setPhoto } = usePhoto();
    const [picture, setPicture] = useState(PicturesArr[photo]?.url);

    useEffect(() => {
        setPicture(PicturesArr[photo]?.url);
    }, [photo, PicturesArr]);

    const trackPhotoId = (id) => {
        setPhoto(id);
    };

    return (
        <Flex justify="center" align="center" bg="white" py={{ base: 8 }} px={{ base: 0, md: 8 }} w="full">
            <Box w="full">
                <Flex bg={trackColor} w="full" justify="center" align="center"
                      boxShadow="md" borderRadius="2xl">
                    <Image borderRadius="2xl" w="full" maxH={{ base: '300px', md: '500px' }} objectFit="contain"
                           src={picture} alt="Selected" />
                </Flex>
                <Flex justify="center" width="full" mt={8}>
                    <Splide
                        options={{
                            type: 'slide',
                            perPage: 3,
                            arrows: true,
                            pagination: false,
                            drag: 'free',
                            gap: '1rem',
                            breakpoints: {
                                768: {
                                    perPage: 1,
                                },
                                1024: {
                                    perPage: 2,
                                },
                            },
                        }}
                    >
                        {PicturesArr?.map((pic, index) => (
                            <SplideSlide key={index} onClick={() => trackPhotoId(index)}>
                                <Picture name={pic.caption} image={pic.FileUrl} />
                            </SplideSlide>
                        ))}
                    </Splide>
                </Flex>
            </Box>
        </Flex>
    );
};

const PhotoViewer = ({ PicturesArr, isModalOpen, onClose }) => {
    return (
        <Modal closeOnOverlayClick={true} size="xl" isOpen={isModalOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton position="absolute" top="1rem" right="1rem" zIndex="2" />
                <ModalBody p={4} overflow="hidden">
                    {PicturesArr.length > 0 ? (
                        <Splide
                            options={{
                                type: 'fade',
                                rewind: true,
                                width: '100%',
                                pagination: true,
                                arrows: true,
                                perPage: 1,
                                perMove: 1,
                                gap: '1rem'
                            }}
                        >
                            {PicturesArr.map((picture, index) => (
                                <SplideSlide key={index}>
                                    <Box height="500px" display="flex" alignItems="center" justifyContent="center">
                                        <img src={picture.FileUrl} alt={`Image ${index}`} style={{ width: '100%', height: 'auto', maxHeight: '500px', objectFit: 'contain' }} />
                                    </Box>
                                </SplideSlide>
                            ))}
                        </Splide>
                    ) : (
                        <Text fontSize="lg" p={5} textAlign="center" color="gray.400">
                            No images in this gallery.
                        </Text>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};


export default PhotoViewer;
