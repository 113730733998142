import { Button } from "@chakra-ui/button";
import { Icon } from "@chakra-ui/icon";
import { Box, Text } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/modal";
import React, {useState} from "react";
import {FaCircleCheck, FaCircleExclamation, FaCircleXmark} from "react-icons/fa6";
import { Link } from "react-router-dom";
import utils from "../../../../utils/commonFunctions";
import {ButtonGroup, useDisclosure} from "@chakra-ui/react";

const CustomModal = ({ isOpen, onClose, type,callback }) => {
  function handleClose() {
   onClose()
  }

  function handleCallback() {
    callback()
    onClose()
  }
  const renderContent = () => {
    switch (type) {
      case 'submit':
        return {
          icon: FaCircleExclamation,
          iconColor: "brand.500",
          borderColor: "blue.50",
          message: "You are about to submit quotation request.Are you sure want to proceed with this action?",
          button: (
              <ButtonGroup>
                <Button
                    variant="ghost"
                    color="gray.600"
                    borderColor="green.300"
                    _hover={{ bg: "gray.100", color: "gray.600" }}
                    onClick={() =>  handleClose()}
                >
                  Not now
                </Button>
                <Button
                    variant="solid"
                    bg='brand.500'
                    color="white"
                    borderColor="brand.500"
                    _hover={{ bg: "brand.500", color: "white" }}
                    onClick={() =>  handleCallback()}
                >
                  Yes, proceed
                </Button>
              </ButtonGroup>
          ),
        };
      case 'cancel':
        return {
          icon: FaCircleXmark,
          iconColor: "red.500",
          borderColor: "red.300",
          message: "You are about to cancel your changes and they will be lost.",
          button: (
              <ButtonGroup>
                <Button
                    variant="ghost"
                    color="gray.600"
                    borderColor="red.300"
                    _hover={{ bg: "gray.100", color: "gray.600" }}
                    onClick={() =>  handleClose()}
                >
                  Not now
                </Button>
                <Button
                    variant="solid"
                    bg='red.500'
                    color="white"
                    borderColor="red.300"
                    _hover={{ bg: "red.500", color: "white" }}
                    onClick={() =>  handleCallback()}
                >
                  Cancel changes
                </Button>
              </ButtonGroup>
          ),
        };
      case 'accept':
        return {
          icon: FaCircleCheck,
          iconColor: "green.500",
          borderColor: "green.300",
          message: "You are about to accept this quotation.You are sure you want to proceed?",
          button: (
              <ButtonGroup>
                <Button
                    variant="ghost"
                    color="gray.600"
                    borderColor="green.300"
                    _hover={{ bg: "gray.100", color: "gray.600" }}
                    onClick={() =>  handleClose()}
                >
                  Not now
                </Button>
                <Button
                    variant="solid"
                    bg='green.500'
                    color="white"
                    borderColor="green.300"
                    _hover={{ bg: "green.500", color: "white" }}
                    onClick={() =>  handleCallback()}
                >
                  Yes, accept
                </Button>
              </ButtonGroup>
          ),
        };
      case 'reject':
        return {
          icon: FaCircleXmark,
          iconColor: "red.500",
          borderColor: "red.300",
          message: "You are about to reject this quotation.You are sure you want to proceed?",
          button: (
              <ButtonGroup>
                <Button
                    variant="ghost"
                    color="gray.600"
                    borderColor="red.300"
                    _hover={{ bg: "gray.100", color: "gray.600" }}
                    onClick={() =>  handleClose()}
                >
                  Not now
                </Button>
                <Button
                    variant="solid"
                    bg='red.500'
                    color="white"
                    borderColor="red.300"
                    _hover={{ bg: "red.500", color: "white" }}
                    onClick={() =>  handleCallback()}
                >
                  Yes, reject
                </Button>
              </ButtonGroup>
          ),
        };
      default:
        return null;
    }
  };

  const content = renderContent();

  return (
      <>
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleClose} isCentered={true} zIndex={10000}>
          <ModalOverlay />
          <ModalContent p={4} borderRadius={"xl"} zIndex={10000}>
            <ModalBody d={'flex'} justifyContent={'center'} align={'center'}>
              <Box w={'full'} h={'full'}>
                <Box >
                  <Icon as={content.icon} boxSize={{ base: "16", md: "24" }} borderWidth={8} borderRadius={'full'} borderColor={content.borderColor} color={content.iconColor} />
                </Box>
                <Text my={2} fontSize={"20px"}>
                  {content.message}
                </Text>
              </Box>
            </ModalBody>
            <ModalFooter display={"flex"} justifyContent={"end"}>
              {content.button}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
  );
};

export default CustomModal;
