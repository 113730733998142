import React, { useState } from "react";
import {
    Box, Heading, Flex, Text
} from "@chakra-ui/react";
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import {AddIcon, StarIcon} from "@chakra-ui/icons";

const allEvents = [

    { color:'pink', date: "April-December 2021", contents: [
            "A house for a less privileged family in Maun",
            "Printing toners for Gakuto Primary School",
            "Food hampers in Masunga",
        "Food hampers in Lobatse","Collaboration with Central District Council to donate house window glasses."
            ,"Branded dustbins, Preschool chairs, and Tables to Karakubis Primary School",
            "Bus Shelter: Tsetseng village",
            "Automatic hand sanitizers",
            "Two air conditioners to Palapye and Maunatlala Primary School."
        ] },


    { color:'blue', date: "January-April 2022", contents: [
            "Bus Shelters: Kanye",
            "Bus Shelters: Palapye",
            "Bus Shelters: Mochudi, Kang, Makopong, Tsesebe, Makaleng, Serowe, Tonota, Masunga",
            "Donation of 3 refurbished branded bus shelters at Mabalane and Ramonaka villages",
            "Serowe Service Centre donated 159 shoes to schools in Malatswai, Mmashoro and Dimajwe villages",
            "Handover: 2 bus shelter in Letlhakane Village",
            "Donation of sanitisers, bowls and buckets to Mosu and Mmea Primary Schools"
        ] },
    {color:'orange', date: "May 2022", contents: [
            "Handover: Kgotla shelter at Maun- Matshwane ward",
            "Handover: Bus shelters at Chanoga, Samochima, and Shakawe",
            "Sponsorship of Mohembo Bridge Walk - Shakawe",
            "Handover: Renovated and refurbished Mahalapye Showground and food hampers to Mahalapye community by China State Construction & Engineering Corp",
            "Handover: 2 bus shelters in Bobobong and Selebi Phikwe",
            "Handover: multipurpose printer, IT equipment and pre-school chairs and tables to Letsibogo Primary School at Mmadinare",
            "Handover: Bus shelter at Digawana and Goodhope",
            "Handover: Bus shelter at Serowe",
            "Sponsorship of Mohembo Bridge Walk – Shakawe",
            "Diacore Marathon",
            "Selibe Phikwe Orange Marathon",
            "Thune Marathon"
        ] },
    {color:'blue', date: "June 2022", contents: [
            "Handover: Bus shelters at Kasane and Mabele Villages",
            "Fixing a Leak at Pelaelo Secondary School in Makaleng",
            "Handover: Toiletry packs to Tlhalogang JSS at Chadibe",
            "Handover: Bus shelter at Mahalapye"
        ] },
    {color:'pink', date: "September-July 2022", contents: [
            "Donation: Printer, copier, and scanner to Monong Primary School at Hukuntsi",
            "Donation: Bus shelter at Palapye",
            "Major sponsorship: Ghanzi Show",
            "Donation: School shoes and toiletry to Gudigwa Primary School",
            "Major sponsor of Ghanzi Show",
            "Donation: 300 bottles of water to Nico United Football Club in Phikwe and 20 tickets for the team’s fundraising dinner.",
            "300 bottles of water to Nico United Football Club in Phikwe and 20 tickets for the team’s fundraising dinner."
        ] },

];

const TimelineElement = ({date,rest,contents,color}) => {
    return (
        <VerticalTimelineElement
            {...rest}
            date={date}
            contentStyle={{ background: 'transparent', color: 'gray' }}
            iconStyle={
                {
                    background: '#0093D0',
                    color: '#fff',
                    border:'#0093D0'
                }
            }
            icon={<StarIcon boxSize="2px" />}
        >
            <Box bg={`${color}.50`} borderRadius={'2xl'} p={4}>
                {contents.map((content)=>(
                    <Box  py={2} px={4} borderRadius={'xl'}>
                        <Text fontWeight={'thin'} borderLeftColor={`${color}.500`} borderLeftWidth={'3px'} px={2}  color={'facebook.800'}>{content}</Text>
                    </Box>

                ))}
            </Box>

        </VerticalTimelineElement>
    )
}

const Timeline = () => {
    const [visibleEvents, setVisibleEvents] = useState(allEvents.slice(0, 4)); // Initially showing 2 events
    const [loadMore, setLoadMore] = useState(true);

    const handleLoadMore = () => {
        const currentLength = visibleEvents.length;
        const moreEvents = allEvents.slice(currentLength, currentLength + 2);
        setVisibleEvents(visibleEvents.concat(moreEvents));
        if (visibleEvents.length + moreEvents.length >= allEvents.length) {
            setLoadMore(false); // Hide the button when all events are displayed
        }
    };

    return (
        <>
            <VerticalTimeline lineColor={"#0093D0"}>
                {visibleEvents.map((event, index) => (
                    <TimelineElement key={index} color={event.color} date={event.date} contents={event.contents} />
                ))}
                {loadMore && (
                    <VerticalTimelineElement
                        iconStyle={
                            {
                                background: '#0093D0',
                                color: '#fff'
                            }
                        }
                        icon={<AddIcon />}
                        iconOnClick={handleLoadMore}
                    >

                    </VerticalTimelineElement>

                )}


            </VerticalTimeline>

        </>
    );
};

const Societies = () => {
    return (
        <Flex justify={'center'} align={'center'} bg={'gray.50'} w={'full'} p={8}>
            <Box w={'full'}>
                <Heading size="xs" mb={2} textAlign={'center'} color={'blue.800'}>Social</Heading>
                <Heading size="lg" mb={4} textAlign={'center'} color={'blue.800'}>
                    Corporate Social <Box as={'span'} color={'#0093D0'}> Initiatives</Box>
                </Heading>
                <Text size="sm" fontWeight={'medium'} px={{ base: 8, lg: 32 }} mb={4} textAlign={'center'} color={'blue.800'} textTransform={'capitalize'}>
                    CORPORATE SOCIAL RESPONSIBILITY PROGRAMME IMPLEMENTATION GUIDELINES 2021
                </Text>
                <Timeline />
            </Box>
        </Flex>
    );
};

export default Societies;
