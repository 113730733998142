import {
    Input,
    Stack,
    Button,
    FormLabel,
    FormControl,
    InputGroup,
    useToast,
    Select, HStack, Text, Switch,

} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";

import {useNavigate} from "react-router-dom";
import {PropagateLoader} from "react-spinners";
import {CustomGeneralToast} from "../../../utils/customChakraToasts";
import {API} from "../../../utils/api";
import BillAlert from "../../../components/PortalContainer/BillAlert";
import utils from "../../../utils/commonFunctions";
import {useUserProfile} from "../../../providers/UserProfileProvider";
import {useQuoteCart} from "../../../providers/QuoteCartProvider";

const SubmitMeterReadingForm = () => {
    const userId=utils.getUserId()
    const {userProfile} = useUserProfile()
    const {customerNumber} = userProfile || {}

    const [number, setNumber] = useState(customerNumber);
    const [utility, setUtility] = useState("");
    const [utilityContracts, setUtilityContracts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [checkForOthers, setCheckForOthers] = useState(false);
    const [success, setSuccess] = useState(false);
    const {addGeneralToast} = CustomGeneralToast();
    const toast = useToast();
    const {refreshFlag,setRefreshFlag}=useQuoteCart()


    useEffect(() => {
        const getUtilityContracts = async () => {
            setUtilityContracts(await utils.getUtilityContractsByCustomerNumber(customerNumber))
        };
        if(!checkForOthers&&customerNumber!==""){ getUtilityContracts()}
    }, []);
    useEffect(() => {
        if(success){
            setNumber("")
            setUtility("")
        }

    }, [success]);
    const requestBill= async () => {
        if (number === "" || utility === "") {
            toast.closeAll();
            addGeneralToast({
                message: "All fields must be filled in",
                type: "warning",
            });
            setLoading(false);
            return;
        }
        setLoading(true);
        try {
            const response = await API.post(
                `/meter/monthly-bill`,
                {
                    customerNumber: number,
                    contractNumber: utility,
                    createdBy: userId,
                }
            );

            const data = await response.data;
            if (response.status === 200) {
                toast.closeAll();
                addGeneralToast({
                    message: data.message,
                    type: "success",
                });
                setLoading(false);
                setSuccess(true)
                setRefreshFlag(true)
            } else {
                toast.closeAll();
                addGeneralToast({
                    message: data.message,
                    type: "error",
                });
                setLoading(false);

            }
        } catch (e) {
            setLoading(false);
            toast.closeAll();
            console.log(e)
            addGeneralToast({
                message: e?.message? e?.message : e.statusText,
                type: "error",
            });
        }

    };
    return <>

        <Stack spacing={1} justify={'center'} w="full" h={"full"} px={4}>
            <BillAlert/>
            <FormControl isRequired id="customerNumber">
                <FormLabel>Customer Number</FormLabel>
                <Input
                    isRequired
                    type="number"
                    value={!utils.isAuthenticated() || checkForOthers ? undefined : number}
                    onChange={(e) => setNumber(e.target.value)}
                    isDisabled={!utils.isAuthenticated() || !checkForOthers}
                />
            </FormControl>

            <FormControl isRequired id="contractNumber">
                <FormLabel>Utility Number</FormLabel>
                <>
                    {!utils.isAuthenticated()|| checkForOthers ? (
                        <Input
                            isRequired
                            type="number"
                            value={utility}
                            onChange={(e) => setUtility(e.target.value)}
                        />
                    ) : (
                        <Select
                            isRequired
                            placeholder="Select Utility"
                            onChange={(e) => setUtility(e.target.value)}
                        >
                            {utilityContracts?.length > 0 && utilityContracts.map((contract) => (
                                <option key={contract?.contractNumber} value={contract?.contractNumber}>
                                    {contract?.contractNumber}
                                </option>
                            ))}

                        </Select>
                    )}

                </>
            </FormControl>

            <Stack spacing={6} mt={2}>
                {loading ? (
                    <Button variant={'solid'}>
                        <PropagateLoader size={8} color={'white'}/>
                    </Button>
                ) : (
                    <Button
                        type="submit"
                        variant="solid"
                        onClick={requestBill}
                    >
                        Request Monthly Bill
                    </Button>
                )}
            </Stack>
            {utils.isAuthenticated()&&
                <HStack mt={2} justifyContent="center" alignItems="center">
                    <Text color={"blue.800"}>Check Bill For Others</Text>
                    <Switch id='bill-for-others' onChange={(e) => setCheckForOthers(e.target.checked)}/>
                </HStack>
            }

        </Stack>


    </>;
};

export default SubmitMeterReadingForm;
