import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Image,
  Text,
  VStack,
  Heading,
} from '@chakra-ui/react';

// Import local images
import image1 from './../../assets/D2 12.jpg';
import utils from "../../utils/commonFunctions";

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slides,setSlides] = useState([{
      imageKey: image1,
      title: '',
      description: '',
    }])
      useEffect(()=>{
        const fetchBanners =async ()=>{
          const response = await utils.fetchBanners({published:true, position:"Slide" });
          if(response&&response.length)
          setSlides(response);
        }
        fetchBanners()
      },[])



  const slidesCount = slides.length;

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === slidesCount - 1 ? 0 : prevSlide + 1
      );
    }, 5000); // Change slide every 3000 ms (5 seconds)

    return () => clearInterval(timer); // Clear interval on component unmount
  }, [slidesCount]);

  const setSlide = (slide) => {
    setCurrentSlide(slide);
  };

  const carouselStyle = {
    transition: 'all .5s',
    ml: `-${currentSlide * 100}%`,
  };
  return (
    <Flex w='full' alignItems='center' justifyContent='center'>
      <Flex w='full' pos='relative' overflow='hidden'>
        <Flex h='450px' w='full' {...carouselStyle}>
          {slides.map((slide, sid) => (
            <Box key={`slide-${sid}`} boxSize='full' shadow='md' flex='none'>
              <Image
                src={slide.imageKey}
                alt={slide.title}
                boxSize='full'
                objectFit='cover'
                filter={slide?.hasText&&'blur(3px)'}
              />
              {slide?.hasText&&
              <VStack
                p='8px 12px'
                pos='absolute'
                top='150px'
                textAlign='center'
                w='full'
                mb='8'
                color='white'
                spacing={4}
              >
                <Heading as='h1' size='3xl'>
                  {slide.title}
                </Heading>
                <Box maxHeight='300px' maxW='650px'>
                  <Text fontSize='18px'>
                    <div dangerouslySetInnerHTML={utils.sanitizeHtml(slide?.description)}/>
                  </Text>
                </Box>

              </VStack>
              }
            </Box>
          ))}
        </Flex>
        <Flex justify='center' pos='absolute' bottom='8px' w='full' mb={4}>
          {Array.from({ length: slidesCount }).map((_, slide) => (
            <Box
              key={`line-${slide}`}
              cursor='pointer'
              h='5px'
              w='40px'
              borderRadius={10}
              mx='2'
              bg={currentSlide === slide ? '#0093d0' : 'white'}
              onClick={() => setSlide(slide)}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Carousel;
