import React, {useEffect, useState, useMemo} from 'react';
import {
  Flex,
  Stack,
  SimpleGrid,
  chakra,
  useColorModeValue,
  Box,
  Icon, Heading, useMediaQuery
} from '@chakra-ui/react';
import Chart from 'react-apexcharts';
import {
  FaCheckCircle,
  FaTimesCircle,
  FaHourglassHalf,
  FaBan,
  FaUndoAlt,
} from 'react-icons/fa';

const Tables = (userPayment) => {

  // Function to return status icon or element
  const StatusIcon = ({ statusName }) => {
    switch (statusName) {
      case 'Approved':
        return <Icon as={FaCheckCircle} color='green.500' />;
      case 'Declined':
      case 'User Cancelled':
        return <Icon as={FaTimesCircle} color='red.500' />;
      case 'Cancelled':
        return <Icon as={FaBan} color='orange.500' />;
      case 'Not Done':
      case 'Received by PayGate':
        return <Icon as={FaHourglassHalf} color='yellow.300' />;
      case 'Settlement Voided':
        return <Icon as={FaUndoAlt} color='blue.500' />;
      default:
        return <></>;
    }
  };
  const [isLargerThanTabletSmallerThanLargerScreen] = useMediaQuery("(min-width: 769px) and (max-width: 1024px)");

  const [payment, setPayment] = useState([0,0,0,0,0,0,0,0,0,0,0,0]);

  const months = useMemo(() => ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'], []);

  // color modes for Chakra UI
  const bg = useColorModeValue('white', 'gray.800');
  const bg2 = useColorModeValue('white', 'gray.800');
  const bg3 = useColorModeValue('gray.100', 'gray.700');

  // Chart data and options for outstanding bills
  const outStandingBillOptions = {
    chart: { id: 'outstanding-bills' },
    xaxis: {
      categories: months,
    },
    colors: ['#2BBCF2'],
  };
  const outStandingBillSeries = [
    {
      name: 'Outstanding Bill (In pula)',
      data: payment,
    },
  ];

  // Chart data and options for client payments
  const paymentsMadeLogOptions = {
    chart: { id: 'payments-made-reports' },
    xaxis: {
      categories: months,
    },
    colors: ['#2BBCF2'],
  };
  const paymentsMadeLog = [
    {
      name: 'Payment History (In Pula)',
      data: payment,
    },
  ];

  console.log(userPayment.userPayment);
  function formatDate(dateString) {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options).replace(',', '');
  }

  function formatTime(dateString) {
    const date = new Date(dateString);
    return date.toTimeString().split(' ')[0];
  }

  const getMonthlyPaymentCount = (data) => {
    const paymentCounts = new Array(12).fill(0);

    data?.forEach(item => {
      const createdDate = new Date(item.createdDate);
      const month = createdDate.getMonth();
      paymentCounts[month]++;
    });

    setPayment(paymentCounts);
  }

  useEffect(() => {
    if (userPayment?.userPayment) {
      getMonthlyPaymentCount(userPayment.userPayment);
    }
  }, [userPayment]);

  console.log(userPayment.userPayment)

  return (
    <Flex
      w='full'
      _dark={{ bg: '#3e3e3e' }}
      py={10}
      alignItems='center'
      justifyContent='center'
    >
      <Stack direction={{ base: 'column', md: 'column', lg: 'row' }} w='full'>
        {/* Table */}
        <Box
          bg={bg}
          p={5}
          shadow='md'
          borderWidth='1px'
          borderRadius='md'
          direction={{ base: 'column' }}
          w={{ base: 'full', md: '3/4' }}
        >
          <chakra.h2 fontSize='xl' mb={5} color='blue.800'>
            Lastest Bill Payments
          </chakra.h2>
          <Stack
            direction={{ base: 'column' }}
            w={{ base: 'full', md: '3/7' }}
            bg={{ md: bg }}
            shadow='lg'
          >
            {userPayment?.userPayment?.map((item, index) => (
              <Flex
                direction={{ base: 'row', md: 'column' }}
                bg={bg2}
                key={index}
              >
                <SimpleGrid
                  spacingY={3}
                  columns={isLargerThanTabletSmallerThanLargerScreen? { base: 1, md: 1 }: { base: 1, md: 4 }}
                  w={{ base: 120, md: 'full' }}
                  textTransform='uppercase'
                  bg={bg3}
                  color={'gray.500'}
                  py={{ base: 1, md: 4 }}
                  px={{ base: 2, md: 10 }}
                  fontSize={{ base: 'sm', md: 'md' }}
                  fontWeight='hairline'
                >
                  <span>Invoice Number</span>
                  <span>Date</span>
                  <span>Time</span>
                  <span> Status</span>
                </SimpleGrid>
                <SimpleGrid
                  spacingY={3}
                  columns={{ base: 1, md: 4 }}
                  w='full'
                  py={2}
                  px={10}
                  fontWeight='hairline'
                >
                  <span>INV00{index + 1}</span>
                  <chakra.span
                    textOverflow='ellipsis'
                    overflow='hidden'
                    whiteSpace='nowrap'
                    pt={{ base: 4, md: 0 }}
                  >
                    {formatDate(item.createdDate)}
                  </chakra.span>
                  <chakra.span
                    textOverflow='ellipsis'
                    overflow='hidden'
                    whiteSpace='nowrap'
                  >
                    {formatTime(item.createdDate)}
                  </chakra.span>
                  <chakra.span
                    textOverflow='ellipsis'
                    overflow='hidden'
                    whiteSpace='nowrap'
                    pl={6}
                  >
                    {/* <StatusIcon statusName={item.paymentType} /> */}
                    {item.paymentType}
                  </chakra.span>
                </SimpleGrid>
              </Flex>
            ))}
            {!userPayment?.userPayment&&
            <Flex h={'full'} justifyItems={'center'} justifyContent={'center'}> No Payments</Flex>
            }

          </Stack>
        </Box>
        {/* Charts */}
        <Stack
          direction={{ base: 'column', md: 'column' }}
          w={{ base: 'full', md: '1/4' }}
          spacing={5}
        >
          <Box bg={bg} p={5} shadow='md' borderWidth='1px' borderRadius='md'>
            <chakra.h2 fontSize='xl' mb={5} color='blue.800'>
              Outstanding Bill Log
            </chakra.h2>
            <Chart
              options={outStandingBillOptions}
              series={outStandingBillSeries}
              type='bar'
              height={200}
            />
          </Box>

          <Box bg={bg} p={5} shadow='md' borderWidth='1px' borderRadius='md'>
            <chakra.h2 fontSize='xl' mb={5} color='blue.800'>
              Payments Made Log
            </chakra.h2>
            <Chart
              options={paymentsMadeLogOptions}
              series={paymentsMadeLog}
              type='area'
              height={200}
            />
          </Box>
        </Stack>
      </Stack>
    </Flex>
  );
};

export default Tables;
