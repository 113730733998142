import React from 'react';
import SelfServiceContainer from "../../components/Containers/SelfServiceContainer";
import WaterShortage from "./Components/WaterShortageGuest";
import utils from "../../utils/commonFunctions";
import { Navigate } from 'react-router-dom';

const  WaterShortageGuest = () => {
  if (utils.isAuthenticated()) {
    // NAVIGATE TO THE REPORT LEAK PAGE
    return <Navigate to={'/self-service-portal/report-water-shortage'}/>
  }

  if (!utils.isAuthenticated()) {
    return (
      <SelfServiceContainer childComponent={<WaterShortage/>}/>
    )
  }
};

export default  WaterShortageGuest;
