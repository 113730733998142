import {
    Input,
    Stack,
    Button,
    FormLabel,
    FormControl,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton, ModalBody,HStack, Text, Switch,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {PropagateLoader} from "react-spinners";
import {CustomGeneralToast} from "../../../utils/customChakraToasts";
import {API} from "../../../utils/api";
import BillAlert from "../../../components/PortalContainer/BillAlert";
import utils from "../../../utils/commonFunctions";
import {useQuoteCart} from "../../../providers/QuoteCartProvider";
const ManageUtilityModal = ({isOpen,onClose}) => {
    const userId=utils.getUserId()
    const [name, setName] = useState(utils.getUser().name);
    const {refreshFlag,setRefreshFlag}=useQuoteCart()
    const [plot, setPlot] = useState("");
    const [number, setNumber] = useState("");
    const [utility, setUtility] = useState("");
    const [loading, setLoading] = useState(false);
    const {addGeneralToast} = CustomGeneralToast();
    const toast = useToast();
    const [checkForOthers, setCheckForOthers] = useState(false);
    useEffect(() => {
        setName(name)
    }, []);

    const addUtility= async () => {
        if (!number||!name||!utility||!plot) {
            toast.closeAll();
            addGeneralToast({
                message: "All fields must be filled in",
                type: "warning",
            });
            setLoading(false);
            return;
        }
        setLoading(true);
        try {
            const response = await API.post(
                `/utility-contracts/`,
                {
                    name:name,
                    customerNumber: number,
                    contractNumber: utility,
                    userId:userId,
                    plot:plot
                }
            );
            const data = await response.data;
            if (response.status === 201) {
                toast.closeAll();
                addGeneralToast({
                    message: data.message,
                    type: "success",
                });
                setLoading(false);
                setNumber("")
                setUtility("")
                setName(utils.getUser().name)
                setPlot("")
                setRefreshFlag(true)
                onClose()
            } else {
                toast.closeAll();
                addGeneralToast({
                    message: data.message,
                    type: "error",
                });
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
            toast.closeAll();
            addGeneralToast({
                message: e?.message || "An unexpected error occurred.",
                type: "error",
            });
        }
    };
    return <>
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody py={10}>
                    <Stack  spacing={1} justify={'center'} w="full" h={"full"} >
                        <BillAlert/>
                        <FormControl isRequired id="customerNumber">
                            <FormLabel>Customer Name</FormLabel>
                           <Input
                                isRequired
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </FormControl>
                        <FormControl isRequired id="customerNumber">
                            <FormLabel>Customer Number</FormLabel>
                           <Input
                                isRequired
                                type="number"
                                value={number}
                                onChange={(e) => setNumber(e.target.value)}
                            />
                        </FormControl>

                        <FormControl isRequired id="utilityNumber">
                            <FormLabel >Utility Number</FormLabel>
                            <Input
                                type="text"
                                value={utility}
                                onChange={(e) => setUtility(e.target.value)}
                            />
                        </FormControl>

                         <FormControl isRequired id="plotNo">
                            <FormLabel >Plot No</FormLabel>
                            <Input
                                type="text"
                                value={plot}
                                onChange={(e) => setPlot(e.target.value)}
                            />
                        </FormControl>

                        <Stack spacing={6} mt={2}>
                            <Button
                                onClick={!loading ?addUtility : undefined}
                            >
                                {!loading ? "Add Utility Contract" : <PropagateLoader size={8} color="white"/>}
                            </Button>
                        </Stack>
                    </Stack>
                </ModalBody>


            </ModalContent>
        </Modal>




    </>;
};

export default ManageUtilityModal;
