import React from "react";
import CheckBillGuest from "../PayBillOnline/Components/CheckBillGuest";
import SelfServiceContainer from "../../components/Containers/SelfServiceContainer";
import utils from "../../utils/commonFunctions";
import { Navigate } from 'react-router-dom';

const ViewAccountBalance = () => {
  if (utils.isAuthenticated()) {
    // NAVIGATE TO THE REPORT LEAK PAGE
    return <Navigate to={'/self-service-portal/pay-bill-online'}/>
  }

  if (!utils.isAuthenticated()) {
    return (
      <SelfServiceContainer childComponent={<CheckBillGuest/>}/>
    )
  }
};

export default ViewAccountBalance;
